// from https://github.com/sindresorhus/array-move
export function arrayMoveMutable<T>(
  array: T[],
  fromIndex: number,
  toIndex: number
): void {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}

export function arrayMoveImmutable<T>(
  array: T[],
  fromIndex: number,
  toIndex: number
): T[] {
  array = [...array];
  arrayMoveMutable(array, fromIndex, toIndex);
  return array;
}

export function getUniqueArray<T>(data: T[] | Set<T>): T[] {
  return [...new Set(data)];
}

export function replaceAt(arr: any[], index: number, newValue: any) {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
}

export function removeAt(arr: any[], index: number) {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

export const makeUnique = <T extends { id: any }>(arr: T[]): T[] => {
  const seenIds = new Set();
  const uniqueList: T[] = [];

  for (const item of arr) {
    if (!seenIds.has(item.id)) {
      seenIds.add(item.id);
      uniqueList.push(item);
    }
  }

  return uniqueList;
};
