import {
  USAGE_TOGAL_KEY,
  USAGE_TOGAL_GPT_KEY,
  USAGE_SHARE_EVENT_KEY,
  USAGE_IMAGE_SEARCH_KEY,
  USAGE_MONTHLY_RENEW_KEY,
} from "src/modules/Settings/const";

import { ReactComponent as TogalIcon } from "src/svg/settings/togal.svg";
import { ReactComponent as GPTUsageIcon } from "src/svg/settings/gpt_count.svg";
import { ReactComponent as TogalUsageIcon } from "src/svg/settings/togal_count.svg";
import { ReactComponent as ShareeUsageIcon } from "src/svg/settings/users_count.svg";
import { ReactComponent as SearchUsageIcon } from "src/svg/settings/search_count.svg";

export const getUsageMessages = (userUsage: any, locales: any) => {
  const togalUsage = userUsage[USAGE_TOGAL_KEY];
  const gptUsage = userUsage[USAGE_TOGAL_GPT_KEY];
  const imageSearchUsage = userUsage[USAGE_IMAGE_SEARCH_KEY];
  const shareEventUsage = userUsage[USAGE_SHARE_EVENT_KEY];

  return {
    [USAGE_TOGAL_KEY]: {
      icon: TogalUsageIcon,
      id: locales.USAGE_TOGAL_ID,
      title:
        togalUsage?.current === togalUsage?.max
          ? locales.USAGE_TOGAL_TITLE_EMPTY
          : togalUsage?.current === 0
          ? locales.USAGE_TOGAL_TITLE_FIRST
          : locales.USAGE_TOGAL_TITLE_MID,
      message:
        togalUsage?.current === togalUsage?.max
          ? locales.USAGE_TOGAL_MESSAGE_EMPTY(togalUsage?.max)
          : togalUsage?.current === 0
          ? locales.USAGE_TOGAL_MESSAGE_FIRST(togalUsage?.max)
          : locales.USAGE_TOGAL_MESSAGE_MID(
              togalUsage?.max,
              togalUsage?.current
            ),
    },
    [USAGE_TOGAL_GPT_KEY]: {
      icon: GPTUsageIcon,
      id: locales.USAGE_GPT_ID,
      title:
        gptUsage?.current === gptUsage?.max
          ? locales.USAGE_GPT_TITLE_EMPTY
          : gptUsage?.current === 0
          ? locales.USAGE_GPT_TITLE_FIRST
          : locales.USAGE_GPT_TITLE_MID,

      message:
        gptUsage?.current === gptUsage?.max
          ? locales.USAGE_GPT_MESSAGE_EMPTY(gptUsage?.max)
          : gptUsage?.current === 0
          ? locales.USAGE_GPT_MESSAGE_FIRST(gptUsage?.max)
          : locales.USAGE_GPT_MESSAGE_MID(gptUsage?.max, gptUsage?.max),
    },
    [USAGE_IMAGE_SEARCH_KEY]: {
      icon: SearchUsageIcon,
      id: locales.USAGE_SEARCHES_ID,
      title:
        imageSearchUsage?.current === imageSearchUsage?.max
          ? locales.USAGE_SEARCHES_TITLE_EMPTY
          : imageSearchUsage?.current === 0
          ? locales.USAGE_SEARCHES_TITLE_FIRST
          : locales.USAGE_SEARCHES_TITLE_MID,
      message:
        imageSearchUsage?.current === imageSearchUsage?.max
          ? locales.USAGE_SEARCHES_MESSAGE_EMPTY(imageSearchUsage?.max)
          : imageSearchUsage?.current === 0
          ? locales.USAGE_SEARCHES_MESSAGE_FIRST(imageSearchUsage?.max)
          : locales.USAGE_SEARCHES_MESSAGE_MID(
              imageSearchUsage?.max,
              imageSearchUsage?.current
            ),
    },
    [USAGE_SHARE_EVENT_KEY]: {
      icon: ShareeUsageIcon,
      id: locales.USAGE_INVITES_ID,
      title:
        shareEventUsage?.current === shareEventUsage?.max
          ? locales.USAGE_INVITES_TITLE_EMPTY
          : shareEventUsage?.current === 0
          ? locales.USAGE_INVITES_TITLE_FIRST
          : locales.USAGE_INVITES_TITLE_MID,
      message:
        shareEventUsage?.current === shareEventUsage?.max
          ? locales.USAGE_INVITES_MESSAGE_EMPTY(shareEventUsage?.max)
          : shareEventUsage?.current === 0
          ? locales.USAGE_INVITES_MESSAGE_FIRST(shareEventUsage?.max)
          : locales.USAGE_INVITES_MESSAGE_MID(
              shareEventUsage?.max,
              shareEventUsage?.current
            ),
    },
    [USAGE_MONTHLY_RENEW_KEY]: {
      icon: TogalIcon,
      id: "",
      title: locales.USAGE_RENEW_TITLE,
      message: locales.USAGE_RENEW_MESSAGES(
        togalUsage,
        gptUsage,
        imageSearchUsage,
        shareEventUsage
      ),
    },
  };
};
