/* eslint-env browser */
// from "NEBULA.GL"

import { Position } from "geojson";
import { CANVAS } from "../../consts/events";
import { CompositeLayer } from "@deck.gl/core";

const EVENT_TYPES = [
  "anyclick",
  "pointermove",
  "panstart",
  "panmove",
  "panend",
  "keyup",
  "keydown",
];

const WINDOW_EVENT_TYPES = [
  CANVAS.MODE_CHANGE_BUTTON,
  CANVAS.INTERACTIVE_MODE_CHANGE_BUTTON,
];

export default class EditableLayer extends CompositeLayer<any, any> {
  // export default class EditableLayer extends CompositeLayer<any> {
  static layerName = "EditableLayer";

  constructor(props: any) {
    super(props);

    this._onkeyup.bind(this);
    this._onkeydown.bind(this);
    this._onanyclick.bind(this);
    this.initializeState.bind(this);
    this._addEventHandlers.bind(this);
    this._removeEventHandlers.bind(this);
    this._forwardEventToCurrentLayer.bind(this);
  }

  // Overridable interaction event handlers
  onLayerClick(event: any) {
    // default implementation - do nothing
  }

  // Overridable interaction event handlers
  onLayerDblClick(event: any) {
    // default implementation - do nothing
  }

  onStartDragging(event: any) {
    // default implementation - do nothing
  }

  onStopDragging(event: any) {
    // default implementation - do nothing
  }

  onDragging(event: any) {
    // default implementation - do nothing
  }

  onPointerMove(event: any) {
    // default implementation - do nothing
  }

  onLayerKeyUp(event: any) {
    // default implementation - do nothing;
  }

  onLayerKeyDown(event: any) {
    // default implementation - do nothing;
  }

  onModeChange(event: any) {
    // default implementation - do nothing;
  }

  onInteractiveModeChange(event: any) {
    // default implementation - do nothing;
  }
  // TODO: implement onCancelDragging (e.g. drag off screen)

  initializeState() {
    this.setState({
      _editableLayerState: {
        // Picked objects at the time the pointer went down
        pointerDownPicks: null,
        // Screen coordinates where the pointer went down
        pointerDownScreenCoords: null,
        // Ground coordinates where the pointer went down
        pointerDownMapCoords: null,

        // Keep track of the mjolnir.js event handler so it can be deregistered
        eventHandler: this._forwardEventToCurrentLayer.bind(this),
      },
    });

    this._addEventHandlers();
  }

  finalizeState() {
    this._removeEventHandlers();
  }

  _addEventHandlers() {
    // @ts-ignore
    const { eventManager } = this.context.deck;
    const { eventHandler } = this.state._editableLayerState;

    for (const eventType of EVENT_TYPES) {
      eventManager.on(eventType, eventHandler, {
        // give nebula a higher priority so that it can stop propagation to deck.gl's map panning handlers
        priority: 100,
      });
    }

    for (const eventType of WINDOW_EVENT_TYPES) {
      window.addEventListener(eventType, eventHandler);
    }
  }

  _removeEventHandlers() {
    // @ts-ignore
    const { eventManager } = this.context.deck;
    const { eventHandler } = this.state._editableLayerState;

    for (const eventType of EVENT_TYPES) {
      eventManager.off(eventType, eventHandler);
    }

    for (const eventType of WINDOW_EVENT_TYPES) {
      window.removeEventListener(eventType, eventHandler);
    }
  }

  // A new layer instance is created on every render, so forward the event to the current layer
  // This means that the first layer instance will stick around to be the event listener, but will forward the event
  // to the latest layer instance.
  private _forwardEventToCurrentLayer(event: any) {
    const currentLayer = this.getCurrentLayer();

    // Use a naming convention to find the event handling function for this event type
    const func = currentLayer[`_on${event.type}`].bind(currentLayer);
    if (!func) {
      console.warn(`no handler for mjolnir.js event ${event.type}`); // eslint-disable-line
      return;
    }
    func(event);
  }

  private _onanyclick({ srcEvent, tapCount }: any) {
    const screenCoords = this.getScreenCoords(srcEvent);
    const mapCoords = this.getMapCoords(screenCoords);
    // @ts-ignore
    const picks = this.getPicks(screenCoords);

    if (tapCount === 2) {
      this.onLayerDblClick({
        mapCoords,
        screenCoords,
        picks,
        sourceEvent: srcEvent,
      });
    } else {
      this.onLayerClick({
        mapCoords,
        screenCoords,
        picks,
        sourceEvent: srcEvent,
      });
    }
  }

  private _onkeyup({ srcEvent }: any) {
    this.onLayerKeyUp(srcEvent);
  }

  private _onkeydown({ srcEvent }: any) {
    this.onLayerKeyDown(srcEvent);
  }

  private _onmodechange(srcEvent: any) {
    this.onModeChange(srcEvent);
  }

  private _oninteractivemodechange(srcEvent: any) {
    this.onInteractiveModeChange(srcEvent);
  }

  private _onpanstart(event: any) {
    const screenCoords = this.getScreenCoords(event.srcEvent);
    const mapCoords = this.getMapCoords(screenCoords);
    // @ts-ignore
    const picks = this.getPicks(screenCoords);

    this.setState({
      _editableLayerState: {
        ...this.state._editableLayerState,
        pointerDownScreenCoords: screenCoords,
        pointerDownMapCoords: mapCoords,
        pointerDownPicks: picks,
      },
    });

    this.onStartDragging({
      picks,
      // @ts-ignore
      screenCoords,
      // @ts-ignore
      mapCoords,
      // @ts-ignore
      pointerDownScreenCoords: screenCoords,
      pointerDownMapCoords: mapCoords,
      cancelPan: event.stopImmediatePropagation,
      sourceEvent: event.srcEvent,
    });
  }

  private _onpanmove(event: any) {
    const { srcEvent } = event;

    const screenCoords = this.getScreenCoords(srcEvent);
    const mapCoords = this.getMapCoords(screenCoords);

    const { pointerDownPicks, pointerDownScreenCoords, pointerDownMapCoords } =
      this.state._editableLayerState;
    // @ts-ignore
    const picks = this.getPicks(screenCoords);

    this.onDragging({
      // @ts-ignore
      screenCoords,
      mapCoords,
      picks,
      pointerDownPicks,
      pointerDownScreenCoords,
      pointerDownMapCoords,
      sourceEvent: srcEvent,
      cancelPan: event.stopImmediatePropagation,
      // another (hacky) approach for cancelling map panning
      // const controller = this.context.deck.viewManager.controllers[
      //   Object.keys(this.context.deck.viewManager.controllers)[0]
      // ];
      // controller._state.isDragging = false;
    });
  }

  private _onpanend({ srcEvent }: any) {
    const screenCoords = this.getScreenCoords(srcEvent);
    const mapCoords = this.getMapCoords(screenCoords);

    const { pointerDownPicks, pointerDownScreenCoords, pointerDownMapCoords } =
      this.state._editableLayerState;
    // @ts-ignore
    const picks = this.getPicks(screenCoords);

    this.onStopDragging({
      picks,
      // @ts-ignore
      screenCoords,
      mapCoords,
      pointerDownPicks,
      pointerDownScreenCoords,
      pointerDownMapCoords,
      sourceEvent: srcEvent,
    });

    this.setState({
      _editableLayerState: {
        ...this.state._editableLayerState,
        pointerDownScreenCoords: null,
        pointerDownMapCoords: null,
        pointerDownPicks: null,
      },
    });
  }

  private _onpointermove(event: any) {
    const { srcEvent } = event;
    const screenCoords = this.getScreenCoords(srcEvent);
    const mapCoords = this.getMapCoords(screenCoords);

    const { pointerDownPicks, pointerDownScreenCoords, pointerDownMapCoords } =
      this.state._editableLayerState;
    // @ts-ignore
    const picks = this.getPicks(screenCoords);

    this.onPointerMove({
      // @ts-ignore
      screenCoords,
      mapCoords,
      picks,
      pointerDownPicks,
      pointerDownScreenCoords,
      pointerDownMapCoords,
      sourceEvent: srcEvent,
    });
  }

  getPicks(screenCoords: any) {
    const extraLayers = (this.props as any).modeConfig.extraLayers || [];

    // @ts-ignore
    return this?.context?.deck?.pickMultipleObjects({
      x: screenCoords[0],
      y: screenCoords[1],
      layerIds: [this.props.id, ...extraLayers],
      radius: (this.props as any).pickingRadius,
      depth: (this.props as any).pickingDepth,
    });
  }

  getScreenCoords(pointerEvent: any) {
    const canvas: any = this.context.gl.canvas;

    return [
      pointerEvent.clientX - canvas.getBoundingClientRect().left,
      pointerEvent.clientY - canvas.getBoundingClientRect().top,
    ];
  }

  getMapCoords(screenCoords: Position) {
    const coordinates = this.context.viewport.unproject([
      screenCoords[0] || 0,
      screenCoords[1] || 0,
      Infinity,
    ]);

    return [coordinates[0], coordinates[1]];
  }
}
