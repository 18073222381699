import React, { memo } from "react";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate } from "react-router";

import { COMMON_LOCALES } from "sf/i18n";
import withPortal from "sf/hoc/withPortal";
import { Button } from "sf/components/Button";
import { CSS, FONT_WEIGHTS } from "sf/consts/style";
import { compose } from "sf/utils/functional/function";
import { Flex, Para, Separator } from "sf/components/Styled";

import { getUsageMessages } from "./utils";
import * as S from "src/components/Style/modal";
import { useAuth } from "src/modules/Auth/context";
import {
  usageModalState,
  DEFAULT_USAGE_MODAL_STATE,
} from "src/state/dashboardStore";
import { USAGE_MONTHLY_RENEW_KEY } from "src/modules/Settings/const";

function UsageModal() {
  const navigate = useNavigate();
  const location = useLocation();
  const { userUsage, locales } = (useAuth as any)();
  const [modalState, set] = useRecoilState(usageModalState);

  const commonLocales = locales[COMMON_LOCALES];

  const usage = userUsage[modalState?.key];
  const usageMessages = getUsageMessages(userUsage, commonLocales);
  const currentMessage = usageMessages[modalState?.key];

  const isRenew = modalState?.key === USAGE_MONTHLY_RENEW_KEY;

  function handleClear() {
    set(DEFAULT_USAGE_MODAL_STATE);
  }

  function handleConfirm() {
    modalState?.onConfirm?.();
    handleClear();
  }

  function navigateToUsageTracking() {
    navigate("/dashboard/admin?tab=usage", {
      state: { from: location },
      replace: true,
    });
    handleClear();
  }

  function navigateToUpgradeSubscription() {
    navigate("/dashboard/admin?tab=billing&subscription", {
      state: { from: location },
      replace: true,
    });
    handleClear();
  }

  if (!modalState?.key) return null;

  return (
    <S.Container width="100vw" height="100vh" bgColor={CSS.BLACK + "30"}>
      <S.CancelContainer />

      <S.AbsContainer flex="column" height="auto" width={CSS.MODAL_M}>
        <S.ItemsContainer
          flex="column"
          bgColor={CSS.WHITE}
          style={{
            padding: "20px",
          }}
        >
          <Flex flex="column">
            <Separator height="20px" />
            <currentMessage.icon
              style={{
                width: "48px",
                height: "48px",
              }}
            />
            <Separator height="20px" />
            <Para
              fontColor={CSS.BLACK}
              fontSize={CSS.FONT_EXBIG2}
              fontWeight={FONT_WEIGHTS.MEDIUM}
            >
              {currentMessage.title}
            </Para>
            <Separator height="20px" />
            {isRenew ? (
              <>
                {currentMessage.message.map(
                  (message: string, index: number) => (
                    <Para
                      key={index}
                      fontColor={CSS.BLACK}
                      style={{
                        marginTop:
                          index === currentMessage.message.length - 1
                            ? "20px"
                            : "0px",
                        textAlign: "center",
                        lineHeight: "1.5",
                      }}
                    >
                      {message}
                    </Para>
                  )
                )}
              </>
            ) : (
              <Flex width="calc(100% - 80px)">
                <Para
                  fontColor={CSS.BLACK}
                  style={{
                    textAlign: "center",
                    lineHeight: "1.5",
                  }}
                >
                  {currentMessage.message}{" "}
                  {usage?.current === 0 ? (
                    <>
                      {commonLocales.USAGE_COMMON_START_TEXT_1}{" "}
                      <span
                        onClick={navigateToUsageTracking}
                        style={{
                          cursor: "pointer",
                          color: CSS.MAINCOLOR,
                        }}
                      >
                        {commonLocales.USAGE_COMMON_START_TEXT_2}
                      </span>{" "}
                    </>
                  ) : (
                    <>
                      <span
                        onClick={navigateToUpgradeSubscription}
                        style={{
                          cursor: "pointer",
                          color: CSS.MAINCOLOR,
                        }}
                      >
                        {commonLocales.USAGE_COMMON_END_TEXT_1}
                      </span>{" "}
                      {commonLocales.USAGE_COMMON_END_TEXT_2}{" "}
                      {currentMessage.id}
                    </>
                  )}
                </Para>
              </Flex>
            )}

            <Separator height="40px" />

            <Button
              id="togal_usage_id"
              fontColor={CSS.WHITE}
              onClick={handleConfirm}
              bgColor={CSS.MAINCOLOR}
              borderColor={CSS.MAINCOLOR}
              value={commonLocales.USAGE_BUTTON_TITLE}
              style={{ width: "calc(50% - 25px)" }}
            />

            {isRenew && (
              <>
                <Separator height="10px" />
                <span
                  onClick={navigateToUpgradeSubscription}
                  style={{
                    cursor: "pointer",
                    color: CSS.MAINCOLOR,
                  }}
                >
                  {commonLocales.USAGE_COMMON_END_TEXT_1}
                </span>{" "}
              </>
            )}
            <Separator height="20px" />
          </Flex>
        </S.ItemsContainer>
      </S.AbsContainer>
    </S.Container>
  );
}

export default compose(memo, withPortal)(UsageModal);
