import fetch from "unfetch";
import qs from "qs";
import urlJoin from "url-join";

import { ERRORS_LOCALES } from "sf/i18n";

import config from "src/config";
import { getSessionId, getUserLocale } from "src/modules/Auth/context";

["get", "post", "put", "patch", "head", "delete"].forEach((method) => {
  request[method] = function runMethodRequest(path, options = {}) {
    return request(path, { ...options, method: method.toUpperCase() });
  };
});

// Shortcut for debugging
window.apiRequest = request;

export default request;

async function request(path, options = {}) {
  options = {
    ...options,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      session: getSessionId(),
      ...options.headers,
    },
  };

  if (
    options.body &&
    typeof options.body !== "string" &&
    options.headers["Content-Type"] === "application/json"
  ) {
    options.body = JSON.stringify(options.body);
  }

  if (!/^https?/.test(path)) {
    path = urlJoin(config.api.base, path);
  }

  if (options.query) {
    const query = { ...options.query };
    if (Array.isArray(query.$order)) {
      // qs formatter doesn't like the order format...
      const [by, direction] = query.$order;
      query.$order = `[["${by}","${direction.toUpperCase()}"]]`;
    }
    if (query.$where) {
      query.$where = JSON.stringify(options.query.$where);
    }
    if (query.$attributes) {
      query.$attributes = JSON.stringify(query.$attributes);
    }
    path = `${path}?${qs.stringify(query)}`;
  }

  const res = await fetch(path, options);
  try {
    if (
      res.headers.get("Content-Type").toLowerCase().includes("application/json")
    ) {
      res.body = await res.json();
    } else {
      res.body = await res.text();
    }
  } catch (e) {
    console.error(e);
  }

  if (!res.ok) {
    const errorsLocales = getUserLocale()[ERRORS_LOCALES];

    const errorMessage =
      res.body?.error?.message || res.body?.error?.errorCode
        ? errorsLocales[res.body?.error?.errorCode]
        : errorsLocales[4001];

    const error = new Error(errorMessage);
    Object.assign(error, res);
    throw error;
  }
  return res;
}
