import styled from "styled-components";
import { ReactComponent as Search } from "../../svg/editor/search.svg";
import { IStyles } from "../Styled";
import { KeyboardEventHandler } from "react";

interface StyledInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
}

export const InputContainer = styled.form`
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

export const StyledInput = styled.input<IStyles & StyledInputProps>`
  background-color: transparent;
  border: ${(props) => props.border ?? "0"};
  font-size: 13px;
  font-weight: 300;
  height: ${({ height }) => height ?? "25px"};
  margin: 0;
  max-width: 100%;
  padding: ${({ padding }) => padding ?? "0"};
  text-align: ${(props) => props.textAlign};
  width: ${({ width }) => width ?? "90%"};
  text-overflow: ellipsis;

  :focus {
    border: ${(props) => props.border ?? "none"};
    outline: none;
  }
`;

export const SearchIcon = styled(Search)`
  color: black;
  height: 25px;
  width: 25px;
`;
