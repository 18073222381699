import React from "react";

import Icon from "../components/Icon";
import { PIPELINE_ICONS_IDS } from "../components/Icon/pipeline_icons";

export const PIPELINE_MODE_TOOLS = {
  2: "Ø 2",
  3: "Ø 3",
  4: "Ø 4",
  5: "Ø 5",
  6: "Ø 6",
  100: "Edit",
  20: "Commode",
  21: "Other fixture",
  22: "Exit drain",
};

export const PIPELINE_TOOLS = [
  {
    id: 1,
    width: 60,
    showIcon: false,
    title: PIPELINE_MODE_TOOLS[2],
    value: PIPELINE_MODE_TOOLS[2],
    icon: () => (
      <Icon
        id={PIPELINE_ICONS_IDS.pipeline}
        style={{
          transform: "rotate(90deg)",
        }}
      />
    ),
  },
  {
    id: 2,
    width: 60,
    showIcon: false,
    title: PIPELINE_MODE_TOOLS[3],
    value: PIPELINE_MODE_TOOLS[3],
    icon: () => (
      <Icon
        id={PIPELINE_ICONS_IDS.pipeline}
        style={{
          transform: "rotate(90deg)",
        }}
      />
    ),
  },
  {
    id: 3,
    width: 60,
    showIcon: false,
    title: PIPELINE_MODE_TOOLS[4],
    value: PIPELINE_MODE_TOOLS[4],
    icon: () => (
      <Icon
        id={PIPELINE_ICONS_IDS.pipeline}
        style={{
          transform: "rotate(90deg)",
        }}
      />
    ),
  },
  {
    id: 4,
    width: 60,
    showIcon: false,
    title: PIPELINE_MODE_TOOLS[5],
    value: PIPELINE_MODE_TOOLS[5],
    icon: () => (
      <Icon
        id={PIPELINE_ICONS_IDS.pipeline}
        style={{
          transform: "rotate(90deg)",
        }}
      />
    ),
  },
  {
    id: 5,
    width: 60,
    showIcon: false,
    title: PIPELINE_MODE_TOOLS[6],
    value: PIPELINE_MODE_TOOLS[6],
    icon: () => (
      <Icon
        id={PIPELINE_ICONS_IDS.pipeline}
        style={{
          transform: "rotate(90deg)",
        }}
      />
    ),
  },
];

export const DRAIN_TOOLS = [
  {
    id: 8,
    width: 130,
    showIcon: false,
    title: PIPELINE_MODE_TOOLS[20],
    value: PIPELINE_MODE_TOOLS[20],
    icon: () => <Icon id={PIPELINE_ICONS_IDS.drain} />,
  },
  {
    id: 9,
    width: 130,
    showIcon: false,
    title: PIPELINE_MODE_TOOLS[21],
    value: PIPELINE_MODE_TOOLS[21],
    icon: () => <Icon id={PIPELINE_ICONS_IDS.drain} />,
  },
  {
    id: 10,
    width: 130,
    showIcon: false,
    title: PIPELINE_MODE_TOOLS[22],
    value: PIPELINE_MODE_TOOLS[22],
    icon: () => <Icon id={PIPELINE_ICONS_IDS.drain} />,
  },
];

export const EDIT_TOOLS = [
  {
    id: 11,
    showIcon: false,
    title: PIPELINE_MODE_TOOLS[100],
    value: PIPELINE_MODE_TOOLS[100],
    icon: () => (
      <Icon
        id={PIPELINE_ICONS_IDS.edit}
        style={{
          width: "22px",
          height: "22px",
        }}
      />
    ),
  },
];

export const ALL_PIPELINE_TOOLS = [PIPELINE_TOOLS, DRAIN_TOOLS, EDIT_TOOLS];
