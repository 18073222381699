export const idKey = "id";

export const setKey = "set_";
export const pageKey = "page_";
export const viewKey = "view_";
export const projectKey = "project_";
export const organizationKey = "org_";

export const defaultIdsMap = new Map([
  [setKey, null],
  [pageKey, null],
  [viewKey, null],
  [projectKey, null],
  [organizationKey, null],
]);

export const MAX_PARALLEL_QUERIES = 10;
export const DEFAULT_PAGINATION_LIMIT = 25;
export const DEFAULT_SKIP_SYNC_MINUTES = 5;

export const VIEWS_ATTRIBUTES = [
  "id",

  "filters",
  "geojson",

  "thumbnail_signed",
  "comparison_page_id",
  "comparison_translations",

  "name",
  "state",
  "set_id",
  "rating",
  "message",
  "user_id",
  "page_id",
  "metadata",
  "project_id",
  "organization_id",
];

export const PAGES_ATTRIBUTES = [
  "id",
  "url",
  "prefix",
  "active",
  "name",
  "index",
  "state",
  "url_dpi",
  "url_width",
  "url_height",
  "status",
  "created",
  "updated",
  "metadata",
  "thumbnail",
  "scale_type",
  "scale_real",
  "scale_units",
  "geojson_text",
  "scale_drawing",

  "text_ready",

  "set_id",
  "user_id",
  "project_id",
  "organization_id",

  "geojson_symbols",

  "view.id",
  "view.name",
  "view.filters",
  "view.updated",
  "view.metadata",
  "view.thumbnail",

  "original_mime",
  "page_folder_id",
  "predicted_scale_real",
  "predicted_scale_type",
  "predicted_scale_units",
  "predicted_scale_drawing",
];

export const PROJECT_ATTRIBUTES = [
  "id",
  "pid",
  "name",
  "color",
  "user_id",
  "scale_units",
  "organization_id",

  "user.id",
  "user.name",
  "user.email",

  "active",
  "updated",
  "created",

  "permissions",
];

const PROJECT_NAME_INDEX = "name";
const PROJECT_USERS_NAME_INDEX = "users[]:name";
const PROJECT_USERS_EMAIL_INDEX = "users[]:email";

export const PROJECTS_SEARCH_INDEX = [
  PROJECT_NAME_INDEX,
  PROJECT_USERS_NAME_INDEX,
  PROJECT_USERS_EMAIL_INDEX,
];

export const PROJECT_INDEX_MAP = {
  name: PROJECT_NAME_INDEX,
  un: PROJECT_USERS_NAME_INDEX,
  username: PROJECT_USERS_NAME_INDEX,
  ue: PROJECT_USERS_NAME_INDEX,
  useremail: PROJECT_USERS_NAME_INDEX,
};

const PAGE_NAME_INDEX = "pageName";
const PAGE_NUMBER_INDEX = "pageNumber";
const PAGE_VIEWS_NAMES = "views[]:name";

export const PAGE_SEARCH_INDEX = [
  PAGE_NAME_INDEX,
  PAGE_NUMBER_INDEX,
  PAGE_VIEWS_NAMES,
];

export const PAGES_INDEX_MAP = {
  n: PAGE_NAME_INDEX,
  name: PAGE_NAME_INDEX,
  nb: PAGE_NUMBER_INDEX,
  number: PAGE_NUMBER_INDEX,
  v: PAGE_VIEWS_NAMES,
  view: PAGE_VIEWS_NAMES,
};

const PAGE_TEXT_VALUE_INDEX = "text";

export const PAGE_TEXT_SEARCH_INDEX = [PAGE_TEXT_VALUE_INDEX];

/**
 *
 * FIELDS TO KEEP IN MEMORY for optimization
 *
 */
export const SET_DELETE_FIELDS = [];
export const PROJECT_DELETE_FIELDS = [];
export const ORG_DELETE_FIELDS = ["accessed", "expires", "payment_data"];

export const PAGE_DELETE_FIELDS = [
  "geojson",
  "geojson_text",
  "geojson_symbols",
  "geojson_text_cleaned",
];
export const VIEW_DELETE_FIELDS = [];

export const defaultSearchPropsMap = new Map([
  [setKey, null],
  [viewKey, null],
  [organizationKey, null],
  [
    pageKey,
    {
      index: PAGE_SEARCH_INDEX,
    },
  ],
  [
    projectKey,
    {
      index: PROJECTS_SEARCH_INDEX,
    },
  ],
]);
export const defaultFilterKeysMap = new Map([
  [viewKey, "set_id"],
  [pageKey, "set_id"],
  [setKey, "project_id"],
  [organizationKey, null],
  [projectKey, "organization_id"],
]);
