import React, { CSSProperties, memo } from "react";
import { CSS } from "../../consts/style";
import * as S from "./styles";

interface IProps {
  color: CSSProperties["color"];
}

const AreaIcon = ({ color = CSS.BLACK }: IProps) => {
  return (
    <S.Container>
      <S.Area color={color} />
    </S.Container>
  );
};

export default memo(AreaIcon);
