import React from "react";
import ReactDom from "react-dom";

import "animate.css";
import "./global.css";
import "regenerator-runtime/runtime";

import App from "./modules/App";
import { Buffer } from "buffer";
import * as api from "./lib/api";
import * as Sentry from "@sentry/react";
import { initAmplitude, initSegment } from "./lib/tracking";
import {
  matchRoutes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
} from "react-router";

// Shortcut for debugging
window.api = api;
// HACK: Vite needs this polyfill
if (typeof window.global === "undefined") {
  window.global = window;
}
window.Buffer = Buffer;

initAmplitude();
initSegment();

//Init Sentry
if (import.meta.env.VITE_TGL_ENV !== "local") {
  Sentry.init({
    dsn: "https://39edb4b623de4e78b362e37e0ae110ed@o1208516.ingest.sentry.io/6341766",
    integrations: [
      Sentry.browserTracingIntegration &&
        Sentry.browserTracingIntegration({
          enableInp: true,
        }),
      !!Sentry.BrowserTracing &&
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
        }),
      !!Sentry.Replay &&
        new Sentry.Replay({
          // Additional SDK configuration goes in here, for example:
          maskAllText: false,
          blockAllMedia: false,
        }),
      !!Sentry.BrowserTracing && new Sentry.BrowserTracing(),
      !!Sentry.BrowserProfilingIntegration &&
        new Sentry.BrowserProfilingIntegration(),
    ].filter(Boolean),

    tracesSampleRate: 0.01,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.VITE_TGL_ENV,
  });
}

ReactDom.render(<App />, document.getElementById("root"));
