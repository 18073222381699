import { AUTH_MESSAGES } from "./auth";
import { ERROR_MESSAGES } from "./errors";
import { EDITOR_MESSAGES } from "./editor";
import { COMMON_MESSAGES } from "./common";
import { LAYOUT_MESSAGES } from "./layout";
import { SETTINGS_MESSAGES } from "./settings";
import { PROJECTS_MESSAGES } from "./projects";

/**
 *
 *
 *  LOCALES CONSTS
 *
 *
 */

// Supported locales
export const ENGLISH = "en-US";
export const SUPPORTED_LOCALES = new Set([ENGLISH]);

// Subgroups
export const AUTH_LOCALES = "auth";
export const ERRORS_LOCALES = "errors";
export const EDITOR_LOCALES = "editor";
export const COMMON_LOCALES = "common";
export const LAYOUT_LOCALES = "layout";
export const PROJECT_LOCALES = "project";
export const SETTINGS_LOCALES = "settings";

export const SUBGROUPS = new Set([
  AUTH_LOCALES,
  COMMON_LOCALES,
  ERRORS_LOCALES,
  EDITOR_LOCALES,
  LAYOUT_LOCALES,
  PROJECT_LOCALES,
  SETTINGS_LOCALES,
]);

/**
 *
 *
 *  LOCALES
 *
 *
 */

export const IM: any = {
  [ENGLISH]: {
    [COMMON_LOCALES]: COMMON_MESSAGES,

    [AUTH_LOCALES]: AUTH_MESSAGES,
    [ERRORS_LOCALES]: ERROR_MESSAGES,
    [EDITOR_LOCALES]: EDITOR_MESSAGES,
    [LAYOUT_LOCALES]: LAYOUT_MESSAGES,
    [PROJECT_LOCALES]: PROJECTS_MESSAGES,
    [SETTINGS_LOCALES]: SETTINGS_MESSAGES,
  },
};

/**
 *
 *
 *  LOCALES UTILS
 *
 *
 */

export function getLocale() {
  if (typeof navigator === "undefined") return "en-US";
  if (navigator.languages !== undefined) return navigator.languages[0];
  return navigator.language;
}

const getLocaleMessages = (userLocale?: string | null) => {
  const locale = userLocale || getLocale();
  if (SUPPORTED_LOCALES.has(locale)) {
    return IM[locale];
  }

  return IM[ENGLISH];
};

export default getLocaleMessages;
