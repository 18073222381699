import React, { memo, useRef } from "react";
import styled from "styled-components";

import { CSS } from "sf/consts/style";
import { noop } from "sf/utils/function";
import { Flex } from "sf/components/Styled";
import { addOpacity } from "sf/utils/style";
import useOnClickOutside from "sf/hooks/useOnClickOutside";

const DEFAULT_MODAL_WIDTH = 250;
const DEFAULT_Z_INDEX = 1000;

const Modal = ({
  onBackdropClick = noop,
  children,
  maxWidth,
  title,
  buttons,
  zIndex,
  topOffset = 0,
  leftOffset = 0,
  rightOffset = 0,
}) => {
  const modalRef = useRef(null);

  useOnClickOutside([modalRef], onBackdropClick);

  return (
    <Backdrop
      topOffset={topOffset}
      leftOffset={leftOffset}
      rightOffset={rightOffset}
    >
      <Container maxWidth={maxWidth} zIndex={zIndex} ref={modalRef}>
        <TextContainer>
          <Title>{title}</Title>
          {children}
        </TextContainer>
        {buttons && <Buttons>{buttons}</Buttons>}
      </Container>
    </Backdrop>
  );
};

export default memo(Modal);

const Backdrop = styled(Flex)`
  height: ${({ topOffset }) => `calc(100vh - ${topOffset}px)`};
  width: ${({ leftOffset, rightOffset }) =>
    `calc(100vw - ${leftOffset + rightOffset}px)`};
  left: ${({ leftOffset }) => `${leftOffset}px`};
  top: ${({ topOffset }) => `${topOffset}px`};
  background-color: ${addOpacity(CSS.BLACK, 50)};
  position: fixed;
  z-index: ${({ zIndex }) => zIndex || DEFAULT_Z_INDEX};
`;

const Buttons = styled(Flex)`
  background-color: ${CSS.GRAY2};
  padding: 16px;
`;

const Container = styled(Flex)`
  border-radius: ${CSS.BORDER_RADIUS};
  flex-direction: column;
  height: auto;
  max-width: ${({ maxWidth }) => maxWidth || DEFAULT_MODAL_WIDTH}px;
  overflow: hidden;
  width: 100%;
`;

const TextContainer = styled(Flex)`
  align-items: flex-start;
  background-color: ${CSS.BACKGROUND};
  flex-direction: column;
  padding: 20px;
`;

const Title = styled.h3`
  font-weight: bold;
  font-size: 20px;
  margin: 0 0 10px;
  text-align: left;
  width: 100%;
`;
