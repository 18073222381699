import { useSetRecoilState } from "recoil";

import { useDataHook } from "./commonDataHook";
import { projectKey, PROJECT_ATTRIBUTES } from "../const";
import {
  STORE_RESET,
  projectsCRUDStore,
  projectsErrorStore,
  projectsLoadingStore,
} from "src/state/dataStore";

export function useProjectsData(pendingData: React.MutableRefObject<any[]>) {
  /** Projects Store */
  const setProjectsCRUD = useSetRecoilState(projectsCRUDStore);
  const setProjectsError = useSetRecoilState(projectsErrorStore);
  const setProjectsLoading = useSetRecoilState(projectsLoadingStore);
  /** Projects Store */

  async function resetData() {
    setProjectsCRUD({
      items: [],
      action: STORE_RESET,
    });
  }

  const {
    getData: getProjects,
    syncData: syncProjects,
    clearData: clearProjects,
    syncSingleItem: syncProject,
  } = useDataHook({
    pendingData,
    useIdSync: true,
    dataKey: projectKey,
    getApiProps: (id: string) => ({
      $attributes: PROJECT_ATTRIBUTES,
      $where: { organization_id: id },
    }),
    getLocalApiProps: (id: string) => ({
      organization_id: id,
    }),
    store: {
      resetData,
      setDataError: setProjectsError,
      setDataIsLoading: setProjectsLoading,
      getItemValue: (project: any) => ({
        id: project.id,
        organization_id: project?.organization_id,
      }),
    },
  });

  return {
    getProjects,
    syncProject,
    syncProjects,
    clearProjects,
  };
}
