import { curry, pipe } from "./function";
import { equals, prop } from "./object";
import { not } from "./logic";

function uniqueFromEntries<T>(entries: [string, T][]): T[] {
  const map = new Map(entries);
  return Array.from(map).map(([, value]) => value);
}

export const comprehension = <T, K, V>(
  fn: (entry1: T, entry2: K) => V,
  array1: T[],
  array2: K[]
): V[] => {
  return array1.map((e1: T) => array2.map((e2: K) => fn(e1, e2))).flat();
};

export function length<T>(array: T[]): number {
  return array.length;
}

export const concat = curry(
  <T>(prefix: string | T[], suffix: string | T[]): string | T[] =>
    typeof prefix === "string"
      ? `${suffix}${prefix}`
      : [].concat(prefix, suffix)
);

export const difference = curry(<T>(arr1: T[], arr2: T[]) =>
  arr1.filter((entry: T): boolean => !arr2.includes(entry))
);

export function distinct<T>(array: T[]): T[] {
  return Array.from(new Set(array));
}

export const drop = curry(<T>(numOfEntriesToDrop: number, array: T[]): T[] =>
  array?.slice(numOfEntriesToDrop)
);

export const each = curry(<T>(fn: (arg: T) => void, arr: T[]): void =>
  arr.forEach(fn)
);

export const every = curry(
  <T>(fn: (arg: T) => boolean, entries: T[]): boolean => entries.every(fn)
);

export const filter = curry(<T>(fn: (element: T) => boolean, arr: T[]): T[] =>
  arr.filter(fn)
);

export const find = curry(
  <T>(fn: (entry: T) => boolean, arr: T[]): T => arr.find(fn)
);

export const flatten = <T>(array: T[][]): T[] => array.flat();

export const groupBy = curry(
  <T>(fn: (arg: T) => string, array: T[]): Record<string, T[]> =>
    array.reduce((acc: Record<string, T[]>, curr: T) => {
      const key = fn(curr);
      acc[key] = concat(acc?.[key] || [], curr);

      return acc;
    }, {})
);

export function head<T>(array: T[]): T {
  if (!array?.length || array?.length === 0) return null;
  return array[0];
}

export const includes = curry(
  <T>(searchVal: T | string, arrayOrString: T[] | string): boolean =>
    typeof arrayOrString === "string"
      ? arrayOrString.includes(searchVal as string)
      : arrayOrString.includes(searchVal as T)
);

export function init<T>(array: T[]): T[] {
  return array?.slice(0, -1);
}

export function insert(arr: any[], index: number, newItem: any) {
  return [...arr?.slice(0, index), newItem, ...arr?.slice(index)];
}

export function insertMultiple(arr: any[], index: number, newItems: any[]) {
  return [...arr?.slice(0, index), ...newItems, ...arr?.slice(index)];
}

export const intersection = curry(<T>(arr1: T[], arr2: T[]) =>
  arr1.filter((entry: T): boolean => arr2.includes(entry))
);

export const isIncluded = curry(<T>(entry: T, array: T[]): boolean => {
  return includes(array, entry);
});

export const join = curry((separator: string, array: string[]): string =>
  array.join(separator)
);

export const juxt = curry(<T>(fns: (arg: T) => any, arg: T) =>
  map((fn: (arg: T) => any) => fn(arg), fns)
);

export function last<T>(array: T[]): T {
  if (!Array.isArray(array)) {
    throw new Error("Input is not an array.");
  }

  return head(array?.slice(-1));
}

export const map = curry(
  (
    fn: (element: any) => any,
    arrOrObj: any[] | Record<string, any>
  ): any[] | Record<string, any> => {
    if (!arrOrObj) return;
    const isInputAnArray = Array.isArray(arrOrObj);
    const input = isInputAnArray ? arrOrObj : Object.entries(arrOrObj);
    const result = input.map(fn);
    return isInputAnArray ? result : Object.fromEntries(result);
  }
);

export const partition = curry(
  <T>(fn: (el: T, index?: number) => boolean, arr: T[]): [T[], T[]] =>
    arr.reduce(
      (acc: [T[], T[]], el: T, index: number): [T[], T[]] => {
        const [passed, failed] = acc;

        if (fn(el, index)) {
          passed.push(el);
        } else {
          failed.push(el);
        }
        return [passed, failed];
      },
      [[], []]
    )
);

export const range = (to: number): number[] =>
  new Array(to).fill(0).map((_, index) => index);

export const reduce = curry(
  <T, V>(fn: (previous: V, entry: T) => V, initial: V, arr: T[]): V => {
    return arr.reduce(fn, initial);
  }
);

export const reject = curry(
  <T>(rejectFn: (arg: T) => boolean, array: T[]): T[] => {
    return array.filter(pipe(rejectFn, not));
  }
);

export const reverse = <T>(list: T[]): T[] => {
  return Array.prototype?.slice.call(list, 0).reverse();
};

export const splice = curry(
  <T>(start: number, end: number, array: T[]): T[] => {
    return array?.splice(start, end);
  }
);

export const slice = curry(<T>(start: number, end: number, array: T[]): T[] => {
  return array?.slice(start, end);
});

export const splitEvery = curry(<T>(chunkLength: number, array: T[]): T[][] =>
  array.reduce((acc: T[][], element: T): T[][] => {
    if (!acc.length) acc.push([]);

    const lastChunk = acc[acc.length - 1];

    if (lastChunk.length < chunkLength) {
      lastChunk.push(element);
    } else {
      acc.push([element]);
    }

    return acc;
  }, [])
);

export const sort = curry(<T>(fn: (a: T, b: T) => number, arr: T[]): T[] =>
  [...arr].sort(fn)
);

export function tail<T>(array: T[]): T[] {
  return array?.slice(1);
}

export const take = curry(<T>(numOfEntriesToTake: number, array: T[]): T[] =>
  array?.slice(0, numOfEntriesToTake)
);

export const wrap = <T>(...args: T[]): T[] => [...args];

export const uniq = (entries: any[]) =>
  entries.reduce((acc, curr) => {
    if (!acc.some(equals(curr))) {
      acc.push(curr);
    }

    return acc;
  }, []);

export const uniqBy = curry(
  <T extends Record<string, any>>(propName: string, array: T[]): T[] => {
    const uniqueKeys = pipe(map(prop(propName)), distinct)(array);

    return uniqueKeys.map((key: string) =>
      array?.find(
        (element) => element && propName in element && element[propName] === key
      )
    );
  }
);

export const uniqByProp = curry(<T>(accessor: "string", array: T[]): T[] => {
  const entriesByProp: [string, T][] = array.map((entry: T) => [
    prop(accessor, entry),
    entry,
  ]);
  return uniqueFromEntries(entriesByProp);
});

export const uniqWith = curry(
  <T>(groupFn: (arg: T) => string, array: T[]): T[] => {
    const entriesByGroupFn: [string, T][] = array.map((entry: T) => [
      groupFn(entry),
      entry,
    ]);
    return uniqueFromEntries(entriesByGroupFn);
  }
);

export const zip = curry(<T>(entries1: T[], entries2: T[]): [T, T][] => {
  if (entries1.length !== entries2.length)
    throw new Error("Arrays size mismatch.");

  return entries1.map((entry1: T, index) => [entry1, entries2[index]]);
});

export const zipMap = curry(
  <T, K, V>(fn: (entry: T, zipArg: K) => V, zipArgs: K[], arr: T[]): V[] =>
    arr.map((entry: T, index: number): V => fn(entry, zipArgs[index]))
);
