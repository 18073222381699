import { Feature, FeatureCollection, Polygon, Position } from "geojson";

import { generateId } from "../../utils/string";
import GeoJsonEditMode from "../base/GeojsonEditMode";
import ImmutableLayersData from "../base/ImmutableLayersData";
import { GEOJSON_TYPES, MARKUP_COLORS, MODES_IDS } from "../../consts/editor";

import {
  getTwoClickPolygon,
  getMarkupFeatureContextProps,
} from "../../utils/geometry";
import {
  getMarkupLineCoords,
  getCloudPolygonCoordinates,
} from "../../utils/coordinates";

export class DrawMarkupCombinedMode extends GeoJsonEditMode {
  _previousFeatures: any = [];
  _cloudFeature: any = null;

  getGuides = (props: any) => {
    const { lastPointerMoveEvent } = props;
    const clickSequence = this.getClickSequence();

    const guides: FeatureCollection = {
      type: GEOJSON_TYPES.FeatureCollection,
      features: [...this._previousFeatures],
    };

    if (this._cloudFeature) {
      guides.features.push(this._cloudFeature);
      guides.features.shift();
    }

    if (clickSequence.length === 0) {
      // nothing to do yet
      return guides;
    }

    const corner1 = clickSequence[0];
    const corner2 = lastPointerMoveEvent.mapCoords;

    const polygon = getTwoClickPolygon(corner1, corner2);
    if (polygon) {
      guides.features.push({
        type: GEOJSON_TYPES.Feature,
        properties: {
          shape: polygon.properties && polygon.properties.shape,
          guideType: GEOJSON_TYPES.markup,
        },
        geometry: polygon.geometry,
      });
    }

    return guides;
  };

  handleDblClick = (event: any, props: any) => {
    if (this._previousFeatures.length === 3) {
      this._previousFeatures = this._previousFeatures.slice(
        0,
        this._previousFeatures.length - 1
      );

      this.checkAndFinishPolygon(props);
    }
  };

  handleClick = (event: any, props: any) => {
    const { modeId } = props?.modeConfig;
    const clickSequence = this.getClickSequence();

    if (this._previousFeatures.length === 0 && clickSequence.length < 1) {
      this.addClickSequence(event);
      return;
    }

    if (this._previousFeatures.length === 0) {
      this.addClickSequence(event);
      const corner1 = clickSequence[0];
      const corner2 = clickSequence[1];

      const polygon = getTwoClickPolygon(corner1, corner2);

      if (modeId === MODES_IDS.markup_cloud) {
        const cloudCoordinates = getCloudPolygonCoordinates(
          polygon.geometry.coordinates
        );

        this._cloudFeature = {
          type: GEOJSON_TYPES.Feature,
          properties: {
            shape: polygon.properties && polygon.properties.shape,
            guideType: GEOJSON_TYPES.markup,
          },
          geometry: {
            type: GEOJSON_TYPES.Polygon,
            coordinates: cloudCoordinates,
          },
        };
      }

      this._previousFeatures.push({
        type: GEOJSON_TYPES.Feature,
        properties: {
          shape: polygon.properties && polygon.properties.shape,
          guideType: GEOJSON_TYPES.markup,
        },
        geometry: polygon.geometry,
      });
      this.resetClickSequence();

      if (modeId === MODES_IDS.markup_text_box) {
        this.checkAndFinishPolygon(props);
      }
      return;
    }

    if (this._previousFeatures.length === 2) {
      this._previousFeatures.push({
        type: GEOJSON_TYPES.Feature,
        properties: {
          shape: "Polygon",
          guideType: GEOJSON_TYPES.markup,
        },
        geometry: {
          type: GEOJSON_TYPES.Polygon,
          coordinates: [[]],
        },
      });
      return;
    }

    if (this._previousFeatures.length === 3) {
      this.checkAndFinishPolygon(props);
      return;
    }
  };

  handlePointerMove = (event: any, props: any) => {
    props.onUpdateCursor("crosshair");

    const mousePosition = event.mapCoords;

    if (
      this._previousFeatures.length === 1 ||
      this._previousFeatures.length === 2
    ) {
      const mainFeature = this._previousFeatures[0];

      const coords = mainFeature.geometry.coordinates[0];
      const lineCoord = getMarkupLineCoords(coords, mousePosition);

      const line = {
        type: GEOJSON_TYPES.Feature,
        properties: {
          shape: "LineString",
          guideType: GEOJSON_TYPES.markup,
        },
        geometry: {
          type: GEOJSON_TYPES.LineString,
          coordinates: [lineCoord, mousePosition],
        },
      };

      if (this._previousFeatures.length > 1) {
        this._previousFeatures[1] = line;
      } else {
        this._previousFeatures.push(line);
      }
    }

    if (this._previousFeatures.length > 2) {
      const lineFeature = this._previousFeatures[1];
      const cCoords = lineFeature.geometry.coordinates[0];
      const coords = lineFeature.geometry.coordinates[1];

      const maxLineY = Math.max(cCoords[1], coords[1]);
      const minLineY = Math.min(cCoords[1], coords[1]);
      const maxLineX = Math.max(cCoords[0], coords[0]);
      const minLineX = Math.min(cCoords[0], coords[0]);
      const lineHeightDiff = maxLineY - minLineY;
      const lineWidthDiff = maxLineX - minLineX;

      const maxMouseY = Math.max(mousePosition[1], coords[1]);
      const minMouseY = Math.min(mousePosition[1], coords[1]);
      const maxMouseX = Math.max(mousePosition[0], coords[0]);
      const minMouseX = Math.min(mousePosition[0], coords[0]);

      let heightDiff = maxMouseY - minMouseY;
      let widthDiff = maxMouseX - minMouseX;
      if (heightDiff < 400) {
        heightDiff = 400;
      }
      if (widthDiff < 400) {
        widthDiff = 400;
      }

      let corner2 = coords;
      if (lineHeightDiff > lineWidthDiff) {
        if (mousePosition[0] > coords[0]) {
          corner2 = [coords[0] - widthDiff, coords[1]] as Position;
        } else {
          corner2 = [coords[0] + widthDiff, coords[1]] as Position;
        }
      } else {
        if (mousePosition[1] > coords[1]) {
          corner2 = [coords[0], coords[1] - heightDiff] as Position;
        } else {
          corner2 = [coords[0], coords[1] + heightDiff] as Position;
        }
      }
      let editedMousePosition = mousePosition;

      const mouseCornerDiffX = Math.abs(mousePosition[0] - corner2[0]);
      const mouseCornerDiffY = Math.abs(mousePosition[1] - corner2[1]);
      if (mouseCornerDiffX < 400) {
        if (mousePosition[0] > corner2[0]) {
          editedMousePosition[0] = corner2[0] + 400;
        } else {
          editedMousePosition[0] = corner2[0] - 400;
        }
      }
      if (mouseCornerDiffY < 400) {
        if (mousePosition[1] > corner2[1]) {
          editedMousePosition[1] = corner2[1] + 400;
        } else {
          editedMousePosition[1] = corner2[1] - 400;
        }
      }

      const polygon = getTwoClickPolygon(editedMousePosition, corner2);

      this._previousFeatures[2] = {
        type: GEOJSON_TYPES.Feature,
        properties: {
          shape: polygon.properties && polygon.properties.shape,
          guideType: GEOJSON_TYPES.markup,
        },
        geometry: polygon.geometry,
      } as Feature<Polygon>;
    }
  };

  handleKeyUp = (event: any, props: any) => {
    if (event.key === "z" || event.key === "Z") {
      if (this._previousFeatures.length === 0) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      if (this._previousFeatures.length === 3) {
        this._previousFeatures = this._previousFeatures.slice(
          0,
          this._previousFeatures.length - 1
        );
      } else {
        this._previousFeatures = [];
        this._cloudFeature = null;
      }
    }

    if (event.key === "Enter" && this._previousFeatures.length === 2) {
      event.preventDefault();
      event.stopPropagation();

      this.checkAndFinishPolygon(props);
    }
  };

  createTentativeFeature(props: any) {
    const { lastPointerMoveEvent } = props;
    const clickSequence = this.getClickSequence();

    const lastCoords = lastPointerMoveEvent
      ? [lastPointerMoveEvent.mapCoords]
      : [];

    let tentativeFeature;
    if (clickSequence.length === 1) {
      tentativeFeature = getTwoClickPolygon(clickSequence[0], lastCoords[0]);
    }

    return tentativeFeature;
  }

  checkAndFinishPolygon(props: any) {
    const {
      modeId,
      options,
      setSelected,
      setDefaultMode,
      additionalProperties,
    } = props.modeConfig;

    const features = props.data.features;

    const markupIds: string[] = [];
    const markupFeatures: any[] = [];

    const boxId = generateId();
    const lineId = generateId();
    const textId = generateId();

    if (this._previousFeatures.length > 0) {
      const style = {
        text: options.text,
        size: options.size.default,
        color: options.color.default,
        border: options.border.default,
        thinkness: options?.thinkness?.default || 0,
        opacity: options.color.default.fillOpacity === 0 ? 0 : 50,
      };
      const textStyle = {
        ...style,
        opacity: 5,
        thinkness: 0,
        color: { ...MARKUP_COLORS[6], fillOpacity: 0.07 },
        border: MARKUP_COLORS[6],
      };

      const boxFeature = {
        type: GEOJSON_TYPES.Feature,
        properties: {
          ...style,

          modeId:
            this._previousFeatures.length === 3
              ? MODES_IDS.markup_box
              : MODES_IDS.markup_text,

          correspondingLineId: lineId,
          bboxFeature: this._previousFeatures[0],
          id: this._previousFeatures.length === 3 ? boxId : textId,
          isCloud: modeId === MODES_IDS.markup_cloud && !!this._cloudFeature,
          additionalProperties,
        },
        geometry:
          modeId === MODES_IDS.markup_cloud && this._cloudFeature
            ? this._cloudFeature.geometry
            : this._previousFeatures[0].geometry,
      };
      markupIds.push(textId);
      markupFeatures.push(boxFeature);

      if (this._previousFeatures.length > 1) {
        const lineFeature = {
          type: GEOJSON_TYPES.Feature,
          properties: {
            ...style,

            id: lineId,
            correspondingTextId: textId,
            modeId: MODES_IDS.markup_line,
            correspondingBoxId:
              this._previousFeatures.length === 3 ? boxId : null,
            additionalProperties,
          },
          geometry: this._previousFeatures[1].geometry,
        };

        markupIds.push(lineId);
        markupFeatures.push(lineFeature);
      }

      if (this._previousFeatures.length === 3) {
        const boxFeature = {
          type: GEOJSON_TYPES.Feature,
          properties: {
            ...textStyle,
            id: textId,
            isSecondaryMarkup: true,
            correspondingLineId: lineId,
            modeId: MODES_IDS.markup_text,
            bboxFeature: this._previousFeatures[2],

            additionalProperties,
          },
          geometry: this._previousFeatures[2].geometry,
        };
        markupIds.push(boxId);
        markupFeatures.push(boxFeature);
      }

      const updatedData = new ImmutableLayersData(features);

      for (const feature of markupFeatures) {
        updatedData.addMarkupFeature(
          feature,
          "",
          feature.properties.modeId,
          feature.properties.id
        );
      }

      const updatedFeatures = updatedData.getLayers();

      const contextFeature =
        markupFeatures.length === 3
          ? updatedFeatures.find((f: any) => f.properties.id === boxId)
          : updatedFeatures.find((f: any) => f.properties.id === textId);

      const contextProps = getMarkupFeatureContextProps(
        contextFeature,
        props.modeConfig.deck.current.deck
      );
      if (contextProps) {
        setSelected([contextFeature.properties.id]);
        setDefaultMode();
      }

      this.resetClickSequence();
      this._cloudFeature = null;
      this._previousFeatures = [];

      const editAction = {
        updatedData: {
          type: GEOJSON_TYPES.FeatureCollection,
          features: updatedFeatures,
        },
        editType: "addFeature",
        editContext: {
          featureIds: markupIds,
          contextProps,
        },
      };

      props.onEdit(editAction);
    }
  }
}
