import React, {
  ReactNode,
  forwardRef,
  CSSProperties,
  MouseEventHandler,
} from "react";

import { IStyles } from "../Styled";
import { noop } from "../../utils/function";
import { CSS, FONT_WEIGHTS } from "../../consts/style";

import * as S from "./styles";

/**
 * Common button component
 *
 * @param value : string - text value for the button
 * @param onClick : function - handles click events
 * @param disabled : boolean - either the button is disabled or not
 * @param animated : boolean - either to animate the button or not
 * @param empty : boolean - either to style the button filled or empty
 * @param error : boolean - either an error or not
 **/

export interface IProps extends IStyles {
  after?: boolean;
  animated?: boolean;
  children?: ReactNode;
  disabled?: boolean;
  disabledColor?: CSSProperties["color"];
  disabledBgColor?: CSSProperties["backgroundColor"];
  disabledBorderColor?: CSSProperties["borderColor"];
  disableOpacity?: boolean;
  empty?: boolean;
  error?: boolean;
  id?: string;
  onClick?:
    | MouseEventHandler<HTMLButtonElement>
    | ((...args: unknown[]) => void);
  size?: string;
  style?: CSSProperties;
  type?: "button" | "submit" | "reset";
  value?: ReactNode;
}

export const Button: any = forwardRef(
  (
    {
      after = false,
      animated = true,
      bgColor = CSS.GREEN,
      borderColor = CSS.MAINCOLOR,
      borderRadius = CSS.BORDER_RADIUS,
      children,
      disabled = false,
      disabledBgColor,
      disabledBorderColor,
      disabledColor,
      disableOpacity = false,
      empty = false,
      error = false,
      fontColor = CSS.WHITE,
      fontSize = "14px",
      fontWeight = FONT_WEIGHTS.SEMIBOLD,
      minWidth = "82px",
      onClick,
      size = "default",
      type,
      value,
      ...props
    }: IProps,
    ref
  ) => {
    return (
      <S.StyledButton
        size={size}
        error={error}
        empty={empty}
        disabled={disabled}
        animated={animated}
        onClick={!disabled ? onClick : noop}
        bgColor={bgColor}
        borderColor={borderColor}
        borderRadius={borderRadius}
        disabledColor={disabledColor}
        disabledBgColor={disabledBgColor}
        disabledBorderColor={disabledBorderColor}
        disableOpacity={disableOpacity}
        minWidth={minWidth}
        ref={ref as any}
        type={type}
        {...props}
      >
        <>
          {!after && children}
          <S.Para
            fontColor={fontColor}
            fontSize={fontSize}
            fontWeight={fontWeight}
          >
            {/** @ts-ignore */}
            {value}
          </S.Para>
          {after && children}
        </>
      </S.StyledButton>
    );
  }
);
