import { Position } from "geojson";
import turfUnion from "@turf/union";
import { point } from "@turf/helpers";

import * as THREE from "three";
// @ts-ignore
import { Geometry } from "@luma.gl/core";
import TubeGeometry from "./three/TubeGeometry";

import { generateId } from "./string";
import { distance2d } from "./modes";
import { RGB_COLORS } from "../consts/style";
import { GEOJSON_TYPES } from "../consts/editor";
import { PIPELINE_MODE_TOOLS } from "../consts/pipeline";
import { handleNearestPointOnLine } from "../modes/edit/utils";
import ImmutableLayersData from "../modes/base/ImmutableLayersData";

let BufferGeometryUtils: any = null;
/**
 *
 *
 * CONSTS
 *
 *
 */

const COLLINEAR_THRESHOLD = 1e-9;
const MAX_INTERSECT_DISTANCE = 200;

type Polygon = Point[];
type Point = [number, number];

/**
 *
 *
 * CONSTS
 *
 *
 */

/**
 *
 *
 * 2D Pipeline Utils
 *
 *
 */
export function roundFeatureCoords(feature: any, nbOfDecimals: number = 2) {
  const roundCoords = (coords: any) => {
    if (Array.isArray(coords[0])) {
      return coords.map((coord: any) => roundCoords(coord));
    }
    return coords.map((coord: any) => parseFloat(coord.toFixed(nbOfDecimals)));
  };

  return {
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: roundCoords(feature.geometry.coordinates),
    },
  };
}

export function getPipelineModeDetails(pipelineMode: string) {
  const isEditMode = pipelineMode === PIPELINE_MODE_TOOLS[100];
  const isDrainMode = [
    PIPELINE_MODE_TOOLS[20],
    PIPELINE_MODE_TOOLS[21],
    PIPELINE_MODE_TOOLS[22],
  ].includes(pipelineMode);
  const isPipelineMode = [
    PIPELINE_MODE_TOOLS[2],
    PIPELINE_MODE_TOOLS[3],
    PIPELINE_MODE_TOOLS[4],
    PIPELINE_MODE_TOOLS[5],
    PIPELINE_MODE_TOOLS[6],
  ].includes(pipelineMode);

  const pipelineWidth =
    pipelineMode === PIPELINE_MODE_TOOLS[2]
      ? 2 * 5
      : pipelineMode === PIPELINE_MODE_TOOLS[3]
      ? 3 * 5
      : pipelineMode === PIPELINE_MODE_TOOLS[4]
      ? 4 * 5
      : pipelineMode === PIPELINE_MODE_TOOLS[5]
      ? 5 * 5
      : pipelineMode === PIPELINE_MODE_TOOLS[6]
      ? 6 * 5
      : 10;

  const pipelineColor =
    pipelineMode === PIPELINE_MODE_TOOLS[20]
      ? RGB_COLORS.DRAIN_COMMODE
      : pipelineMode === PIPELINE_MODE_TOOLS[21]
      ? RGB_COLORS.DRAIN_OTHER_FIXTURE
      : pipelineMode === PIPELINE_MODE_TOOLS[22]
      ? RGB_COLORS.DRAIN_EXIT
      : RGB_COLORS.DRAIN_COMMODE;

  return {
    isEditMode,
    isDrainMode,
    isPipelineMode,

    pipelineWidth,
    pipelineColor,
  };
}

export function snapNearestPoint(pipelines: any[], point: any) {
  const pipeline = pipelines.find(
    (p) => p.properties.id === point.properties.parentId
  );
  const pipelineCoords = pipeline.geometry.coordinates;
  const pointCoords = point.geometry.coordinates;

  let snapped = false;
  let snappedPoint = null;

  for (const coord of pipelineCoords) {
    const dist = distance2d(coord[0], coord[1], pointCoords[0], pointCoords[1]);

    if (dist < 30) {
      snapped = true;
      snappedPoint = {
        ...point,
        properties: {
          ...point.properties,
          snapped: true,
          dist,
        },
        geometry: {
          ...point.geometry,
          coordinates: coord,
        },
      };
      break;
    }
  }

  return snapped ? snappedPoint : point;
}

export function getPipelinesNearestPoint(
  pipelines: any[],
  mapCoords: Position
): any {
  let nearest = null;
  let parentId = null;
  let nearestIndex = null;

  const referencePoint = point(mapCoords);

  for (let i = 0; i < pipelines.length; i++) {
    const pipeline = pipelines[i];
    if (!pipeline.properties.isDrainLine) {
      const coordinates = pipeline.geometry.coordinates;

      for (let j = 0; j < coordinates.length - 1; j++) {
        const lineCoords = [coordinates[j], coordinates[j + 1]];
        const line = {
          type: GEOJSON_TYPES.Feature,
          geometry: {
            type: GEOJSON_TYPES.LineString,
            coordinates: lineCoords,
          },
          properties: {},
        };

        const nearestPoint = handleNearestPointOnLine(
          line,
          referencePoint,
          null,
          10
        );

        if (nearestPoint) {
          const cleanedPoint = roundFeatureCoords({
            ...nearestPoint,
            geometry: {
              type: "Point",
              coordinates: nearestPoint.geometry.coordinates,
            },
            properties: {
              ...nearestPoint.properties,
              snapped: false,
              dist: nearestPoint.properties.dist,
            },
          });

          if (
            !nearest ||
            cleanedPoint.properties.dist < nearest.properties.dist
          ) {
            parentId = pipeline.properties.id;
            nearestIndex = j;
            nearest = cleanedPoint;
          }
        }
      }
    }
  }

  return nearest
    ? snapNearestPoint(pipelines, {
        ...nearest,
        properties: {
          ...(nearest?.properties || {}),
          parentId,
          positionIndexes: [nearestIndex + 1],
        },
      })
    : null;
}

export function getUpdatedPipelines(
  pipelines: any[],
  nearestPoint: any,
  modeConfig: any
) {
  const updatedData = new ImmutableLayersData(pipelines)
    .addPosition(
      nearestPoint.properties.parentId,
      nearestPoint.properties.positionIndexes,
      nearestPoint.geometry.coordinates,
      modeConfig.view,
      modeConfig?.colorMap
    )
    .getLayers()
    .map((p: any) =>
      p.properties.id === nearestPoint.properties.parentId
        ? {
            ...p,
            properties: {
              ...p.properties,
              linePolygon: lineToPolygon(p, p.properties.pipelineWidth),
            },
          }
        : p
    );

  return updatedData;
}

export function getNewDrainLine(mapCoords: any, nearestCoordinates: any) {
  const newLineFeature = {
    type: GEOJSON_TYPES.Feature,
    geometry: {
      type: GEOJSON_TYPES.LineString,
      coordinates: [nearestCoordinates, mapCoords],
    },
    properties: {
      types: ["line"],
    },
  };
  const newLinePolygon = lineToPolygon(newLineFeature, 10);

  return {
    ...newLineFeature,
    properties: {
      ...newLineFeature.properties,
      id: generateId(),
      pipelineWidth: 10,
      isDrainLine: true,
      linePolygon: newLinePolygon,
    },
  };
}

/**
 *
 * code started from https://github.com/Turfjs/turf/blob/master/packages/turf-line-offset/index.js
 * and added the mitter types and the geometry conversion
 */

function scalarMult(s: any, v: any) {
  return [s * v[0], s * v[1]];
}

function intersectSegments(a: any, b: any) {
  var p = a[0];
  var r = [a[1][0] - a[0][0], a[1][1] - a[0][1]];
  var q = b[0];
  var s = [b[1][0] - b[0][0], b[1][1] - b[0][1]];

  var cross = r[0] * s[1] - s[0] * r[1];
  var qmp = [q[0] - p[0], q[1] - p[1]];
  var numerator = qmp[0] * s[1] - s[0] * qmp[1];
  var t = numerator / cross;
  var intersection = [p[0] + scalarMult(t, r)[0], p[1] + scalarMult(t, r)[1]];
  return intersection;
}

function isParallel(a: any, b: any) {
  var r = [a[1][0] - a[0][0], a[1][1] - a[0][1]];
  var s = [b[1][0] - b[0][0], b[1][1] - b[0][1]];
  return r[0] * s[1] - s[0] * r[1] === 0;
}

function intersection(a: any, b: any) {
  if (isParallel(a, b)) return false;
  return intersectSegments(a, b);
}

// from https://github.com/Turfjs/turf/blob/master/packages/turf-line-offset/index.js
function processSegment(point1: any, point2: any, offset: number) {
  var L = Math.sqrt(
    (point1[0] - point2[0]) * (point1[0] - point2[0]) +
      (point1[1] - point2[1]) * (point1[1] - point2[1])
  );

  var out1x = point1[0] + (offset * (point2[1] - point1[1])) / L;
  var out1y = point1[1] + (offset * (point1[0] - point2[0])) / L;

  var out2x = point2[0] + (offset * (point2[1] - point1[1])) / L;
  var out2y = point2[1] + (offset * (point1[0] - point2[0])) / L;

  return [
    [out1x, out1y],
    [out2x, out2y],
  ];
}

function calculateInnerAngle(p1: Point, p2: Point, intersectionPoint: Point) {
  const vector1 = [p1[0] - intersectionPoint[0], p1[1] - intersectionPoint[1]];
  const vector2 = [p2[0] - intersectionPoint[0], p2[1] - intersectionPoint[1]];

  const dotProduct = vector1[0] * vector2[0] + vector1[1] * vector2[1];
  const magnitude1 = Math.sqrt(
    vector1[0] * vector1[0] + vector1[1] * vector1[1]
  );
  const magnitude2 = Math.sqrt(
    vector2[0] * vector2[0] + vector2[1] * vector2[1]
  );

  if (magnitude1 === 0 || magnitude2 === 0) {
    return null;
  }

  const cosAngle = dotProduct / (magnitude1 * magnitude2);

  const angleInDegrees = Math.acos(cosAngle) * (180 / Math.PI);
  return angleInDegrees;
}

function areCollinear(p1: Point, p2: Point, p3: any) {
  const crossProduct =
    (p2[0] - p1[0]) * (p3[1] - p1[1]) - (p3[0] - p1[0]) * (p2[1] - p1[1]);

  return Math.abs(crossProduct) < COLLINEAR_THRESHOLD;
}

function extendLine(p1: Point, p2: Point, distance: number) {
  const dx = p2[0] - p1[0];
  const dy = p2[1] - p1[1];
  const lineLength = Math.sqrt(dx ** 2 + dy ** 2);

  const ux = dx / lineLength;
  const uy = dy / lineLength;

  const newX = p2[0] + ux * distance;
  const newY = p2[1] + uy * distance;

  return [newX, newY];
}

// from https://github.com/Turfjs/turf/blob/master/packages/turf-line-offset/index.js
function lineOffset(
  id: string,
  coords: any,
  distance: number,
  cache: Map<any, any>
) {
  const segments: any = [];
  const finalCoords: any = [];

  coords.forEach(function (currentCoords: any, index: number) {
    if (index !== coords.length - 1) {
      const segment: any = processSegment(
        currentCoords,
        coords[index + 1],
        distance
      );
      segments.push(segment);

      if (coords.length === 2) {
        finalCoords.push(segment[0]);
        finalCoords.push(segment[1]);
      } else {
        if (index > 0) {
          const previousSegment = segments[index - 1];
          const intersects: any = intersection(segment, previousSegment);

          if (intersects !== false) {
            const distanceToIntersect = Math.sqrt(
              (currentCoords[0] - intersects[0]) *
                (currentCoords[0] - intersects[0]) +
                (currentCoords[1] - intersects[1]) *
                  (currentCoords[1] - intersects[1])
            );
            const angle = calculateInnerAngle(
              previousSegment[0],
              segment[1],
              intersects
            );

            const angle2 = calculateInnerAngle(
              previousSegment[0],
              segment[1],
              currentCoords
            );

            if (!!angle && !!distanceToIntersect) {
              const cond1 = distanceToIntersect < MAX_INTERSECT_DISTANCE;
              const cond2 =
                distanceToIntersect >= MAX_INTERSECT_DISTANCE && angle > angle2;
              const cond = cond1 || cond2;

              if (cond) {
                previousSegment[1] = intersects;
                segment[0] = intersects;

                finalCoords.push(previousSegment[0]);

                if (index === coords.length - 2) {
                  finalCoords.push(segment[0]);
                  finalCoords.push(segment[1]);
                }
              } else {
                // apply mitter options

                // extend the previous segment
                previousSegment[1] = extendLine(
                  previousSegment[0],
                  previousSegment[1],
                  Math.abs(distance * 2)
                );
                segment[0] = extendLine(
                  segment[1],
                  segment[0],
                  Math.abs(distance * 2)
                );
                // extend the previous segment

                finalCoords.push(previousSegment[0]);
                finalCoords.push(previousSegment[1]);

                if (index === coords.length - 2) {
                  finalCoords.push(segment[0]);
                  finalCoords.push(segment[1]);
                }
              }
            } else {
              finalCoords.push(previousSegment[0]);
              finalCoords.push(previousSegment[1]);

              if (index === coords.length - 2) {
                finalCoords.push(currentCoords);
              }
            }
          } else {
            if (
              !areCollinear(
                previousSegment[0],
                previousSegment[1],
                currentCoords
              )
            ) {
              finalCoords.push(previousSegment[0]);

              if (index === coords.length - 2) {
                finalCoords.push(segment[0]);
                finalCoords.push(segment[1]);
              }
            }
          }
        }
      }
    }
  });

  return finalCoords;
}

export function lineToPolygon(
  lineFeature: any,
  width: number = 20,
  cache?: Map<any, any>
): any {
  const linePoints: Point[] =
    lineFeature.geometry.type === GEOJSON_TYPES.LineString
      ? lineFeature.geometry.coordinates
      : lineFeature.geometry.type === GEOJSON_TYPES.MultiLineString
      ? lineFeature.geometry.coordinates[0]
      : [];

  if (linePoints.length < 2) {
    return [];
  }

  const leftLinePoints = lineOffset("left", linePoints, width, cache);
  const rightLinePoints: Point[] = lineOffset(
    "right",
    linePoints,
    -width,
    cache
  );

  rightLinePoints.reverse();

  const polygon: Polygon = leftLinePoints.concat(rightLinePoints);
  polygon.push(leftLinePoints[0]);

  const polygonFeature = {
    type: GEOJSON_TYPES.Feature,
    geometry: {
      type: GEOJSON_TYPES.Polygon,
      coordinates: [polygon],
    },
    properties: {
      opacity: 20,
      color: RGB_COLORS.PIPELINE,
      borderColor: RGB_COLORS.PIPELINE,
    },
  };

  return polygonFeature;
}

/**
 *
 *
 * 2d joint generation
 *
 *
 */

function getOtherPipelinesNeighbours(
  pipelines: any[],
  id: string,
  key: string
) {
  const neighbours = [];

  for (let i = 0; i < pipelines.length; i++) {
    const pipeline = pipelines[i];
    if (pipeline.properties.id !== id) {
      const coordinates = pipeline.geometry.coordinates;

      for (let j = 0; j < coordinates.length; j++) {
        const point = coordinates[j];
        const pointKey = `${point[0]}-${point[1]}`;
        if (key === pointKey) {
          if (j > 0) {
            neighbours.push([
              coordinates[j - 1],
              pipeline.properties.pipelineWidth,
            ]);
          }
          if (j < coordinates.length - 1) {
            neighbours.push([
              coordinates[j + 1],
              pipeline.properties.pipelineWidth,
            ]);
          }
        }
      }
    }
  }

  return neighbours;
}

export function getSharedPoints(pipelines: any[]) {
  const sharedPoints = new Map();

  for (let i = 0; i < pipelines.length; i++) {
    const pipeline = pipelines[i];
    const coordinates = pipeline.geometry.coordinates;

    for (let j = 0; j < coordinates.length; j++) {
      const point = coordinates[j];
      const key = `${point[0]}-${point[1]}`;

      const pointNeighbours = [];

      if (!sharedPoints.has(key)) {
        if (j > 0) {
          pointNeighbours.push([
            coordinates[j - 1],
            pipeline.properties.pipelineWidth,
          ]);
        }
        if (j < coordinates.length - 1) {
          pointNeighbours.push([
            coordinates[j + 1],
            pipeline.properties.pipelineWidth,
          ]);
        }
        pointNeighbours.push(
          ...getOtherPipelinesNeighbours(pipelines, pipeline.properties.id, key)
        );

        sharedPoints.set(key, pointNeighbours);
      }
    }
  }

  return sharedPoints;
}

function getPointAtDistance(
  p1: [number, number],
  p2: [number, number],
  distance: number
): [number, number] {
  const lineDistance = distance2d(p1[0], p1[1], p2[0], p2[1]);
  const ratio = distance / lineDistance;
  return [p1[0] + (p2[0] - p1[0]) * ratio, p1[1] + (p2[1] - p1[1]) * ratio];
}

function lineCoordsToPolygonCoords(lineCoords: any[], width: number) {
  const line = {
    type: GEOJSON_TYPES.Feature,
    geometry: {
      type: GEOJSON_TYPES.LineString,
      coordinates: lineCoords,
    },
    properties: {},
  };

  return lineToPolygon(line, width);
}

function generateJoint(
  controlPoint: [number, number],
  points: any[],
  width: number
): any {
  const jointWidth = width + 10;
  const jointLength = Math.min(width * 6, 150);

  const endJointWidth = width + 20;
  const endJointLength = Math.min(width * 2, 20);

  let joint: any = null;

  for (let i = 0; i < points.length - 1; i++) {
    const p1 = points[i];
    const p2 = points[i + 1];

    const cp1 = getPointAtDistance(controlPoint, p1, jointLength);
    const cp2 = getPointAtDistance(controlPoint, p2, jointLength);

    const ce1 = getPointAtDistance(cp1, controlPoint, endJointLength);
    const ce2 = getPointAtDistance(cp2, controlPoint, endJointLength);

    const endLineCoords = [ce1, cp1];
    const startLineCoords = [cp2, ce2];
    const jointLineCoords = [cp1, controlPoint, cp2];

    const endLinePolygon = lineCoordsToPolygonCoords(
      endLineCoords,
      endJointWidth
    );
    const startLinePolygon = lineCoordsToPolygonCoords(
      startLineCoords,
      endJointWidth
    );
    const jointLinePolygon = lineCoordsToPolygonCoords(
      jointLineCoords,
      jointWidth
    );

    if (!joint) {
      joint = jointLinePolygon;
    }

    joint = turfUnion(joint, jointLinePolygon);
    joint = turfUnion(joint, startLinePolygon);
    joint = turfUnion(joint, endLinePolygon);
  }

  return joint
    ? {
        ...joint,
        properties: {
          ...joint.properties,
          id: generateId(),
          opacity: 255,
          color: RGB_COLORS.PIPELINE,
          borderColor: RGB_COLORS.DARKJOINT,
        },
      }
    : null;
}

export function generateJoints(pipelines: any[], cache: Map<any, any>) {
  const sharedPoints = getSharedPoints(pipelines);

  const joints: any = [];

  sharedPoints.forEach((neighbours, key) => {
    const controlPoint = key.split("-").map((c: string) => parseFloat(c));

    if (neighbours.length > 1) {
      const neighboursCoords = neighbours.map((n: any) => n[0]);
      const maxNeighbourWidth = neighbours.reduce(
        (acc: number, n: any) => Math.max(acc, n[1] || 0),
        0
      );

      const cacheKey =
        [...neighboursCoords, controlPoint].join("-") + maxNeighbourWidth;
      if (cache.has(cacheKey)) {
        joints.push(cache.get(cacheKey));
        return;
      } else {
        const joint = generateJoint(
          controlPoint,
          neighboursCoords,
          maxNeighbourWidth
        );
        if (joint) {
          cache.set(cacheKey, joint);
          joints.push(joint);
        }
      }
    }
  });

  return joints;
}

/**
 *
 *
 * 3D Pipeline Utils
 *
 *
 */

export function getPipelineTubeGeometries(pipeline: any, width: number = 50) {
  const pipelineCoordinates = pipeline?.geometry.coordinates;

  const pipelineTHREEVectors = pipelineCoordinates.map(
    (coord: any) => new THREE.Vector3(coord[0], coord[1], coord[2] || 0)
  );

  const tubes: any = [];
  const radialSegments = 16;

  for (let i = 0; i < pipelineCoordinates.length - 1; i++) {
    const curve = new THREE.CatmullRomCurve3(
      [pipelineTHREEVectors[i], pipelineTHREEVectors[i + 1]],
      false,
      "catmullrom",
      0
    );

    const tube = new TubeGeometry(curve, 1, width, radialSegments, false);
    tube.computeVertexNormals();

    const colors = [];

    for (let i = 0; i < tube.attributes.position.count; i++) {
      colors.push(0.2, 0.2, 1, 1);
    }
    tube.setAttribute("color", new THREE.Float32BufferAttribute(colors, 4));

    tubes.push(tube);
  }

  return tubes;
}

export async function generate3dPipeline(pipelines: any) {
  if (!BufferGeometryUtils) {
    BufferGeometryUtils = await import(
      "three/examples/jsm/utils/BufferGeometryUtils.js"
    );
  }

  if (!pipelines || pipelines.length === 0) return null;
  const tubes: any[] = [];

  for (const pipeline of pipelines) {
    const width = pipeline.properties.pipelineWidth || 50;

    const pipelineTubes = getPipelineTubeGeometries(pipeline, width);
    tubes.push(...pipelineTubes);
  }

  // Merge geometries
  // @ts-ignore
  const geometry = BufferGeometryUtils.mergeBufferGeometries(tubes, true);

  const colors = geometry.attributes.color.array;
  const positions = geometry.attributes.position.array;
  const indices = geometry.index ? geometry.index.array : [];

  const lumaGeometry = new Geometry({
    attributes: {
      positions: {
        value: positions,
        size: 3,
      },
      colors: {
        size: 4,
        value: new Float32Array(colors),
      },
    },
    indices,
  });

  return lumaGeometry;
}
