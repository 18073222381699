import React, { useMemo } from "react";
import { Descriptions } from "../const";

import { EXPORT_PDF_STATES } from "src/modules/Editor/constants";
import { hashToRgb } from "sf/utils/style";

export default function useNotification({ notification }) {
  const profileColor = useMemo(
    () => hashToRgb(notification?.payload?.user?.id || ""),
    [notification?.payload?.user?.id]
  );
  const profileHeaderName = useMemo(
    () =>
      notification?.payload?.user?.name
        ?.split(" ")
        .map((section) => section[0])
        .filter(Boolean)
        .slice(0, 2)
        .join("") || "",
    [notification?.payload?.user?.name]
  );

  const isFailedPDF = useMemo(() => {
    return notification?.type === EXPORT_PDF_STATES.EXPORT_FAILURE;
  }, [
    notification?.type,
    notification?.payload?.multipageObject?.export_state,
  ]);

  const isUrl = useMemo(() => {
    try {
      new URL(notification?.payload?.multipageObject?.export_url);
      return true;
    } catch (e) {
      return false;
    }
  }, [notification?.payload?.multipageObject?.export_url]);

  const isMultipagePDF = useMemo(() => {
    return notification?.payload?.multipageObject;
  }, [notification]);

  const description = useMemo(
    () =>
      notification?.type in Descriptions
        ? Descriptions[notification?.type](notification)
        : "No description",
    [notification?.type]
  );

  return {
    isMultipagePDF,
    description,
    isFailedPDF,
    isUrl,
    profileColor,
    profileHeaderName,
  };
}
