import styled, { keyframes } from "styled-components";

import * as P from "./props";
import { CSS } from "../../../consts/style";
import { addOpacity } from "../../../utils/style";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const SpinnerWrapper = styled.div<P.SpinnerProps>`
  display: inline-block;
  border-radius: 50%;

  width: ${({ size }) => size || "30px"};
  height: ${({ size }) => size || "30px"};
  border: 2px solid ${({ color }) => addOpacity(color, 30) || CSS.WHITE};
  border-top-color: ${({ color }) => color || CSS.WHITE};

  animation: ${spin} 0.6s linear infinite;
`;
