export const COMMON_MESSAGES = {
  LOGIN: "Login",
  REGISTER: "Register",
  CONTINUE: "Continue",
  DOWNLOAD: "Download",
  REMOVE: "Remove",
  UPDATE: "Update",

  MONTH: "Month",
  YEAR: "Year",

  EMAIL: "Email",
  PASSWORD: "Password",
  LASTNAME: "Last Name",
  FIRSTNAME: "First Name",
  ORGANIZATION: "Organization",
  COMPANY_NAME: "Company Name",
  PHONE_NUMBER: "Phone Number",

  USER: "User",
  ADMIN: "Admin",
  VIEWER: "Viewer",

  ROLE: "Role",
  ADDED: "Added",
  LAST_SEEN: "Last seen",
  STATUS: "Status",
  TYPE: "Type",

  EXTERNAL: "External",
  INTERNAL: "Internal",

  SUBMIT: "Submit",
  LOADING: "Loading...",

  SAVE_BUTTON: "Save",

  SEARCH: "Search",
  PROJECTS: "Projects",
  LIBRARY: "Library",
  SETTINGS: "Settings",
  SUPERADMIN: "Superadmin",

  CM_STAR: "Star",
  CM_EDIT: "Edit",
  CM_DELETE: "Delete",
  CM_UNSTAR: "Un-star",
  CM_RESTORE: "Restore",
  CM_ARCHIVE: "Archive",
  CM_DUPLICATE: "Duplicate",

  SM_OWNER: "Created by",
  SM_ALPHA: "Alphabetical",
  SM_CREATED: "Date created",
  SM_LAST_MODIFIED: "Last modified",
  SM_O_ASC: "Ascending",
  SM_O_DESC: "Descending",

  UNITS_IMPERIAL: "Imperial",
  UNITS_METRIC: "Metric",

  NAME: "Name",
  ICON: "Icon",
  COLOR: "Color",
  CONFIRM: "Confirm",
  CONFIRM_BUTTON_TITLE: "Create",

  SAVE: "Save",
  CLOSE: "Close",
  CANCEL: "Cancel",
  DISMISS: "Dismiss",
  SELECTED: "Selected",

  SELECT_OPTION: "Select option",

  ANNUAL_PLAN: "Annual plan",
  MONTHLY_PLAN: "Monthly plan",
  CONTACT_SALES: "Contact Sales",
  ENTERPRISE_SOLUTIONS: "Enterprise Solutions",

  /**
   *
   *  USAGE MODAL LOCALES
   *
   */
  USAGE_TOGAL_ID: "Togals",
  USAGE_TOGAL_TITLE_FIRST: "Your first Togal!",
  USAGE_TOGAL_TITLE_MID: "Togals (AI-assisted take-offs)",
  USAGE_TOGAL_TITLE_EMPTY: "You ran out of Togals",
  USAGE_TOGAL_MESSAGE_FIRST: (max: number = 5) =>
    `You get ${max} AI-automated takeoffs per month with your current plan.`,
  USAGE_TOGAL_MESSAGE_MID: (max: number = 5, current: number = 1) =>
    `You have ${max - current} AI-automated takeoffs left for this month.`,
  USAGE_TOGAL_MESSAGE_EMPTY: (max: number = 5) =>
    `You used up your ${max} AI-automated takeoffs for that month.`,

  USAGE_GPT_ID: "prompts",
  USAGE_GPT_TITLE_FIRST: "Your first prompt!",
  USAGE_GPT_TITLE_MID: "TogalCHAT prompts / responses",
  USAGE_GPT_TITLE_EMPTY: "You ran out of prompts!",
  USAGE_GPT_MESSAGE_FIRST: (max: number = 5) =>
    `You get ${max} prompts and responses per month with your current plan.`,
  USAGE_GPT_MESSAGE_MID: (max: number = 5, current: number = 1) =>
    `You have ${max - current} prompts and responses left for this month.`,
  USAGE_GPT_MESSAGE_EMPTY: (max: number = 5) =>
    `You used up your ${max} prompts and responses for this month.`,

  USAGE_SEARCHES_ID: "searches",
  USAGE_SEARCHES_TITLE_FIRST: "Your first image search!",
  USAGE_SEARCHES_TITLE_MID: "AI-assisted image searches",
  USAGE_SEARCHES_TITLE_EMPTY: "You ran out of image searches",
  USAGE_SEARCHES_MESSAGE_FIRST: (max: number = 5) =>
    `You get ${max} AI-powered image/symbol searches per month with your current plan.`,
  USAGE_SEARCHES_MESSAGE_MID: (max: number = 5, current: number = 1) =>
    `You have ${max - current} AI-powered searches left for this month.`,
  USAGE_SEARCHES_MESSAGE_EMPTY: (max: number = 5) =>
    `You used up the ${max} AI-powered searches for this month.`,

  USAGE_INVITES_ID: "invites",
  USAGE_INVITES_TITLE_FIRST: "Your first collaboration!",
  USAGE_INVITES_TITLE_MID: "Shared participants",
  USAGE_INVITES_TITLE_EMPTY: "You ran out of invites",
  USAGE_INVITES_MESSAGE_FIRST: (max: number = 5) =>
    `You get to share with ${max} other internal person in your account. You don't have limits with sharing to external parties.`,
  USAGE_INVITES_MESSAGE_MID: (max: number = 5, current: number = 1) =>
    `You have ${max - current} invites left for this month.`,
  USAGE_INVITES_MESSAGE_EMPTY: (max: number = 5) =>
    `You already used ${max} invites.`,

  USAGE_RENEW_TITLE: "New cycle!",
  USAGE_RENEW_MESSAGES: (
    togalUsage: any,
    gptUsage: any,
    imageSearchUsage: any,
    shareEventUsage: any
  ) => [
    `Your special feature counts have refreshed. You now have:`,
    `${togalUsage.max - togalUsage.current} Togals (AI-assisted take-offs)`,
    `${gptUsage.max - gptUsage.current} TogalCHAT prompts / responses`,
    `${
      imageSearchUsage.max - imageSearchUsage.current
    } AI-assisted image searches`,
    shareEventUsage.current === shareEventUsage.max
      ? "(Participants for collaboration already allocated)"
      : `${
          shareEventUsage.max - shareEventUsage.current
        } participants for collaboration still available.`,
    "These counts do not roll over to the next month.",
  ],

  USAGE_BUTTON_TITLE: "Got it",

  USAGE_COMMON_START_TEXT_1: "You can track your usage",
  USAGE_COMMON_START_TEXT_2: "here",

  USAGE_COMMON_END_TEXT_1: "Upgrade your plan",
  USAGE_COMMON_END_TEXT_2: "for unlimited",

  /**
   *
   *  USAGE MODAL LOCALES
   *
   */
};
