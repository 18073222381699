import React from "react";

export const SET_ICON_IDS = {
  ACTIVITY_INDICATOR_ICON_ID: "set_activity_indicator_icon_id",
  AREA_ICON_ID: "set_area_icon_id",
  LINE_ICON_ID: "set_line_icon_id",
  COUNT_ICON_ID: "set_count_icon_id",
  MARKUP_ICON_ID: "set_markup_icon_id",
  COMPARISON_ICON_ID: "set_comparison_icon_id",
};

export const SET_ICONS = {
  [SET_ICON_IDS.ACTIVITY_INDICATOR_ICON_ID]: {
    width: "8",
    height: "8",
    color: "#323232",
    viewBox: "0 0 8 8",

    icon: ActivityIndicatorIcon,
  },

  [SET_ICON_IDS.AREA_ICON_ID]: {
    width: "11",
    height: "10",
    color: "#323232",
    viewBox: "0 0 11 10",

    icon: AreaIcon,
  },

  [SET_ICON_IDS.LINE_ICON_ID]: {
    width: "11",
    height: "10",
    color: "#323232",
    viewBox: "0 0 11 10",

    icon: LineIcon,
  },
  [SET_ICON_IDS.COUNT_ICON_ID]: {
    width: "11",
    height: "10",
    color: "#323232",
    viewBox: "0 0 11 10",

    icon: CountIcon,
  },
  [SET_ICON_IDS.MARKUP_ICON_ID]: {
    width: "16",
    height: "16",
    color: "#323232",
    viewBox: "0 0 16 16",

    icon: MarkupIcon,
  },
  [SET_ICON_IDS.COMPARISON_ICON_ID]: {
    width: "16",
    height: "16",
    color: "#323232",
    viewBox: "0 0 16 16",

    icon: ComparisonIcon,
  },
};

export function ActivityIndicatorIcon({ color = "#323232" }) {
  return <rect width="8" height="8" rx="4" fill={color} />;
}

export function AreaIcon({ color = "#323232" }) {
  return (
    <>
      <rect
        x="0.700195"
        y="9.5"
        width="9"
        height="9"
        rx="0.5"
        transform="rotate(-90 0.700195 9.5)"
        stroke={color}
      />
      <line
        x1="5.20602"
        y1="9.52152"
        x2="0.63331"
        y2="4.94882"
        stroke={color}
        stroke-linejoin="round"
      />
      <line
        x1="9.55465"
        y1="4.70316"
        x2="5.20593"
        y2="0.35444"
        stroke={color}
        stroke-linejoin="round"
      />
      <line
        x1="9.59567"
        y1="9.33793"
        x2="0.815951"
        y2="0.558214"
        stroke={color}
        stroke-linejoin="round"
      />
      <rect
        opacity="0.5"
        x="0.700195"
        y="0.5"
        width="9"
        height="9"
        fill={color}
      />
    </>
  );
}

export function LineIcon({ color = "#323232" }) {
  return (
    <>
      <line
        x1="0.700195"
        y1="5.5"
        x2="9.7002"
        y2="5.5"
        stroke={color}
        stroke-linecap="round"
      />
      <circle cx="1.7002" cy="5.5" r="1.5" fill={color} />
      <circle cx="8.7002" cy="5.5" r="1.5" fill={color} />
    </>
  );
}

export function CountIcon({ color = "#323232" }) {
  return (
    <>
      <rect
        x="0.700195"
        y="9.5"
        width="9"
        height="9"
        rx="4.5"
        transform="rotate(-90 0.700195 9.5)"
        stroke={color}
      />
      <circle
        opacity="0.3"
        cx="5.2002"
        cy="5"
        r="4.5"
        fill={color}
        stroke={color}
      />
    </>
  );
}

export function MarkupIcon({ color = "#323232" }) {
  return (
    <>
      <g clip-path="url(#clip0_7362_171176)">
        <path
          d="M12.4787 3.51856C14.952 5.9919 14.952 10.0019 12.4787 12.4752C10.0053 14.9486 5.99533 14.9486 3.522 12.4752C1.04867 10.0019 1.04867 5.9919 3.522 3.51856C5.99467 1.0459 10.0053 1.0459 12.4787 3.51856Z"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.33334 13.7434V10.4174C5.33334 10.1041 5.40667 9.79473 5.54867 9.5154L8 4.66406L10.4513 9.5154C10.5933 9.79473 10.6667 10.1041 10.6667 10.4174V13.7434"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.6153 9.97873L9.35064 10.9274L7.99998 9.91406L6.64931 10.9274L5.38464 9.97873"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7362_171176">
          <rect width="16" height="16" fill={color} />
        </clipPath>
      </defs>
    </>
  );
}

export function ComparisonIcon({ color = "#323232" }) {
  return (
    <>
      <g clip-path="url(#clip0_7580_151100)">
        <path
          d="M5.625 13.625L12.5 13.625C12.845 13.625 13.125 13.345 13.125 13L13.125 6.125C13.125 5.78 12.845 5.5 12.5 5.5L5.625 5.5C5.28 5.5 5 5.78 5 6.125L5 13C5 13.345 5.28 13.625 5.625 13.625Z"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.625 3.875L9.625 3.25C9.62505 3.08583 9.59274 2.92327 9.52994 2.77159C9.46714 2.61991 9.37507 2.4821 9.25898 2.36602C9.1429 2.24993 9.00509 2.15786 8.85341 2.09506C8.70173 2.03226 8.53917 1.99995 8.375 2L2.75 2C2.58583 1.99996 2.42327 2.03226 2.27159 2.09506C2.11992 2.15786 1.9821 2.24993 1.86602 2.36602C1.74993 2.4821 1.65786 2.61992 1.59506 2.77159C1.53226 2.92327 1.49995 3.08584 1.5 3.25L1.5 8.875C1.49996 9.03917 1.53226 9.20173 1.59506 9.35341C1.65786 9.50509 1.74993 9.6429 1.86602 9.75898C1.9821 9.87507 2.11992 9.96714 2.27159 10.0299C2.42327 10.0927 2.58584 10.125 2.75 10.125L3.375 10.125"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7580_151100">
          <rect
            width="15"
            height="15"
            fill={color}
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </>
  );
}
