import styled, { css } from "styled-components";

import { Flex } from "sf/components/Styled";

import { ReactComponent as CheckIcon } from "src/svg/check.svg";
import { ReactComponent as DropdownIcon } from "src/svg/dropdown.svg";
import { ReactComponent as CombinedIcon } from "src/svg/combined.svg";

import { CSS } from "sf/consts/style";
import { addOpacity } from "sf/utils/style";

export const Container = styled.div`
  height: 37px;
  border: 1px solid ${addOpacity(CSS.GRAY6, 50)};
  border-radius: ${CSS.BORDER_RADIUS};

  display: flex;
  align-items: center;
  font-size: ${CSS.FONT_DEFAULT};

  overflow: hidden;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

  ${(props) =>
    props.active &&
    !props.disabled &&
    css`
      min-width: 320px;
      max-width: 400px;
    `}

  ${(props) =>
    props.dark &&
    css`
      border-color: ${CSS.HEADERGRAY};
      border-radius: 0 ${CSS.BORDER_RADIUS} ${CSS.BORDER_RADIUS} 0;
    `}

    @media (max-width: 1280px) {
    min-width: 0px;
  }
`;

export const IconContainer = styled.div`
  min-width: 37px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.notAllowed ? "not-allowed" : "pointer")};

  ${(props) =>
    props.active &&
    !props.disabled &&
    css`
      border-right: 1px solid
        ${(props) => (props.dark ? CSS.GREY + "50" : CSS.DARKGREY + "50")};
    `}

  ${(props) =>
    props.dark &&
    css`
      path {
        stroke: ${CSS.WHITE};
      }
    `}
`;

export const ProfileHeadContainer = styled.div`
  width: ${(props) => props?.size || "36px"};
  height: ${(props) => props?.size || "36px"};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  line-height: 0;
  color: ${CSS.WHITE};
  text-transform: uppercase;
  font-size: ${CSS.FONT_SMALL};
`;

export const AbsoluteContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${CSS.Z_INDEX_MAX};

  width: 100vw;
  height: 100vh;

  ${(props) =>
    props.colored &&
    css`
      background-color: ${addOpacity(CSS.DARKGREY, 20)};
    `}
`;

export const DropdownContainer = styled.div`
  position: absolute;
  z-index: ${CSS.Z_INDEX_MAX};
  max-width: 311px;

  width: 100%;
  padding: 0;

  min-height: 40px;
  max-height: 200px;
  overflow-y: auto;

  box-shadow: ${CSS.BOX_SHADOW};

  border-radius: ${CSS.BORDER_RADIUS};

  display: flex;
  flex-direction: column;
`;

export const StyledCheckIcon = styled(CheckIcon)`
  path {
    stroke: ${(props) => (props.dark ? CSS.WHITE : CSS.BLACK)};
  }
`;

export const StyledDropdownIcon = styled(DropdownIcon)`
  path {
    stroke: ${(props) => (props.dark ? CSS.WHITE : CSS.BLACK)};
  }
`;

export const StyledCombinedIcon = styled(CombinedIcon)`
  path {
    stroke: ${(props) => (props.dark ? CSS.WHITE : CSS.BLACK)};
  }
`;

export const UserProfileContainer = styled(Flex)`
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;

export const ViewsEditorContainer = styled.div`
  position: absolute;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;

  top: 100px;
  left: calc(50vw - 275px);
  z-index: ${CSS.Z_INDEX_MAX};

  width: 550px;
  min-height: 100px;

  box-shadow: ${CSS.BOX_SHADOW};
  background-color: ${CSS.GRAY2};
  border-radius: ${CSS.BORDER_RADIUS};
`;

export const ViewsEditorUsersSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-y: overlay;
  width: 100%;
  max-height: calc(100vh - 266px);
`;

export const UserRole = styled.p`
  text-transform: capitalize;
`;

export const AddUserContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  min-height: 40px;
  width: calc(100% - 60px);
  margin-left: 10px;

  border-radius: ${CSS.BORDER_RADIUS};
  transition: all 0.2s ease;

  ${(props) =>
    props.isOpen &&
    css`
      flex-direction: column;
      background-color: ${CSS.WHITE};
      box-shadow: ${CSS.BOX_SHADOW};
      height: 330px;
      min-height: 330px;
      transition: all 0.2s ease;
      padding: 20px;
      overflow: hidden;
    `}
`;

export const AddButton = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${CSS.MIDGREY};

  display: flex;
  align-items: center;
  justify-content: center;

  line-height: 0;
  font-size: ${CSS.FONT_EXBIG};

  cursor: pointer;
`;

export const AddUsersInputContainer = styled.div`
  position: relative;
  height: 38px;
  min-height: 38px;
  width: 100%;

  border-radius: ${CSS.BORDER_RADIUS};
  border: 1px solid ${CSS.BORDER_COLOR};

  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const InputUserName = styled.div`
  flex: none;
  height: 28px;
  margin-right: 10px;
  padding: 0 5px;

  display: flex;
  line-height: 0;
  cursor: pointer;
  align-items: center;

  background-color: ${CSS.GRAY4};
  border-radius: ${CSS.BORDER_RADIUS};

  & span {
    margin: 0 5px;
    font-weight: 600;
  }
`;

export const Input = styled.input`
  width: 100%;
  min-width: 160px;

  border: none;
  outline: none;
`;

export const FoundUsersResultContainer = styled.div`
  position: absolute;
  top: 56px;
  z-index: 10;

  display: flex;
  flex-direction: column;

  min-height: 40px;
  width: calc(100% - 40px);
  box-shadow: ${CSS.BOX_SHADOW};
  background-color: ${CSS.WHITE};
  border-radius: ${CSS.BORDER_RADIUS};
`;

export const FoundUsersContainer = styled.div`
  height: 40px;

  :hover {
    background-color: ${(props) =>
      props.dark ? addOpacity(CSS.BLACK, 20) : CSS.GREY};
  }
`;

export const RolesDropdownContainer = styled.div`
  position: absolute;
  z-index: ${CSS.Z_INDEX_MAX};

  display: flex;
  flex-direction: column;

  width: 130px;
  min-height: 40px;

  box-shadow: ${CSS.BOX_SHADOW};
  background-color: ${CSS.WHITE};
  border-radius: ${CSS.BORDER_RADIUS};
`;

export const SingleRoleContainer = styled(Flex)`
  height: 40px;
  padding: 0 10px;
  text-transform: capitalize;
  cursor: pointer;

  :hover {
    background-color: ${(props) => (props.dark ? CSS.DARKGREY : CSS.GREY)};
  }
`;

export const MultiAvatarContainer = styled(Flex)`
  min-width: 30px;
`;

export const SingleAvatar = styled(Flex)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${CSS.MIDGREY};
  border: 1px solid ${CSS.GREY};
  color: ${CSS.WHITE};
  font-size: 10px;
  font-weight: 500;

  ${(props) => props.margined && "margin-left: -6px;"}
`;

export const PendingSpan = styled.span`
  display: inline-table;

  font-size: 12px;
  font-weight: 500;
  padding: 6px 7px;
  margin-left: 10px;

  background-color: ${CSS.WHITE};
  border-radius: ${CSS.BORDER_RADIUS};
`;
