import { useEffect, useRef } from "react";

// from useHooks https://usehooks.com/
export function usePrevious<T>(props: T, initial?: T): T {
  const ref = useRef<T>(initial);

  useEffect(() => {
    ref.current = props;
  }, [props]);

  return ref.current;
}
