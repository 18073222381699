import * as THREE from "three";

// from https://github.com/mrdoob/three.js/blob/master/src/geometries/TubeGeometry.js
//extending TubeGeometry, default TubeGeometry is not good enough,

class TubeGeometry extends THREE.BufferGeometry {
  type: string;

  normals: any;
  tangents: any;
  binormals: any;
  parameters: any;

  constructor(
    path: any = null,
    tubularSegments: number = 64,
    radius: number = 1,
    radialSegments: number = 8,
    closed: boolean = false
  ) {
    super();

    this.type = "TubeGeometry";

    this.parameters = {
      path: path,
      tubularSegments: tubularSegments,
      radius: radius,
      radialSegments: radialSegments,
      closed: closed,
    };

    const frames = path.computeFrenetFrames(tubularSegments, closed);

    // expose internals

    this.tangents = frames.tangents;
    this.normals = frames.normals;
    this.binormals = frames.binormals;

    // helper variables

    const vertex = new THREE.Vector3();
    const normal = new THREE.Vector3();
    const uv = new THREE.Vector2();
    let P = new THREE.Vector3();

    // buffer

    const vertices: any = [];
    const normals: any = [];
    const uvs: any = [];
    const indices: any = [];

    // create buffer data

    generateBufferData();

    // build geometry

    this.setIndex(indices);
    this.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(vertices, 3)
    );
    this.setAttribute("normal", new THREE.Float32BufferAttribute(normals, 3));
    this.setAttribute("uv", new THREE.Float32BufferAttribute(uvs, 2));

    // functions

    function generateBufferData() {
      for (let i = 0; i < tubularSegments; i++) {
        generateSegment(i);
      }
      generateSegment(closed === false ? tubularSegments : 0);
      generateUVs();
      generateIndices();
    }

    function generateSegment(i: number) {
      // we use getPointAt to sample evenly distributed points from the given path

      P = path.getPointAt(i / tubularSegments, P);
      // retrieve corresponding normal and binormal

      const N = frames.normals[i];
      const B = frames.binormals[i];

      // generate normals and vertices for the current segment

      for (let j = 0; j <= radialSegments; j++) {
        const v = (j / radialSegments) * Math.PI * 2;

        const sin = Math.sin(v);
        const cos = -Math.cos(v);

        // normal

        normal.x = cos * N.x + sin * B.x;
        normal.y = cos * N.y + sin * B.y;
        normal.z = cos * N.z + sin * B.z;
        normal.normalize();

        normals.push(normal.x, normal.y, normal.z);

        // vertex

        vertex.x = P.x + radius * normal.x;
        vertex.y = P.y + radius * normal.y;
        vertex.z = P.z + radius * normal.z;

        vertices.push(vertex.x, vertex.y, vertex.z);
      }
    }

    function generateIndices() {
      for (let j = 1; j <= tubularSegments; j++) {
        for (let i = 1; i <= radialSegments; i++) {
          const a = (radialSegments + 1) * (j - 1) + (i - 1);
          const b = (radialSegments + 1) * j + (i - 1);
          const c = (radialSegments + 1) * j + i;
          const d = (radialSegments + 1) * (j - 1) + i;

          // faces

          indices.push(a, b, d);
          indices.push(b, c, d);
        }
      }
    }

    function generateUVs() {
      for (let i = 0; i <= tubularSegments; i++) {
        for (let j = 0; j <= radialSegments; j++) {
          uv.x = i / tubularSegments;
          uv.y = j / radialSegments;

          uvs.push(uv.x, uv.y);
        }
      }
    }
  }
}

export default TubeGeometry;

export class CustomTubeGeometry extends THREE.BufferGeometry {
  type: string;

  normals: any;
  tangents: any;
  binormals: any;
  parameters: any;

  constructor(points: any = [], radius = 1, radialSegments = 8) {
    super();

    this.type = "CustomTubeGeometry";

    // helper variables
    const vertex = new THREE.Vector3();
    const normal = new THREE.Vector3();
    const uv = new THREE.Vector2();

    // buffer data
    const vertices: any = [];
    const normals: any = [];
    const uvs: any = [];
    const indices: any = [];

    // generate buffer data
    generateBufferData();

    // build geometry
    this.setIndex(indices);
    this.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(vertices, 3)
    );
    this.setAttribute("normal", new THREE.Float32BufferAttribute(normals, 3));
    this.setAttribute("uv", new THREE.Float32BufferAttribute(uvs, 2));

    // functions
    function generateBufferData() {
      const numPoints = points.length;
      generateSegment(points[numPoints - 1], points[0], points[1]);
      for (let i = 1; i < numPoints - 1; i++) {
        generateSegment(points[i - 1], points[i], points[i + 1]);
      }
      generateSegment(points[numPoints - 2], points[numPoints - 1], points[0]);

      // Generate UVs and indices
      generateUVs();
      generateIndices();
    }

    function generateSegment(
      prevPoint: any,
      currentPoint: any,
      nextPoint: any
    ) {
      const prevToCurr = currentPoint.clone().sub(prevPoint).normalize();
      const nextToCurr = nextPoint.clone().sub(currentPoint).normalize();
      const normalDir = prevToCurr.clone().cross(nextToCurr).normalize();

      const binormal = new THREE.Vector3()
        .crossVectors(prevToCurr, normalDir)
        .normalize();

      // generate vertices and normals for the current segment
      for (let j = 0; j <= radialSegments; j++) {
        const theta = (j / radialSegments) * Math.PI * 2;
        const cosTheta = Math.cos(theta);
        const sinTheta = Math.sin(theta);

        // Interpolate between the current point and its neighbors
        const interpolationFactor = j / radialSegments;
        const interpolatedPoint = new THREE.Vector3()
          .copy(currentPoint)
          .add(nextToCurr.clone().multiplyScalar(interpolationFactor))
          .add(prevToCurr.clone().multiplyScalar(1 - interpolationFactor));

        // vertex
        vertex
          .copy(interpolatedPoint)
          .add(binormal.clone().multiplyScalar(radius * cosTheta))
          .add(normalDir.clone().multiplyScalar(radius * sinTheta));
        vertices.push(vertex.x, vertex.y, vertex.z);

        // normal
        normals.push(normalDir.x, normalDir.y, normalDir.z);
      }
    }

    function generateUVs() {
      for (let i = 0; i < points.length; i++) {
        for (let j = 0; j <= radialSegments; j++) {
          uv.x = j / radialSegments;
          uv.y = i / (points.length - 1);
          uvs.push(uv.x, uv.y);
        }
      }
    }

    function generateIndices() {
      for (let i = 0; i < points.length - 1; i++) {
        for (let j = 0; j < radialSegments; j++) {
          const a = i * (radialSegments + 1) + j;
          const b = a + 1;
          const c = (i + 1) * (radialSegments + 1) + j;
          const d = c + 1;

          indices.push(a, b, c);
          indices.push(b, d, c);
        }
      }
    }
  }
}
