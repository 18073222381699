import IAnalyticsService from "./IAnalyticsService";

// TODO: After confirming with Chip, we should either remove sending events completely or bring them back, for now we're just disabling them
const IS_SMARTLOOK_DISABLED = false;

export default class SmartLookService implements IAnalyticsService {
  sendEvent(eventType: string, eventProperties?: Record<string, string>): void {
 
    if (IS_SMARTLOOK_DISABLED) return;

    // @ts-ignore
    if (import.meta.env.VITE_TGL_ENV === "local") {
      console.info("SMARTLOOK event called", eventType);
    }

    // @ts-ignore
    if (import.meta.env.VITE_TGL_ENV === "prod") {
      if (!(window as any).smartlook) return;

      (window as any).smartlook("track", eventType, eventProperties);
    }
  }
}
