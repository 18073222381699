import { curry } from "./function";

export const add = curry(
  (augend: number, addend: number): number => augend + addend
);

export const subtract = curry(
  (subtrahend: number, minuend: number): number => minuend - subtrahend
);
export const divide = curry(
  (divisor: number, dividend: number): number => dividend / divisor
);
export const multiply = curry(
  (multiplier: number, multiplicand: number): number =>
    multiplier * multiplicand
);

export const min = curry((min: number, value: number): number =>
  Math.min(value, min)
);

export const max = curry((min: number, value: number): number =>
  Math.max(value, min)
);
