import { useEffect } from "react";

export default function useOnClickOutside(
  refs: any,
  handler: any,
  textToCheck?: string
) {
  useEffect(() => {
    const listener = (event: any) => {
      const eventTargetText =
        event.target.innerText || event.target.textContent;

      if (
        refs.some((ref: any) => {
          return textToCheck
            ? eventTargetText?.trim() === textToCheck ||
                ref?.current?.contains(event.target)
            : !ref.current || ref?.current?.contains(event.target);
        })
      ) {
        return;
      }

      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [refs, handler]);
}
