const env = import.meta.env.VITE_TGL_ENV;
const base = import.meta.env.VITE_REACT_APP_API_BASE_URL;
const stripeKey = import.meta.env.VITE_REACT_APP_STRIPE_KEY;
const websocket = import.meta.env.VITE_REACT_APP_WS_BASE_URL;
const backendRequestLimit = import.meta.env.VITE_BACKEND_REQUEST_LIMIT;

export default {
  api: {
    base,
    websocket,
    backendRequestLimit,
  },
  env,
  stripeKey,
};
