import { createTheme } from "@material-ui/core/styles";
import rawLightTheme from "./light";
import rawDarkTheme from "./dark";

export const lightTheme = createTheme(rawLightTheme);
export const darkTheme = createTheme(rawDarkTheme);

// Expose theme objects on window for debugging.
window.theme = window.lightTheme = lightTheme;
window.darkTheme = darkTheme;
