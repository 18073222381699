import React, { createContext, Ref } from "react";
import { Feature, Position } from "geojson";

import { IGeoJsonState } from "../../consts";
import { IControllerProps } from "../../consts";
import { noop } from "../../../../utils/function";
import { IViewState } from "../../../../types/editor";
import { DEFAULT_GROUP } from "../../../../consts/editor";
import { FeatureTypesWithCoordinates } from "../../../../modes/types";

type PassedControllerProps = Pick<
  IControllerProps,
  | "deckGlId"
  | "imagesData"
  | "geoJson"
  | "filteredGeoJson"
  | "geoJsonProps"
  | "mode"
  | "colorMap"
  | "groupFilter"
  | "editorSettings"
  | "useSelectionBox"
  | "getZoom"
  | "setSelected"
  | "saveFeatures"
  | "setDefaultMode"
>;

interface IHovered {
  feature: Feature<FeatureTypesWithCoordinates>;
  hoveredPos: Position;
}

interface IControllerContext extends PassedControllerProps {
  context: IContext;
  deckGlRef: Ref<any>;
  geoJsonState: IGeoJsonState;
  hovered: IHovered;
  mouseCoords: Position;
  viewState: IViewState;
  clearContext: () => void;
}

const DEFAULT_VALUE: IControllerContext = {
  colorMap: {},
  context: null,
  deckGlId: "",
  deckGlRef: null,
  filteredGeoJson: [],
  geoJsonProps: {},
  geoJsonState: null,
  geoJson: [],
  groupFilter: DEFAULT_GROUP,
  hovered: null,
  mouseCoords: [0, 0],
  viewState: null,
  imagesData: [],
  editorSettings: {},
  getZoom: (initial: number) => initial,
  setSelected: noop,
  saveFeatures: noop,
  setDefaultMode: noop,
  clearContext: noop,
};

export const ControllerContext =
  createContext<IControllerContext>(DEFAULT_VALUE);

export interface IContext {
  left: number;
  top: number;
  selections: number[];
  selected: Feature[];
  isMarkup: boolean;
  isHole?: boolean;
}
