import { generateId } from "../../utils/string";

import { CommonDrawMode } from "./common-draw";
import { head } from "../../utils/functional/list";
import ImmutableLayersData from "../base/ImmutableLayersData";
import { CLASSIFICATION_TYPES, GEOJSON_TYPES } from "../../consts/editor";

export class DrawLineMode extends CommonDrawMode {
  handleAddFeature = (event: any, props: any) => {
    const isCtrlCmd = event.ctrlKey || event.metaKey;

    const features = props.data.features;
    const clickSequence = this.getClickSequence();
    const { view, activeClassification } = props.modeConfig;

    if (clickSequence.length > 0) {
      const coordinates: any = this?.mainFeature?.geometry?.coordinates;
      const cleanedCoords =
        coordinates?.length > 0 &&
        coordinates[0]?.length &&
        Array.isArray(head(coordinates[0]))
          ? coordinates[0].slice(0, -1)
          : coordinates?.slice(0, -1);

      const featureClassification =
        activeClassification?.id &&
        activeClassification?.type === CLASSIFICATION_TYPES[1]
          ? activeClassification.id
          : null;

      const lineIds = [];
      let updatedData: any = new ImmutableLayersData(features);
      const previousIndices = this._arcs.flatMap((arc) => [
        ...arc.ignoreIndices,
        arc.endPointIdx,
      ]);
      const addedArcsIds = new Set();

      if (props?.is2PointsMode || isCtrlCmd) {
        const newId = generateId();
        updatedData.addFeature(
          {
            type: GEOJSON_TYPES.LineString,
            coordinates:
              isCtrlCmd && !props?.is2PointsMode ? cleanedCoords : coordinates,
          },
          null,
          newId,
          featureClassification,
          null,
          view,
          [],
          null,
          props.featureAccessKey,
          {
            [activeClassification?.id || ""]: activeClassification,
          },
          { arcs: this?.mainFeature?.properties?.arcs || [] }
        );

        lineIds.push(newId);
      } else {
        for (const idx in cleanedCoords) {
          const index = Number(idx);
          if (index > 0) {
            const newId = generateId();
            const arc = previousIndices.includes(index)
              ? this._arcs.find((arc) =>
                  [
                    arc.endPointIdx,
                    arc.startPointIdx,
                    ...arc.ignoreIndices,
                  ].includes(index)
                )
              : null;

            if (arc) {
              if (!addedArcsIds.has(arc.id)) {
                const arcCoordinates = cleanedCoords.slice(
                  arc.startPointIdx,
                  arc.endPointIdx + 1
                );
                const lineArc = {
                  ...arc,
                  startPointIdx: 0,
                  endPointIdx: arcCoordinates.length - 1,
                  ignoreIndices: new Array(arcCoordinates.length - 2)
                    .fill(0)
                    .map((_, i) => i + 1),
                };

                updatedData.addFeature(
                  {
                    type: GEOJSON_TYPES.LineString,
                    coordinates: arcCoordinates,
                  },
                  null,
                  newId,
                  featureClassification,
                  null,
                  view,
                  [],
                  null,
                  props.featureAccessKey,
                  {
                    [activeClassification?.id || ""]: activeClassification,
                  },
                  { arcs: [lineArc] }
                );

                lineIds.push(newId);
                addedArcsIds.add(arc.id);
              }
            } else {
              updatedData.addFeature(
                {
                  type: GEOJSON_TYPES.LineString,
                  coordinates: [cleanedCoords[index - 1], cleanedCoords[index]],
                },
                null,
                newId,
                featureClassification,
                null,
                view,
                [],
                null,
                props.featureAccessKey,
                {
                  [activeClassification?.id || ""]: activeClassification,
                }
              );
              lineIds.push(newId);
            }
          }
        }
      }

      updatedData = updatedData.getLayers();

      props.onEdit({
        updatedData: {
          type: GEOJSON_TYPES.FeatureCollection,
          features: updatedData,
        },
        editType: "addLine",
        editContext: {
          featureIds: lineIds,
        },
      });

      this._resetState();
    }
  };
}
