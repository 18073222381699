// from useHooks https://usehooks.com/
import {
  useRef,
  useMemo,
  useEffect,
  useCallback,
  MutableRefObject,
} from "react";

type CallbackFunction = (time: number) => void;

export function useRafLoop(
  callback: CallbackFunction,
  initiallyActive = true,
  dependencies: any[] = []
) {
  const raf: MutableRefObject<number | null> = useRef(null);

  const rafActivity = useRef(false);
  const rafCallback = useRef<CallbackFunction>(callback);
  rafCallback.current = callback;

  const step = useCallback((time) => {
    if (rafActivity.current && rafCallback.current) {
      rafCallback.current(time);
      raf.current = requestAnimationFrame(step);
    }
  }, []);

  const stop = useCallback(() => {
    if (rafActivity.current) {
      rafActivity.current = false;
      if (raf.current) {
        cancelAnimationFrame(raf.current);
      }
    }
  }, []);

  const start = useCallback(() => {
    if (!rafActivity.current) {
      rafActivity.current = true;
      raf.current = requestAnimationFrame(step);
    }
  }, [step]);

  const isActive = useCallback(() => rafActivity.current, []);

  const result = useMemo(
    () => [stop, start, isActive],
    [stop, start, isActive]
  );

  useEffect(() => {
    if (initiallyActive) {
      start();
    }

    return stop;
  }, []);

  useEffect(() => {
    rafCallback.current = callback;
  }, [callback, ...dependencies]);

  return result;
}
