import { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { valueof } from "../types/valueof";
import { KEYS } from "../consts/keysAndMouse";

export const useKeyDownEvent = (
  keys: Array<valueof<typeof KEYS>> | Array<string>,
  callback: (event: KeyboardEvent) => void,
  node?: HTMLInputElement,
  dependencies?: any
) => {
  // implement the callback ref pattern
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  // handle what happens on key press
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      // check if one of the key is part of the ones we want
      if (keys.some((key) => event.key === key)) {
        callbackRef.current(event);
      }
    },
    [keys]
  );

  useEffect(() => {
    if (typeof dependencies === "boolean" && dependencies) {
      return;
    }
    // target is either the provided node or the document
    let targetNode = node ?? document;
    // attach the event listener
    targetNode && targetNode.addEventListener("keydown", handleKeyPress);
    // remove the event listener

    return () =>
      targetNode && targetNode.removeEventListener("keydown", handleKeyPress);
  }, [handleKeyPress, node, dependencies]);
};

export const useKeyUpEvent = (
  keys: Array<valueof<typeof KEYS>> | Array<string>,
  callback: (event: KeyboardEvent) => void,
  node?: HTMLInputElement,
  dependencies?: any
) => {
  // implement the callback ref pattern
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  // handle what happens on key press
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      // check if one of the key is part of the ones we want
      if (keys.some((key) => event.key === key)) {
        callbackRef.current(event);
      }
    },
    [keys]
  );

  useEffect(() => {
    if (typeof dependencies === "boolean" && dependencies) {
      return;
    }
    // target is either the provided node or the document
    let targetNode = node ?? document;
    // attach the event listener
    targetNode && targetNode.addEventListener("keyup", handleKeyPress);
    // remove the event listener

    return () =>
      targetNode && targetNode.removeEventListener("keyup", handleKeyPress);
  }, [handleKeyPress, node, dependencies]);
};
