import { atom } from "recoil";

export const DEFAULT_SHORTCUTS_STATE = {
  visible: false,
  pressedBtn: null,
};

export const shortcutsGlobalState = atom({
  key: `shortcutsGlobalState`,
  default: DEFAULT_SHORTCUTS_STATE,
});
