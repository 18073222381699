import { doInfiniteRequest } from "src/constants/request";
import request from "../request";
import { withGlobalOrg, withGlobalOrgWhere, splitLargeWhere } from "./_helpers";

/* eslint-disable camelcase */

export async function create(props) {
  const { body } = await request.post("project", {
    body: withGlobalOrg(props),
  });
  return body;
}

export async function get({ id, query }) {
  const { body } = await request.get(`project/${id}`, { query });
  return body;
}

export async function duplicate({ id }) {
  const { body } = await request.post(`project/${id}/copy`);
  return body;
}

export async function list(query) {
  const { body } = await request.get("project", {
    query: withGlobalOrgWhere(query),
  });
  return body;
}

export async function listAll(query) {
  if (query?.$where?.organization_id == null) {
    query = withGlobalOrgWhere(query);
  }
  return await doInfiniteRequest("project", query);
}

export async function update({ id, ...rest }) {
  const { body } = await request.put(`project/${id}`, {
    body: withGlobalOrg(rest),
  });
  return body;
}

export async function remove({ id }) {
  const { success } = await request.delete(`project/${id}`);
  return success;
}

export async function batchRemove(query) {
  return await splitLargeWhere("delete", "project", { query });
}
