import request from "../request";
import { withGlobalOrg, withGlobalOrgWhere } from "./_helpers";

export async function listFolders(query) {
  const { body } = await request.get("classification/folder", {
    query: withGlobalOrgWhere(query),
  });
  return body;
}

export async function addFolder(props) {
  const { body } = await request.post("classification/folder", {
    body: withGlobalOrg(props),
  });
  return body;
}

export async function getFolderById({ id }) {
  const { body } = await request.get(`classification/folder/${id}`);
  return body;
}

export async function updateFolderById({ id, ...rest }) {
  const { body } = await request.put(`classification/folder/${id}`, {
    body: withGlobalOrg(rest),
  });
  return body;
}

export async function removeFolderById(id) {
  const { success } = await request.delete(`classification/folder/${id}`);
  return success;
}
