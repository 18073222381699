import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useSetRecoilState, useRecoilState } from "recoil";
import { useLocation } from "react-router";

import * as notificationsApi from "src/lib/api/notifications";

import config from "src/config";
import Header from "./Components/Header";
import Notifications from "./Components/Notifications";

import { typesOfEvents } from "./const";
import { NotificationsContainer, CloseContainer } from "./style";
import { useAuth, getUserOrganizationId } from "src/modules/Auth/context";
import {
  notificationsListState,
  notitificationsCountState,
  notitificationsVisibleState,
} from "src/state/notifications";

const RECONNECT_INTERVAL = 1000 * 1;
const SOCKET_STATE_CONNECTING = 0;
const SOCKET_STATE_OPEN = 1;
const SOCKET_PENDING_STATES = [SOCKET_STATE_CONNECTING, SOCKET_STATE_OPEN];

export function subscribe(eventName, listener) {
  if (!window.socket) {
    throw new Error("Socket is not initialized");
  }
  const id = `${eventName}:${getNextListenerId()}`;
  if (window.socket.listeners[id]) {
    unsubscribe(id);
  }
  window.socket.listeners[id] = listener;
  return () => unsubscribe(id);
}

export function unsubscribe(listenerId) {
  if (!window.socket) {
    throw new Error("Socket is not initialized");
  }
  delete window.socket.listeners[listenerId];
}

function getNextListenerId() {
  if (!window.socket) {
    throw new Error("Socket is not initialized");
  }

  if (!window.socket.nextListenerId) {
    window.socket.nextListenerId = 0;
  }

  return window.socket.nextListenerId++;
}

export default function Notification() {
  const setCount = useSetRecoilState(notitificationsCountState);
  const [visible, setVisible] = useRecoilState(notitificationsVisibleState);
  const [userNotificationsList, setuserNotifications] = useRecoilState(
    notificationsListState
  );

  const { isAuthenticated, user } = useAuth();
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const organizationId = getUserOrganizationId();
  const location = useLocation();

  const userNotifications = useQuery(
    ["user_notifications", organizationId, user?.id],
    () => {
      if (!organizationId || !user?.id) return;
      return notificationsApi.list({
        $offset: 0,
        $limit: 30,
        $where: {
          user_id: user.id,
          organization_id: organizationId,
          type: typesOfEvents,
        },
      });
    },
    {
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      cacheTime: 30 * 60 * 1000,
    }
  );

  const notifications = useMemo(
    () =>
      (userNotificationsList || []).filter((n) =>
        activeTab === 0 ? true : !n.read
      ),
    [activeTab, userNotificationsList]
  );

  useEffect(() => {
    setuserNotifications(userNotifications?.data?.rows || []);
    return () => {
      setuserNotifications([]);
    };
  }, [userNotifications?.data?.rows, window.socket]);

  const unreadNotifications = useMemo(
    () => (userNotifications?.data?.rows || []).filter((n) => !n.read),
    [userNotifications?.data?.rows]
  );

  useEffect(() => {
    setCount(
      (userNotifications?.data?.rows || []).filter((n) => !n.read).length
    );
  }, [userNotifications?.data?.rows]);

  useEffect(() => {
    if (visible) {
      userNotifications.refetch();
    }
  }, [visible]);

  useEffect(() => {
    if (isAuthenticated && user?.id) {
      connect();
    }
  }, [user?.id, location, organizationId, isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated && window.socket) {
      window.socket.close();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (window.socket) {
      window.socket.listeners.notifications = (notification) => {
        if (typesOfEvents.includes(notification.type)) {
          setuserNotifications((prev) => [...prev, notification]);
          userNotifications.refetch();
        }
      };
    }
  }, [user?.id, organizationId, window.socket, window?.socket?.listeners]);

  function connect() {
    if (
      !window.socket ||
      SOCKET_PENDING_STATES.indexOf(window.socket?.readyState) === -1
    ) {
      let oldListeners = {};
      if (window.socket) {
        oldListeners = window.socket.listeners;
      }
      const url = `${config.api.websocket}/${organizationId}/${user.id}`;
      window.socket = new WebSocket(url);
      window.socket.listeners = oldListeners || {};

      window.socket.onmessage = (message) => {
        for (const listenerId of Object.keys(window.socket.listeners)) {
          const eventName = listenerId.split(":")[0];
          const messageData = JSON.parse(message.data);
          const incomingEventName = messageData?.type?.split("_")[0];
          if (typesOfEvents.includes(messageData?.type)) {
            userNotifications.refetch();
          }
          if (
            eventName &&
            incomingEventName &&
            eventName.toLocaleLowerCase() ===
              incomingEventName.toLocaleLowerCase() &&
            messageData.read === false &&
            user.id === messageData.user_id
          ) {
            window.socket.listeners[listenerId](messageData, (data) => {
              window.socket.send(JSON.stringify({ ...data, read: true }));
            });
          }
        }
      };

      window.socket.onopen = () => {};

      window.socket.onerror = (err) => {
        console.error("Socket connection failed with err: ", err);
        setTimeout(connect, RECONNECT_INTERVAL);
      };

      window.socket.onclose = () => {
        setTimeout(connect, RECONNECT_INTERVAL);
      };
    }
  }

  async function markAsRead(notification) {
    setIsLoading(true);
    try {
      await notificationsApi.markAsRead(notification.id);
      userNotifications.refetch();
    } catch (e) {
      console.error("notification error : ", e);
    }
    setIsLoading(false);
  }

  async function markAllAsRead() {
    setIsLoading(true);
    try {
      await notificationsApi.markAllAsRead(user.id, organizationId);
      userNotifications.refetch();
    } catch (e) {
      console.error("notification error : ", e);
    }
    setIsLoading(false);
  }

  return (
    <>
      {visible && <CloseContainer onClick={() => setVisible(false)} />}

      <NotificationsContainer visible={visible}>
        <Header
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          markAllAsRead={markAllAsRead}
          clear={() => setVisible(false)}
          shoudlShowMarkAll={unreadNotifications?.length > 0}
          isLoading={isLoading || userNotifications.isLoading}
        />
        <Notifications
          markAsRead={markAsRead}
          notifications={notifications}
          isLoading={isLoading || userNotifications.isLoading}
        />
      </NotificationsContainer>
    </>
  );
}
