import { useSetRecoilState } from "recoil";

import { setKey } from "../const";
import { useDataHook } from "./commonDataHook";
import {
  STORE_RESET,
  setsCRUDStore,
  setsErrorStore,
  setsLoadingStore,
} from "src/state/dataStore";

export function useSetsData(pendingData: React.MutableRefObject<any[]>) {
  /** Sets Store */
  const setSetsCRUD = useSetRecoilState(setsCRUDStore);
  const setSetsError = useSetRecoilState(setsErrorStore);
  const setSetsLoading = useSetRecoilState(setsLoadingStore);
  /** Sets Store */

  async function resetData() {
    setSetsCRUD({
      items: [],
      action: STORE_RESET,
    });
  }

  const {
    getData: getSets,
    syncData: syncSets,
    clearData: clearSets,
    syncSingleItem: syncSet,
  } = useDataHook({
    pendingData,
    useIdSync: true,
    dataKey: setKey,
    getApiProps: (id: string) => ({
      $where: { project_id: id },
    }),
    getLocalApiProps: (id: string) => ({
      project_id: id,
    }),
    store: {
      resetData,
      setDataError: setSetsError,
      setDataIsLoading: setSetsLoading,
      getItemValue: (set: any) => ({
        id: set.id,
        project_id: set?.project_id,
        organization_id: set?.organization_id,
      }),
    },
  });

  return {
    getSets,
    syncSet,
    syncSets,
    clearSets,
  };
}
