import kinks from "@turf/kinks";
import turfIntersect from "@turf/intersect";
import turfUnion from "@turf/union";

import { generateId } from "../../utils/string";
import { head } from "../../utils/functional/list";
import { GEOJSON_TYPES } from "../../consts/editor";
import { CommonDrawMode } from "../draw/common-draw";
import ImmutableLayersData from "../base/ImmutableLayersData";
import { getCleanedShapes, getIntersectFeatures } from "./utils";

export class MergeMode extends CommonDrawMode {
  handleAddFeature = (_event: any, props: any) => {
    const clickSequence = this.getClickSequence();

    if (clickSequence?.length > 2) {
      const mainCoordinates = this.mainFeature?.properties?.closed
        ? this.mainFeature.geometry.coordinates[0]
        : clickSequence;
      const coordinates: any = [[...mainCoordinates, head(mainCoordinates)]];

      const feature: any = {
        type: GEOJSON_TYPES.Feature,
        properties: {
          arcs: this._arcs,
        },
        geometry: {
          type: GEOJSON_TYPES.Polygon,
          coordinates,
        },
      };

      const featureKinks = kinks(feature);
      const isEdgeIntersection = featureKinks?.features?.length > 1;

      if (
        (isEdgeIntersection && !this.mainFeature?.properties?.closed) ||
        this._lock
      ) {
        return;
      }

      const editAction = this.getMergeEditAction(feature, props);

      if (editAction) {
        props.onEdit(editAction);
      }

      this._resetState();
    }
  };

  getMergeEditAction = (feature: any, props: any): any => {
    const { geoJson, view, colorMap } = props.modeConfig;

    const selectedFeatures = props.data.features;
    const intersectedFeatures = getIntersectFeatures(feature, props);

    if (!intersectedFeatures?.length) return null;

    const properties = intersectedFeatures[0].properties;

    let featureIds = [];
    let leftFeatures = [];
    let mergedFeature = feature;

    let updatedData = new ImmutableLayersData(geoJson);

    for (const selectedFeature of intersectedFeatures) {
      featureIds.push(selectedFeature.properties.id);

      if (selectedFeature.geometry.type === GEOJSON_TYPES.MultiPolygon) {
        const cleanedShapes = getCleanedShapes([selectedFeature]);
        for (const cleanedShape of cleanedShapes) {
          const isIntersect = turfIntersect(
            cleanedShape as any,
            mergedFeature as any
          );

          if (isIntersect) {
            const union = turfUnion(feature, cleanedShape);

            if (union) {
              if (union.geometry.type === GEOJSON_TYPES.MultiPolygon) {
                mergedFeature.geometry.coordinates =
                  union.geometry.coordinates.flat();
              } else {
                mergedFeature.geometry.coordinates = union.geometry.coordinates;
              }
            }
          } else {
            const newId = generateId();
            featureIds.push(newId);
            leftFeatures.push({
              ...cleanedShape,
              properties: {
                ...selectedFeature.properties,
                id: newId,
              },
            });
          }
        }
      }

      if (selectedFeature.geometry.type === GEOJSON_TYPES.Polygon) {
        const union = turfUnion(feature, selectedFeature);

        if (union) {
          if (union.geometry.type === GEOJSON_TYPES.MultiPolygon) {
            mergedFeature.geometry.coordinates =
              union.geometry.coordinates.flat();
          } else {
            mergedFeature.geometry.coordinates = union.geometry.coordinates;
          }
        }
      }
    }

    updatedData = updatedData.filterSelected(featureIds, true);

    for (const leftFeature of leftFeatures) {
      updatedData = updatedData.addFeature(
        leftFeature.geometry,
        leftFeature.properties.name,
        leftFeature.properties.id,
        leftFeature.properties.className,
        null,
        view,
        [],
        null,
        null,
        colorMap,
        leftFeature.properties
      );
    }

    const mergedFeatureId = generateId();
    featureIds.push(mergedFeatureId);
    mergedFeature = {
      ...mergedFeature,
      properties: {
        ...properties,
        id: mergedFeatureId,
      },
    };

    updatedData = updatedData.addFeature(
      mergedFeature.geometry,
      mergedFeature.properties.name,
      mergedFeature.properties.id,
      mergedFeature.properties.className,
      null,
      view,
      [],
      null,
      null,
      colorMap,
      mergedFeature.properties
    );

    const fixIds = [
      ...leftFeatures.map((f: any) => f.properties.id),
      mergedFeatureId,
    ];

    for (const f of [...selectedFeatures, feature]) {
      for (const id of fixIds) {
        if (f.properties.arcs?.length) {
          updatedData = updatedData.fixArcs(id, f, view, colorMap);
        }
      }
    }

    return {
      updatedData: {
        type: GEOJSON_TYPES.FeatureCollection,
        features: updatedData.getLayers(),
      },
      editType:
        selectedFeatures?.length > 0
          ? "unionGeometry"
          : "unionGeometryNonSelected",
      editContext: {
        featureIds,
      },
    };
  };
}
