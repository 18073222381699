import { LayerExtension } from "deck.gl";

export default class ScatterLayerExtension extends LayerExtension {
  getShaders() {
    return {
      inject: {
        "vs:#decl": `
          attribute float instanceShape;
          attribute float instanceSWidth;
          attribute float instanceSHeight;
          attribute float instanceSelected;
          
          varying float shape;
          varying float swidth;
          varying float sheight;
          varying float selected;
          varying vec4 vFillColor2;
        `,
        "vs:#main-end": `
          vFillColor2 = vec4(instanceFillColors.rgb, instanceFillColors.a * opacity);
          selected = instanceSelected;
          shape = instanceShape;
          swidth = instanceSWidth;
          sheight = instanceSHeight;
        `,
        "fs:#decl": `
            varying float shape;
            varying float swidth;
            varying float sheight;
            varying float selected;
            varying vec4 vFillColor2;

            float smoothedge(float v) {
                return smoothstep(0.095, 0.1, v);
            }

            float circle(vec2 p, float radius) {
                return length(p) - radius;
            }

            float triangle(vec2 p, float size) {
                vec2 q = abs(p);
                return max(q.x * 0.866025 + p.y * 0.5, -p.y * 0.5) - size * 0.5;
            }

            float triangle2(vec2 p, float size) {
                vec2 q = abs(p);
                return max(q.x * 0.866025 - p.y * 0.5, +p.y * 0.5) - size * 0.5;
            }

            float rect(vec2 p, vec2 size) {  
                vec2 d = abs(p) - size;
                return min(max(d.x, d.y), 0.0) + length(max(d,0.0));
            }

            //http://thndl.com/square-shaped-shaders.html
            float polygon(vec2 p, int vertices, float size, float angle) {
                float a = atan(p.x, p.y) + angle;
                float b = 6.28319 / float(vertices);
                return cos(floor(0.5 + a / b) * b - a) * length(p) - size;
            }

            //http://www.iquilezles.org/www/articles/distfunctions2d/distfunctions2d.htm
            float star(vec2 p, float size, int n, float m) {
                float an = 3.141593 / float(n);
                float en = 3.141593 / m;
                vec2 acs = vec2(cos(an), sin(an));
                vec2 ecs = vec2(cos(en), sin(en));

                float bn = mod(atan(p.x, p.y) + an, 2.0 * an) - an;
                p = length(p) * vec2(cos(bn), abs(sin(bn)));

                p -= size * acs;
                p += ecs * clamp(-dot(p, ecs), 0.0, size * acs.y / ecs.y);
                return length(p) * sign(p.x);
            }
            `,

        "fs:DECKGL_FILTER_COLOR": `
            float d = 0.;

            if (shape == 0.) {
              d = circle(vec2(geometry.uv), .8); // circle
            } else if (shape == 1. || shape == 2.) {
              d = rect(vec2(geometry.uv), vec2(swidth, sheight)); // rectangle
            } else if (shape == 3.) {
              d = polygon(vec2(geometry.uv), 3, 0.5, 0.); // triangle up
            } else if (shape == 4.) {
              d = polygon(vec2(geometry.uv), 3, 0.5, 3.141593); // triangle down
            } else if (shape == 5.) {
              d = polygon(vec2(geometry.uv), 4, 0.7, 0.78); // polygon4
            } else if (shape == 6.) {
              d = polygon(vec2(geometry.uv), 5, 0.8, 0.); // polygon5
            } else if (shape == 7.) {
              d = star(vec2(geometry.uv), 1., 5, 3.5); // star
            } else {
              d = circle(vec2(geometry.uv), 0.8); // circle
            }

            float opacity = vFillColor2.a;
            vec3 col = vec3(1.) * vFillColor2.rgb;
            if (sign(d) == -1.0) {
              if (selected == 1.) {
                color = mix(vec4(col, opacity), vec4(0., 0., 0., 1.), 1.0 - smoothstep(0.095, 0.1, abs(d)) );
              } else {
                color = mix(vec4(col, opacity), vec4(col, 1.), 1.0 - smoothstep(0.095, 0.1, abs(d)) );
              }
            } else {
                //color = vec4(1.);
                discard;
            }

          `,
      },
    };
  }

  initializeState(context: any, extension: any) {
    (this as any).getAttributeManager().addInstanced({
      instanceShape: {
        size: 1,
        accessor: "getShape",
        defaultValue: 4,
      },
      instanceSelected: {
        size: 1,
        accessor: "getSelected",
        defaultValue: 0,
      },
      instanceSWidth: {
        size: 1,
        accessor: "getSWidth",
        defaultValue: 1,
      },
      instanceSHeight: {
        size: 1,
        accessor: "getSHeight",
        defaultValue: 1,
      },
    });
  }

  updateState({ props, oldProps, changeFlags }: any) {
    super.updateState({ props, oldProps, changeFlags }, null);

    if (changeFlags.extensionsChanged) {
      const { gl } = (this as any).context;
      if ((this as any).state.model.delete) (this as any).state.model.delete();
      (this as any).state.model = (this as any)._getModel(gl);
      (this as any).getAttributeManager().invalidateAll();
    }
  }
}
