import React from "react";

export const EXPORT_ICON_IDS = {
  EXPORT_SORT_ICON_ID: "export_sort",
};

export const EXPORT_ICONS = {
  [EXPORT_ICON_IDS.EXPORT_SORT_ICON_ID]: {
    width: "20",
    height: "20",
    color: "#323232",
    viewBox: "0 0 20 20",

    icon: ExportSortIcon,
  },
};

export function ExportSortIcon({ color = "#323232" }) {
  return (
    <>
      <path
        d="M7.33957 10.2564V4.10096L4.76105 6.67948L4.1665 6.08975L7.75625 2.5L11.346 6.08975L10.7514 6.67948L8.17292 4.10096V10.2564H7.33957ZM12.2274 17.5L8.63765 13.9103L9.23221 13.3205L11.8107 15.899V9.74358H12.6441V15.899L15.2226 13.3205L15.8172 13.9103L12.2274 17.5Z"
        fill={color}
      />
    </>
  );
}
