import React from "react";
import { COMPARISON_LAYERS } from "../../consts/editor";

export const COMPARISON_ICONS = {
  [COMPARISON_LAYERS.original]: {
    width: "15",
    height: "15",
    color: "#323232",
    viewBox: "0 0 25 25",

    icon: ComparisonOriginalIcon,
  },
  [COMPARISON_LAYERS.overlay]: {
    width: "15",
    height: "15",
    color: "#323232",
    viewBox: "0 0 25 25",

    icon: ComparisonOverlayIcon,
  },
  [COMPARISON_LAYERS.comparison]: {
    width: "15",
    height: "15",
    color: "#323232",
    viewBox: "0 0 16 16",

    icon: ComparisonComparisonIcon,
  },
};

export function ComparisonOriginalIcon({ color = "#323232" }) {
  return (
    <>
      <path
        d="M19.25 21.5L20.3929 21.5C20.6202 21.4999 20.8381 21.4096 20.9989 21.2489C21.1596 21.0881 21.2499 20.8702 21.25 20.6429L21.25 19.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.25 11.5L9.25 10.3571C9.25008 10.1298 9.34041 9.91187 9.50114 9.75114C9.66187 9.59042 9.87984 9.50008 10.1071 9.5L11.25 9.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.25 14.5L21.25 16.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.25 14.5L9.25 16.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.25 11.5L21.25 10.3571C21.2499 10.1298 21.1596 9.91187 20.9989 9.75114C20.8381 9.59042 20.6202 9.50008 20.3929 9.5L19.25 9.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 21.5L10.1071 21.5C9.87984 21.4999 9.66187 21.4096 9.50114 21.2489C9.34041 21.0881 9.25008 20.8702 9.25 20.6429L9.25 19.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 9.5L16.25 9.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 21.5L16.25 21.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.25 16.5L5.25 16.5C4.98734 16.5001 4.72723 16.4484 4.48455 16.3479C4.24186 16.2474 4.02136 16.1001 3.83563 15.9144C3.64989 15.7286 3.50258 15.5081 3.40209 15.2655C3.30161 15.0228 3.24993 14.7627 3.25 14.5L3.25 5.5C3.24993 5.23734 3.30161 4.97724 3.40209 4.73455C3.50258 4.49187 3.64989 4.27136 3.83562 4.08563C4.02136 3.8999 4.24186 3.75258 4.48455 3.6521C4.72723 3.55161 4.98734 3.49993 5.25 3.5L14.25 3.5C14.5127 3.49993 14.7728 3.55161 15.0155 3.6521C15.2581 3.75258 15.4786 3.8999 15.6644 4.08563C15.8501 4.27136 15.9974 4.49187 16.0979 4.73455C16.1984 4.97723 16.2501 5.23734 16.25 5.5L16.25 6.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function ComparisonOverlayIcon({ color = "#323232" }) {
  return (
    <>
      <path
        d="M9.25 21.5H20.25C20.802 21.5 21.25 21.052 21.25 20.5V9.5C21.25 8.948 20.802 8.5 20.25 8.5H9.25C8.698 8.5 8.25 8.948 8.25 9.5V20.5C8.25 21.052 8.698 21.5 9.25 21.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.25 5.5V4.5C3.25 3.948 3.698 3.5 4.25 3.5H5.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.25 15.5V16.5C3.25 17.052 3.698 17.5 4.25 17.5H5.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 5.5V4.5C17.25 3.948 16.802 3.5 16.25 3.5H15.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 3.5H9.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.25 11.5V9.5V11.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function ComparisonComparisonIcon({
  color = "#FF0000",
  color2 = "#2F80ED",
}) {
  return (
    <>
      <g clipPath="url(#clip0_7514_205526)">
        <path
          d="M5.96094 13.625L12.8359 13.625C13.1809 13.625 13.4609 13.345 13.4609 13L13.4609 6.125C13.4609 5.78 13.1809 5.5 12.8359 5.5L5.96094 5.5C5.61594 5.5 5.33594 5.78 5.33594 6.125L5.33594 13C5.33594 13.345 5.61594 13.625 5.96094 13.625Z"
          stroke={color2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.96094 3.875L9.96094 3.25C9.96098 3.08583 9.92868 2.92327 9.86588 2.77159C9.80308 2.61991 9.711 2.4821 9.59492 2.36602C9.47884 2.24993 9.34102 2.15786 9.18935 2.09506C9.03767 2.03226 8.8751 1.99995 8.71094 2L3.08594 2C2.92177 1.99996 2.75921 2.03226 2.60753 2.09506C2.45585 2.15786 2.31804 2.24993 2.20195 2.36602C2.08587 2.4821 1.9938 2.61992 1.931 2.77159C1.86819 2.92327 1.83589 3.08584 1.83594 3.25L1.83594 8.875C1.83589 9.03917 1.86819 9.20173 1.931 9.35341C1.9938 9.50509 2.08587 9.6429 2.20195 9.75898C2.31804 9.87507 2.45585 9.96714 2.60753 10.0299C2.75921 10.0927 2.92177 10.125 3.08594 10.125L3.71094 10.125"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7514_205526">
          <rect
            width="15"
            height="15"
            fill="white"
            transform="translate(0.335938 0.5)"
          />
        </clipPath>
      </defs>
    </>
  );
}
