export const getFinalViewName = (view: any): string =>
  `${view?.page?.metadata?.cv_number || view?.metadata?.cv_number || ""}${
    view?.page?.metadata?.cv_name || view?.metadata?.cv_name || view?.page?.name
  }` || "";

export const getViewName = (view: any) =>
  view?.page?.metadata?.cv_name ||
  view?.metadata?.cv_name ||
  view?.page?.name ||
  view?.name ||
  "";

export const getViewNumber = (view: any) =>
  view?.page?.metadata?.cv_number || view?.metadata?.cv_number || "";

export const getPageNameFromPageObject = (page: any) => {
  return (
    page?.metadata?.cv_name ||
    (page?.views || []).find((v: any) => v?.metadata?.cv_name) ||
    page?.name ||
    ""
  );
};

export const getPageNumberFromPageObject = (page: any) => {
  return (
    page?.metadata?.cv_number ||
    (page?.views || []).find((p: any) => p?.metadata?.cv_number) ||
    ""
  );
};

export const getFinalPageNameFromPageObject = (page: any): string =>
  `${getPageNumberFromPageObject(page)} ${getPageNameFromPageObject(page)}` ||
  "";

export const toLowerCase = (str: string): string => str.toLowerCase();
