import React from "react";

import QuantityPanel from "./Components/QuantityPanel";
import ShortcutsList from "./Components/ShortcutsList";

import useShortcuts from "./hook";

import {
  Background,
  Container,
  CancelIconContainer,
  ShortcutsContainer,
} from "./styles";

function ShortcutsGuid() {
  const { close, visible } = useShortcuts();

  if (!visible) return null;

  return (
    <Container>
      <CancelIconContainer onClick={close} />
      <Background>
        <ShortcutsContainer>
          <QuantityPanel />
          <ShortcutsList />
        </ShortcutsContainer>
      </Background>
    </Container>
  );
}

export default ShortcutsGuid;
