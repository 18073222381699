export const ROLE_USER = "user";
export const ROLE_INFO = "info";
export const ROLE_ERROR = "error";
export const ROLE_ASSISTANT = "assistant";

export const THIS_SET = "This Set";
export const ALL_PROJECTS = "All Projects";
export const THIS_PROJECT = "This Project";
export const THIS_DRAWING = "This Drawing";

export const PANEL_WIDTH_SNAP = 40;
export const MIN_PANEL_WIDTH = 400;

export const DEFAULT_CONVERSATION = [
  {
    id: 1,
    role: ROLE_ASSISTANT,
    content: `**Hey, welcome to Togal GPT. Think of this as a place to have a conversation with someone who has read and consumed your building plans.** \n <br/><br/> **For now, I can only consume text based information, so I work well with written specifications. In the future I will be able to consume take-off information including geometry, measurements and objects.** \n <br/><br/> **Go ahead ... ask me anything.**`,
  },
];

export const DEFAULT_CONVERSATION_IDS = DEFAULT_CONVERSATION.map((c) => c.id);

export const DROPDOWN_IDS = {
  drawing: 0,
  set: 1,
  project: 2,
};

interface DropdownVisibleProps {
  setID?: string;
  orgID?: string;
  viewID?: string;
  pageID?: string;
  projectID?: string;
}

export const DROPDOWN_OPTIONS = [
  {
    id: DROPDOWN_IDS.project,
    label: "project",
    visible: (props: DropdownVisibleProps) => props?.projectID,
    disabled: (props: DropdownVisibleProps) => !props?.projectID,
    getId: (props: DropdownVisibleProps) => props?.projectID,
    apiProps: (props: DropdownVisibleProps) => ({
      project_id: props.projectID,
      organization_id: props.orgID,
    }),
  },
  {
    id: DROPDOWN_IDS.set,
    label: "set",
    visible: (props: DropdownVisibleProps) => props?.setID,
    disabled: (props: DropdownVisibleProps) => !props?.setID,
    getId: (props: DropdownVisibleProps) => props?.setID,
    apiProps: (props: DropdownVisibleProps) => ({
      set_id: props.setID,
      organization_id: props.orgID,
    }),
  },
  {
    id: DROPDOWN_IDS.drawing,
    label: "drawing",
    visible: (props: DropdownVisibleProps) => props?.viewID,
    disabled: (props: DropdownVisibleProps) => !props?.viewID,
    getId: (props: DropdownVisibleProps) => props?.viewID,
    apiProps: (props: DropdownVisibleProps) => ({
      page_id: props.pageID,
      organization_id: props.orgID,
    }),
  },
];
