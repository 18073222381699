import React from "react";
import styled from "styled-components";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

import { ReactComponent as FileIcon } from "src/svg/file.ai.svg";
import { ReactComponent as LoadingIcon } from "src/svg/loading.svg";
import { ReactComponent as FileRedIcon } from "src/svg/file.red.svg";
import { ReactComponent as DowloadIcon } from "src/svg/arrow_down.svg";

import { EXPORT_START } from "../const";

import { timeSince } from "src/lib/utils";
import { CSS, FONT_WEIGHTS } from "sf/consts/style";
import useNotification from "../hooks/useNotification";
import TooltipWrapper from "sf/components/TooltipWrapper";
import { notitificationsVisibleState } from "src/state/notifications";
import { NotificationItem, AbsContainer, MarkButton } from "../style";
import { ProfileHeadContainer } from "src/components/ViewManager/style";
import { Flex, Para, ParaWithEllipsis, Separator } from "sf/components/Styled";

function downloadFile(filePath, name) {
  var link = document.createElement("a");
  link.href = filePath;
  link.download = name;
  link.click();
  link.remove();
}

const NotificationItemWrappedGet = ({ isPage }) =>
  !isPage
    ? styled(NotificationItem)`
        .download-icon {
          display: none;
        }
      `
    : styled(NotificationItem)`
        .download-icon {
          display: none;
        }
        &:hover {
          .download-icon {
            display: block !important;
          }
        }
        :hover {
          .file-icon {
            display: none;
          }
        }
      `;

export default function Notifications({
  isLoading,
  notification,
  markAsRead,
  isPage,
}) {
  const getCountReady = (notification) => {
    const { payload } = notification;
    const { multipageObject } = payload;
    return ` ${Object.keys(multipageObject?.pages_urls).length} of ${
      multipageObject?.page_ids?.length
    }`;
  };
  const navigate = useNavigate();
  const {
    description,
    isFailedPDF,
    isUrl,
    profileColor,
    profileHeaderName,
    isMultipagePDF,
  } = useNotification({ notification });
  const NotificationItemWrapped = NotificationItemWrappedGet({ isPage });

  const setVisible = useSetRecoilState(notitificationsVisibleState);

  return (
    <NotificationItemWrapped
      isPage={isPage}
      notification={notification}
      style={{
        cursor: "pointer",
      }}
      onClick={() => {
        if (!isPage) {
          navigate("/dashboard/Notifications");
          setVisible(false);
          markAsRead(notification);
          return;
        }
        if (isUrl && !isFailedPDF) {
          downloadFile(
            notification?.payload?.multipageObject?.export_url,
            description?.split("/").pop() || "",
            isPage,
            navigate
          );
        }
        markAsRead(notification);
      }}
    >
      {!notification?.read && !isLoading && !isPage && (
        <AbsContainer>
          <MarkButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              markAsRead(notification);
            }}
          >
            Mark as Read
          </MarkButton>
        </AbsContainer>
      )}
      <Flex width="30px">
        {!notification?.read && (
          <Flex
            width="10px"
            height="10px"
            bgColor={isFailedPDF ? CSS.ERROR : CSS.MAINCOLOR}
            style={{ borderRadius: "10px" }}
          />
        )}
      </Flex>
      <ProfileHeadContainer
        size={"40px"}
        style={{
          backgroundColor: isMultipagePDF
            ? isFailedPDF
              ? CSS.PINK
              : CSS.MAINCOLORLIGHT
            : profileColor,
        }}
      >
        {isMultipagePDF ? (
          isFailedPDF ? (
            <FileRedIcon />
          ) : notification?.type === EXPORT_START ? (
            <LoadingIcon />
          ) : (
            <>
              <DowloadIcon
                className="download-icon"
                style={{
                  cursor: "pointer",
                  fontSize: "100px",
                  color: CSS.BLUE,
                  transition: "fill .4s ease",
                }}
              />
              <FileIcon className="file-icon" />
            </>
          )
        ) : (
          profileHeaderName
        )}
      </ProfileHeadContainer>
      <Separator width="10px" />
      <Flex width="calc(100% - 100px)" flex="column">
        {isMultipagePDF && (
          <Flex justify="flex-start">
            <TooltipWrapper
              position="top"
              indent={15}
              text={
                isFailedPDF
                  ? "PDF failed"
                  : notification?.type === EXPORT_START
                  ? "PDF is generating"
                  : "PDF created"
              }
            >
              <ParaWithEllipsis fontWeight={FONT_WEIGHTS.EXTRABOLD}>
                {isFailedPDF
                  ? "PDF failed"
                  : notification?.type === EXPORT_START
                  ? "PDF is generating"
                  : "PDF created"}
              </ParaWithEllipsis>
            </TooltipWrapper>
          </Flex>
        )}

        <Separator height="4px" />
        <Flex justify="flex-start">
          {isMultipagePDF && (
            <TooltipWrapper
              position="bottom"
              indent={15}
              text={`${description.split("/").pop()}`}
            >
              <ParaWithEllipsis>
                {isFailedPDF
                  ? `Something went wrong with ${notification?.payload?.multipageObject?.name}`
                  : notification?.type === EXPORT_START
                  ? `PDF ${
                      notification?.payload?.multipageObject?.name || ""
                    } pages ready: ${getCountReady(notification)}`
                  : `PDF ${description.split("/").pop()} is ready for download`}
              </ParaWithEllipsis>
            </TooltipWrapper>
          )}
        </Flex>
        <Separator height="4px" />
        <Flex justify="flex-start">
          {isMultipagePDF ? (
            <TooltipWrapper>
              <Para style={{ flexShrink: 0 }}>
                {timeSince(new Date(notification?.created))} ago{" "}
              </Para>
            </TooltipWrapper>
          ) : (
            <TooltipWrapper position="bottom" indent={15} text={description}>
              <ParaWithEllipsis>{description}</ParaWithEllipsis>
              <Para style={{ flexShrink: 0 }}>
                {timeSince(new Date(notification?.created))} ago{" "}
              </Para>
            </TooltipWrapper>
          )}
        </Flex>
      </Flex>
    </NotificationItemWrapped>
  );
}
