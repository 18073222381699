import request from "../request";
import { withGlobalOrg, withGlobalOrgWhere } from "./_helpers";

export async function listClassifications(query) {
  const { body } = await request.get("classification", {
    query: withGlobalOrgWhere(query),
  });
  return body;
}

export async function addClassification(props) {
  const { body } = await request.post("classification", {
    body: withGlobalOrg(props),
  });
  return body;
}

export async function getClassificationById({ id }) {
  const { body } = await request.get(`classification/${id}`);
  return body;
}

export async function updateClassificationById({ id, ...rest }) {
  const { body } = await request.put(`classification/${id}`, {
    body: withGlobalOrg(rest),
  });
  return body;
}

export async function removeClassificationById(id) {
  const { success } = await request.delete(`classification/${id}`);
  return success;
}
