import Fraction from "fraction.js";
import cuid from "cuid";

import { curry, pipe } from "./functional/function";
import { replace, split, trim } from "./functional/string";
import {
  INCHES_TO_CENTIMETERS,
  INCHES_TO_FEET,
  INCHES_TO_METERS,
  METERS_TO_MILLIMETERS,
} from "../consts/geometry";
import { head, join, map, reverse, splitEvery } from "./functional/list";

const SEPARATOR_TYPES = {
  DECIMAL: "decimal",
  GROUP: "group",
};

export const generateId = () => cuid();

const snapToEighth = (value: number) => Math.round(value * 8) / 8;

function prettyMillimeters(mm: number) {
  const shouldShowMm = mm < 1000;

  const meters = Math.floor(mm / 1000);
  mm %= 1000;

  const centimeters = Math.floor(mm / 10);
  mm %= 10;

  let result = "";
  if (meters > 0) result += `${meters}m `;
  if (centimeters > 0) result += `${centimeters}cm `;
  if (shouldShowMm && (mm > 0 || result === ""))
    result += `${Math.round(mm)}mm`;

  return result.trim();
}

export const prettyInches = (
  value: number,
  hideFraction: boolean = false,
  useMetrics: boolean = false,
  returnValues: boolean = false,
  toFixedValue: number = null
) => {
  if (isNaN(value)) return 0;
  if (useMetrics) {
    return returnValues
      ? value / INCHES_TO_METERS
      : prettyMillimeters((value / INCHES_TO_METERS) * METERS_TO_MILLIMETERS);
  }

  value = toFixedValue && returnValues ? value : snapToEighth(value);

  let feet = Math.floor(value / INCHES_TO_FEET);
  let inches;

  if (hideFraction) {
    inches =
      toFixedValue && returnValues
        ? parseFloat((value - feet * INCHES_TO_FEET).toFixed(toFixedValue))
        : Math.round(value - feet * INCHES_TO_FEET);

    if (inches === INCHES_TO_FEET) {
      feet++;
      inches = 0;
    }
  } else {
    let fraction = new Fraction(value - feet * INCHES_TO_FEET);

    fraction = fraction.simplify(0.1);
    inches = fraction.toFraction(true);
  }

  return returnValues
    ? [feet, inches]
    : [feet ? `${feet}'` : null, `${inches}"`].filter(Boolean).join(" ");
};

export const capitalize = (str: string): string => {
  const [head, ...rest] = str?.split("");
  return [head.toUpperCase(), rest.join("").toLowerCase()].join("");
};

export const ellipsis = curry((limit: number, str: string): string =>
  str?.length > limit ? str?.substring(0, limit) + "..." : str
);

export const roundNumber = (value: number, precision: number = 2): string =>
  (
    Math.round(Math.pow(10, precision) * value) / Math.pow(10, precision)
  ).toFixed(precision);

export const lowerCaseIncludes = curry(
  (mainString?: string, includedString?: string): boolean =>
    mainString?.toLowerCase().includes(includedString?.toLowerCase())
);

export const getCurrentBrowserLocale = (): string =>
  window.navigator.languages
    ? head(window.navigator.languages as string[])
    : window.navigator.language;

export const getCurrentSystemLocale = (): string =>
  Intl.DateTimeFormat().resolvedOptions().locale;

// https://stackoverflow.com/questions/1074660/with-a-browser-how-do-i-know-which-decimal-separator-does-the-operating-system
export const getSeparator = (type: string, locale: string) => {
  const numberWithGroupAndDecimal = 1000.1;
  return (
    Intl.NumberFormat(locale)
      .formatToParts(numberWithGroupAndDecimal)
      .find((part) => part.type === type)?.value || ""
  );
};

export const addThousandSeparators = curry(
  (locale: string | null, numberAsString: string): string => {
    const [characteristic, mantissa] = numberAsString.split(".");

    return [
      pipe(
        split(""),
        reverse,
        splitEvery(3),
        map(pipe(reverse, join(""))),
        reverse,
        join(getSeparator(SEPARATOR_TYPES.GROUP, locale))
      )(characteristic),
      mantissa,
    ].join(getSeparator(SEPARATOR_TYPES.DECIMAL, locale));
  }
);

export function normalizeNumber(value: string): string {
  return pipe(
    trim,
    replace(/[^\d,.]/g, ""),
    replace(/,/g, "."),
    split("."),
    ([head, ...rest]: string[]) =>
      rest.length ? [head, rest.join("")] : [head],
    join(".")
  )(value);
}

export const formatNumber = (value: number): string =>
  Intl.NumberFormat(getCurrentBrowserLocale()).format(Math.round(value) || 0);

export const prettyNumber = (value: number): string =>
  pipe(roundNumber, addThousandSeparators(getCurrentBrowserLocale()))(value);

export const toExcelNumber = (value: number): string =>
  pipe(roundNumber, addThousandSeparators(getCurrentSystemLocale()))(value);
