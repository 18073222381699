export async function downloadImage(url: string) {
  const img = new Image();
  img.crossOrigin = "Anonymous";
  img.src = url + "?cache=none?dummy=" + new Date().getTime();

  await new Promise((resolve, reject) => {
    img.onload = resolve;
    img.onerror = reject;
  });

  return img;
}

export const getMapImage = async (url: string) => {
  const img = new Image();
  img.crossOrigin = "anonymous";
  img.src = url + "?cache=none";
  await new Promise((resolve) => (img.onload = resolve));

  return img;
};

export function getMapImageData(img: HTMLImageElement) {
  const cvs = document.createElement("canvas");
  const ctx = cvs.getContext("2d") as CanvasRenderingContext2D;
  cvs.width = img.width;
  cvs.height = img.height;
  ctx.drawImage(img, 0, 0);
  const data = ctx.getImageData(0, 0, img.width, img.height);
  cvs.remove();
  return data;
}
