import { OrthographicView } from "@deck.gl/core";
import bearing from "@turf/bearing";
import { point } from "@turf/helpers";
import destination from "@turf/destination";

import { RGBA_COLORS } from "../consts/style";
import { RgbaRepresentation } from "../types/editor";
import pointToLineDistance from "@turf/point-to-line-distance";
import { MODE_RECTANGLE, MODE_SPHERE } from "../consts/coordinates";
import {
  GEOJSON_TYPES,
  ARC_EDIT_HANDLE,
  ARC_CONTROL_EDIT_HANDLE,
} from "../consts/editor";

export function toDeckColor(
  color: RgbaRepresentation = RGBA_COLORS.RED,
  defaultColor: RgbaRepresentation = RGBA_COLORS.RED
) {
  if (!Array.isArray(color)) {
    return defaultColor;
  }
  return [color[0] * 255, color[1] * 255, color[2] * 255, color[3] * 255];
}

//
// a GeoJSON helper function that calls the provided function with
// an argument that is the most deeply-nested array having elements
// that are arrays of primitives as an argument, e.g.
//
// {
//   "type": "MultiPolygon",
//   "coordinates": [
//       [
//           [[30, 20], [45, 40], [10, 40], [30, 20]]
//       ],
//       [
//           [[15, 5], [40, 10], [10, 20], [5, 10], [15, 5]]
//       ]
//   ]
// }
//
// the function would be called on:
//
// [[30, 20], [45, 40], [10, 40], [30, 20]]
//
// and
//
// [[15, 5], [40, 10], [10, 20], [5, 10], [15, 5]]
//
export function recursivelyTraverseNestedArrays<T>(
  array: T[] | T[][],
  prefix: T[] | T,
  fn: (arg: T[], prefix: T) => T
): boolean {
  if (!Array.isArray(array[0])) {
    return true;
  }
  for (let i = 0; i < array.length; i++) {
    if (
      recursivelyTraverseNestedArrays(
        array[i] as T[],
        [...(prefix as T[]), i],
        fn
      )
    ) {
      fn(array as T[], prefix as T);
      break;
    }
  }
  return false;
}

export function generatePointsParallelToLinePoints(
  p1: number[],
  p2: number[],
  mapCoords: number[]
): number[][] {
  const pad = 200;
  p1 = p1.map((p) => p / pad);
  p2 = p2.map((p) => p / pad);
  mapCoords = mapCoords.map((p) => p / pad);

  const lineString = {
    type: GEOJSON_TYPES.LineString as any,
    coordinates: [p1, p2],
  };
  const pt = point(mapCoords);
  const ddistance = pointToLineDistance(pt, lineString);
  const lineBearing = bearing(p1, p2);

  // Check if current point is to the left or right of line
  // Line from A=(x1,y1) to B=(x2,y2) a point P=(x,y)
  // then (x−x1)(y2−y1)−(y−y1)(x2−x1)
  const isPointToLeftOfLine =
    (mapCoords[0] - p1[0]) * (p2[1] - p1[1]) -
    (mapCoords[1] - p1[1]) * (p2[0] - p1[0]);

  // Bearing to draw perpendicular to the line string
  const orthogonalBearing =
    isPointToLeftOfLine < 0 ? lineBearing - 90 : lineBearing - 270;

  // Get coordinates for the point p3 and p4 which are perpendicular to the lineString
  // Add the distance as the current position moves away from the lineString
  const p3 = destination(p2, ddistance, orthogonalBearing);
  const p4 = destination(p1, ddistance, orthogonalBearing);

  const p3Coords = p3.geometry.coordinates.map((p) => p * pad);
  const p4Coords = p4.geometry.coordinates.map((p) => p * pad);

  return [p3Coords, p4Coords];
}

export function distance2d(
  x1: number,
  y1: number,
  x2: number,
  y2: number
): number {
  const dx = x1 - x2;
  const dy = y1 - y2;
  return Math.sqrt(dx * dx + dy * dy);
}

interface IPointInfo {
  index?: number;
  x0?: number;
  y0?: number;
}

export function nearestPointOnProjectedLine(
  line: any,
  inPoint: any,
  viewport: any
) {
  // const wmViewport = new WebMercatorViewport(viewport);
  const wmViewport: any = new OrthographicView(viewport);

  const coordinates = line.geometry.coordinates;
  const projectedCoords = coordinates.map(
    ([x, y, z = 0]: [number, number, number]) => wmViewport.project([x, y, z])
  );

  const [x, y] = wmViewport.project(inPoint.geometry.coordinates);

  let minDistance = Infinity;
  let minPointInfo: IPointInfo = {};

  projectedCoords.forEach(([x2, y2]: [number, number], index: number) => {
    if (index === 0) {
      return;
    }

    const [x1, y1] = projectedCoords[index - 1];

    const A = y1 - y2;
    const B = x2 - x1;
    const C = x1 * y2 - x2 * y1;

    const div = A * A + B * B;
    const distance = Math.abs(A * x + B * y + C) / Math.sqrt(div);

    if (distance < minDistance) {
      minDistance = distance;
      minPointInfo = {
        index,
        x0: (B * (B * x - A * y) - A * C) / div,
        y0: (A * (-B * x + A * y) - B * C) / div,
      };
    }
  });

  const { index, x0, y0 } = minPointInfo;
  const [x1, y1, z1 = 0] = projectedCoords[index - 1];
  const [x2, y2, z2 = 0] = projectedCoords[index];

  const lineLength = distance2d(x1, y1, x2, y2);
  const startToPointLength = distance2d(x1, y1, x0, y0);
  const ratio = startToPointLength / lineLength;
  const z0 = mix(z1, z2, ratio);

  return {
    type: GEOJSON_TYPES.Feature,
    geometry: {
      type: GEOJSON_TYPES.Point,
      coordinates: wmViewport.unproject([x0, y0, z0]),
    },
    properties: {
      // TODO: calculate the distance in proper units
      dist: minDistance,
      index: index - 1,
    },
  };
}

export function mix(a: number, b: number, ratio: number): number {
  return b * ratio + a * (1 - ratio);
}

export function getPickedEditHandle(picks: any[]) {
  const handles = getPickedEditHandles(picks);
  return handles.length ? handles[0] : null;
}

export function getPickedSnapSourceEditHandle(picks: any[]) {
  const handles = getPickedEditHandles(picks);
  return handles.find(
    (handle: any) => handle.properties.editHandleType === "snap-source"
  );
}

// from http://jsfiddle.net/alnitak/hEsys/
export function getValueByKey(object: any, key: string): any {
  key = key.replace(/\[(\w+)\]/g, ".$1");
  key = key.replace(/^\./, "");
  const a = key.split(".");

  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (k in object) {
      object = object[k];
    } else {
      return;
    }
  }
  return object;
}

export function getNonGuidePicks(picks: any[]) {
  return picks && picks.filter((pick) => !pick.isGuide);
}

export function getPickedExistingEditHandle(picks: any[]) {
  const handles = getPickedEditHandles(picks);
  return handles.find(
    ({ properties }) =>
      properties.featureIndex >= 0 && properties.editHandleType === "existing"
  );
}

export function getPickedIntermediateEditHandle(picks: any[]) {
  const handles = getPickedEditHandles(picks);
  return handles.find(
    ({ properties }) =>
      properties.featureIndex >= 0 &&
      properties.editHandleType === "intermediate"
  );
}

export function getPickedEditHandles(picks: any[]) {
  return (
    (picks &&
      picks
        .filter(
          (pick: any) =>
            pick?.isGuide &&
            pick?.object?.properties?.guideType === "editHandle"
        )
        .map((pick: any) => pick.object)) ||
    []
  );
}

export function getEditHandlesForGeometry(
  geometry: any,
  featureIndex: number = 0,
  editHandleType: string = "existing",
  onlyBorders: boolean = false,
  id = ""
) {
  let handles: any[] = [];

  switch (geometry.type) {
    case GEOJSON_TYPES.Point:
      // positions are not nested
      handles = [
        {
          type: GEOJSON_TYPES.Feature,
          properties: {
            id,
            guideType: "editHandle",
            editHandleType,
            positionIndexes: [],
            featureIndex,
          },
          geometry: {
            type: GEOJSON_TYPES.Point,
            coordinates: geometry.coordinates,
          },
        },
      ];
      break;
    case GEOJSON_TYPES.MultiPoint:
    case GEOJSON_TYPES.LineString:
      // positions are nested 1 level
      handles = handles.concat(
        getEditHandlesForCoordinates(
          geometry.coordinates,
          [],
          featureIndex,
          editHandleType,
          onlyBorders,
          id
        )
      );
      break;
    case GEOJSON_TYPES.Polygon:
    case GEOJSON_TYPES.MultiLineString:
      // positions are nested 2 levels
      for (let a = 0; a < geometry.coordinates.length; a++) {
        handles = handles.concat(
          getEditHandlesForCoordinates(
            geometry.coordinates[a],
            [a],
            featureIndex,
            editHandleType,
            false,
            id
          )
        );
        if (geometry.type === GEOJSON_TYPES.Polygon) {
          // Don't repeat the first/last handle for Polygons
          handles = handles.slice(0, -1);
        }
      }

      break;
    case GEOJSON_TYPES.MultiPolygon:
      // positions are nested 3 levels
      for (let a = 0; a < geometry.coordinates.length; a++) {
        for (let b = 0; b < geometry.coordinates[a].length; b++) {
          handles = handles.concat(
            getEditHandlesForCoordinates(
              geometry.coordinates[a][b],
              [a, b],
              featureIndex,
              editHandleType,
              false,
              id
            )
          );
          // Don't repeat the first/last handle for Polygons
          handles = handles.slice(0, -1);
        }
      }

      break;
    default:
      throw Error(`Unhandled geometry type: ${geometry.type}`);
  }

  return handles;
}

export function getDimensionLineFeature(feature: any) {
  if (feature?.properties?.types?.includes(GEOJSON_TYPES.dimensionLine)) {
    return {
      ...feature,
      geometry: {
        type: GEOJSON_TYPES.LineString,
        coordinates:
          feature?.geometry?.type === GEOJSON_TYPES.MultiLineString
            ? feature.geometry.coordinates[0]
            : feature.geometry.coordinates,
      },
    };
  }

  return feature;
}
export function getEditHandlesForFeature(
  feature: any,
  featureIndex: number = 0,
  editHandleType: string = "existing",
  onlyBorders: boolean = false,
  id = ""
) {
  const geometry = feature.geometry;

  const handles = getEditHandlesForGeometry(
    geometry,
    featureIndex,
    editHandleType,
    onlyBorders,
    id
  );

  if (
    feature?.properties?.types?.includes(GEOJSON_TYPES.markup) &&
    feature?.properties?.correspondingTextId
  ) {
    handles.shift();
  }

  if (
    feature.properties?.arcs?.length > 0 &&
    (feature.geometry.type === GEOJSON_TYPES.Polygon ||
      feature.geometry.type === GEOJSON_TYPES.LineString)
  ) {
    const allArcStartIndexes = feature.properties.arcs.map(
      (arc: any) => arc.startPointIdx
    );
    const allArcEndIndexes = feature.properties.arcs.map(
      (arc: any) => arc.endPointIdx
    );
    const allArcsIgnoreIndices = feature.properties.arcs.flatMap(
      (arc: any) => arc.ignoreIndices
    );
    const allArcIndices = [
      ...allArcStartIndexes,
      ...allArcEndIndexes,
      ...allArcsIgnoreIndices,
    ];

    const trimmedHandles: any = [];
    let arcIndexToKeep = -1;

    handles.forEach((handle: any, idx: number) => {
      if (allArcIndices.includes(idx)) {
        const arc = feature.properties.arcs.find((arc: any) =>
          [arc.startPointIdx, arc.endPointIdx, ...arc.ignoreIndices].includes(
            idx
          )
        );

        const is2pointsMode =
          arc?.type === MODE_RECTANGLE || arc?.type === MODE_SPHERE;

        const arcs = feature.properties.arcs.filter((arc: any) =>
          [arc.startPointIdx, arc.endPointIdx].includes(idx)
        );

        if (arc) {
          arcIndexToKeep =
            arc.ignoreIndices[Math.ceil((arc.ignoreIndices.length - 1) / 2)];

          const updatedHandle = handle;

          if (
            allArcEndIndexes.includes(idx) ||
            allArcStartIndexes.includes(idx)
          ) {
            updatedHandle.properties.arcIds = arcs.map((a: any) => a.id);
            updatedHandle.properties.type = ARC_EDIT_HANDLE;
            trimmedHandles.push(updatedHandle);
          }

          if (arcIndexToKeep === idx && !is2pointsMode) {
            updatedHandle.properties.arcIds = [arc.id];
            updatedHandle.properties.type = ARC_CONTROL_EDIT_HANDLE;
            trimmedHandles.push(updatedHandle);
          }
        }
      } else {
        if (
          feature?.properties?.arcs?.length > 0 &&
          [MODE_RECTANGLE, MODE_SPHERE].includes(
            feature?.properties?.arcs[0].type
          )
        ) {
          //ignore this case
        } else {
          trimmedHandles.push(handle);
        }
      }
    });

    return trimmedHandles;
  }

  return handles;
}

function getEditHandlesForCoordinates(
  coordinates: any,
  positionIndexPrefix: any,
  featureIndex: number,
  editHandleType: string = "existing",
  onlyBorders: boolean = false,
  id = ""
) {
  const editHandles = [];
  for (let i = 0; i < coordinates.length; i++) {
    const position = coordinates[i];

    if (onlyBorders) {
      if (i === 0 || i === coordinates.length - 1) {
        editHandles.push({
          type: GEOJSON_TYPES.Feature,
          properties: {
            guideType: "editHandle",
            positionIndexes: [...positionIndexPrefix, i],
            featureIndex,
            editHandleType,
            id,
          },
          geometry: {
            type: GEOJSON_TYPES.Point,
            coordinates: position,
          },
        });
      }
    } else {
      editHandles.push({
        type: GEOJSON_TYPES.Feature,
        properties: {
          guideType: "editHandle",
          positionIndexes: [...positionIndexPrefix, i],
          featureIndex,
          editHandleType,
          id,
        },
        geometry: {
          type: GEOJSON_TYPES.Point,
          coordinates: position,
        },
      });
    }
  }
  return editHandles;
}
