import { FeatureCollection } from "geojson";

import { last } from "../../utils/functional/list";
import { GEOJSON_TYPES } from "../../consts/editor";
import GeoJsonEditMode from "../base/GeojsonEditMode";
import { getBufferedGeometry } from "../../modes/mutate/utils";
import {
  lineToPolygon,
  generateJoints,
  getNewDrainLine,
  roundFeatureCoords,
  generate3dPipeline,
  getUpdatedPipelines,
  getPipelineModeDetails,
  getPipelinesNearestPoint,
} from "../../utils/pipeline";

import {
  getStateTooltip,
  createSnapTargets,
  getDistanceTooltip,
  createTentativeFeature,
} from "../../utils/coordinates";
import { generateId } from "../../utils/string";
import { getEditHandlesForFeature } from "../../utils/modes";

export class DrawPipelineMode extends GeoJsonEditMode {
  indexedTargets: any;

  _lineFeature: any;
  _cache: any = new Map();

  _joints: any = [];
  _drains: any = [];
  _nearestPoint: any;
  _pipelines: any = [];
  _pipelineMesh: any = null;

  constructor() {
    super();
  }

  _resetState = () => {
    this.resetClickSequence();

    this._lineFeature = null;
    this.indexedTargets = null;
  };

  get3dGuides = (props: any) => {
    return this._pipelineMesh;
  };

  getGuides = (props: any) => {
    const { lastPointerMoveEvent, modeConfig } = props;
    const modeDetails = getPipelineModeDetails(modeConfig?.pipelineMode);

    if (!modeDetails.isPipelineMode && this._clickSequence.length > 0) {
      this._resetState();
    }

    const guideType = props?.modeConfig?.guideType || "tentative";

    if (!this.indexedTargets) {
      this.indexedTargets = createSnapTargets([], []);
    }

    const guides: FeatureCollection = {
      type: GEOJSON_TYPES.FeatureCollection,
      features: [],
    };

    if (
      (modeDetails.isPipelineMode || modeDetails.isDrainMode) &&
      this._pipelines?.length > 0 &&
      this._clickSequence.length === 0
    ) {
      const mapCoords = lastPointerMoveEvent && lastPointerMoveEvent.mapCoords;
      try {
        const nearest = getPipelinesNearestPoint(this._pipelines, mapCoords);

        if (nearest) {
          this._nearestPoint = nearest;
          guides.features.push(nearest);
        } else {
          this._nearestPoint = null;
        }
      } catch (error) {
        console.error("error", error);
        this._nearestPoint = null;
      }
    } else if (this._nearestPoint) {
      this._nearestPoint = null;
    }

    if (modeDetails.isPipelineMode) {
      if (this._clickSequence.length > 0) {
        const [mainFeature, ...features] = createTentativeFeature(
          props,
          this,
          guideType
        );

        const mainLine = {
          ...mainFeature,
          geometry: {
            type: GEOJSON_TYPES.LineString,
            coordinates:
              mainFeature.geometry.type === GEOJSON_TYPES.MultiLineString
                ? mainFeature.geometry.coordinates[0]
                : mainFeature.geometry.coordinates,
          },
        };

        this._lineFeature = mainLine;
        guides.features = guides.features.concat(
          getEditHandlesForFeature(this._lineFeature, -1)
        );

        if (this._lineFeature) {
          const linePolygon = lineToPolygon(
            this._lineFeature,
            modeDetails.pipelineWidth,
            this._cache
          );
          if (linePolygon) {
            this._lineFeature = {
              ...this._lineFeature,
              properties: {
                ...this._lineFeature.properties,
                linePolygon,
                pipelineWidth: modeDetails.pipelineWidth,
              },
            };

            guides.features.push(linePolygon);
          }
        }
      }
    }

    if (this._pipelines.length > 0) {
      guides.features = guides.features.concat(
        this._pipelines.map((p: any) => p.properties.linePolygon || p)
      );
    }

    guides.features = guides.features.concat(this._drains);
    guides.features = guides.features.concat(this._joints);

    if (this._pipelines.length > 0) {
      this._pipelines.forEach((p: any) => {
        guides.features = guides.features.concat(
          getEditHandlesForFeature(p, -1)
        );
      });
    }

    return guides;
  };

  getModeTooltips = (props: any) => {};

  getTooltips = (props: any) => {
    const { modeConfig } = props;
    if (modeConfig?.guideType === GEOJSON_TYPES.markup) return [];

    const distanceTooltips = getDistanceTooltip(props, this);
    const stateTooltips = getStateTooltip(props, this);

    const modeTooltips: any = this.getModeTooltips(props);

    return [...distanceTooltips, ...stateTooltips, ...(modeTooltips || [])];
  };

  handleAddFeature = (event: any, props: any) => {
    this._pipelines.push(
      roundFeatureCoords({
        ...this._lineFeature,
        properties: {
          ...this._lineFeature.properties,
          id: generateId(),
        },
      })
    );
    this._resetState();

    this._joints = generateJoints(this._pipelines, this._cache);

    if (this._pipelines.length > 0) {
      this.generateMesh();
    }
  };

  // handleDblClick = (event: any, props: any) => {
  // const { picks } = event;
  // const clickedEditHandle = getPickedEditHandle(picks);
  //   this.handleAddFeature(event.sourceEvent, props);
  // };

  handleClick = (event: any, props: any) => {
    const isShiftKey = event.sourceEvent.shiftKey;

    const modeDetails = getPipelineModeDetails(props?.modeConfig?.pipelineMode);

    if (modeDetails.isPipelineMode) {
      const nearestCoordinates = isShiftKey
        ? null
        : this._nearestPoint?.geometry?.coordinates;
      const coordinates = this._lineFeature?.geometry?.coordinates;
      const lastCoordinate =
        coordinates?.length > 0
          ? Array.isArray(last(coordinates[0]))
            ? last(coordinates[0])
            : last(coordinates)
          : null;

      if (this._nearestPoint && !isShiftKey) {
        const updatedData = this._nearestPoint.properties.snapped
          ? this._pipelines
          : getUpdatedPipelines(
              this._pipelines,
              this._nearestPoint,
              props?.modeConfig
            );
        this._pipelines = updatedData;
      }

      const mapCoords = nearestCoordinates || lastCoordinate || event.mapCoords;
      this.addClickSequence({ mapCoords });
    }

    if (modeDetails.isDrainMode) {
      const nearestCoordinates = isShiftKey
        ? null
        : this._nearestPoint?.geometry?.coordinates;
      const mapCoords = event.mapCoords;

      if (nearestCoordinates && !isShiftKey) {
        const updatedData = this._nearestPoint.properties.snapped
          ? this._pipelines
          : getUpdatedPipelines(
              this._pipelines,
              this._nearestPoint,
              props?.modeConfig
            );

        const newDrainLine = getNewDrainLine(mapCoords, nearestCoordinates);

        this._pipelines = [...updatedData, newDrainLine];
        this._joints = generateJoints(this._pipelines, this._cache);
      }

      const pointFeature = {
        type: GEOJSON_TYPES.Feature,
        geometry: {
          type: GEOJSON_TYPES.Point,
          coordinates: [mapCoords[0], mapCoords[1]],
        },
        properties: {
          types: ["point"],
          color: modeDetails.pipelineColor,
        },
      };

      const bufferedPoint = getBufferedGeometry(pointFeature, 1);
      if (bufferedPoint) {
        this._drains = [...this._drains, roundFeatureCoords(bufferedPoint)];
      }

      this.generateMesh();
    }
  };

  generateMesh = async () => {
    this._pipelineMesh = await generate3dPipeline(this._pipelines);
  };

  handleKeyDown = (event: any, props: any) => {
    const modeDetails = getPipelineModeDetails(props?.modeConfig?.pipelineMode);

    const ctrlOrCmd = event.metaKey || event.ctrlKey || event.altKey;

    if (event.key === "Enter") {
      event.preventDefault();
      if (modeDetails.isPipelineMode && this._clickSequence.length > 0) {
        this.handleAddFeature(event, props);
      }
    }

    if (event.key === "Escape") {
      event.preventDefault();

      if (this._clickSequence.length > 0) {
        event.stopPropagation();
      }

      this._resetState();
    }

    if (
      modeDetails.isPipelineMode &&
      (event.key === "z" ||
        event.key === "Z" ||
        (ctrlOrCmd && (event.key === "Z" || event.key === "z")))
    ) {
      event.preventDefault();
      const clickSequence = this.getClickSequence();

      if (clickSequence.length > 1) {
        this.setClickSequence(clickSequence.slice(0, -1));
      } else {
        this._resetState();
      }
    }

    if (event.key === "p" || event.key === "P") {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  handlePointerMove = (event: any, props: any) => {
    const modeDetails = getPipelineModeDetails(props?.modeConfig?.pipelineMode);

    if (modeDetails.isPipelineMode || modeDetails.isDrainMode) {
      props.onUpdateCursor("crosshair");
    } else {
      props.onUpdateCursor("default");
    }
  };
}
