/* eslint-disable quotes */
export const EMPTY_SET_FOLDER_ID = "other";
export const AREA_MODES = ["polygon", "rect"];

export const MAX_QUANTITY_LIST_WIDTH = 700;
export const DEFAULT_QUANTITY_LIST_WIDTH = 350;

export const DEFAULT_QUANTITY_ROUND = 2;
export const MAX_NUMBER_OF_LAST_USED_CLS = 10;

export const VIEW_MODE_ROLES = ["viewer"];
export const ALLOWED_EDIT_ROLES = ["admin", "superadmin"];
export const ALLOWED_SET_EDIT_ROLES = ["manager", "owner"];

export const HEADER_MODES = {
  NONE: "none",
  EXPORT: "export",
  LIBRARY: "library",
  BREAKDOWN: "breakdown",
  QUANTITIES: "quantities",
  CLASSIFICATIONS: "classifications",
};

export const EXPORT_PDF_STATES = {
  EXPORT_START: "EXPORT_CREATE",
  EXPORT_SUCCESS: "EXPORT_SUCCESS",
  EXPORT_FAILURE: "EXPORT_FAILURE",
};

export const VIEW_STATES = {
  SCALING_START: "SCALING_START",
  SCALING_SUCCESS: "SCALING_SUCCESS",
  SCALING_FAILURE: "SCALING_FAILURE",
  COMPARISON_START: "COMPARISON_START",
  COMPARISON_SUCCESS: "COMPARISON_SUCCESS",
  COMPARISON_FAILURE: "COMPARISON_FAILURE",
  VECTORIZATION_START: "VECTORIZATION_START",
  VECTORIZATION_PENDING: "VECTORIZATION_PENDING",
  VECTORIZATION_TEXT_COMPLETE: "VECTORIZATION_TEXT_COMPLETE",
  VECTORIZATION_COUNTS_COMPLETE: "VECTORIZATION_COUNTS_COMPLETE",
  VECTORIZATION_REGIONS_COMPLETE: "VECTORIZATION_REGIONS_COMPLETE",
  VECTORIZATION_ROOMS_COMPLETE: "VECTORIZATION_ROOMS_COMPLETE",
  VECTORIZATION_WALLS_COMPLETE: "VECTORIZATION_WALLS_COMPLETE",
  VECTORIZATION_WALL_POLYGONS_COMPLETE: "VECTORIZATION_WALL_POLYGONS_COMPLETE",
  VECTORIZATION_CLASSIFICATION_COMPLETE:
    "VECTORIZATION_CLASSIFICATION_COMPLETE",
  VECTORIZATION_SUCCESS: "VECTORIZATION_SUCCESS",
  VECTORIZATION_FAILURE: "VECTORIZATION_FAILURE",
  VECTORIZATION_COUNTS_FAILURE: "VECTORIZATION_COUNTS_FAILURE",
  VECTORIZATION_REGIONS_FAILURE: "VECTORIZATION_REGIONS_FAILURE",
  VECTORIZATION_ROOMS_FAILURE: "VECTORIZATION_ROOMS_FAILURE",
  VECTORIZATION_WALLS_FAILURE: "VECTORIZATION_WALLS_FAILURE",
  VECTORIZATION_TOGAL_COMPLETE: "VECTORIZATION_TOGAL_COMPLETE",
  VECTORIZATION_ROOMS_GIA_COMPLETE: "VECTORIZATION_ROOMS_GIA_COMPLETE",
  VECTORIZATION_WALL_POLYGONS_FAILURE: "VECTORIZATION_WALL_POLYGONS_FAILURE",
  VECTORIZATION_CLASSIFICATION_FAILURE: "VECTORIZATION_CLASSIFICATION_FAILURE",
};

//BREAKDOWNS
export const BREAKDOWN_LEVEL_LABELS = {
  0: "Tier",
  1: "Item",
  2: "Subitem",
};
