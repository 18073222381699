export const BREAKDOWN_LIST_MODES = {
  panel: "panel",
  editor: "editor",
  popup: "popup",
};

export const DEFAULT_BREAKDOWNS = [
  {
    id: "000",
    name: "Tier 1",
    children: [
      {
        id: "000-111",
        name: "Tier 1-Item 1",
        children: [
          {
            id: "000-111-222",
            name: "Tier 1-Item 1-Subitem 1",
            children: [],
          },
        ],
      },
    ],
  },
];

export const DEFAULT_BREAKDOWNS_IDS = ["000", "000-111", "000-111-222"];

export function flattenChildren(data) {
  return data?.children?.length > 0
    ? data.children.map(flattenChildren)
    : data.id;
}

export function flattenBreakdowns(breakdowns) {
  return (
    breakdowns
      ?.map((br) =>
        br?.children.length > 0 ? [br, ...flattenBreakdowns(br.children)] : [br]
      )
      ?.flat() || breakdowns
  );
}

export function reconstructBreakdowns(breakdowns) {
  const filteredBreakdowns = breakdowns?.filter((br) => br?.id);

  return filteredBreakdowns
    ?.filter((br) => br?.id?.split("-").length === 1)
    ?.map((l0) => ({
      ...l0,
      children: filteredBreakdowns
        .filter(
          (l1) => l1.id.split("-").length === 2 && l1.id.startsWith(l0.id)
        )
        .map((l1) => ({
          ...l1,
          children: filteredBreakdowns.filter(
            (l2) => l2.id.split("-").length === 3 && l2.id.startsWith(l1.id)
          ),
        })),
    }));
}
