export const MILLISECOND = 1;
export const HOURS_IN_DAY = 24;
export const DAYS_IN_MONTH = 30;
export const MONTHS_IN_YEAR = 12;
export const MINUTES_IN_HOUR = 60;
export const SECONDS_IN_MINUTE = 60;
export const MILLISECONDS_IN_SECOND = 1000;

export const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * MINUTES_IN_HOUR;
export const SECONDS_IN_DAY = SECONDS_IN_HOUR * HOURS_IN_DAY;
export const SECONDS_IN_MONTH = SECONDS_IN_DAY * DAYS_IN_MONTH;
export const SECONDS_IN_YEAR = SECONDS_IN_MONTH * MONTHS_IN_YEAR;

export const MINUTES_IN_DAY = MINUTES_IN_HOUR * HOURS_IN_DAY;

export const SECOND = MILLISECONDS_IN_SECOND;
export const MINUTE = SECONDS_IN_MINUTE * SECOND;
export const HOUR = MINUTES_IN_HOUR * MINUTE;
export const DAY = HOURS_IN_DAY * HOUR;
export const MONTH = DAY * DAYS_IN_MONTH;
export const YEAR = MONTH * MONTHS_IN_YEAR;

export enum DurationUnits {
  days,
  years,
  hours,
  months,
  seconds,
  minutes,
  milliseconds,
}

export const DURATIONS_MAP = {
  [DurationUnits.days]: DAY,
  [DurationUnits.hours]: HOUR,
  [DurationUnits.years]: YEAR,
  [DurationUnits.months]: MONTH,
  [DurationUnits.seconds]: SECOND,
  [DurationUnits.minutes]: MINUTE,
  [DurationUnits.milliseconds]: MILLISECOND,
};

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
