import request from "../request";
import { withGlobalOrg, withGlobalOrgWhere } from "./_helpers";
import { doInfiniteRequest } from "src/constants/request";

export async function create(values) {
  const { body } = await request.post("view", {
    body: withGlobalOrg(values),
  });

  return body;
}

export async function get({ id, query }) {
  const { body } = await request.get(`view/${id}`, { query });
  return body;
}

export async function list(query) {
  if (query?.$where?.organization_id == null) {
    query = withGlobalOrgWhere(query);
  }
  const { body } = await request.get("view", {
    query,
  });
  return body;
}

export async function listAll(query) {
  if (query?.$where?.organization_id == null) {
    query = withGlobalOrgWhere(query);
  }
  return await doInfiniteRequest("view", query);
}

export async function update({ id, ...values }) {
  const { body } = await request.put(`view/${id}`, {
    body: withGlobalOrg(values),
  });
  return body;
}

export async function duplicate({ id }) {
  const { body } = await request.post(`view/${id}/copy`);
  return body;
}

export async function reject({ id }) {
  const { body } = await request.post(`view/${id}/reject`);
  return body;
}

export async function accept({ id }) {
  const { body } = await request.post(`view/${id}/accept`);
  return body;
}

export async function reset(id, features) {
  const searchQuery = window.location.search;

  const { body } = await request.post(`view/${id}/reset?${searchQuery}`, {
    body: features,
  });

  return body;
}

export async function remove({ id }) {
  const { body } = await request.delete(`view/${id}`);
  return body;
}

export async function atomicUpdate(id, query) {
  const { body } = await request.put(`view/${id}/atomic`, {
    body: query,
  });

  return body;
}

export async function getHistoryData(id) {
  const { body } = await request.get(`view/${id}/geojson`);
  return body;
}

export async function getHistory(id, version) {
  const { body } = await request.get(`view/${id}/geojson/${version}`);
  return body;
}

export async function applyVersion(id, version) {
  const { body } = await request.put(`view/${id}/geojson/${version}`);
  return body;
}

export async function createVersion(id, name) {
  const { body } = await request.post(`view/${id}/geojson`, {
    body: { name },
  });
  return body;
}
