import { EXPORT_PDF_STATES } from "../Editor/constants";
export const TABS = ["All", "Unread"];

export const INVITE_ACCEPT = "INVITE_ACCEPT";
export const INVITE_CREATE = "INVITE_CREATE";
export const INVITE_REJECT = "INVITE_REJECT";
export const EXPORT_SUCCESS = EXPORT_PDF_STATES.EXPORT_SUCCESS;
export const EXPORT_FAILURE = EXPORT_PDF_STATES.EXPORT_FAILURE;
export const EXPORT_START = EXPORT_PDF_STATES.EXPORT_START;

export const Descriptions = {
  E: (n: any) => n?.payload?.multipageObject?.export_url,
  EXPORT_SUCCESS: (n: any) => n?.payload?.multipageObject?.export_url,
  EXPORT_FAILURE: (n: any) => "PDF export failed",
  INVITE_ACCEPT: (n: any) =>
    `Invitation for ${n?.payload?.user?.name} to join ${
      n?.payload?.set?.name || "___"
    } set was accepted`,
  INVITE_CREATE: (n: any) =>
    `You have been invited to join ${n?.payload?.set?.name || "___"} set`,
  INVITE_REJECT: (n: any) =>
    `Invitation for ${n?.payload?.user?.name} to join ${
      n?.payload?.set?.name || "___"
    } set was rejected`,
};
export const typesOfEvents = [
  INVITE_CREATE,
  INVITE_ACCEPT,
  INVITE_REJECT,
  EXPORT_PDF_STATES.EXPORT_SUCCESS,
  EXPORT_PDF_STATES.EXPORT_FAILURE,
  EXPORT_PDF_STATES.EXPORT_START,
];
