export const KEYS = {
  SPACE: " ",
  TAB: "Tab",
  ALT: "Alt",
  ENTER: "Enter",
  ESCAPE: "Escape",
  CONTROL: "Control",
  BACKSPACE: "Backspace",
  ARROWUP: "ArrowUp",
  ARROWDOWN: "ArrowDown",
} as const;

export const EVENT_TYPES = {
  KEY_UP: "keyup",
  KEY_DOWN: "keydown",
  MOUSE_UP: "mouseup",
  MOUSE_DOWN: "mousedown",
};

export const MOUSE_BUTTONS = {
  LEFT: 0,
  MIDDLE: 1,
  RIGHT: 2,
  FOURTH: 3,
  FIFTH: 4,
};

export const SHORTCUTS_KEYS: any = {
  PAN_TOOL: ["Q"],
  PAN_TOOL_TEMPORARY: [" "],
  CENTER_VIEW: ["F"],
  UNDO: ["Z + CONTROL"],
  DRAW: ["DOUBLE_CLICK_CLASS"],
  DRAW_WITH_LAST_CLASS: ["L"],
  DRAW_UNCONSTRAINED: ["SHIFT + SHIFT"],
  EXIT_DRAWING_MODE: ["ESCAPE"],
  CLOSE_POLYGON: ["ENTER", "DOUBLE_CLICK"],
  RECTANGLE_MODE: ["CONTROL + CONTROL"],
  UNDO_LAST_POINT: ["Z"],
  END_LINE: ["ENTER", "DOUBLE_CLICK"],
  SELECT_TOOL: ["ESCAPE"],
  MULTI_SELECT: ["SINGLE_CLICK + SHIFT"],
  RECTANGLE_SELECT_WHOLE: [],
  RECTANGLE_SELECT_PARTIAL: [],
  COPY: ["C + CONTROL"],
  PASTE: ["V + CONTROL"],
  SELECT_ALL: ["A + CONTROL"],
  MOVE_MULTIPLE_POINT: [],
  ROTATE_90: ["R"],
  FLIP_VERTICAL: ["V"],
  FLIP_HORIZONTAL: ["H"],
  DUPLICATE_IN_PLACE: ["D + CONTROL"],
  GROUP_SELECTED_ITEMS: ["G + CONTROL"],
  UNGROUP_SELECTED_ITEMS: ["U + CONTROL"],
};
