import { atom } from "recoil";

export const shareeTrialModalState = atom({
  key: "shareeTrialModalState",
  default: false,
});

export const DEFAULT_USAGE_MODAL_STATE = {
  key: null,
  onConfirm: null,
};

export const usageModalState = atom({
  key: "usageModalState",
  default: DEFAULT_USAGE_MODAL_STATE,
});
