import cuid from "cuid";
import React, { useMemo, useState, MouseEvent, useRef, useEffect } from "react";
import { getParagraphDimensions } from "../../utils/dom";
import { Para, Tooltip } from "../Styled";
import { CSS, FONT_WEIGHTS } from "../../consts/style";
import styled, { css } from "styled-components";
import Icon, { ALL_ICONS } from "../Icon";

export interface TooltipWrapper {
  position: string;
  indent: number;
  padding?: number;
  maxWidth?: string;
  text: string;
  fontSize?: string;
  fontWeight?: string;
  type?: string;
  delay?: number;
  isShown?: boolean | null;
  isFlex?: boolean;
  shrink?: string;
  grow?: string | number;
  lineHeight?: string;
  children: any;
  style?: any;
  iconId?: string;
}

const TooltipWrapper: React.FC<TooltipWrapper> = ({
  type,
  delay,
  shrink,
  children,
  grow = 1,
  indent = 0,
  text = null,
  padding = 5,
  iconId = null,
  isShown = null,
  isFlex = false,
  position = "right",
  maxWidth = "300px",
  fontSize = CSS.FONT_DEFAULT,
  fontWeight = FONT_WEIGHTS.REGULAR,
  lineHeight = CSS.LINE_HEIGHT_SMALL,
  ...props
}) => {
  const [isOn, setOn] = useState(false);
  const [coords, setCoords] = useState({});

  const { width, height } = useMemo(
    () =>
      text
        ? getParagraphDimensions(
            text,
            fontSize,
            fontWeight,
            maxWidth,
            lineHeight
          )
        : iconId
        ? { width: ALL_ICONS[iconId].width, height: ALL_ICONS[iconId].height }
        : { width: null, height: null },
    [text]
  );

  let timer = useRef(null);

  useEffect(() => {
    if (!isShown) {
      setOn(false);
    }
  }, [isShown]);

  const updateTooltipCoords = (target: any) => {
    const rect = target.getBoundingClientRect();

    if (isShown ?? width > Math.round(rect.width)) {
      setOn(true);

      if (position === "top") {
        setCoords({
          top: rect.top - indent,
          left: rect.left - padding + (type ? rect.width / 2 - width / 2 : 0),
        });
        return;
      }

      if (position === "bottom") {
        setCoords({
          top: rect.bottom + indent - padding,
          left: rect.left - padding + (type ? rect.width / 2 - width / 2 : 0),
        });
        return;
      }

      if (position === "right" && window.innerWidth - rect.right > 350) {
        setCoords({
          top: rect.top + rect.height / 2 - height / 2 - padding,
          left: rect.right + indent,
        });
        return;
      }

      if (position === "left" || window.innerWidth - rect.right < 350) {
        setCoords({
          top: rect.top + rect.height / 2 - height / 2 - padding,
          right: window.innerWidth - rect.left + indent,
        });
        return;
      }
    }
  };

  const mouseEnterHandler = (e: MouseEvent) => {
    if (delay) {
      e.persist();
      timer.current = setTimeout(() => {
        updateTooltipCoords(e.target);
      }, delay);
    } else {
      updateTooltipCoords(e.target);
    }
  };

  const mouseLeaveHandler = () => {
    clearTimeout(timer.current);
    setOn(false);
  };

  return (
    <>
      <ComponentContainer
        isFlex={isFlex}
        grow={grow}
        shrink={shrink}
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseLeaveHandler}
        {...props}
      >
        {children}
      </ComponentContainer>
      {isOn && (
        <Tooltip
          type={type}
          coords={coords}
          padding={padding}
          maxWidth={maxWidth}
        >
          {typeof text === "string" &&
            text?.split("\n").map((line) => <Para key={cuid()}>{line}</Para>)}

          {iconId && <Icon id={iconId} />}
        </Tooltip>
      )}
    </>
  );
};

interface IComponentContainer {
  isFlex?: boolean;
  shrink?: string;
  grow?: string | number;
}

export const ComponentContainer = styled.div`
  overflow: hidden;
  max-width: 100%;
  ${({ isFlex }: IComponentContainer) =>
    isFlex &&
    css`
      display: flex;
      justify-content: center;
      aligh-content: center;
    `};
  ${({ shrink }: IComponentContainer) => shrink && `flex-shrink: ${shrink};`};
  ${({ grow }: IComponentContainer) =>
    grow !== undefined &&
    `flex-grow: ${grow};`}; // undefined check so 0 doesn't fail
`;

export default TooltipWrapper;
