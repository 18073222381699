export const DEFAULT_STROKE_WIDTH = 2;
export const DEFAULT_OPACITY = 1;

export const SHAPES = {
  CIRCLE: "circle",
  SQUARE: "square",
  RECTANGLE: "rectangle",
  TRIANGLE: "triangle",
  TRIANGLE_FLIPPED: "triangle_flipped",
  DIAMOND: "diamond",
  PENTAGON: "pentagon",
  STAR: "star",
} as const;

export const PDF_REACT_COMPONENTS: any = {
  [SHAPES.SQUARE]: "PATH",
  [SHAPES.RECTANGLE]: "PATH",
  [SHAPES.STAR]: "PATH",
  [SHAPES.CIRCLE]: "ELLIPSE",
  [SHAPES.TRIANGLE]: "PATH",
  [SHAPES.TRIANGLE_FLIPPED]: "PATH",
  [SHAPES.DIAMOND]: "PATH",
  [SHAPES.PENTAGON]: "PATH",
};

export const SVG_PROPS: any = {
  [SHAPES.SQUARE]: {
    d: "M48.792077 48.792077H207.20792V207.20792H48.792077Z",
  },
  [SHAPES.RECTANGLE]: {
    d: "M68.792077 68.792077H207.20792V137.20792H68.792077Z",
  },
  [SHAPES.STAR]: {
    d: "m176.31185 206.72157-48.50258-35.78903-48.647341 35.49997 17.428308-59.49486-47.187487-37.60982 59.27389-.98076 19.48384-58.744212 19.20504 58.888732 59.22916 1.30392-47.4046 37.37599z",
  },
  [SHAPES.CIRCLE]: {
    cx: "128",
    cy: "128",
    rx: "80",
    ry: "80",
  },
  [SHAPES.TRIANGLE]: {
    d: "m128 58.754327 79.22008 139.245553H48.77992Z",
  },
  [SHAPES.TRIANGLE_FLIPPED]: {
    d: "m128 197.24567 79.22008-139.245548H48.77992Z",
  },
  [SHAPES.DIAMOND]: {
    d: "M128 48.792077 207.20792 128 128 207.20792 48.792077 128Z",
  },
  [SHAPES.PENTAGON]: {
    d: "m176.31185 206.72157-97.149921-.28906-29.759179-97.10468 78.75773-59.724972 78.4342 60.192652Z",
  },
};
