import { useSetRecoilState } from "recoil";

import { organizationKey } from "../const";
import { useDataHook } from "./commonDataHook";
import {
  STORE_RESET,
  organizationsCRUDStore,
  organizationsErrorStore,
  organizationsLoadingStore,
} from "src/state/dataStore";

export function useOrganizationsData(
  pendingData: React.MutableRefObject<any[]>
) {
  /** ORGS Store */
  const setOrgsCRUD = useSetRecoilState(organizationsCRUDStore);
  const setOrgsError = useSetRecoilState(organizationsErrorStore);
  const setOrgsLoading = useSetRecoilState(organizationsLoadingStore);
  /** ORGS Store */

  async function resetData() {
    setOrgsCRUD({
      items: [],
      action: STORE_RESET,
    });
  }

  const {
    getData: getOrganizations,
    syncData: syncOrganizations,
    clearData: clearOrganizations,
    syncSingleItem: syncOrganization,
  } = useDataHook({
    pendingData,
    useIdSync: false,
    dataKey: organizationKey,
    store: {
      resetData,
      setDataError: setOrgsError,
      setDataIsLoading: setOrgsLoading,
      getItemValue: (org: any) => org,
    },
  });

  return {
    getOrganizations,
    syncOrganization,
    syncOrganizations,
    clearOrganizations,
  };
}
