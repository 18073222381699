import amplitude from "amplitude-js";
import IAnalyticsService from "./IAnalyticsService";

class AmplitudeService implements IAnalyticsService {
  constructor() {
    AmplitudeService.init();
  }

  private static init() {
    // @ts-ignore
    if (import.meta.env.VITE_TGL_ENV === "prod") {
      try {
        amplitude
          .getInstance()
          // @ts-ignore
          .init(import.meta.env.VITE_REACT_APP_AMPLITUDE as string);
      } catch (e) {
        console.error("amplitude error : ", e);
      }
    }
  }

  sendEvent(eventType: string, eventProperties?: Record<string, string>) {
    // @ts-ignore
    if (import.meta.env.VITE_TGL_ENV === "local") {
      console.log("AMPLITUDE event called", eventType);
      // @ts-ignore
    } else if (import.meta.env.VITE_TGL_ENV === "prod") {
      try {
        amplitude.getInstance().logEvent(eventType, eventProperties);
      } catch (e) {
        console.error("amplitude error : ", e);
      }
    }
  }
}

export default AmplitudeService;
