import { ReactComponent as UsersIcon } from "src/svg/settings/users.svg";
import { ReactComponent as BillingIcon } from "src/svg/settings/billing.svg";
import { ReactComponent as UsageIcon } from "src/svg/settings/features_count.svg";

import { ReactComponent as GPTUsageIcon } from "src/svg/settings/gpt_count.svg";
import { ReactComponent as TogalUsageIcon } from "src/svg/settings/togal_count.svg";
import { ReactComponent as ShareeUsageIcon } from "src/svg/settings/users_count.svg";
import { ReactComponent as SearchUsageIcon } from "src/svg/settings/search_count.svg";

import { CSS } from "sf/consts/style";
import { ADMIN_ROLE, USER_ROLE, VIEWER_ROLE } from "sf/permissions";

import { DEFAULT_ALERT } from "src/modules/Project/const";

export const CONFIRM_INVITE_ID = "confirm_invite";

export const USAGE_TOGAL_KEY = "TOGAL";
export const USAGE_TOGAL_GPT_KEY = "TOGAL_GPT";
export const USAGE_SHARE_EVENT_KEY = "SHARE_EVENT";
export const USAGE_SHAREE_KEY = "SHARE_WITH_PERSON";
export const USAGE_IMAGE_SEARCH_KEY = "IMAGE_SEARCH";
export const USAGE_MONTHLY_RENEW_KEY = "MONTHLY_RENEW";

export const LAST_INVOICE_FAILED = "failed";
export const PAUSED_PAYMENT_METHOD = "pause";

export const STATUS_PAID = "paid";
export const STATUS_ACTIVE = "active";
export const STATUS_PAUSED = "paused";
export const STATUS_UNPAID = "unpaid";
export const STATUS_TRIAL = "trialing";
export const STATUS_PENDING = "pending";
export const STATUS_CANCELED = "canceled";
export const STATUS_PAST_DUE = "past_due";
export const STATUS_INCOMPLETE = "incomplete";
export const STATUS_UNVERIFIED = "unverified";
export const STATUS_INCOMPLETE_EXPIRED = "incomplete_expired";

export const FAILED_STATUSES = [
  STATUS_PAUSED,
  STATUS_UNPAID,
  STATUS_PAST_DUE,
  STATUS_INCOMPLETE,
  STATUS_INCOMPLETE_EXPIRED,
];

export const DEFAULT_PRODUCT_NAME = "Togal Growth";
export const RESTRICTED_PRODUCT_NAME = "Togal Essential";

export const YEARLY_PRICE = 299900;
export const MONTHLY_PRICE = 29900;
export const YEARLY_RESTRICTED_PRICE = 150000;
export const MONTHLY_RESTRICTED_PRICE = 15000;

export const YEARLY_KEY = "year";
export const MONTHLY_KEY = "month";

export const YEARLY_PLAN_KEY = "year_plan";
export const MONTHLY_PLAN_KEY = "month_plan";
export const CURRENT_PLAN_KEY = "current_plan";
export const YEARLY_RESTRICTED_PLAN_KEY = "year_restricted_plan";
export const MONTHLY_RESTRICTED_PLAN_KEY = "month_restricted_plan";

const USERS_NAME_INDEX = "name";
const USERS_EMAIL_INDEX = "email";
const USERS_STATUS_INDEX = "status";

export const USERS_SEARCH_INDEX = [
  USERS_NAME_INDEX,
  USERS_EMAIL_INDEX,
  USERS_STATUS_INDEX,
];

export const USERS_INDEX_MAP = {
  name: USERS_NAME_INDEX,
  email: USERS_EMAIL_INDEX,
  status: USERS_STATUS_INDEX,
};

// export const USERS_GRID_LIST_TEMPLATE =        Temporarily removing the projects count column
//   "1fr 1.7fr 80px 80px 80px 80px 160px 36px";
export const USERS_GRID_LIST_TEMPLATE = "1fr 1.7fr 80px 80px 100px 160px 36px";
export const INVOICES_GRID_LIST_TEMPLATE = "80px repeat(6, 1fr) 36px";

export const SORT_TYPE = "sort";
export const SEPRATOR_TYPE = "separator";
export const SORT_ORDER_TYPE = "sortOrder";

export const FILTER_IDS = {
  users: "users",
  usage: "usage",
  billing: "billing",
};

export const SETTINGS_FILTERS = [
  {
    id: FILTER_IDS.users,
    icon: UsersIcon,
    title: "Users",
    disabled: false,
    isEssentialsOnly: false,
  },
  {
    id: FILTER_IDS.billing,
    icon: BillingIcon,
    title: "Billing",
    disabled: false,
    isEssentialsOnly: false,
  },
  {
    id: FILTER_IDS.usage,
    icon: UsageIcon,
    title: "Feature count",
    disabled: false,
    isEssentialsOnly: true,
  },
];

export const BILLING_IDS = {
  payment: "payment",
  invoices: "invoices",
  subscription: "subscription",
};

export const BILLING_TABS = (locales: any) => [
  {
    title: locales.OVERVIEW,
    id: BILLING_IDS.subscription,
  },
  {
    title: locales.PAYMENT_METHOD,
    id: BILLING_IDS.payment,
  },
  {
    title: locales.INVOICES,
    id: BILLING_IDS.invoices,
  },
];

export const USERS_SORT_MENU_IDS = {
  asc: "asc",
  sep: "sep",
  desc: "desc",
  name: "name",
  email: "email",
  added: "added",
  status: "status",
};

export const USERS_SORT_MENU = (locales: any) => [
  {
    multiplier: 1,
    key: "name",
    type: SORT_TYPE,
    title: locales.NAME,
    id: USERS_SORT_MENU_IDS.name,
  },
  {
    key: "email",
    multiplier: 1,
    type: SORT_TYPE,
    title: locales.EMAIL,
    id: USERS_SORT_MENU_IDS.email,
  },
  {
    isDate: true,
    multiplier: 1,
    key: "created",
    type: SORT_TYPE,
    title: locales.SM_CREATED,
    id: USERS_SORT_MENU_IDS.added,
  },
  {
    title: "",
    type: SEPRATOR_TYPE,
    id: USERS_SORT_MENU_IDS.sep,
    filter: (_item: any, _user: any, _filterID: string) => true,
  },
  {
    type: SORT_ORDER_TYPE,
    title: locales.SM_O_ASC,
    id: USERS_SORT_MENU_IDS.asc,
  },
  {
    type: SORT_ORDER_TYPE,
    title: locales.SM_O_DESC,
    id: USERS_SORT_MENU_IDS.desc,
  },
];

export const USERS_CONTEXT_MENU_IDS = {
  edit: "edit",
  download: "download",
  editInvit: "editInvit",
  deleteUser: "deleteUser",
  resendInvitation: "resendInvitation",
  resendVerification: "resendVerification",
  cancelInvitation: "cancelInvitation",
};

export const INVOICES_CONTEXT_MENU = (locales: any) => [
  {
    fontColor: CSS.BLACK,
    title: locales.DOWNLOAD,
    id: USERS_CONTEXT_MENU_IDS.download,
  },
];

export const USERS_CONTEXT_MENU = (locales: any) => [
  {
    fontColor: CSS.BLACK,
    title: locales.EDIT_USER_CM,
    id: USERS_CONTEXT_MENU_IDS.edit,
    filter: (item: any) => item.status === STATUS_ACTIVE,
  },
  {
    fontColor: CSS.BLACK,
    title: locales.EDIT_INVIT_CM,
    id: USERS_CONTEXT_MENU_IDS.edit,
    filter: (item: any) => item.status === STATUS_PENDING && !item.isExternalUser,
  },
  {
    fontColor: CSS.ERROR,
    title: locales.DELETE_USER_CM,
    id: USERS_CONTEXT_MENU_IDS.deleteUser,
    filter: (item: any) => item.status === STATUS_ACTIVE,
  },
  {
    fontColor: CSS.BLACK,
    title: locales.RE_SEND_INVIT_CM,
    id: USERS_CONTEXT_MENU_IDS.resendInvitation,
    filter: (item: any) => item.status === STATUS_PENDING,
  },
  {
    fontColor: CSS.BLACK,
    title: locales.RE_SEND_EMAIL_VERIF_CM,
    id: USERS_CONTEXT_MENU_IDS.resendVerification,
    filter: (item: any) => item.status === STATUS_UNVERIFIED,
  },
  {
    fontColor: CSS.ERROR,
    title: locales.CANCEL_INVI_CM,
    id: USERS_CONTEXT_MENU_IDS.cancelInvitation,
    filter: (item: any) => item.status === STATUS_PENDING,
  },
];

export const USER_ROLES = (locales: any) => [
  {
    id: USER_ROLE,
    name: locales.USER,
    isSelected: true,
  },
  {
    id: ADMIN_ROLE,
    name: locales.ADMIN,
    isSelected: false,
  },
  {
    id: VIEWER_ROLE,
    name: locales.VIEWER,
    isSelected: false,
  },
];

export const STRIPE_OPTIONS: any = {
  mode: "setup",
  layout: "tabs",
  paymentMethodTypes: ["card"],
  paymentMethodCreation: "manual",
  paymentMethodOptions: {
    us_bank_account: {
      verification_method: "automatic",
      financial_connections: {
        permissions: ["payment_method", "transactions"],
      },
    },
  },
  appearance: {
    theme: "stripe",
    variables: {
      colorPrimary: CSS.MAINCOLOR,
      colorBackground: CSS.WHITE,
      colorText: CSS.BLACK,
      colorDanger: CSS.ERROR,
      spacingUnit: "2px",
      borderRadius: CSS.BORDER_RADIUS,
    },
    rules: {
      ".Block": {
        padding: "12px",
        boxShadow: "none",
        backgroundColor: CSS.ORANGE5,
      },
      ".Input": {
        padding: "10px",
        width: "100%",
      },
      ".Input:disabled, .Input--invalid:disabled": {
        color: "lightgray",
      },
      ".Label": {
        fontWeight: "500",
        margin: "10px 0 4px 0",
      },
    },
  },
};

export const PLANS_DETAILS = (locales: any) => [
  {
    isEssentialsPlan: true,
    key: RESTRICTED_PRODUCT_NAME,
    name: RESTRICTED_PRODUCT_NAME,
    getIsActive: (isEssentials: boolean) => isEssentials,
    getPrice: ({ isYearly, yearlyRestrictedPlan, monthlyRestrictedPlan }) =>
      `$${
        (isYearly
          ? yearlyRestrictedPlan?.amount || YEARLY_RESTRICTED_PRICE
          : monthlyRestrictedPlan?.amount || MONTHLY_RESTRICTED_PRICE) / 100
      } `,
    features: [
      locales.ESSENTIALS_PLAN_FEATURE_1,
      locales.ESSENTIALS_PLAN_FEATURE_2,
      locales.ESSENTIALS_PLAN_FEATURE_3,
      locales.ESSENTIALS_PLAN_FEATURE_4,
    ],
  },
  {
    isEssentialsPlan: false,
    key: DEFAULT_PRODUCT_NAME,
    name: DEFAULT_PRODUCT_NAME,
    getIsActive: (isEssentials: boolean) => !isEssentials,
    getPrice: ({ isYearly, yearlyPlan, monthlyPlan }) =>
      `$${
        (isYearly
          ? yearlyPlan?.amount || YEARLY_PRICE
          : monthlyPlan?.amount || MONTHLY_PRICE) / 100
      } `,
    features: [
      locales.DEFAULT_PLAN_FEATURES_1,
      locales.DEFAULT_PLAN_FEATURES_2,
      locales.DEFAULT_PLAN_FEATURES_3,
      locales.DEFAULT_PLAN_FEATURES_4,
    ],
  },
];

export const FEATURE_USAGES = (locales: any) => [
  {
    icon: TogalUsageIcon,
    id: USAGE_TOGAL_KEY,
    title: locales.TOGAL_COUNT,
  },
  {
    icon: GPTUsageIcon,
    id: USAGE_TOGAL_GPT_KEY,
    title: locales.TOGAL_GPT_COUNT,
  },
  {
    icon: SearchUsageIcon,
    id: USAGE_IMAGE_SEARCH_KEY,
    title: locales.IMAGE_SEARCH_COUNT,
  },
  {
    icon: ShareeUsageIcon,
    id: USAGE_SHARE_EVENT_KEY,
    title: locales.SHAREE_COUNT,
  },
];

export const DELETE_ALERT = {
  ...DEFAULT_ALERT,
  customProps: null,
  customComponent: null,
};
