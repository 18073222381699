import { atom, selector } from "recoil";

import { groupBy } from "sf/utils/editor";
import { localStorageStoreHelper } from "src/state/utils";
import { DEFAULT_GROUP, TAKE_OFF_TYPES } from "sf/consts/editor";
import { getClassificationsByID } from "src/modules/Editor/utils";
import ImmutableLayersData from "sf/modes/base/ImmutableLayersData";
import { DEFAULT_COMPARISON_TRANSFORMS } from "sf/consts/coordinates";
/**
 * ---------------------------------------------------------------------------------------------------------
 * ---------------------------------------------------------------------------------------------------------
 * ---------------------------------------------------------------------------------------------------------
 * LAYERS CONSTS
 * ---------------------------------------------------------------------------------------------------------
 * ---------------------------------------------------------------------------------------------------------
 * ---------------------------------------------------------------------------------------------------------
 */
export const MANUAL_DATA = "MANUAL";

export const SORT_TYPES = {
  default: {
    key: "",
    label: "Manual",
  },
  alpha: {
    key: "alpha",
    label: "Alphabetical",
  },
  unit: {
    key: "unit",
    label: "Quantity 1",
  },
  unit2: {
    key: "unit2",
    label: "Quantity 2",
  },
  unit3: {
    key: "unit3",
    label: "Quantity 3",
  },
  classificationCustomId: {
    key: "classificationCustomId",
    label: "ID",
  },
};

export const SEARCH_FILTER_KEY = "searchFilter";
export const TAKEOFF_FILTER_KEY = "takeOffFilter";

export const FILTER_TYPES = [
  "EQUAL",
  "BIGGER",
  "SMALLER",
  "INCLUDES",
  "INCLUDES_MULTIPLE",
  "CUSTOM_FUNC",
];
export const GROUP_TYPES = ["DEFAULT", "RANGE", "CUSTOM_KEYS", "CUSTOM_FUNC"];

export const DEFAULT_TAKEOFFS_FILTER = {
  type: 4,
  key: "properties.types",
  value: TAKE_OFF_TYPES.map((el) => el.id),
};

export const DEFAULT_SEARCH_FILTER = {
  type: 3,
  key: "properties.name",
  value: null,
};

export const CLASSIFICATION_SEARCH_FILTER = {
  type: 5,
  key: "properties.className",
  value: null,
  func: (value, key, groupMap) => {
    return groupMap[key]?.label.toLowerCase().includes(value.toLowerCase());
  },
};

export const BREAKDOWN_GROUP = {
  type: 0,
  key: "properties.highlight",
  value: null,
};

export const AREA_GROUP = {
  type: 1,
  key: "properties.area",
  value: 50,
};

export const PERIM_GROUP = {
  type: 1,
  key: "properties.perimeter",
  value: 10,
};

export const DEFAULT_COMPARISON_STATE = {
  isLoading: false,
  isBaseImageVisible: true,
  isTargetImageVisible: false,
  isResultVisible: false,
  baseImage: null,
  targetImage: null,
  resultImage: null,
  targetImageID: null,
  comparisonColors: null,
  targetImageTransforms: DEFAULT_COMPARISON_TRANSFORMS,
  features: [],
  selectedFeatures: [],
  hiddenFeatures: [],
};

/**
 * ---------------------------------------------------------------------------------------------------------
 * ---------------------------------------------------------------------------------------------------------
 * ---------------------------------------------------------------------------------------------------------
 * DRAWING COMPARISON LAYERS STATE
 * ---------------------------------------------------------------------------------------------------------
 * ---------------------------------------------------------------------------------------------------------
 * ---------------------------------------------------------------------------------------------------------
 */

//holds the state for drawing comparison

export const drawingComparisonState = atom({
  key: "drawingComparisonState",
  default: DEFAULT_COMPARISON_STATE,
});

export const drawingComparisonLoadingState = selector({
  key: "drawingComparisonLoadingState",
  get: ({ get }) => get(drawingComparisonState).isLoading,
  set: ({ set, get }, value) => {
    set(drawingComparisonState, {
      ...get(drawingComparisonState),
      isLoading: value,
    });
  },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const drawingComparisonBaseImage = selector({
  key: "drawingComparisonBaseImage",
  get: ({ get }) => get(drawingComparisonState).baseImage,
  set: ({ set, get }, value) => {
    set(drawingComparisonState, {
      ...get(drawingComparisonState),
      baseImage: value,
    });
  },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const drawingComparisonTargetImage = selector({
  key: "drawingComparisonTargetImage",
  get: ({ get }) => get(drawingComparisonState).targetImage,
  set: ({ set, get }, value) => {
    set(drawingComparisonState, {
      ...get(drawingComparisonState),
      targetImage: value,
    });
  },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const drawingComparisonResultImage = selector({
  key: "drawingComparisonResultImage",
  get: ({ get }) => get(drawingComparisonState).resultImage,
  set: ({ set, get }, value) => {
    set(drawingComparisonState, {
      ...get(drawingComparisonState),
      resultImage: value,
    });
  },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const drawingComparisonImagesVisibility = selector({
  key: "drawingComparisonImagesVisibility",
  get: ({ get }) => ({
    isBaseImageVisible: get(drawingComparisonState).isBaseImageVisible,
    isTargetImageVisible: get(drawingComparisonState).isTargetImageVisible,
    isResultVisible: get(drawingComparisonState).isResultVisible,
  }),
  set: (
    { set, get },
    [isBaseImageVisible, isTargetImageVisible, isResultVisible]
  ) => {
    set(drawingComparisonState, {
      ...get(drawingComparisonState),
      isBaseImageVisible,
      isTargetImageVisible,
      isResultVisible,
    });
  },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const comparisonModeLayers = selector({
  key: "comparisonModeLayers",
  get: ({ get }) => get(drawingComparisonState).features,
  set: ({ set, get }, value) => {
    set(drawingComparisonState, {
      ...get(drawingComparisonState),
      features: value,
    });
  },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const comparisonModeSelectedLayers = selector({
  key: "comparisonModeSelectedLayers",
  get: ({ get }) => get(drawingComparisonState).selectedFeatures,
  set: ({ set, get }, value) => {
    set(drawingComparisonState, {
      ...get(drawingComparisonState),
      selectedFeatures: value,
    });
  },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const comparisonModeHiddenLayers = selector({
  key: "comparisonModeHiddenLayers",
  get: ({ get }) => get(drawingComparisonState).hiddenFeatures,
  set: ({ set, get }, value) => {
    set(drawingComparisonState, {
      ...get(drawingComparisonState),
      hiddenFeatures: value,
    });
  },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const comparisonModeTargetID = selector({
  key: "comparisonModeTargetID",
  get: ({ get }) => get(drawingComparisonState).targetImageID,
  set: ({ set, get }, value) => {
    set(drawingComparisonState, {
      ...get(drawingComparisonState),
      targetImageID: value,
    });
  },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const comparisonModeTargetTransforms = selector({
  key: "comparisonModeTargetTransforms",
  get: ({ get }) => get(drawingComparisonState).targetImageTransforms,
  set: ({ set, get }, value) => {
    set(drawingComparisonState, {
      ...get(drawingComparisonState),
      targetImageTransforms: value,
    });
  },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const drawingComparisonColors = selector({
  key: "drawingComparisonColors",
  get: ({ get }) => get(drawingComparisonState).comparisonColors,
  set: ({ set, get }, value) => {
    set(drawingComparisonState, {
      ...get(drawingComparisonState),
      comparisonColors: value,
    });
  },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const getComparisonGroupedLayers = selector({
  key: "getComparisonGroupedLayers",
  get: ({ get }) => {
    const features = get(comparisonModeLayers);
    const groupRes = groupBy(features, DEFAULT_GROUP, null);

    return groupRes;
  },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

/**
 * ---------------------------------------------------------------------------------------------------------
 * ---------------------------------------------------------------------------------------------------------
 * ---------------------------------------------------------------------------------------------------------
 * LAYERS STATE
 * ---------------------------------------------------------------------------------------------------------
 * ---------------------------------------------------------------------------------------------------------
 * ---------------------------------------------------------------------------------------------------------
 */

// holds current set state
export const editorSetState = atom({
  key: "editorSetState",
  default: null,
});

// holds active view features
export const selectedViewFeatures = atom({
  key: "selectedViewFeatures",
  default: null,
});

// holds selection state
export const selectedFeaturesIndices = atom({
  key: "selectedFeaturesIndices",
  default: [],
});

export const legendSettingsState = atom({
  key: "legendSettingsState",
  default: {
    showCountLabels: true,
    showClassificationCustomId: false,
    showQuantity1: true,
    showQuantity2: false,
    showQuantity3: false,
  },
});

export const legendSettingsStateFromStorage = localStorageStoreHelper(
  "legendSettingsState",
  legendSettingsState,
  []
);

// holds highlited state
export const highlitedFeaturesIndices = atom({
  key: "highlitedFeaturesIndices",
  default: new Set(),
});

export const lastActiveClassificationId = atom({
  key: "lastActiveClassificationId",
  default: null,
});

export const activeClassificationId = atom({
  key: "activeClassificationId",
  default: null,
});

// holds active view features
export const selectedViewLayersFilters = atom({
  key: "selectedViewLayersFilters",
  default: {
    takeOffFilter: DEFAULT_TAKEOFFS_FILTER,
    searchFilter: CLASSIFICATION_SEARCH_FILTER,
  },
});

export const selectedViewFeaturesGroupFilter = atom({
  key: "selectedViewFeaturesGroupFilter",
  default: DEFAULT_GROUP,
});

export const layersTakeOffFilter = selector({
  key: "layersTakeOffFilter",
  get: ({ get }) => get(selectedViewLayersFilters).takeOffFilter.value,
  set: ({ set, get }, value) =>
    set(selectedViewLayersFilters, {
      ...get(selectedViewLayersFilters),
      takeOffFilter: {
        ...get(selectedViewLayersFilters).takeOffFilter,
        value,
      },
    }),
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const layersSearchFilter = selector({
  key: "layersSearchFilter",
  get: ({ get }) => get(selectedViewLayersFilters).searchFilter.value,
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const layersFilters = selector({
  key: "layersFilters",
  get: ({ get }) => {},
  set: ({ set, get }, { key, value }) => {
    set(selectedViewLayersFilters, {
      ...get(selectedViewLayersFilters),
      [key]: {
        ...get(selectedViewLayersFilters)[key],
        value,
      },
    });
  },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const getFilteredLayers = selector({
  key: "filteredLayers",
  get: ({ get }) => {
    const editorSet = get(editorSetState);
    const groupClMap = getClassificationsByID(editorSet?.classification || []);

    const updatedGeoJson = get(selectedViewFeatures);

    return new ImmutableLayersData(updatedGeoJson)
      .applyFilters(get(selectedViewLayersFilters), groupClMap)
      .getLayers();
  },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const getGroupedLayers = selector({
  key: "groupedLayers",
  get: ({ get }) => {
    const editorSet = get(editorSetState);
    const features = get(getFilteredLayers);
    const group = get(selectedViewFeaturesGroupFilter);
    const groupClMap = getClassificationsByID(editorSet?.classification);

    return groupBy(features, group, groupClMap);
  },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});
