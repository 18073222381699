import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { last } from "sf/utils/functional/list";

import * as externalApi from "src/lib/api/external";
import {
  STATUS_TRIAL,
  STATUS_ACTIVE,
  STATUS_PAUSED,
  FAILED_STATUSES,
  YEARLY_PLAN_KEY,
  MONTHLY_PLAN_KEY,
  LAST_INVOICE_FAILED,
  PAUSED_PAYMENT_METHOD,
  YEARLY_RESTRICTED_PLAN_KEY,
  MONTHLY_RESTRICTED_PLAN_KEY,
} from "src/modules/Settings/const";
import { ORG_TYPE } from "../const";
import { isSelfService, isSuperAdmin } from "../utils";

export default function useSubscription({ user, organization }) {
  const isSS = isSelfService();
  const isSuper = isSuperAdmin();
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);

  useEffect(() => {
    async function prepareData() {
      setSubscriptionDetails(null);

      const plan = await getPlan();
      const invoices = await getInvoices();
      const subscription = await getSubscription();
      const paymentMethod = await getCurrentPaymentMethod();

      setSubscriptionDetails({
        organization_id: organization?.id,
        subscription,
        plan,
        invoices,
        paymentMethod,
      });
    }

    if (
      user?.id &&
      organization?.id &&
      organization?.type !== ORG_TYPE.EXTERNAL &&
      (user?.organization_id === organization.id || isSuper) &&
      (!subscriptionDetails ||
        subscriptionDetails?.organization_id !== organization?.id)
    ) {
      prepareData();
    }
  }, [organization, user?.id]);

  async function getSubscription() {
    try {
      const res = await externalApi.getSubscription(organization?.id);
      return res;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async function getInvoices() {
    try {
      const res = await externalApi.getInvoices({
        $limit: 12,
        organization_id: organization?.id,
      });
      return res;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async function getPlan() {
    try {
      const res = await externalApi.getPlan(organization?.id);
      return res;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async function getCurrentPaymentMethod() {
    try {
      const res = await externalApi.getPaymentMethod(organization?.id);
      return res;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  /**
   *
   * SUbscription props
   *
   */

  const monthlyPlan = useMemo(
    () =>
      subscriptionDetails?.plan && MONTHLY_PLAN_KEY in subscriptionDetails?.plan
        ? subscriptionDetails?.plan[MONTHLY_PLAN_KEY]
        : null,
    [subscriptionDetails]
  );

  const yearlyPlan = useMemo(
    () =>
      subscriptionDetails?.plan && YEARLY_PLAN_KEY in subscriptionDetails?.plan
        ? subscriptionDetails?.plan[YEARLY_PLAN_KEY]
        : null,
    [subscriptionDetails]
  );

  const monthlyRestrictedPlan = useMemo(
    () =>
      subscriptionDetails?.plan &&
      MONTHLY_RESTRICTED_PLAN_KEY in subscriptionDetails?.plan
        ? subscriptionDetails?.plan[MONTHLY_RESTRICTED_PLAN_KEY]
        : null,
    [subscriptionDetails]
  );

  const yearlyRestrictedPlan = useMemo(
    () =>
      subscriptionDetails?.plan &&
      YEARLY_RESTRICTED_PLAN_KEY in subscriptionDetails?.plan
        ? subscriptionDetails?.plan[YEARLY_RESTRICTED_PLAN_KEY]
        : null,
    [subscriptionDetails]
  );

  const isOnEssentialsPlan = useMemo(
    () =>
      !!yearlyRestrictedPlan &&
      !!monthlyRestrictedPlan &&
      [monthlyRestrictedPlan?.id, yearlyRestrictedPlan?.id]?.includes(
        subscriptionDetails?.subscription?.plan?.id
      ),
    [subscriptionDetails, yearlyRestrictedPlan, monthlyRestrictedPlan]
  );

  const isPaymentFailed = useMemo(
    () => FAILED_STATUSES.includes(subscriptionDetails?.subscription?.status),
    [subscriptionDetails]
  );

  const isNoPaymentData = useMemo(
    () =>
      !subscriptionDetails?.paymentMethod?.data?.length ||
      !subscriptionDetails?.paymentMethod?.data,
    [subscriptionDetails]
  );

  const isUserVerified = useMemo(
    () =>
      (organization?.users?.length ? organization?.users : [])?.find(
        (u: any) => u?.id === user?.id
      )?.verified,
    [user?.id, organization]
  );

  const isActiveSubscription = useMemo(
    () =>
      (!!subscriptionDetails?.subscription?.id &&
        (subscriptionDetails?.subscription?.status === STATUS_ACTIVE ||
          subscriptionDetails?.subscription?.status === STATUS_TRIAL) &&
        subscriptionDetails?.subscription?.cancel_at_period_end === false) ||
      isPaymentFailed,
    [subscriptionDetails, isPaymentFailed]
  );

  const trialProps = useMemo(() => {
    const totalMinutesDiff = moment
      .utc(organization?.expires)
      .diff(moment.utc(), "minutes");

    const trialDaysLeft = Math.floor(totalMinutesDiff / 1440);
    const trialHoursLeft = Math.floor((totalMinutesDiff % 1440) / 60);
    const trialMinutesLeft = totalMinutesDiff % 60;

    const isTrialExpired =
      trialDaysLeft <= 0 && trialHoursLeft <= 0 && trialMinutesLeft <= 0;

    const isOldUser =
      !subscriptionDetails?.subscription || !subscriptionDetails?.paymentMethod;

    const isTrial =
      (subscriptionDetails?.subscription?.status === STATUS_TRIAL ||
        (subscriptionDetails?.subscription?.status === STATUS_PAUSED &&
          subscriptionDetails?.subscription?.trial_settings?.end_behavior
            ?.missing_payment_method === PAUSED_PAYMENT_METHOD)) &&
      !isOldUser &&
      isSS &&
      organization?.id === user?.org?.id;

    const isTrialSubscription = isTrial && isNoPaymentData;
    const isTrialWithPayment = isTrial && !isNoPaymentData;

    const isTrialEnded =
      isTrial &&
      isTrialExpired &&
      subscriptionDetails?.subscription?.status === STATUS_PAUSED &&
      subscriptionDetails?.subscription?.trial_settings?.end_behavior
        ?.missing_payment_method === PAUSED_PAYMENT_METHOD;

    const isTrialCanceled =
      isTrial && subscriptionDetails?.subscription?.cancel_at_period_end;

    const invoices = subscriptionDetails?.invoices?.data || [];
    const isLastInvoiceFailed =
      invoices?.length &&
      (last(invoices) as any)?.status === LAST_INVOICE_FAILED;
    const isTrialEndedWithIssues =
      isTrialWithPayment && isTrialExpired && isLastInvoiceFailed;

    return {
      isTrial,
      isOldUser,
      isTrialEnded,
      trialDaysLeft,
      isPaymentFailed,
      isTrialCanceled,
      isNoPaymentData,
      isTrialWithPayment,
      isTrialSubscription,
      isTrialEndedWithIssues,
    };
  }, [
    isSS,
    organization,
    isPaymentFailed,
    isNoPaymentData,
    subscriptionDetails,
  ]);

  const subscriptionState = useMemo(
    () => ({
      trialProps,
      yearlyPlan,
      monthlyPlan,
      isUserVerified,
      isNoPaymentData,
      isPaymentFailed,
      isOnEssentialsPlan,
      isActiveSubscription,
      yearlyRestrictedPlan,
      monthlyRestrictedPlan,
      quantity: subscriptionDetails?.subscription?.quantity || 1,
    }),
    [
      yearlyPlan,
      trialProps,
      monthlyPlan,
      isUserVerified,
      isPaymentFailed,
      isNoPaymentData,
      isOnEssentialsPlan,
      subscriptionDetails,
      isActiveSubscription,
      yearlyRestrictedPlan,
      monthlyRestrictedPlan,
    ]
  );

  const subscriptionActions = useMemo(
    () => ({
      getPlan,
      getInvoices,
      getSubscription,
      setSubscriptionDetails,
      getCurrentPaymentMethod,
    }),
    [
      getPlan,
      getInvoices,
      getSubscription,
      setSubscriptionDetails,
      getCurrentPaymentMethod,
    ]
  );

  return {
    subscriptionState,
    subscriptionDetails,

    subscriptionActions,
  };
}
