import React, { useMemo } from "react";
import styled from "styled-components";
import { useLocation } from "react-router";

import { CSS } from "sf/consts/style";
import { Button } from "sf/components/Button";
import useService from "sf/services/useService";
import AnalyticsService from "sf/services/AnalyticsService";
import { Flex, Para, Separator } from "sf/components/Styled";

import Modal from "src/components/Modal";
import * as userApi from "src/lib/api/user";
import { useAuth } from "src/modules/Auth/context";

export default function InvitationsManager() {
  const { pathname } = useLocation();
  const analyticsService = useService(AnalyticsService);
  const { pendingInvites, setPendingInvites, setUser } = useAuth();

  const shouldShowInvites = useMemo(
    () => !pathname.includes("accept_invite") && pendingInvites?.length > 0,
    [pathname, pendingInvites]
  );

  async function handleAcceptInvite(invite) {
    try {
      await userApi.acceptInvite(invite.id);

      setUser((prev) => ({
        ...prev,
        organization_ids: [
          ...new Set([
            ...(prev?.organization_ids || []),
            invite.organization_id,
          ]),
        ],
        organizations: [
          ...(prev?.organizations || []),
          invite?.organization || {},
        ].filter(Boolean),
      }));

      setPendingInvites((prev) =>
        prev.filter((prevInvite) => prevInvite.id !== invite.id)
      );
      analyticsService.sendEvent("ACCEPT_INVITE");
    } catch (e) {
      console.error(e);
    }
  }

  async function handleRefuseInvite(invite) {
    try {
      await userApi.deleteInvite(invite.id);

      setPendingInvites((prev) =>
        prev.filter((prevInvite) => prevInvite.id !== invite.id)
      );
      analyticsService.sendEvent("REFUSE_INVITE");
    } catch (e) {
      console.error(e);
    }
  }

  async function acceptAllInvitations() {
    for (const invite of pendingInvites) {
      await handleAcceptInvite(invite);
    }
  }

  return shouldShowInvites ? (
    <Modal
      title="Invitations"
      zIndex={CSS.Z_INDEX_MAX}
      maxWidth="500"
      buttons={
        <StyledButton
          value="Accept All"
          fontColor={CSS.WHITE}
          bgColor={CSS.MAINCOLOR}
          onClick={acceptAllInvitations}
        />
      }
    >
      <StyledPara>Please accept or refuse the following invitations</StyledPara>

      <Separator height="20px" />
      {pendingInvites.map((invite) => (
        <InvitationContainer key={invite.id}>
          <Para
            style={{
              maxWidth: "240px",
              wordBreak: "break-all",
            }}
          >
            <strong>{invite?.user?.name || "______"}</strong> invited you to
            join the organization{" "}
            <strong>{invite?.organization?.name || "______"}</strong>
          </Para>
          <Flex width="auto">
            <InvitationButton
              onClick={() => handleAcceptInvite(invite)}
              fontColor="white"
            >
              Accept
            </InvitationButton>
            <Separator width="10px" />
            <RefuseButton
              style={{ borderColor: CSS.ERROR }}
              onClick={() => handleRefuseInvite(invite)}
            >
              Refuse
            </RefuseButton>
          </Flex>
        </InvitationContainer>
      ))}
      <Separator height="20px" />
    </Modal>
  ) : null;
}

const StyledPara = styled(Para)`
  line-height: 1.5;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const InvitationContainer = styled(Flex)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid ${CSS.GRAY4};
  border-radius: ${CSS.BORDER_RADIUS};
`;

const InvitationButton = styled(Flex)`
  width: 66px;
  height: 26px;
  cursor: pointer;
  border-radius: ${CSS.BORDER_RADIUS};

  :hover {
    background-color: ${CSS.GRAY2};
  }
`;

const RefuseButton = styled(InvitationButton)`
  border: 1px solid ${CSS.ERROR};
  color: ${CSS.ERROR};
`;
