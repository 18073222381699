import React from "react";

import ShortcutButton from "./ShortcutButton";
import Classification from "./Classification";

import {
  Title,
  Divider,
  SubTitle,
  CContainer,
  TopContainer,
  TitleContainer,
  BottomContainer,
  MacUserContainer,
  TitleBottomContainer,
  QuantityPanelContainer,
} from "../styles";

import { Para } from "sf/components/Styled";

const ITEMS_LIST = [
  {
    id: 0,
    type: "outlined",
    btnTitle: "Click",
    title: "Select items",
  },
  {
    id: 1,
    type: "outlined",
    btnTitle: "Double Click",
    title: "Draw with classification",
  },
  {
    id: 2,
    type: "outlined",
    btnTitle: "Drag",
    title: "Reorder",
  },
  {
    id: 3,
    type: "outlined",
    btnTitle: "Right Click",
    title: "Context menu",
  },
];

function QuantityPanel() {
  return (
    <QuantityPanelContainer>
      <div>
        <Title style={{ marginBottom: "19px" }} isTitle>
          Quantity Panel
        </Title>
        <TopContainer>
          <TitleContainer margin="0 0 20px 0">
            <ShortcutButton type="outlined" title="Click" />
            <SubTitle>Show / hide individual items</SubTitle>
          </TitleContainer>
          <CContainer>
            <TitleContainer>
              <ShortcutButton type="outlined" title="Click" />
              <SubTitle>Hide / show</SubTitle>
            </TitleContainer>
            <TitleContainer margin="0 0 20xp 0">
              <ShortcutButton margin="0 5px 0 0" type="filled" title="Ctrl" />
              <ShortcutButton type="outlined" title="Click" />
              <SubTitle>Hide all other</SubTitle>
            </TitleContainer>
          </CContainer>
          <TitleContainer margin="0 0 0 40px">
            <ShortcutButton type="outlined" title="Click" />
            <SubTitle>Draw with classification</SubTitle>
          </TitleContainer>
        </TopContainer>
        <Classification />
        <BottomContainer>
          {ITEMS_LIST.map((item) => {
            return (
              <TitleBottomContainer key={item.id}>
                <ShortcutButton type={item.type} title={item.btnTitle} />
                <SubTitle>{item.title}</SubTitle>
              </TitleBottomContainer>
            );
          })}
        </BottomContainer>
      </div>
      <MacUserContainer>
        <Para style={{ color: "white" }}>For Mac Users</Para>
        <div style={{ display: "flex" }}>
          <ShortcutButton type="filled" title="Ctrl" />
          <Divider>=</Divider>
          <ShortcutButton type="filled" title="Command/Cmd ⌘" />
        </div>
      </MacUserContainer>
    </QuantityPanelContainer>
  );
}

export default QuantityPanel;
