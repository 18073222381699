import React, {
  useRef,
  useMemo,
  useState,
  useContext,
  useCallback,
  createContext,
} from "react";
import { useRafLoop } from "sf/hooks";
import { getMapImageData } from "sf/modules/Cache/utils";

const CACHE_PADDING = 2;

async function downloadImage(url) {
  const img = new Image();
  img.crossOrigin = "Anonymous";
  img.src = url + "?cache=none";

  await new Promise((resolve, reject) => {
    img.onload = resolve;
    img.onerror = reject;
  });

  return img;
}

const CacheContext = createContext();
export const useCache = () => useContext(CacheContext);

export const CacheProvider = ({ children }) => {
  const lastTime = useRef(0);

  const imageCache = useRef(new Map());
  const imagesQueue = useRef(new Set());
  const imageCacheMap = useRef(new Map());

  const [cache, setCache] = useState(new Map());

  useRafLoop(
    async (time) => {
      const lastStep = lastTime.current;
      const targetFPS = 1000;
      const step = time - lastStep;

      if (step >= targetFPS) {
        lastTime.current = time;

        const cond = (k) =>
          !imageCache.current.has(k) ||
          (imageCache.current.has(k) &&
            !(
              imageCache.current.get(k).isLoaded ||
              imageCache.current.get(k).isLoading ||
              imageCache.current.get(k).error
            ));

        const currentQueue = [...new Set(imagesQueue.current)].filter((k) =>
          cond(k)
        );

        for (const k of imageCache.current.keys()) {
          if (
            (cond(k) ||
              imageCache.current.get(k).url !==
                imageCacheMap.current.get(k).url) &&
            !currentQueue.includes(k)
          ) {
            currentQueue.push(k);
          }
        }

        if (currentQueue.length > 0) {
          currentQueue.forEach((k) => {
            const currentQueueImage = imageCacheMap.current.get(k);

            const url = currentQueueImage?.url || null;
            const cacheData = imageCache.current.has(k)
              ? imageCache.current.get(k)
              : {};

            const newImage = {
              id: cacheData?.id || k,
              context: cacheData?.context || null,
              isLoading: cacheData?.isLoading || false,
              isLoaded: cacheData?.isLoaded || false,
              imgData: cacheData?.imgData || null,
              url,
              error: cacheData?.error || null,
            };

            imageCache.current.set(k, newImage);
          });

          const sortedImages = [...currentQueue].sort((a, b) => {
            const priorityA = imageCacheMap.current.get(a).priority;
            const priorityB = imageCacheMap.current.get(b).priority;

            return priorityA < priorityB ? -1 : priorityA > priorityB ? 1 : 0;
          });

          const imageToProcessKey = sortedImages[0];

          if (
            imageCacheMap.current.get(imageToProcessKey).priority <=
            CACHE_PADDING
          ) {
            imageCache.current.set(imageToProcessKey, {
              ...imageCache.current.get(imageToProcessKey),
              isLoading: true,
            });

            setCache(new Map(imageCache.current));

            try {
              const currentImageCache =
                imageCache.current.get(imageToProcessKey);

              if (!currentImageCache) {
                throw new Error("Image not present in cache");
              }

              const url = currentImageCache?.url || null;
              const img = await downloadImage(url);
              const imgContextData = getMapImageData(img);

              imageCache.current.set(imageToProcessKey, {
                ...imageCache.current.get(imageToProcessKey),
                imgData: img,
                context: imgContextData,
                isLoading: false,
                isLoaded: true,
                error: null,
              });
            } catch (e) {
              console.error("error loading the image : ", e);
              imageCache.current.set(imageToProcessKey, {
                ...imageCache.current.get(imageToProcessKey),
                imgData: null,
                context: null,
                isLoading: false,
                isLoaded: false,
                error: "An error occured while trying to load the image : ",
              });
            }
            setCache(new Map(imageCache.current));
          }
        }
      }
    },
    true,
    [setCache, lastTime, imageCache, imagesQueue, imageCacheMap]
  );

  const addImages = useCallback(
    (imgs) => {
      [...imagesQueue.current].forEach((k) => {
        if (cache.has(k)) {
          imagesQueue.current.delete(k);
        }
      });

      imgs.forEach(({ key, url, priority }) => {
        const imageCache = cache.has(key) ? cache.get(key) : null;

        if (
          !imageCache ||
          (imageCache && !imageCache.isLoaded) ||
          (imageCache && !imageCache.isLoading) ||
          (imageCache && imageCache.url !== url)
        ) {
          imageCacheMap.current.set(key, { key, url, priority });
          imagesQueue.current.add(key);
          return;
        }
      });
    },
    [cache]
  );

  const value = (window.cacheContext = useMemo(
    () => ({
      cache,
      addImages,
    }),
    [cache, addImages]
  ));

  return (
    <CacheContext.Provider value={value}>{children}</CacheContext.Provider>
  );
};
