import request from "../request";
import { withGlobalOrg, withGlobalOrgWhere } from "./_helpers";

export async function listClassificationSets(query) {
  const { body } = await request.get("classification/set", {
    query: withGlobalOrgWhere(query),
  });
  return body;
}

export async function addClassificationSet(props) {
  const { body } = await request.post("classification/set", {
    body: withGlobalOrg(props),
  });
  return body;
}

export async function getClassificationSetById(id) {
  const { body } = await request.get(`classification/set/${id}`);
  return body;
}

export async function updateClassificationSetById({ id, ...rest }) {
  const { body } = await request.put(`classification/set/${id}`, {
    body: withGlobalOrg(rest),
  });
  return body;
}

export async function removeClassificationSetById(id) {
  const { success } = await request.delete(`classification/set/${id}`);
  return success;
}

export async function duplicateClassificationsFromSet(from, to) {
  const { success } = await request.post(
    `classification/set/${from}/set/${to}/copy`
  );
  return success;
}
