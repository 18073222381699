import React from "react";
import { CSS } from "sf/consts/style";
import styled from "styled-components";

function ShortcutButton({ type = "outlined", title = "", margin = "" }) {
  return (
    <ButtonContainer margin={margin} type={type}>
      {title}
    </ButtonContainer>
  );
}

export default ShortcutButton;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  padding: 0 10px;
  width: max-content;
  font-weight: 700;
  font-size: 14px;
  position: relative;
  margin: ${(props) => props.margin && props.margin};

  border-radius: ${CSS.BORDER_RADIUS};
  border: 1px solid ${CSS.MAIN2};

  background-color: ${(props) =>
    props.type === "outlined" ? CSS.DARKGREY : CSS.MAIN2};
  color: ${(props) => (props.type === "outlined" ? CSS.MAIN2 : CSS.DARKGREY)};
`;
