import React, { FC } from "react";
import { createPortal } from "react-dom";

export default function withPortal<T>(WrappedComponent: FC<T>) {
  return ({ innerModal = false, ...props }: any) => {
    const [isDOMReady, set] = React.useState(false);

    React.useEffect(() => {
      set(true);
    }, []);

    if (!isDOMReady) return null;

    return createPortal(
      <WrappedComponent {...props} />,
      document.getElementById(innerModal ? "inner-modal" : "modal")
    );
  };
}
