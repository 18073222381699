import request from "../request";

export async function detectRegions(vector, features) {
  const searchQuery = window.location.search;

  const { body } = await request.post(
    `togal/scale/${vector.id}?${searchQuery}`,
    {
      body: features,
    }
  );
  return body;
}

export async function detectSymbol(
  vector,
  bounding_box,
  organization_id = null
) {
  const { body } = await request.post(`togal/symbols`, {
    body: {
      bounding_box,
      view_id: vector.id,
      ...(organization_id ? { organization_id } : {}),
    },
  });
  return body;
}

export async function detectSymbolInSet(
  vector,
  bounding_box,
  organization_id = null
) {
  const { body } = await request.post(`togal/symbols`, {
    body: {
      bounding_box,
      view_id: vector.id,
      set_id: vector.set_id,
      ...(organization_id ? { organization_id } : {}),
    },
  });
  return body;
}

export async function detectPatterns(vector, bounding_box) {
  const { body } = await request.post(`togal/patterns`, {
    body: {
      bounding_box,
      view_id: vector.id,
    },
  });
  return body;
}

export async function getSymbolSearchResults({
  symbolSearchAttemptId,
  next_page = null,
}) {
  const { limit, offset } = next_page ?? {};

  const { body } = await request.get(`symbol_search`, {
    query: {
      $where: {
        symbol_search_attempt_id: symbolSearchAttemptId,
      },
      $order: ["created", "asc"],
      ...(limit != null && { $limit: limit }),
      ...(offset != null && { $offset: offset }),
    },
  });
  return body;
}
