import React from "react";

export const ORGSWITCHER_IDS = {
  org1: "org_org1",
  org2: "org_org2",
  org3: "org_org3",
  org4: "org_org4",
  org5: "org_org5",
  org6: "org_org6",
  org7: "org_org7",
  org8: "org_org8",
};

export const ORGSWITCHER_ICONS = {
  [ORGSWITCHER_IDS.org1]: {
    width: "24",
    height: "24",
    color: "#323232",
    viewBox: "0 0 24 24",
    icon: OrgIcon1,
  },
  [ORGSWITCHER_IDS.org2]: {
    width: "24",
    height: "24",
    color: "#323232",
    viewBox: "0 0 24 24",
    icon: OrgIcon2,
  },
  [ORGSWITCHER_IDS.org3]: {
    width: "24",
    height: "24",
    color: "#323232",
    viewBox: "0 0 24 24",
    icon: OrgIcon3,
  },
  [ORGSWITCHER_IDS.org4]: {
    width: "24",
    height: "24",
    color: "#323232",
    viewBox: "0 0 24 24",
    icon: OrgIcon4,
  },
  [ORGSWITCHER_IDS.org5]: {
    width: "24",
    height: "24",
    color: "#323232",
    viewBox: "0 0 24 24",
    icon: OrgIcon5,
  },
  [ORGSWITCHER_IDS.org6]: {
    width: "24",
    height: "24",
    color: "#323232",
    viewBox: "0 0 24 24",
    icon: OrgIcon6,
  },
  [ORGSWITCHER_IDS.org7]: {
    width: "24",
    height: "24",
    color: "#323232",
    viewBox: "0 0 24 24",
    icon: OrgIcon7,
  },
  [ORGSWITCHER_IDS.org8]: {
    width: "24",
    height: "24",
    color: "#323232",
    viewBox: "0 0 24 24",
    icon: OrgIcon8,
  },
};

export function OrgIcon1({ color = "#323232" }) {
  return (
    <>
      <g clipPath="url(#clip0_1727_77455)">
        <path
          d="M17 11V3H7V7H3V21H11V17H13V21H21V11H17ZM7 19H5V17H7V19ZM7 15H5V13H7V15ZM7 11H5V9H7V11ZM11 15H9V13H11V15ZM11 11H9V9H11V11ZM11 7H9V5H11V7ZM15 15H13V13H15V15ZM15 11H13V9H15V11ZM15 7H13V5H15V7ZM19 19H17V17H19V19ZM19 15H17V13H19V15Z"
          fill="#1C2931"
        />
      </g>
      <defs>
        <clipPath id="clip0_1727_77455">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  );
}

export function OrgIcon2({ color = "#323232" }) {
  return (
    <>
      <g clipPath="url(#clip0_1727_77406)">
        <path
          d="M22.6097 18.9895L13.5297 9.90948C14.4597 7.56948 13.9797 4.80948 12.0897 2.90948C9.78972 0.609483 6.20972 0.399483 3.65972 2.25948L7.49972 6.10948L6.07972 7.51948L2.24972 3.68948C0.389717 6.22948 0.599717 9.81948 2.89972 12.1095C4.75972 13.9695 7.46972 14.4595 9.78972 13.5895L18.8997 22.6995C19.2897 23.0895 19.9197 23.0895 20.3097 22.6995L22.6097 20.3995C23.0097 20.0195 23.0097 19.3895 22.6097 18.9895ZM19.6097 20.5895L10.1497 11.1295C9.53972 11.5795 8.85972 11.8495 8.14972 11.9495C6.78972 12.1495 5.35972 11.7395 4.31972 10.6995C3.36972 9.75948 2.92972 8.49948 2.99972 7.25948L6.08972 10.3495L10.3297 6.10948L7.23972 3.01948C8.47972 2.94948 9.72972 3.38948 10.6797 4.32948C11.7597 5.40948 12.1697 6.89948 11.9197 8.28948C11.7997 8.99948 11.4997 9.65948 11.0397 10.2495L20.4897 19.6995L19.6097 20.5895Z"
          fill="#1C2931"
        />
      </g>
      <defs>
        <clipPath id="clip0_1727_77406">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  );
}

export function OrgIcon3({ color = "#323232" }) {
  return (
    <>
      <g clipPath="url(#clip0_1727_77468)">
        <path
          d="M6.36035 18.7828L6.61035 21.0028L8.23035 19.4628L11.0004 11.8628C10.3204 11.6928 9.72035 11.3528 9.23035 10.8828L6.36035 18.7828Z"
          fill="#1C2931"
        />
        <path
          d="M14.77 10.8828C14.28 11.3528 13.67 11.6928 13 11.8628L15.77 19.4628L17.39 21.0028L17.65 18.7828L14.77 10.8828Z"
          fill="#1C2931"
        />
        <path
          d="M15 8C15 6.7 14.16 5.6 13 5.18V3H11V5.18C9.84 5.6 9 6.7 9 8C9 9.66 10.34 11 12 11C13.66 11 15 9.66 15 8ZM12 9C11.45 9 11 8.55 11 8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8C13 8.55 12.55 9 12 9Z"
          fill="#1C2931"
        />
      </g>
      <defs>
        <clipPath id="clip0_1727_77468">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  );
}

export function OrgIcon4({ color = "#323232" }) {
  return (
    <>
      <g clipPath="url(#clip0_1727_77431)">
        <path
          d="M21.6698 18.1672L16.3698 12.8672H15.3798L12.8398 15.4072V16.3972L18.1398 21.6972C18.5298 22.0872 19.1598 22.0872 19.5498 21.6972L21.6698 19.5772C22.0598 19.1972 22.0598 18.5572 21.6698 18.1672ZM18.8398 19.5872L14.5998 15.3472L15.3098 14.6372L19.5498 18.8772L18.8398 19.5872Z"
          fill="#1C2931"
        />
        <path
          d="M17.34 10.19L18.75 8.78L20.87 10.9C22.04 9.73 22.04 7.83 20.87 6.66L17.33 3.12L15.92 4.53V1.71L15.22 1L11.68 4.54L12.39 5.25H15.22L13.81 6.66L14.87 7.72L11.98 10.61L7.85 6.48V5.06L4.83 2.04L2 4.87L5.03 7.9H6.44L10.57 12.03L9.72 12.88H7.6L2.3 18.18C1.91 18.57 1.91 19.2 2.3 19.59L4.42 21.71C4.81 22.1 5.44 22.1 5.83 21.71L11.13 16.41V14.29L16.28 9.14L17.34 10.19ZM9.36 15.34L5.12 19.58L4.41 18.87L8.65 14.63L9.36 15.34Z"
          fill="#1C2931"
        />
      </g>
      <defs>
        <clipPath id="clip0_1727_77431">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  );
}

export function OrgIcon5({ color = "#323232" }) {
  return (
    <>
      <g clipPath="url(#clip0_1727_77441)">
        <path
          d="M19.7304 14.23L7.00035 1.5L3.11035 5.39L11.2404 17.06C10.4604 17.84 10.4604 19.11 11.2404 19.89L12.6504 21.3C13.4304 22.08 14.7004 22.08 15.4804 21.3L19.7204 17.06C20.5104 16.28 20.5104 15.01 19.7304 14.23ZM5.71035 5.62L7.00035 4.33L15.4904 12.82L12.6804 15.63L5.71035 5.62ZM14.0704 19.88L12.6604 18.47L16.9004 14.23L18.3104 15.64L14.0704 19.88Z"
          fill="#1C2931"
        />
      </g>
      <defs>
        <clipPath id="clip0_1727_77441">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  );
}

export function OrgIcon6({ color = "#323232" }) {
  return (
    <>
      <g clipPath="url(#clip0_1727_77445)">
        <path
          d="M21 6H3C1.9 6 1 6.9 1 8V16C1 17.1 1.9 18 3 18H21C22.1 18 23 17.1 23 16V8C23 6.9 22.1 6 21 6ZM21 16H3V8H5V12H7V8H9V12H11V8H13V12H15V8H17V12H19V8H21V16Z"
          fill="#1C2931"
        />
      </g>
      <defs>
        <clipPath id="clip0_1727_77445">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  );
}

export function OrgIcon7({ color = "#323232" }) {
  return (
    <>
      <g clipPath="url(#clip0_1727_77449)">
        <path
          d="M20 7V3C20 2.45 19.55 2 19 2H7C6.45 2 6 2.45 6 3V4H4C2.9 4 2 4.9 2 6V10C2 11.1 2.9 12 4 12H12V15H11C10.45 15 10 15.45 10 16V22C10 22.55 10.45 23 11 23H15C15.55 23 16 22.55 16 22V16C16 15.45 15.55 15 15 15H14V12C14 10.9 13.1 10 12 10H4V6H6V7C6 7.55 6.45 8 7 8H19C19.55 8 20 7.55 20 7ZM8 4H18V6H8V4ZM14 21H12V17H14V21Z"
          fill="#1C2931"
        />
      </g>
      <defs>
        <clipPath id="clip0_1727_77449">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  );
}

export function OrgIcon8({ color = "#323232" }) {
  return (
    <>
      <g clipPath="url(#clip0_1727_77461)">
        <path
          d="M18 3L15 6V3H9C6.24 3 4 5.24 4 8H9V20C9 20.55 9.45 21 10 21H14C14.55 21 15 20.55 15 20V8L18 11H20V3H18ZM13 19H11V13H13V19ZM11 11V6H6.77C7.32 5.39 8.11 5 9 5H13V11H11Z"
          fill="#1C2931"
        />
      </g>
      <defs>
        <clipPath id="clip0_1727_77461">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  );
}
