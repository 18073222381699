import { CSSProperties } from "react";
import styled from "styled-components";

import { ReactComponent as CheckIcon } from "src/svg/editor/check.svg";

import { Flex, Para } from "../Styled";
import { CSS } from "../../consts/style";
import { addOpacity } from "../../utils/style";

export const LEGEND_WIDTH = 400;

export const LabelContainer = styled.div`
  position: relative;
  background: ${CSS.WHITE};
  border: 1px solid ${CSS.GREY};
  border-radius: ${CSS.BORDER_RADIUS};
  min-height: 35px;
  margin: 4px;
  width: 100%;
  user-select: none;
  height: auto;

  display: grid;
  grid-template-columns: 1fr 80px 80px 80px;
`;

export const IconContainer = styled.div<CSSProperties>`
  position: relative;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => props.opacity || 1};

  ::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

export const CountPara = styled(Para)`
  background-color: ${CSS.GREY};
  border-radius: 10px;
  margin-left: 5px;
  padding: 2px 7px 2px 7px;
`;

export const AbsContainer = styled(Flex)`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
  cursor: move;
`;

export const WidthDragContainer = styled(AbsContainer)`
  height: 100%;
  width: 10px;
  cursor: col-resize;
  right: -4px;
  top: 0;
  z-index: 11;

  opacity: 0;
  background: ${addOpacity(CSS.BLACK, 40)};
`;

export const HeightDragContainer = styled(AbsContainer)`
  width: 100%;
  height: 10px;
  bottom: -4px;
  z-index: 11;
  cursor: row-resize;

  opacity: 0;
  background: ${addOpacity(CSS.BLACK, 40)};
`;

export const SizeDragContainer = styled(AbsContainer)`
  width: 20px;
  height: 20px;
  bottom: -9px;
  right: -9px;
  border-radius: 50%;
  border: 2px solid ${CSS.WHITE};

  z-index: 12;
  cursor: nwse-resize;

  opacity: 0;
  background: ${CSS.BLACK};
`;

export const Container = styled.div`
  position: absolute;
  padding: 20px;
  min-height: 40px;
  width: ${LEGEND_WIDTH}px;

  transform-origin: top left;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  background: ${CSS.WHITE};
  z-index: 2;
  cursor: pointer;
  user-select: none;
  box-shadow: inset 0 0 0 2px ${CSS.BLACK};

  &:hover ${WidthDragContainer} {
    opacity: 1;
  }

  &:hover ${HeightDragContainer} {
    opacity: 1;
  }

  &:hover ${SizeDragContainer} {
    opacity: 1;
  }
`;

export const StyledCheckIcon = styled(CheckIcon)`
  width: 12px;
  height: 12px;

  g {
    opacity: 1;
  }
`;
