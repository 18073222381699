import { curry } from "./function";

export const gte = curry(
  (comparedTerm: number, term: number): boolean => term >= comparedTerm
);

export const gt = curry(
  (comparedTerm: number, term: number): boolean => term > comparedTerm
);

export const lte = curry(
  (comparedTerm: number, term: number): boolean => term <= comparedTerm
);

export const lt = curry(
  (comparedTerm: number, term: number): boolean => term < comparedTerm
);
