import React from "react";
import { MODES_IDS } from "./editor";

import Icon from "../components/Icon";

export const TEXT_ALIGNMENT = {
  START: "start",
  MIDDLE: "middle",
  END: "end",
};

export const TEXT_TOOLS = [
  {
    id: 1,
    showIcon: true,
    title: "Text Box",
    value: MODES_IDS.markup_text_box,
    icon: () => <Icon id={MODES_IDS.markup_text_box} />,
  },
  {
    id: 2,
    showIcon: true,
    title: "Text Box",
    value: MODES_IDS.markup_text,
    icon: () => <Icon id={MODES_IDS.markup_text} />,
  },
  {
    id: 3,
    showIcon: true,
    title: "Text Cloud",
    value: MODES_IDS.markup_cloud,
    icon: () => <Icon id={MODES_IDS.markup_cloud} />,
  },
];

export const DRAW_TOOLS = [
  {
    id: 4,
    showIcon: true,
    title: "Rectangle",
    value: MODES_IDS.markup_box,
    icon: () => <Icon id={MODES_IDS.markup_box} />,
  },
  {
    id: 5,
    showIcon: true,
    title: "Circle",
    value: MODES_IDS.markup_ellipse,
    icon: () => <Icon id={MODES_IDS.markup_ellipse} />,
  },
  {
    id: 6,
    showIcon: true,
    title: "Polygon",
    value: MODES_IDS.markup_polygon,
    icon: () => <Icon id={MODES_IDS.markup_polygon} />,
  },
  {
    id: 7,
    showIcon: true,
    title: "Draw",
    value: MODES_IDS.markup_freehand,
    icon: () => <Icon id={MODES_IDS.markup_freehand} />,
  },
];

export const LINE_TOOLS = [
  {
    id: 8,
    showIcon: true,
    title: "Line",
    value: MODES_IDS.markup_line,
    icon: () => <Icon id={MODES_IDS.markup_line} />,
  },
  {
    id: 9,
    showIcon: true,
    title: "Arrow",
    value: MODES_IDS.markup_arrow,
    icon: () => <Icon id={MODES_IDS.markup_arrow} />,
  },
];

export const ALL_MARKUP_TOOLS = [TEXT_TOOLS, DRAW_TOOLS, LINE_TOOLS];

export const MARKUP_THICKNESS_OPTIONS = new Array(6).fill(0).map((_, idx) => ({
  id: idx,
  title: idx,
  value: idx,
  showIcon: false,
  isConfig: true,
  icon: () => <Icon id={MODES_IDS.markup_thickness} />,
}));

export const MARKUP_LINE_THICKNESS_OPTIONS = new Array(5)
  .fill(0)
  .map((_, idx) => ({
    id: idx + 1,
    title: idx + 1,
    value: idx + 1,
    showIcon: false,
    isConfig: true,
    icon: () => <Icon id={MODES_IDS.markup_thickness} />,
  }));

export const MARKUP_BORDER_OPTIONS = [
  {
    id: 10,
    title: "Border",
    value: "border",
    showIcon: false,
    isConfig: true,
    icon: () => <Icon id={MODES_IDS.markup_border} />,
  },
];

export const MARKUP_FILL_OPTIONS = [
  {
    id: 11,
    title: "Fill",
    value: "fill",
    showIcon: false,
    isConfig: true,
    icon: () => <Icon id={MODES_IDS.markup_fill} />,
  },
];

export const TEXT_ALIGN_OPTIONS = [
  {
    id: 12,
    title: "Align Left",
    value: "left",
    showIcon: true,
    isConfig: true,
    icon: () => <Icon id={MODES_IDS.markup_align_left} />,
  },
  {
    id: 13,
    title: "Align Center",
    value: "center",
    showIcon: true,
    isConfig: true,
    icon: () => <Icon id={MODES_IDS.markup_align_center} />,
  },
  {
    id: 14,
    title: "Align Right",
    value: "right",
    showIcon: true,
    isConfig: true,
    icon: () => <Icon id={MODES_IDS.markup_align_right} />,
  },
  {
    id: 15,
    title: "Align Justify",
    value: "justify",
    showIcon: true,
    isConfig: true,
    icon: () => <Icon id={MODES_IDS.markup_align_justify} />,
  },
];

export const TEXT_FONTS_OPTIONS = [
  {
    id: 17,
    title: "Arial",
    value: "Arial",
    showIcon: false,
    isConfig: true,
  },
  {
    id: 18,
    title: "Times New Roman",
    value: "Times New Roman",
    showIcon: false,
    isConfig: true,
  },
  {
    id: 19,
    title: "Courier New",
    value: "Courier New",
    showIcon: false,
    isConfig: true,
  },
  {
    id: 20,
    title: "Verdana",
    value: "Verdana",
    showIcon: false,
    isConfig: true,
  },
  {
    id: 21,
    title: "Georgia",
    value: "Georgia",
    showIcon: false,
    isConfig: true,
  },
  {
    id: 22,
    title: "Palatino",
    value: "Palatino",
    showIcon: false,
    isConfig: true,
  },
  {
    id: 23,
    title: "Garamond",
    value: "Garamond",
    showIcon: false,
    isConfig: true,
  },
  {
    id: 24,
    title: "Bookman",
    value: "Bookman",
    showIcon: false,
    isConfig: true,
  },
  {
    id: 25,
    title: "Comic Sans MS",
    value: "Comic Sans MS",
    showIcon: false,
    isConfig: true,
  },
  {
    id: 26,
    title: "Trebuchet MS",
    value: "Trebuchet MS",
    showIcon: false,
    isConfig: true,
  },
  {
    id: 27,
    title: "Arial Black",
    value: "Arial Black",
    showIcon: false,
    isConfig: true,
  },
  {
    id: 28,
    title: "Impact",
    value: "Impact",
    showIcon: false,
    isConfig: true,
  },
  {
    id: 29,
    title: "Lucida Sans Unicode",
    value: "Lucida Sans Unicode",
    showIcon: false,
    isConfig: true,
  },
  {
    id: 30,
    title: "Tahoma",
    value: "Tahoma",
    showIcon: false,
    isConfig: true,
  },
  {
    id: 31,
    title: "Courier",
    value: "Courier",
    showIcon: false,
    isConfig: true,
  },
  {
    id: 32,
    title: "Lucida Console",
    value: "Lucida Console",
    showIcon: false,
    isConfig: true,
  },
];

export const MARKUP_TEXT_STYLES = ["none", "bold", "italic", "underline"];

export const TEXT_DECORATION_OPTIONS = [
  {
    id: 33,
    title: "Bold",
    value: MARKUP_TEXT_STYLES[1],
    showIcon: true,
    isConfig: true,
    icon: () => <Icon id={MODES_IDS.markup_text_bold} />,
  },
  {
    id: 34,
    title: "Italic",
    value: MARKUP_TEXT_STYLES[2],
    showIcon: true,
    isConfig: true,
    icon: () => <Icon id={MODES_IDS.markup_text_italic} />,
  },
  {
    id: 35,
    title: "Underline",
    value: MARKUP_TEXT_STYLES[3],
    showIcon: true,
    isConfig: true,
    icon: () => <Icon id={MODES_IDS.markup_text_underline} />,
  },
];
