import { atom, selector } from "recoil";

export const notitificationsVisibleState = atom({
  key: `notitificationsVisibleState`,
  default: false,
});

export const notitificationsCountState = atom({
  key: `notitificationsCountState`,
  default: 0,
});

export const notificationsList = atom({
  key: `notificationsList`,
  default: [],
});

export const notificationsListState = selector({
  key: `notificationsListState`,
  get: ({ get }) => {
    const list = get(notificationsList);
    return list;
  },
  set: ({ set }, value) => {
    set(notificationsList, value);
  },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});
