export const VIEW_PREFIX = " View";
export const COMBINED_VIEW = "Combined";

// global roles
export const USER_ROLE = "user";
export const ADMIN_ROLE = "admin";
export const VIEWER_ROLE = "viewer";
export const SUPERADMIN_ROLE = "superadmin";

// set roles
export const SET_ROLE_OWNER = "owner";
export const SET_ROLE_EDITOR = "editor";
export const SET_ROLE_VIEWER = "viewer";
export const SET_ROLE_MANAGER = "manager";
export const SET_ROLE_EDITOR_ISOLATED = "isolated_editor";

// permission keys:
export const PROJECT_PERMISSIONS = "PROJECT";
export const NAVIGATION_PERMISSIONS = "NAVIGATION";

export function getPermissions(user: any, org: any) {
  const userRole =
    (Array(org?.users) || []).find((u: any) => u?.id === user?.id)?.type ||
    user?.type;

  const userOrganizationIds = [
    user?.organization_id,
    ...(user?.organization_ids || []),
  ].filter(Boolean);

  const isUserInOrg = userOrganizationIds.includes(org?.id);

  const isSuperAdmin = user?.type === SUPERADMIN_ROLE;
  const isViewer = !isSuperAdmin && (!isUserInOrg || userRole === VIEWER_ROLE);
  const isAdmin = userRole === ADMIN_ROLE && user?.organization_id === org?.id;

  return {
    [NAVIGATION_PERMISSIONS]: {
      METABASE: isSuperAdmin,
      SUPERADMIN: isSuperAdmin,
      SETTINGS: isAdmin || isSuperAdmin,
      LIBRARY: isUserInOrg || isSuperAdmin,
    },
    [PROJECT_PERMISSIONS]: {
      ADD: !isViewer,
      SELECT: !isViewer,
      CONTEXT: !isViewer,

      STAR: (project: any) =>
        !(user?.metadata?.starredOrgs || []).includes(project?.id),
      UNSTAR: (project: any) =>
        (user?.metadata?.starredOrgs || []).includes(project?.id),
      DUPLICATE: true,
      EDIT: (project: any) =>
        project?.user_id === user?.id || isAdmin || isSuperAdmin,
      RESTORE: (project: any) =>
        !project.active &&
        (project?.user_id === user?.id || isAdmin || isSuperAdmin),
      ARCHIVE: (project: any) =>
        project.active &&
        (project?.user_id === user?.id || isAdmin || isSuperAdmin),
      DELETE: (project: any) =>
        !project.active &&
        (project?.user_id === user?.id || isAdmin || isSuperAdmin),

      FILTER_ALL: true,
      FILTER_MINE: !isViewer,
      FILTER_SHARED: !isViewer,
      FILTER_STARRED: !isViewer,
      FILTER_ARCHIVED: !isViewer,
    },
  };
}
