export const methods = [
  "get",
  "post",
  "put",
  "patch",
  "delete",
  "head",
  "options",
  "trace",
];

export const descriptions = {
  "000": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Status",

  100: "Everything is OK. The client should continue with the request or ignore it if it is already finished.",
  101: "A response to an Upgrade request header by the client. Indicates the protocol the server is switching to.",

  200: "The request has succeeded.",
  201: "The request has succeeded. A new resource has been created as a result of it.",
  202: "The request has been received but not yet acted upon.",
  203: "The request has succeeded. Returned meta-information set is not exact set as available from the origin server, but collected from a local or a third party copy.",
  204: "The request has succeeded. There is no content to send for this request, but the headers may be useful.",
  205: "The request has succeeded. User agent should reset document view for which this request was sent.",
  206: "The request has succeeded. Range header was sent by the client to separate download into multiple streams.",

  300: "The request has more than one possible response. User-agent or user should choose one of them.",
  301: "The URI of the requested resource has been changed permanently. A new URI should be given in the response.",
  302: "The URI of the requested resource has been changed temporarily. A new URI should be given in the response.",
  303: "Redirect client to get requested resource at another URI with a GET request.",
  304: "Response has not been modified.",
  307: "Temporarily redirect client to get requested resource at another URI with the same method that was used for the prior request.",
  308: "Permanently redirect client to get requested resource at another URI with the same method that was used for the prior request.",

  400: "The server could not understand the request due to invalid syntax.",
  401: "The client must authenticate itself to get the requested response.",
  403: "The client does not have access rights to the content.",
  404: "The server can not find requested resource.",
  405: "The request method is known by the server but has been disabled and cannot be used.",
  406: "Performed server-driven content negotiation, unable to find any content following the criteria given by the user agent.",
  407: "The client must authenticate itself with a proxy to get the requested response.",
  408: "The server would like to shut down this unused connection.",
  409: "The request conflicts with the current state of the server.",
  410: "The requested content has been permenantly deleted from server, with no forwarding address",
  411: "Content-Length header field is not defined and the server requires it.",
  412: "The client has indicated preconditions in its headers which the server does not meet.",
  413: "Request entity is larger than limits defined by the server.",
  414: "The URI requested by the client is longer than the server is willing to interpret.",
  415: "The media format of the requested data is not supported by the server.",
  416: "The range specified by the Range header field in the request can't be fulfilled.",
  417: "The expectation indicated by the Expect request header field can't be met by the server.",
  421: "The request was directed at a server that is not able to produce a response.",
  426: "The server refuses to perform the request using the current protocol but might be willing to do so after the client upgrades to a different protocol.",
  428: "The origin server requires the request to be conditional.",
  429: "The user has sent too many requests in a given amount of time.",
  431: "The server is unwilling to process the request because its header fields are too large.",
  451: "The user requests an illegal resource, such as a web page censored by a government.",

  500: "The server has encountered a situation it doesn't know how to handle.",
  501: "The request method is not supported by the server and cannot be handled.",
  502: "The server, while working as a gateway to get a response needed to handle the request, got an invalid response.",
  503: "The server is not ready to handle the request.",
  504: "The server is acting as a gateway and cannot get a response in time.",
  505: "The HTTP version used in the request is not supported by the server.",
  506: "The server has an internal configuration error.",
  507: "The server has an internal configuration error.",
  510: "Further extensions to the request are required for the server to fulfill it.",
  511: "The client needs to authenticate to gain network access.",
};

export const http = {
  CONTINUE: 100,
  SWITCHING_PROTOCOL: 101,

  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NON_AUTHORITATIVE_INFORMATION: 203,
  NO_CONTENT: 204,
  RESET_CONTENT: 205,
  PARTIAL_CONTENT: 206,

  MULTIPLE_CHOICE: 300,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  SEE_OTHER: 303,
  NOT_MODIFIED: 304,
  TEMPORARY_REDIRECT: 307,
  PERMANENT_REDIRECT: 308,

  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  PROXY_AUTHENTICATION_REQUIRED: 407,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  GONE: 410,
  "LENGTH REQUIRED": 411,
  PRECONDITION_FAILED: 412,
  PAYLOAD_TOO_LARGE: 413,
  URI_TOO_LONG: 414,
  UNSUPPORTED_MEDIA_TYPE: 415,
  REQUESTED_RANGE_NOT_SATISFIABLE: 416,
  EXPECTATION_FAILED: 417,
  MISDIRECTED_REQUEST: 421,
  UPGRADE_REQUIRED: 426,
  PRECONDITION_REQUIRED: 428,
  TOO_MANY_REQUESTS: 429,
  REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
  UNAVAILABLE_FOR_LEGAL_REASONS: 451,

  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
  VARIANT_ALSO_NEGOTIATES: 506,
  INSUFFICIENT_STORAGE: 507,
  NOT_EXTENDED: 510,
  NETWORK_AUTHENTICATION_REQUIRED: 511,
};
