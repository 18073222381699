import { React, useCallback, useRef, useState, Fragment } from "react";

import { Flex, Para } from "sf/components/Styled";
import { CSS, FONT_WEIGHTS } from "sf/consts/style";

import useShortcuts from "src/components/ShortcutsGuide/hook";
import { ReactComponent as WandIcon } from "src/svg/dashboard/magic_wand.svg";

import { LINKS } from "../const";

import * as S from "../styles";
import useOnClickOutside from "sf/hooks/useOnClickOutside";

export default function SupportLinksMenu() {
  const modalRef = useRef(null);
  const buttonRef = useRef(null);

  const { open } = useShortcuts();

  const [areLinksOpen, setAreLinksOpen] = useState(false);
  const [isHubSpotChatOpened, setIsHubSpotChatOpened] = useState(false);

  const handleButtonClick = useCallback(() => {
    if (isHubSpotChatOpened) {
      setIsHubSpotChatOpened(false);
    }
    setAreLinksOpen(!areLinksOpen);
    // eslint-disable-next-line no-unused-expressions
    window?.HubSpotConversations?.widget?.close?.();
  }, [areLinksOpen]);

  const handleLinkClick = (e, link) => {
    e.stopPropagation();

    if (link.url) {
      let newWindow = window.open(link.url, "_blank");
      if (newWindow) {
        newWindow.focus();
      }
    }

    if (link.openHubspot) {
      // eslint-disable-next-line no-unused-expressions
      window?.HubSpotConversations?.widget?.open?.();
      setIsHubSpotChatOpened(true);
    }

    if (link?.isShortcut) {
      open();
    }

    setAreLinksOpen(false);
  };

  useOnClickOutside([modalRef], () => {
    setAreLinksOpen(false);
  });

  return (
    <>
      <S.StyledButton ref={buttonRef} onClick={handleButtonClick}>
        {isHubSpotChatOpened ? "X" : "?"}
      </S.StyledButton>
      {areLinksOpen && (
        <S.LinksContainer flex="column" ref={modalRef}>
          {LINKS.map((link, idx) => (
            <Fragment key={link?.text || "" + idx}>
              {link.isSeparator ? (
                <Flex height="14px">
                  <Flex height="1px" bgColor={CSS.GRAY6} />
                </Flex>
              ) : (
                <S.LinkButton
                  bgColor={CSS.TRANSPARENT}
                  onClick={(e) => handleLinkClick(e, link)}
                >
                  {link.isTogaGPT && (
                    <WandIcon style={{ width: "16px", marginRight: "5px" }} />
                  )}
                  <Para
                    fontColor={CSS.WHITE}
                    fontWeight={FONT_WEIGHTS.LIGHT}
                    fontSize={CSS.FONT_SMALL}
                  >
                    {link?.text}
                  </Para>
                </S.LinkButton>
              )}
            </Fragment>
          ))}
        </S.LinksContainer>
      )}
    </>
  );
}
