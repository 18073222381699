import styled from "styled-components";

import { Para } from "sf/components/Styled";
import { CSS, FONT_WEIGHTS } from "sf/consts/style";
import { ReactComponent as CloseIcon } from "../../svg/close.svg";

export const CloseContainer = styled.div`
  position: fixed;
  z-index: ${CSS.Z_INDEX_MAX};

  left: 0;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100vh;

  background: ${CSS.TRANSPARENT};
`;

interface NotificationsContainerProps {
  visible: boolean;
}

export const NotificationsContainer = styled.div`
  position: fixed;
  right: ${(props: NotificationsContainerProps) =>
    props?.visible ? "0px" : "-400px"};
  top: 60px;
  z-index: ${CSS.Z_INDEX_MAX};

  display: flex;
  align-items: flex-end;
  flex-direction: column;

  width: 100%;
  max-width: 400px;
  height: calc(100vh - 60px);

  background: ${CSS.WHITE};
  box-shadow: ${CSS.BOX_SHADOW};
  transition: right 0.2s ease-in-out;
`;

export const StyledPara = styled(Para)`
  cursor: pointer;
  font-weight: ${FONT_WEIGHTS.BOLD};
`;

export const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 20px;

  width: 12px;
  height: 12px;
  cursor: pointer;
`;

export const NoticationsListContainer = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  overflow-y: auto;
`;

export const AbsContainer = styled.div`
  position: absolute;
  top: 0;
  right: -140px;
  width: 140px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${CSS.GRAY2};

  transition: right 0.2s ease-in-out;
`;

export const NotificationItem = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 12px 0;

  display: flex;
  align-items: center;

  &:hover {
    background: ${CSS.GRAY1};
  }

  &:hover ${AbsContainer} {
    right: 0;
  }
`;

export const MarkButton = styled.div`
  padding: 5px 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  color: ${CSS.WHITE};
  background: ${CSS.MAINCOLOR};
  border-radius: ${CSS.BORDER_RADIUS};
`;

export const MarkAllButton = styled(MarkButton)`
  visibility: hidden;
  border: 1px solid ${CSS.GRAY6};
  background: ${CSS.WHITE};
  color: ${CSS.BLACK};
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: 50px;
  padding: 0 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid ${CSS.GRAY4};

  &:hover * ${MarkAllButton} {
    visibility: visible;
  }
`;
