import React, { useState } from "react";
import { useNavigate } from "react-router";

import * as externalApi from "src/lib/api/external";

export function Procore() {
  const navigate = useNavigate();
  const { search } = window.location;
  const [, code] = search.replace("?", "").split("=");

  async function handleAuth(code) {
    const user = await externalApi.procoreAuth(code);
    try {
      localStorage.setItem("togal-user", JSON.stringify(user));
    } catch (error) {
      console.error("Error setting user in local storage", error);
    }
    navigate("/dashboard/projects");
  }

  handleAuth(code);

  return <div>Please wait while we process your authorization.</div>;
}

export function Metabase(props) {
  const [iframeURL, setIframeURL] = useState();

  async function fetchURL() {
    const { url } = await externalApi.metabaseEmbed(props.dashboard);
    setIframeURL(url);
  }

  fetchURL();

  return (
    <iframe
      title="procore"
      src={iframeURL}
      frameBorder={0}
      width="100%"
      allowTransparency
    />
  );
}
