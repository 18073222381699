import { CommonDrawMode } from "./common-draw";
import { GEOJSON_TYPES } from "../../consts/editor";
import ImmutableLayersData from "../base/ImmutableLayersData";

import { generateId } from "../../utils/string";
import { head } from "../../utils/functional/list";
import { getMarkupFeatureContextProps } from "../../utils/geometry";
import { calculateDistance, makeArrowFromLine } from "../../utils/coordinates";

export class DrawArrowMarkupMode extends CommonDrawMode {
  handleAddFeature = (_event: any, props: any) => {
    const features = props.data.features;
    const clickSequence = this.getClickSequence();

    if (clickSequence.length > 0) {
      const coordinates: any = this?.mainFeature?.geometry?.coordinates;
      const cleanedCoords =
        coordinates[0]?.length && Array.isArray(head(coordinates[0]))
          ? coordinates[0]
          : coordinates;

      const lineIds: string[] = [];
      let updatedData: any = new ImmutableLayersData(features);

      for (const idx in cleanedCoords) {
        const index = Number(idx);
        if (index > 0) {
          const newId = generateId();

          const p1 = cleanedCoords[index - 1];
          const p2 = cleanedCoords[index];

          const distance = calculateDistance(p1, p2);
          const arrowSize = Math.max(Math.min(distance / 10, 400), 40);

          const options = props?.modeConfig?.options;

          const feature = {
            type: GEOJSON_TYPES.Feature,
            properties: {
              text: options.text,
              size: options.size.default,
              color: options.color.default,
              border: options.border.default,
              thinkness: options?.thinkness?.default || 0,
              opacity: options.color.default.fillOpacity === 0 ? 0 : 50,
            },
            geometry: {
              type: GEOJSON_TYPES.LineString,
              coordinates: [p1, p2],
            },
          };

          const arrowFeature = makeArrowFromLine(feature, 45, arrowSize);

          updatedData.addMarkupFeature(
            arrowFeature,
            "",
            props.modeConfig.modeId,
            newId
          );
          lineIds.push(newId);
        }
      }

      updatedData = updatedData.getLayers();

      const contextProps = getMarkupFeatureContextProps(
        updatedData.find((f: any) => f?.properties?.id === lineIds[0]),
        props.modeConfig.deck.current.deck
      );
      if (contextProps) {
        props.modeConfig.setSelected(contextProps.selections);
        props.modeConfig.setDefaultMode();
      }

      props.onEdit({
        updatedData: {
          type: GEOJSON_TYPES.FeatureCollection,
          features: updatedData,
        },
        editType: "addLine",
        editContext: {
          featureIds: lineIds,
          contextProps,
        },
      });

      this._resetState();
    }
  };
}
