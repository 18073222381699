import React, { ReactElement } from "react";

import { ModifyMode } from "./edit";
import { CutMode } from "./mutate/cut-tool";
import { DrawLineMode } from "./draw/draw-line";
import { MergeMode } from "./mutate/merge-tool";
import { DrawPointMode } from "./draw/draw-point";
import { DrawSplitMode } from "./draw/draw-split";
import { DrawPolygonMode } from "./draw/draw-polygon";
import { DrawRectangleMode } from "./draw/draw-rectangle";
import { DrawEllipseMarkupMode } from "./draw/draw-ellipse";
import { DrawLineMarkupMode } from "./draw/draw-line-markup";
import { ImageTransformMode } from "./mutate/image-transform";
import { DrawArrowMarkupMode } from "./draw/draw-arrow-markup";
import { DrawDimensionLineMode } from "./draw/draw-dimension-line";
import { DrawMarkupPolygonMode } from "./draw/draw-markup-polygon";
import { DrawMarkupCombinedMode } from "./draw/draw-markup-combined";
import { DrawMarkupFreeHandMode } from "./draw/draw-markup-freehand";
import { DrawMarkupRectangleMode } from "./draw/draw-markup-rectangle";

import {
  MODES_IDS,
  TAKE_OFFS,
  TAKE_OFF_TYPES,
  DEFAULT_MARKUP_COLOR,
  DEFAULT_MARKUP_FONT_SIZE,
  DEFAULT_MARKUP_TEXT_COLOR,
  DEFAULT_MARKUP_TRANSPARENT,
} from "../consts/editor";
import {
  MARKUP_TEXT_STYLES,
  TEXT_ALIGN_OPTIONS,
  TEXT_FONTS_OPTIONS,
} from "../consts/markup";

import { PanMode } from "./pan";
import Icon from "../components/Icon";
import { SearchMode } from "./search_mode";
import { MagicToolMode } from "./mutate/magic-tool";
import { DrawPipelineMode } from "./draw/draw-pipeline";
import { PIPELINE_MODE_TOOLS } from "../consts/pipeline";

export const DEFAULT_MARKUP_TEXT_OPTIONS = {
  type: "text",
  title: "Text",
  default: "",
  fontSize: 14,
  fontWeight: 400,
  isVisible: true,
  fontStyle: MARKUP_TEXT_STYLES[0],
  fontColor: DEFAULT_MARKUP_TEXT_COLOR,
  textAlign: TEXT_ALIGN_OPTIONS[0].value,
  fontFamily: TEXT_FONTS_OPTIONS[0].value,
};

function getMarkupOptions(
  options = {
    sizeDefault: DEFAULT_MARKUP_FONT_SIZE,
    thiknessDefault: 2,
    opacityDefault: 100,

    colorTitle: "Color",
    borderTitle: "Border",
    sizeTitle: "Text Size",
    opacityTitle: "Opacity",
    thinknessTitle: "Thickness",

    isSizeVisible: false,
    isColorVisible: true,
    isTextVisible: false,
    isBorderVisible: true,
    isOpacityVisible: false,
    isThiknessVisible: false,

    colorDefault: DEFAULT_MARKUP_COLOR,
    borderDefault: DEFAULT_MARKUP_COLOR,
  }
) {
  return {
    color: {
      type: "color",
      title: options.colorTitle,
      default: options.colorDefault,
      isVisible: options.isColorVisible,
    },
    border: {
      type: "border",
      title: options.borderTitle,
      default: options.borderDefault,
      isVisible: options.isBorderVisible,
    },
    size: {
      step: 1,
      min: 4,
      max: 40,
      type: "number",
      title: options.sizeTitle,
      default: options.sizeDefault,
      isVisible: options.isSizeVisible,
    },
    thinkness: {
      min: 1,
      step: 1,
      max: 20,
      type: "number",
      title: options.thinknessTitle,
      default: options.thiknessDefault,
      isVisible: options.isThiknessVisible,
    },
    opacity: {
      min: 10,
      step: 10,
      max: 100,
      type: "number",
      title: options.opacityTitle,
      default: options.opacityDefault,
      isVisible: options.isOpacityVisible,
    },
    text: {
      ...DEFAULT_MARKUP_TEXT_OPTIONS,
      isVisible: options.isTextVisible,
    },
  };
}

interface IMode {
  id: string;
  mode?: any;
  config?: any;
  title?: string;
  width?: number;
  isMarkup?: boolean;
  shortcut?: string;
  takeOffs?: string[];
  isTakoffMode?: boolean;
  isComparisonMode?: boolean;
  isSetScaleManualMode?: boolean;
  icon?: () => ReactElement;
  ignoreShortcutOnModes?: any[];
  shouldUserBeEditor?: boolean;
}

export const MODES: Record<string, IMode> = {
  pointer: {
    mode: ModifyMode,
    id: MODES_IDS.pointer,
    isTakoffMode: true,
    isComparisonMode: true,
    shouldUserBeEditor: false,

    config: {},
    width: 100,
    title: "Select",
    shortcut: "Escape",

    icon: () => <Icon id={MODES_IDS.pointer} />,
    takeOffs: TAKE_OFF_TYPES.map((tk) => tk.id),
    ignoreShortcutOnModes: [MODES_IDS.polygon, MODES_IDS.line],
  },

  pan: {
    mode: PanMode,
    id: MODES_IDS.pan,

    isTakoffMode: true,
    isComparisonMode: true,
    isSetScaleManualMode: true,
    shouldUserBeEditor: false,

    width: 60,
    config: {},
    title: "Pan",
    shortcut: "q",

    icon: () => <Icon id={MODES_IDS.pan} />,
    ignoreShortcutOnModes: [] as any[],
    takeOffs: TAKE_OFF_TYPES.map((tk) => tk.id),
  },
  comparison_align: {
    mode: ImageTransformMode,
    id: MODES_IDS.comparison_align,

    isTakoffMode: false,
    isComparisonMode: true,
    shouldUserBeEditor: false,

    config: {},
    width: 100,
    shortcut: "p",
    title: "Align",

    icon: () => <Icon id={MODES_IDS.comparison_align} />,
    ignoreShortcutOnModes: [] as any[],
    takeOffs: TAKE_OFF_TYPES.map((tk) => tk.id),
  },
  polygon: {
    mode: DrawPolygonMode,
    id: MODES_IDS.polygon,

    isTakoffMode: false,
    isComparisonMode: false,
    shouldUserBeEditor: true,

    config: {},
    width: 130,
    shortcut: "p",
    title: "Draw polygon",

    icon: () => <Icon id={MODES_IDS.polygon} />,
    ignoreShortcutOnModes: [] as any[],
    takeOffs: [
      TAKE_OFF_TYPES[TAKE_OFFS.WITHOUT_BOUNDARIES].id,
      TAKE_OFF_TYPES[TAKE_OFFS.WITH_BOUNDARIES].id,
    ],
  },
  rect: {
    id: MODES_IDS.rect,
    mode: DrawRectangleMode,

    isTakoffMode: false,
    isComparisonMode: false,
    shouldUserBeEditor: true,

    config: {},
    width: 150,
    shortcut: "o",
    title: "Draw rectangle",

    icon: () => <Icon id={MODES_IDS.rect} />,
    ignoreShortcutOnModes: [] as any[],
    takeOffs: [
      TAKE_OFF_TYPES[TAKE_OFFS.WITHOUT_BOUNDARIES].id,
      TAKE_OFF_TYPES[TAKE_OFFS.WITH_BOUNDARIES].id,
    ],
  },
  line: {
    id: MODES_IDS.line,
    mode: DrawLineMode,

    isTakoffMode: false,
    isComparisonMode: false,
    shouldUserBeEditor: true,

    config: {
      isMultiLineMode: true,
    },
    width: 100,
    shortcut: "l",
    title: "Draw line",

    icon: () => <Icon id={MODES_IDS.line} />,
    ignoreShortcutOnModes: [] as any[],
    takeOffs: [TAKE_OFF_TYPES[TAKE_OFFS.JUST_BOUNDARIES].id],
  },
  point: {
    id: MODES_IDS.point,
    mode: DrawPointMode,

    isTakoffMode: false,
    isComparisonMode: false,
    shouldUserBeEditor: true,

    config: {},
    width: 100,
    shortcut: "k",
    title: "Draw point",

    icon: () => <Icon id={MODES_IDS.point} />,
    ignoreShortcutOnModes: [] as any[],
    takeOffs: [TAKE_OFF_TYPES[TAKE_OFFS.COUNT].id],
  },
  merge: {
    id: MODES_IDS.merge,
    mode: MergeMode,

    isTakoffMode: true,
    isComparisonMode: false,
    shouldUserBeEditor: true,

    width: 90,
    shortcut: "m",
    title: "Merge",
    config: {
      booleanOperation: "union",
    },

    icon: () => <Icon id={MODES_IDS.merge} />,
    ignoreShortcutOnModes: [] as any[],
    takeOffs: [
      TAKE_OFF_TYPES[TAKE_OFFS.WITH_BOUNDARIES].id,
      TAKE_OFF_TYPES[TAKE_OFFS.WITHOUT_BOUNDARIES].id,
    ],
  },
  hole: {
    id: MODES_IDS.hole,
    mode: CutMode,

    isTakoffMode: true,
    isComparisonMode: false,
    shouldUserBeEditor: true,

    width: 140,
    shortcut: "c",
    title: "Cut / Subtract",
    config: {
      booleanOperation: "difference",
    },

    icon: () => <Icon id={MODES_IDS.hole} />,
    ignoreShortcutOnModes: [] as any[],
    takeOffs: [
      TAKE_OFF_TYPES[TAKE_OFFS.WITH_BOUNDARIES].id,
      TAKE_OFF_TYPES[TAKE_OFFS.WITHOUT_BOUNDARIES].id,
    ],
  },
  split: {
    id: MODES_IDS.split,
    mode: DrawSplitMode,

    isTakoffMode: true,
    isComparisonMode: false,
    shouldUserBeEditor: true,

    width: 70,
    config: {
      isMultiLineMode: true,
    },
    shortcut: "s",
    title: "Split",

    icon: () => <Icon id={MODES_IDS.split} />,
    ignoreShortcutOnModes: [] as any[],
    takeOffs: [
      TAKE_OFF_TYPES[TAKE_OFFS.WITH_BOUNDARIES].id,
      TAKE_OFF_TYPES[TAKE_OFFS.WITHOUT_BOUNDARIES].id,
    ],
  },

  dimensionLine: {
    mode: DrawDimensionLineMode,
    id: MODES_IDS.dimensionLine,

    isTakoffMode: true,
    isComparisonMode: false,
    isSetScaleManualMode: true,
    shouldUserBeEditor: true,

    width: 105,
    config: {
      guideType: "markup",
      isMultiLineMode: true,
      isSingleLineMode: true,
      showDimensionLine: true,

      options: getMarkupOptions({
        sizeDefault: DEFAULT_MARKUP_FONT_SIZE,
        thiknessDefault: 2,
        opacityDefault: 100,

        isTextVisible: false,
        isColorVisible: false,
        isSizeVisible: false,
        isBorderVisible: true,
        isThiknessVisible: true,
        isOpacityVisible: false,

        colorTitle: "Color",
        borderTitle: "Border",
        sizeTitle: "Text Size",
        opacityTitle: "Opacity",
        thinknessTitle: "Thickness",

        colorDefault: DEFAULT_MARKUP_COLOR,
        borderDefault: DEFAULT_MARKUP_COLOR,
      }),
    },
    shortcut: "d",
    title: "Measure",

    icon: () => <Icon id={MODES_IDS.dimensionLine} />,
    ignoreShortcutOnModes: [] as any[],
    takeOffs: TAKE_OFF_TYPES.map((tk) => tk.id),
  },

  pipeline: {
    id: MODES_IDS.pipeline,
    mode: DrawPipelineMode,

    isTakoffMode: false,
    isComparisonMode: false,
    shouldUserBeEditor: true,

    config: {
      isMultiLineMode: true,
      pipelineMode: PIPELINE_MODE_TOOLS[2],
    },
    width: 140,
    shortcut: "p",
    title: "Pipeline editor",

    icon: () => <Icon id={MODES_IDS.line} width={18} color="blue" />,
    ignoreShortcutOnModes: [] as any[],
    takeOffs: [TAKE_OFF_TYPES[TAKE_OFFS.JUST_BOUNDARIES].id],
  },

  magic_tool: {
    mode: MagicToolMode,
    id: MODES_IDS.magic_tool,

    isTakoffMode: false,
    isComparisonMode: false,
    isSetScaleManualMode: true,
    shouldUserBeEditor: true,

    width: 105,
    config: {},
    shortcut: "i",
    title: "Magic tool",

    icon: () => <Icon id={MODES_IDS.magic_tool} />,
    ignoreShortcutOnModes: [] as any[],
    takeOffs: TAKE_OFF_TYPES.map((tk) => tk.id),
  },
  markup_text_box: {
    id: MODES_IDS.markup_text_box,
    mode: DrawMarkupCombinedMode,

    isMarkup: true,
    shouldUserBeEditor: true,

    width: 50,
    shortcut: "",
    title: "Text",
    config: {
      mode: MODES_IDS.markup_text_box,
      isArcEnabled: false,
      options: getMarkupOptions({
        sizeDefault: DEFAULT_MARKUP_FONT_SIZE,
        opacityDefault: 0.5,
        thiknessDefault: 2,

        isSizeVisible: true,
        isTextVisible: true,
        isColorVisible: true,
        isBorderVisible: true,
        isOpacityVisible: false,
        isThiknessVisible: true,

        borderTitle: "Border",
        sizeTitle: "Text Size",
        opacityTitle: "Opacity",
        colorTitle: "Text Color",
        thinknessTitle: "Thickness",

        colorDefault: DEFAULT_MARKUP_COLOR,
        borderDefault: DEFAULT_MARKUP_COLOR,
      }),
    },

    icon: () => <Icon id={MODES_IDS.markup_text_box} />,
    ignoreShortcutOnModes: [] as any[],
    takeOffs: TAKE_OFF_TYPES.map((tk) => tk.id),
  },
  markup_cloud: {
    id: MODES_IDS.markup_cloud,
    mode: DrawMarkupCombinedMode,

    isMarkup: true,
    shouldUserBeEditor: true,

    width: 50,
    shortcut: "",
    title: "Text",
    config: {
      isArcEnabled: false,
      mode: MODES_IDS.markup_cloud,
      options: getMarkupOptions({
        sizeDefault: DEFAULT_MARKUP_FONT_SIZE,
        opacityDefault: 0.5,
        thiknessDefault: 2,

        isSizeVisible: true,
        isTextVisible: true,
        isColorVisible: true,
        isBorderVisible: true,
        isOpacityVisible: false,
        isThiknessVisible: true,

        borderTitle: "Border",
        sizeTitle: "Text Size",
        opacityTitle: "Opacity",
        colorTitle: "Text Color",
        thinknessTitle: "Thickness",

        borderDefault: DEFAULT_MARKUP_COLOR,
        colorDefault: DEFAULT_MARKUP_TRANSPARENT,
      }),
    },

    icon: () => <Icon id={MODES_IDS.markup_cloud} />,
    ignoreShortcutOnModes: [] as any[],
    takeOffs: TAKE_OFF_TYPES.map((tk) => tk.id),
  },
  markup_text: {
    id: MODES_IDS.markup_text,
    mode: DrawMarkupCombinedMode,

    isMarkup: true,
    shouldUserBeEditor: true,

    width: 50,
    shortcut: "",
    title: "Text",
    config: {
      isArcEnabled: false,
      mode: MODES_IDS.markup_text,
      options: getMarkupOptions({
        sizeDefault: DEFAULT_MARKUP_FONT_SIZE,
        opacityDefault: 0.5,
        thiknessDefault: 2,

        isSizeVisible: true,
        isTextVisible: true,
        isColorVisible: true,
        isBorderVisible: true,
        isOpacityVisible: false,
        isThiknessVisible: true,

        borderTitle: "Border",
        sizeTitle: "Text Size",
        opacityTitle: "Opacity",
        colorTitle: "Text Color",
        thinknessTitle: "Thickness",

        colorDefault: DEFAULT_MARKUP_COLOR,
        borderDefault: DEFAULT_MARKUP_COLOR,
      }),
    },

    icon: () => <Icon id={MODES_IDS.markup_text} />,
    ignoreShortcutOnModes: [] as any[],
    takeOffs: TAKE_OFF_TYPES.map((tk) => tk.id),
  },
  markup_box: {
    id: MODES_IDS.markup_box,
    mode: DrawMarkupRectangleMode,

    isMarkup: true,
    shouldUserBeEditor: true,

    width: 80,
    shortcut: "",
    title: "Highlight",
    config: {
      options: getMarkupOptions({
        sizeDefault: DEFAULT_MARKUP_FONT_SIZE,
        opacityDefault: 50,
        thiknessDefault: 2,

        isTextVisible: false,
        isColorVisible: true,
        isSizeVisible: false,
        isBorderVisible: true,
        isOpacityVisible: true,
        isThiknessVisible: true,

        colorTitle: "Color",
        borderTitle: "Border",
        sizeTitle: "Text Size",
        opacityTitle: "Opacity",
        thinknessTitle: "Thickness",

        colorDefault: DEFAULT_MARKUP_COLOR,
        borderDefault: DEFAULT_MARKUP_COLOR,
      }),
    },

    icon: () => <Icon id={MODES_IDS.markup_box} />,
    ignoreShortcutOnModes: [] as any[],
    takeOffs: TAKE_OFF_TYPES.map((tk) => tk.id),
  },
  markup_sep1: {
    id: null as string,
  },
  markup_arrow: {
    mode: DrawArrowMarkupMode,
    id: MODES_IDS.markup_arrow,

    isMarkup: true,
    shouldUserBeEditor: true,

    width: 60,
    title: "Line",
    shortcut: "",
    config: {
      guideType: "markup",
      isMultiLineMode: true,
      isSingleLineMode: true,
      options: getMarkupOptions({
        sizeDefault: DEFAULT_MARKUP_FONT_SIZE,
        thiknessDefault: 2,
        opacityDefault: 100,

        isTextVisible: false,
        isColorVisible: false,
        isSizeVisible: false,
        isBorderVisible: true,
        isThiknessVisible: true,
        isOpacityVisible: false,

        colorTitle: "Color",
        borderTitle: "Border",
        sizeTitle: "Text Size",
        opacityTitle: "Opacity",
        thinknessTitle: "Thickness",

        colorDefault: DEFAULT_MARKUP_COLOR,
        borderDefault: DEFAULT_MARKUP_COLOR,
      }),
    },

    icon: () => <Icon id={MODES_IDS.markup_arrow} />,
    ignoreShortcutOnModes: [] as any[],
    takeOffs: TAKE_OFF_TYPES.map((tk) => tk.id),
  },
  markup_line: {
    mode: DrawLineMarkupMode,
    id: MODES_IDS.markup_line,

    isMarkup: true,
    shouldUserBeEditor: true,

    width: 60,
    title: "Line",
    shortcut: "",
    config: {
      guideType: "markup",
      isMultiLineMode: true,
      isSingleLineMode: true,
      options: getMarkupOptions({
        sizeDefault: DEFAULT_MARKUP_FONT_SIZE,
        thiknessDefault: 2,
        opacityDefault: 100,

        isTextVisible: false,
        isColorVisible: false,
        isSizeVisible: false,
        isBorderVisible: true,
        isThiknessVisible: true,
        isOpacityVisible: false,

        colorTitle: "Color",
        borderTitle: "Border",
        sizeTitle: "Text Size",
        opacityTitle: "Opacity",
        thinknessTitle: "Thickness",

        colorDefault: DEFAULT_MARKUP_COLOR,
        borderDefault: DEFAULT_MARKUP_COLOR,
      }),
    },

    icon: () => <Icon id={MODES_IDS.markup_line} />,
    ignoreShortcutOnModes: [] as any[],
    takeOffs: TAKE_OFF_TYPES.map((tk) => tk.id),
  },
  markup_rounded_box: {
    mode: DrawMarkupRectangleMode,
    id: MODES_IDS.markup_rounded_box,

    isMarkup: true,
    shouldUserBeEditor: true,

    width: 50,
    title: "Box",
    shortcut: "",
    config: {
      options: getMarkupOptions({
        sizeDefault: DEFAULT_MARKUP_FONT_SIZE,
        opacityDefault: 0,
        thiknessDefault: 2,

        isTextVisible: true,
        isColorVisible: true,
        isSizeVisible: false,
        isBorderVisible: false,
        isThiknessVisible: true,
        isOpacityVisible: false,

        colorTitle: "Color",
        borderTitle: "Border",
        sizeTitle: "Text Size",
        opacityTitle: "Opacity",
        thinknessTitle: "Thickness",

        colorDefault: DEFAULT_MARKUP_COLOR,
        borderDefault: DEFAULT_MARKUP_COLOR,
      }),
    },

    icon: () => <Icon id={MODES_IDS.markup_rounded_box} />,
    ignoreShortcutOnModes: [] as any[],
    takeOffs: TAKE_OFF_TYPES.map((tk) => tk.id),
  },
  markup_ellipse: {
    mode: DrawEllipseMarkupMode,
    id: MODES_IDS.markup_ellipse,

    isMarkup: true,
    shouldUserBeEditor: true,

    width: 60,
    shortcut: "",
    title: "Ellipse",
    config: {
      options: getMarkupOptions({
        sizeDefault: DEFAULT_MARKUP_FONT_SIZE,
        opacityDefault: 0,
        thiknessDefault: 2,

        isTextVisible: false,
        isColorVisible: true,
        isSizeVisible: false,
        isBorderVisible: true,
        isThiknessVisible: true,
        isOpacityVisible: false,

        colorTitle: "Color",
        borderTitle: "Border",
        sizeTitle: "Text Size",
        opacityTitle: "Opacity",
        thinknessTitle: "Thickness",

        colorDefault: DEFAULT_MARKUP_COLOR,
        borderDefault: DEFAULT_MARKUP_COLOR,
      }),
    },

    icon: () => <Icon id={MODES_IDS.markup_ellipse} />,
    ignoreShortcutOnModes: [] as any[],
    takeOffs: TAKE_OFF_TYPES.map((tk) => tk.id),
  },
  markup_polygon: {
    mode: DrawMarkupPolygonMode,
    id: MODES_IDS.markup_polygon,

    isMarkup: true,
    shouldUserBeEditor: true,

    width: 70,
    shortcut: "",
    title: "Polygon",
    config: {
      guideType: "markup",
      options: getMarkupOptions({
        sizeDefault: DEFAULT_MARKUP_FONT_SIZE,
        opacityDefault: 0,
        thiknessDefault: 2,

        isTextVisible: false,
        isColorVisible: true,
        isSizeVisible: false,
        isBorderVisible: true,
        isThiknessVisible: true,
        isOpacityVisible: false,

        colorTitle: "Color",
        borderTitle: "Border",
        sizeTitle: "Text Size",
        opacityTitle: "Opacity",
        thinknessTitle: "Thickness",

        colorDefault: DEFAULT_MARKUP_COLOR,
        borderDefault: DEFAULT_MARKUP_COLOR,
      }),
    },

    icon: () => <Icon id={MODES_IDS.markup_polygon} />,
    ignoreShortcutOnModes: [] as any[],
    takeOffs: TAKE_OFF_TYPES.map((tk) => tk.id),
  },
  search: {
    mode: SearchMode,
    id: MODES_IDS.search,
    isTakoffMode: false,
    isComparisonMode: false,
    shouldUserBeEditor: true,

    config: {},
    width: 100,
    title: "Select",
    shortcut: "o",

    icon: () => <Icon id={MODES_IDS.search} />,
    takeOffs: TAKE_OFF_TYPES.map((tk) => tk.id),
    ignoreShortcutOnModes: [] as any,
  },

  markup_freehand: {
    mode: DrawMarkupFreeHandMode,
    id: MODES_IDS.markup_freehand,

    isMarkup: true,
    shouldUserBeEditor: true,

    width: 80,
    shortcut: "",
    title: "Freehand",
    config: {
      options: getMarkupOptions({
        sizeDefault: DEFAULT_MARKUP_FONT_SIZE,
        opacityDefault: 0,
        thiknessDefault: 2,

        isTextVisible: false,
        isColorVisible: false,
        isSizeVisible: false,
        isBorderVisible: true,
        isThiknessVisible: true,
        isOpacityVisible: false,

        colorTitle: "Color",
        borderTitle: "Border",
        sizeTitle: "Text Size",
        opacityTitle: "Opacity",
        thinknessTitle: "Thickness",

        colorDefault: DEFAULT_MARKUP_COLOR,
        borderDefault: DEFAULT_MARKUP_COLOR,
      }),
    },

    icon: () => <Icon id={MODES_IDS.markup_freehand} />,
    ignoreShortcutOnModes: [] as any[],
    takeOffs: TAKE_OFF_TYPES.map((tk) => tk.id),
  },
};
