export interface IClass {
  new (): any;
  name: string;
}

const ServiceFactory = (() => {
  const instances: Record<string, any> = {};

  const createInstance = (ServiceConstructor: any) => {
    return new ServiceConstructor();
  };

  return {
    getInstance: (ServiceConstructor: any) => {
      const serviceName: string = ServiceConstructor.name;

      if (!instances[serviceName]) {
        instances[serviceName] = createInstance(ServiceConstructor);
      }

      return instances[serviceName];
    },
  };
})();

export default ServiceFactory;
