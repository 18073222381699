import { IStyles } from "../../components/Styled";
import React, {
  ChangeEvent,
  CSSProperties,
  FocusEvent,
  KeyboardEvent,
  useCallback,
} from "react";
import * as S from "./styles";
import TooltipWrapper from "../TooltipWrapper";

interface IProps extends IStyles {
  lang?: string;
  step?: string;
  type?: string;
  value?: string;
  border?: string;
  readOnly?: boolean;
  disabled?: boolean;
  maxLength?: number;
  showIcon?: boolean;
  inputMode?:
    | "text"
    | "none"
    | "search"
    | "email"
    | "tel"
    | "url"
    | "numeric"
    | "decimal";
  autoFocus?: boolean;
  placeholder?: string;
  max?: string | number;
  min?: string | number;
  defaultValue?: string;
  style?: CSSProperties;
  padding?: IStyles["padding"];
  textAlign?: IStyles["textAlign"];
  width?: Exclude<CSSProperties["width"], number>;
  height?: Exclude<CSSProperties["height"], number>;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
}

export default function Input({
  max,
  min,
  step,
  lang,
  value,
  onBlur,
  height,
  border,
  onFocus,
  maxLength,
  placeholder,
  defaultValue,
  type = "text",
  onChange = null,
  readOnly = false,
  showIcon = false,
  autoFocus = false,
  textAlign = "left",
  inputMode = "text",
  ...props
}: IProps) {
  const handleNameKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>): void => {
      if (e.key === "Enter") (e.target as HTMLInputElement).blur();
    },
    []
  );

  return (
    <S.InputContainer autoComplete="off">
      <TooltipWrapper position="bottom" indent={15} text={defaultValue}>
        <S.StyledInput
          min={min}
          max={max}
          lang={lang}
          step={step}
          type={type}
          name="hidden"
          value={value}
          onBlur={onBlur}
          border={border}
          height={height}
          onFocus={onFocus}
          autoComplete="off"
          onChange={onChange}
          readOnly={readOnly}
          maxLength={maxLength}
          autoFocus={autoFocus}
          textAlign={textAlign}
          placeholder={placeholder}
          defaultValue={defaultValue}
          inputMode={inputMode}
          onKeyDown={handleNameKeyDown}
          style={{ cursor: readOnly && "not-allowed" }}
          {...props}
        />
      </TooltipWrapper>
      {showIcon && <S.SearchIcon />}
    </S.InputContainer>
  );
}
