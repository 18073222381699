import { curry } from "./function";

export const endsWith = curry((prefix: string, givenString: string): boolean =>
  givenString.endsWith(prefix)
);

export const replace = curry(
  (matcher: any, replaceFn: any, value: string): string =>
    value.replace(matcher, replaceFn)
);
export const replaceAll = curry(
  (matcher: RegExp | string, replacedWith: string, value: string): string => {
    return value.replaceAll(matcher, replacedWith);
  }
);

export const split = curry((separator: string, str: string): string[] =>
  str.split(separator)
);

export const startsWith = curry(
  (prefix: string, givenString: string): boolean =>
    givenString.startsWith(prefix)
);

export const toLower = (str: string): string => str.toLowerCase();

export const toUpper = (str: string): string => str.toUpperCase();

export function trim(str: string): string {
  return str.trim();
}
