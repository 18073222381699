import styled from "styled-components";

import { CSS } from "sf/consts/style";
import { addOpacity } from "sf/utils/style";
import { Flex } from "sf/components/Styled";
import { Button } from "sf/components/Button";

export const StyledButton = styled(Button)`
  position: fixed;
  right: 14px;
  bottom: 13px;
  z-index: ${CSS.Z_INDEX_MAX};
  border: 0 solid ${CSS.TRANSPARENT};
  border-radius: 50%;
  min-width: auto;
  height: 65px;
  width: 65px;
  background-color: ${CSS.GRAY8};
  color: ${CSS.WHITE};
  font-size: 25px;
`;

export const LinkButton = styled(Button)`
  border: 0 solid ${CSS.TRANSPARENT};
  width: 100%;
  text-align: left;
  height: 24px;
  font-size: 12px;
  justify-content: flex-start;

  :hover {
    background-color: ${addOpacity(CSS.WHITE, 10)};
  }
`;

export const LinksContainer = styled(Flex)`
  position: fixed;
  right: 20px;
  bottom: 82px;
  width: auto;
  height: auto;
  background-color: ${CSS.GRAY8};
  border-radius: ${CSS.BORDER_RADIUS};
  padding: 8px 0;
  min-width: 180px;
  z-index: ${CSS.Z_INDEX_MAX};
`;
