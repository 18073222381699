import IAnalyticsService from "./IAnalyticsService";
import AmplitudeService from "./AmplitudeService";
import SegmentService from "./SegmentService";
import NoopAnalyticsService from "./NoopAnalyticsService";

export default class AnalyticsService implements IAnalyticsService {
  private instance: IAnalyticsService;
  private instance2: IAnalyticsService;

  constructor() {
    // @ts-ignore
    if (import.meta.env.VITE_REACT_APP_AMPLITUDE) {
      this.instance = new AmplitudeService();
    } else {
      this.instance = new NoopAnalyticsService();
    }
    // @ts-ignore
    if (import.meta.env.VITE_REACT_APP_SEGMENT) {
      this.instance2 = new SegmentService();
    } else {
      this.instance2 = new NoopAnalyticsService();
    }
  }

  sendEvent(eventType: string, eventProperties?: Record<string, any>) {
    this.instance.sendEvent(eventType, eventProperties);
    return this.instance2.sendEvent(eventType, eventProperties);
  }
}
