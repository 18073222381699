import { fontFamily, proximaNova } from "./fonts";

export default {
  typography: {
    fontFamily,
    body1: {
      fontSize: "17px",
    },
  },
  palette: {
    editor: {
      selectedDot: "#fffaf0",
      selectedDotBorder: "black",
    },
    white: {
      main: "#ffffff",
    },
    primary: {
      main: "#3CAF45",
      light: "#3db842",
      dark: "#1f5c21",
      gradient: "linear-gradient(#0c4b28 0%, #121c17 100%)",
      lightGradient:
        "linear-gradient(to bottom right, #034b23 0%, #086e35 49.05%, #034b23 100%)",
      ultralightGradient:
        "linear-gradient(to right, #3db842 0%, #47a34b 65.02%, #1f5c21 100%)",
      contrastText: "#fff",
    },
    secondary: {
      main: "#3DB842",
      contrastText: "#fff",
    },
    action: {
      hoverOpacity: 0.18,
      focusOpacity: 0.2,
      selectedOpacity: 0.1,
    },
    grey: {
      // Default material greys with a few changes/additions
      50: "#fafafa",
      100: "#F4F5F7",
      200: "#eeeeee",
      300: "#EAEAEA",
      350: "#D8D5D5",
      400: "#bdbdbd",
      500: "#9e9e9e",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
      A100: "#d5d5d5",
      A200: "#aaaaaa",
      A400: "#303030",
      A700: "#616161",
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiInput: {
      disableUnderline: true,
    },
    MuiFilledInput: {
      disableUnderline: true,
    },
  },
  overrides: {
    MuiButton: {
      text: {
        textTransform: "none",
        fontWeight: "bold",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "5px !important",
        margin: "2px",
        backgroundColor: "white",
      },
      input: {
        padding: "16px 12px !important",
        textAlign: "left",
      },
    },
    MuiFilledInput: {
      root: {
        borderRadius: "5px !important",
        margin: "2px",
      },
      input: {
        padding: "16px 12px !important",
        textAlign: "left",
      },
    },
    MuiCssBaseline: {
      "@global": {
        "@font-face": proximaNova,
      },
    },
  },
};
