import { useEffect, useState } from "react";
import { useAuth } from "src/modules/Auth/context";

export default function SmartLook() {
  const { user } = useAuth();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isLoaded) return;
    const script = window.document.createElement("script");

    script.innerHTML = `
window.smartlook || (function(d) {
    var o = smartlook = function() {
            o.api.push(arguments)
        },
        h = d.getElementsByTagName('head')[0];
    var c = d.createElement('script');
    o.api = new Array();
    c.async = true;
    c.type = 'text/javascript';
    c.charset = 'utf-8';
    c.src = 'https://web-sdk.smartlook.com/recorder.js';
    h.appendChild(c);
})(document);
smartlook('init', 'c10db6262f84e25a27489b706ccaa61f5608e495', {
    region: 'eu'
});
    `;

    window.document.head.append(script);

    const newScript = window.document.querySelector(
      'script[src="https://web-sdk.smartlook.com/recorder.js"]'
    );
    newScript.onload = () => {
      if (user) {
        window.smartlook("identify", user.email, user);
      }

      setIsLoaded(true);
    };
  }, [user, isLoaded]);

  useEffect(() => {
    if (!isLoaded) return;

    if (user) {
      window.smartlook("identify", user.email, user);
    }
  }, [user, isLoaded]);

  return null;
}
