import { Feature } from "geojson";

import { TAKE_OFF_TYPES, TAKE_OFFS } from "../consts/editor";
import {
  FEET_TO_METERS,
  INCHES_TO_FEET,
  SQUARE_FEET_TO_METERS,
} from "../consts/geometry";
import {
  UNIT_YARD,
  UNIT_FEET,
  UNIT_METER,
  UNIT_MILLIMETER,
} from "../consts/classifications";

import { getLocale } from "../i18n";
import { prettyNumber } from "./string";
import { imperialToMetric, MEASUREMENT_MODES } from "./unitTransformation";

export const squareFeetToMetricsSq = (val: number): number => {
  return val / SQUARE_FEET_TO_METERS;
};

export const getInternationalizedArea = (
  val: number,
  useMetrics: boolean
): string => {
  return useMetrics
    ? `${prettyNumber(imperialToMetric(MEASUREMENT_MODES.SQUARE, val))} m²`
    : `${prettyNumber(val)} sq.ft`;
};

export const getInternationalizedPerimeter = (
  val: number,
  useMetrics: boolean
): string => {
  return useMetrics
    ? `${prettyNumber(imperialToMetric(MEASUREMENT_MODES.LINEAR, val))} m`
    : `${prettyNumber(val)} ft`;
};

export const feetsToMetrics = (val: number): number => {
  return val / FEET_TO_METERS;
};

export const inchesToMetrics = (val: number): number => {
  return val / INCHES_TO_FEET / FEET_TO_METERS;
};

export const meterToFeets = (val: number): number => {
  return val * FEET_TO_METERS;
};

export const meterToInches = (val: number): number => {
  return val * FEET_TO_METERS * INCHES_TO_FEET;
};

export const inchesToMeters = (val: number): number => {
  return val * 0.0254;
};

export const inchesToMillimeters = (val: number): number => {
  return val * 25.4;
};

export const inchesToYards = (val: number): number => {
  return val / 36;
};

export const inchesToFeet = (val: number): number => {
  return val / 12;
};

export const measurementConversion = (type: string, value: number) => {
  if (value === 0 || !value) {
    return "";
  }
  switch (type) {
    case UNIT_YARD:
      return inchesToYards(value).toFixed(2);
    case UNIT_METER:
      return inchesToMeters(value).toFixed(2);
    case UNIT_MILLIMETER:
      return inchesToMillimeters(value).toFixed(2);
    case UNIT_FEET:
      return inchesToFeet(value).toFixed(2);

    default:
      return value;
  }
};

export function getFeatureUnit(
  feature: Feature,
  useMetrics: boolean = false,
  returnUnit: boolean = true
) {
  let unit = null;

  if (
    feature.properties.types.includes(
      TAKE_OFF_TYPES[TAKE_OFFS.WITHOUT_BOUNDARIES].id
    ) ||
    feature.properties.types.includes(
      TAKE_OFF_TYPES[TAKE_OFFS.WITH_BOUNDARIES].id
    )
  ) {
    unit = useMetrics
      ? squareFeetToMetricsSq(parseFloat(feature.properties.area || 0))
      : feature.properties.area;

    return returnUnit
      ? useMetrics
        ? Intl.NumberFormat(getLocale()).format(
            parseFloat(unit.toFixed(2)) || 0
          ) + " m²"
        : Intl.NumberFormat(getLocale()).format(
            parseFloat(unit.toFixed(2)) || 0
          ) + " sq.ft"
      : unit;
  } else if (
    feature.properties.types.includes(
      TAKE_OFF_TYPES[TAKE_OFFS.JUST_BOUNDARIES].id
    )
  ) {
    unit = useMetrics
      ? feetsToMetrics(parseFloat(feature.properties.perimeter || 0))
      : feature.properties.perimeter;

    return returnUnit
      ? useMetrics
        ? Intl.NumberFormat(getLocale()).format(
            parseFloat(unit.toFixed(2)) || 0
          ) + " m"
        : Intl.NumberFormat(getLocale()).format(
            parseFloat(unit.toFixed(2)) || 0
          ) + " ft"
      : unit;
  }

  return unit;
}

export function getFeaturesUnitSum(
  features: Feature[] = [],
  useMetrics: boolean = false,
  returnUnit: boolean = true
): string {
  if (!features?.length) {
    return "";
  }

  const type = features[0].properties.types.includes(
    TAKE_OFF_TYPES[TAKE_OFFS.JUST_BOUNDARIES].id
  )
    ? 0
    : 1;

  let sum = 0;
  features.forEach((feature) => {
    sum += getFeatureUnit(feature, useMetrics, false);
  });

  return returnUnit
    ? useMetrics
      ? Intl.NumberFormat(getLocale()).format(parseFloat(sum.toFixed(2)) || 0) +
        (type === 1 ? " m²" : " m")
      : Intl.NumberFormat(getLocale()).format(parseFloat(sum.toFixed(2)) || 0) +
        (type === 1 ? " sq.ft" : " ft")
    : `${sum}`;
}
