import React from "react";
import styled from "styled-components";

import { CSS } from "../../consts/style";

interface Props {
  error: Error;
  componentStack: string;
}

export const ErrorBoundary =
  (companyName: string, companyEmail: string) =>
  ({ error, componentStack }: Props) =>
    (
      <ErrorContainer>
        <div>
          <>
            <ErrorHeader>
              We apologize, an error has occured on our side while handling this
              activity.
            </ErrorHeader>
            <ErrorSubHeader>
              Please reload the page, and if you continue to see this error,
              please contact {companyName} team ({companyEmail}).
            </ErrorSubHeader>
          </>
        </div>
        <ErrorBox>
          <p>Details: {error.toString()}</p>
          <pre>{componentStack.split("    ")[1]}</pre>
        </ErrorBox>
      </ErrorContainer>
    );

const ErrorHeader = styled.p``;

const ErrorSubHeader = styled.p``;

const ErrorContainer = styled.div`
  display: flex;
  text-align: center;
  height: calc(100vh);
  flex-direction: column;
  background-color: ${CSS.GREY};
  align-items: center;
  justify-content: center;
`;

const ErrorBox = styled.section`
  background-color: #ffffff;
  padding: 0px 16px;
`;
