import React from "react";
import { CircularProgress } from "@material-ui/core";

import { TABS } from "../const";
import { CSS } from "sf/consts/style";
import { Flex, Separator } from "sf/components/Styled";
import {
  StyledPara,
  MarkAllButton,
  StyledCloseIcon,
  HeaderContainer,
} from "../style";

export default function Header({
  clear,
  isLoading,
  activeTab = 0,
  setActiveTab,
  markAllAsRead,
  shoudlShowMarkAll = false,
  tabs = TABS,
  showCloseIcon = true,
}) {
  return (
    <HeaderContainer>
      <Flex width="auto">
        {tabs.map((tab, index) => (
          <React.Fragment key={tab}>
            <StyledPara
              active={activeTab === index}
              onClick={() => setActiveTab(index)}
              fontColor={activeTab === index ? CSS.BLACK : CSS.GRAY6}
            >
              {tab}
            </StyledPara>
            <Separator width="12px" />
          </React.Fragment>
        ))}

        {isLoading && <CircularProgress size="16px" />}
      </Flex>

      <Flex width="auto">
        {!isLoading && shoudlShowMarkAll && (
          <MarkAllButton onClick={markAllAsRead}>
            Mark all as read
          </MarkAllButton>
        )}
        <Separator width="32px" />

        {showCloseIcon && <StyledCloseIcon onClick={clear} />}
      </Flex>
    </HeaderContainer>
  );
}
