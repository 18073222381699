import { doInfiniteRequest } from "src/constants/request";
import request from "../request";

/* eslint-disable camelcase */

export async function create(data) {
  const { body } = await request.post("organization", {
    body: data,
  });
  return body;
}

export async function get({ id, query }) {
  const { body } = await request.get(`organization/${id}`, { query });
  return body;
}

export async function list(query) {
  const { body } = await request.get("organization", { query });
  return body;
}

export async function listAll(query) {
  return await doInfiniteRequest("organization", query);
}

export async function update({ id, ...rest }) {
  const { body } = await request.put(`organization/${id}`, {
    body: rest,
  });
  return body;
}

export async function remove({ id }) {
  const { body } = await request.delete(`organization/${id}`);
  return body;
}

export async function convertShareeToTrial({ id }) {
  const { body } = await request.put(`organization/${id}/convert`, {});
  return body;
}
