export const CANVAS_PANEL_ID = "canvas_panel";
export const CANVAS_HEADER_ID = "canvas_header";

const PAN_SIZE = 50;

interface IPanState {
  topBorderHeight: number;
  rightBorderWidth: number;
  windowWidth: number;
  windowHeight: number;
}

export function getInitialCanvasState() {
  const panel = document.getElementById(CANVAS_PANEL_ID);
  const header = document.getElementById(CANVAS_HEADER_ID);

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  let topBorderHeight = 0;
  let rightBorderWidth = 0;

  if (header) {
    const headerBox = header.getBoundingClientRect();
    topBorderHeight = Math.round(headerBox.height + headerBox.top);
  }

  if (panel) {
    const panelBox = panel.getBoundingClientRect();
    rightBorderWidth = Math.round(windowWidth - panelBox.left);
  }

  return { topBorderHeight, rightBorderWidth, windowWidth, windowHeight };
}

function mapAndSnap(a: number, b: number, c: number, d: number) {
  return function (x: number) {
    x = Math.max(b, Math.min(x, a));

    const mappedValue = c + ((x - a) * (d - c)) / (b - a);

    return mappedValue;
  };
}

export function getPanningVector(
  e: any,
  panState: IPanState,
  deckContext: any
) {
  const event = e.sourceEvent;

  const viewport = deckContext?.viewManager?._viewports[0];
  const zoom = viewport ? viewport.zoom : 0;

  const smoothedZoom = 7 - zoom;
  const speed = (smoothedZoom > 7 ? 10 : 1) * smoothedZoom;

  const getPanSpeed = mapAndSnap(PAN_SIZE, 0, 0, speed);

  const { clientX, clientY } = event;

  const mPosTop = clientY - panState.topBorderHeight;
  const mPosBottom = panState.windowHeight - clientY;

  const mPosLeft = clientX;
  const mPosRight = panState.windowWidth - clientX - panState.rightBorderWidth;

  const panTop = getPanSpeed(mPosTop);
  const panBottom = getPanSpeed(mPosBottom);

  const panLeft = getPanSpeed(mPosLeft);
  const panRight = getPanSpeed(mPosRight);

  let targetX = viewport.position[0];
  let targetY = viewport.position[1];

  const panX = panRight - panLeft;
  const panY = panBottom - panTop;

  const target = [targetX + panX, targetY + panY, 0];

  const shouldUpdate =
    panTop > 0 || panBottom > 0 || panLeft > 0 || panRight > 0;

  return {
    panX,
    panY,
    zoom,
    target,
    shouldUpdate,
    originaltarget: [targetX, targetY, 0],
  };
}
