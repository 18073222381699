export const curry = (fn: Function, initialArgs: any[] = []): Function => {
  return (...furtherArgs: any[]) =>
    ((rest) => (rest.length >= fn.length ? fn(...rest) : curry(fn, rest)))([
      ...initialArgs,
      ...furtherArgs,
    ]);
};

export function always<T>(arg: T): () => T {
  return () => arg;
}

export const apply = curry(<T, K>(fn: (...args: T[]) => K, args: T[]) => {
  return fn(...args);
});

export function compose(...args: any[]) {
  if (args.length === 0) {
    throw new Error("compose requires at least one argument");
  }
  return pipe.apply(this, args.reverse());
}

export function identity<T>(arg: T): T {
  return arg;
}

export const pipe = (...fns: Function[]) => {
  if (fns.length === 0) throw new Error("pipe requires at least one argument");

  return (...args: any[]) => {
    const list = fns.slice();
    if (list.length > 0) {
      const fn = list.shift();
      let result = fn(...args);
      while (list.length > 0) {
        result = list.shift()(result);
      }

      return result;
    }
  };
};

export const reverseArgs = curry(<T, K>(fn: (...args: T[]) => K, args: T[]) => {
  return fn(...args.reverse());
});

export const tap = curry(<T, K>(fn: (arg: T) => K, value: T) => {
  fn(value);
  return value;
});

export function identityLogger<T>(arg: T, message?: string): T {
  console.log(arg, message ?? "");
  return arg;
}
