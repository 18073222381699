export const ERROR_MESSAGES = {
  1000: "Database Error",
  1001: "The client must authenticate itself to get the requested response.",
  1002: "Operation is forbidden",
  1003: "The request conflicts with the current state of the server.",
  1004: "The server can not find requested resource.",
  1005: "Got Lamda error while togal-convert",
  1006: "Got Lamda error while togal-stich",

  2000: "You may not create a classification set within a drawing set.",
  2001: "You may not set both a classification set and a drawing set at the same time.",
  2002: "Unrecognized phase provided",

  3000: "Invalid data structure for vectorization payload.",
  3001: "You may not request external credentials for the user.",
  3002: "You may not request metadata for the user.",
  3003: "You may not use the same classification set for both the source and destination.",
  3004: "Request could not be fulfilled",
  3005: "Email is not valid or already in use.",
  3006: "The organization is not properly configured for SAML.",
  3007: "You may not invite superadmin users to a set.",
  3008: "You may not specify an admin user as a viewer or editor. They must be a manager.",
  3009: "Users from your company must be added via Settings > Users.",
  3010: "No corresponfing invite found",
  3011: "You may only use this endpoint to accept an invite to a set.",
  3012: "You may only use this endpoint to accept an invite for an existing user.",
  3013: "The scale_drawing and scale_real are required to initiate Togal scaling.",
  3014: "This endpoint may only be accessed by superadmins or admins/users who belong to the requested organization.",
  3015: "You may not remove yourself from your own set.",
  3016: "The drawing is being processed, it cannot be duplicated.",
  3017: "You must provide an organization name to create this user.",
  3018: `Organization with the such name already exists.`,
  3019: "Your organization requires you to login using SSO. Please use the Microsoft login or contact your admin.",
  3020: "The scale_drawing and scale_real are required to initiate Togal scaling.",
  3021: "Invalid file provided for addition.",
  3022: "The name specified for the view does not exist as part of the set permissions.",
  3023: "Only an admin or superadmin may accept or reject a drawing.",
  3024: "You must provide either symbol_id or bounding_box. At least one, not both.",
  3025: "Invalid response for symbol extraction.",
  3026: "The callback URL is required for the async version of this endpoint.",
  3027: "The superadmin user must have the appropriate flag to modify a user.",
  3028: "The admin user must be of the same organization to modify a user.",
  3029: "Session must match the user being modified.",
  3030: "The attempted user type change is not valid.",
  3031: "You may not de-activate your own account.",
  3032: "You may not delete your own account.",
  3033: "The superadmin user must have the appropriate flag to delete a user.",
  3034: "The admin user must be of the same organization to delete a user.",
  3035: "Wrong search_attempt_id provided symbol search result cannot be handled",
  3036: "The number of pages in the set must equal the number of pages provided in the body.",
  3037: "Unable to find a match for a page in the set. The body must include all pages in the set.",
  3038: "ms_token, email, password should be provided.",
  3039: "You can not specify the View-Only user as editor or manager. He can only be specified as Viewer.",
  3040: "Cannot make this operation due to plan restriction",
  3041: "Payment start date is not provided",
  3042: "As a superadmin, you must provide the organization_id to which this object will belong to.",
  3043: "You must supply an organization_id for this request.",
  3044: "You cannot invite more people than paid quantity in your plan",
  3046: "You cannot reduce the number of users below the current number of users and invites.",

  4000: "Unknown error",
  4001: "An unexpected error occurred",
};

// Error codes
export const ERROR_CODES = new Set(
  Object.keys(ERROR_MESSAGES).map((code) => code)
);
