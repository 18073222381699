import styled from "styled-components";
import { CSS } from "sf/consts/style";
import { Flex } from "sf/components/Styled";

interface IStyleProps {
  bgImage?: string;
  overflowY?: string;
  overflowX?: string;
  alignItems?: string;
  justifyContent?: string;
}

export const Container = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${CSS.Z_INDEX_MAX}};
`;

export const CancelContainer = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const AbsContainer = styled(Flex)`
  position: absolute;
  display: flex;

  @media (max-width: 768px) {
    max-width: calc(100% - 20px);
    max-height: calc(100vh - 100px);
    overflow-y: ${({ overflowY }: IStyleProps) => overflowY || "scroll"};
  }
`;

export const ItemsContainer = styled(Flex)`
  justify-content: ${({ justifyContent }: IStyleProps) =>
    justifyContent || "center"};
  border-radius: ${CSS.BORDER_RADIUS};
  box-shadow: ${CSS.BOX_SHADOW};
  overflow: visible;
  ${({ bgImage }: IStyleProps) =>
    bgImage && `background-image: url(${bgImage});`}
`;

export const Row = styled(Flex)`
  width: 100%;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${CSS.GREY};
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  ${({ overflowY }: IStyleProps) => overflowY && `overflow-y: ${overflowY};`};
  ${({ overflowX }: IStyleProps) => overflowX && `overflow-x: ${overflowX};`};
  ${({ alignItems }: IStyleProps) =>
    alignItems && `align-items: ${alignItems};`}
`;
