import {
  getSuperAdminOrganizationId,
  getUserOrganizationId,
  isSuperAdmin,
} from "src/modules/Auth/context";
import request from "../request";

export const withGlobalOrg = (object) => {
  let organizationId = isSuperAdmin && getSuperAdminOrganizationId();

  if (!organizationId) {
    organizationId = getUserOrganizationId();
  }
  if (isSuperAdmin && !object.organization_id) {
    object.organization_id = organizationId;
  }

  return object;
};

export const withGlobalOrgWhere = (object) => {
  let organizationId = isSuperAdmin && getSuperAdminOrganizationId();

  if (!organizationId) {
    organizationId = getUserOrganizationId();
  }
  if (object && !object?.$where?.organization_id) {
    object.$where = object.$where || {};
    object.$where.organization_id = organizationId;
  }
  return object;
};

export const withCurrentOrgQuery = (object) => {
  if (isSuperAdmin()) {
    const currentOrgId = getUserOrganizationId();
    object.current_organization_id = currentOrgId;
  }
  return object;
};

export const withCurrentOrgUrl = (url) => {
  if (isSuperAdmin()) {
    const currentOrgId = getUserOrganizationId();
    url = `${url}${
      url.includes("?") ? "&" : "?"
    }current_organization_id=${currentOrgId}`;
  }
  return url;
};

export const splitLargeWhere = async (method, object, options) => {
  const limit = 25;
  const { $where } = options.query;
  const index = Object.keys($where).findIndex(
    (k) => Array.isArray($where[k]) && $where[k].length > limit
  );

  const queries = [];
  const promises = [];

  if (index === -1) {
    queries.push(options);
    promises.push(request[method](object, options));
  } else {
    const key = Object.keys($where)[index];
    const value = $where[key];

    for (let i = 0; i < value.length; i += limit) {
      const query = {
        ...options,
        query: {
          ...options.query,
          $where: {
            ...$where,
            [key]: value.slice(i, i + limit),
          },
        },
      };

      queries.push(query);
      promises.push(request[method](object, query));
    }
  }

  const results = await Promise.allSettled(promises);
  const queryResults = results.map((result, i) => ({
    isError: result.status !== "fulfilled",
    query: queries[i],
    result,
  }));

  const errorsCount = queryResults.filter((r) => r.isError).length;

  return {
    results: queryResults,
    isError: errorsCount > 0,
    isAllError: errorsCount === queries.length,
    errorMessage:
      errorsCount > 0
        ? queryResults.find((r) => r?.isError)?.result?.reason ||
          `Failed to ${method} ${queries.length} ${object} queries`
        : null,
  };
};
