import request from "../request";

export async function procoreAuth(code) {
  const { body } = await request.post("external/procore/auth", {
    body: { code },
  });
  return body;
}

export async function procoreGetFiles(company, project, set) {
  const { body } = await request.get(
    `external/procore/company/${company}/project/${project}/set/${set}/file`
  );
  return body;
}

export async function procoreCreateFile(
  company,
  project,
  set,
  project_id,
  drawing
) {
  const { body } = await request.post(
    `external/procore/company/${company}/project/${project}/set/${set}/file`,
    {
      body: { project_id, drawing },
    }
  );
  return body;
}

export async function procoreGetSets(company, project) {
  const { body } = await request.get(
    `external/procore/company/${company}/project/${project}/set`
  );
  return body.map((p) => {
    p.selected = false;
    return p;
  });
}

export async function procoreCreateSet(company, project, set, project_id) {
  const { body } = await request.post(
    `external/procore/company/${company}/project/${project}/set/${set}`,
    {
      body: { project_id },
    }
  );
  return body;
}

export async function procoreGetProjects(company) {
  const { body } = await request.get(
    `external/procore/company/${company}/project`
  );
  return body.map((p) => {
    p.selected = false;
    return p;
  });
}

export async function procoreCreateProject(company, project, project_id) {
  const { body } = await request.post(
    `external/procore/company/${company}/project/${project}`,
    {
      body: { project_id },
    }
  );
  return body;
}

export async function procoreGetCompanies() {
  const { body } = await request.get("external/procore/company");
  return body.map((c) => {
    c.selected = false;
    return c;
  });
}

export async function metabaseEmbed(dashboard) {
  const { body } = await request.post("external/metabase/embed", {
    body: { dashboard },
  });
  return body;
}

export async function chat(body) {
  const res = await request.post("external/openai/prompt", {
    body,
  });
  return res.body;
}

export async function ratePrompt(id, body) {
  const res = await request.put(`prompt/${id}`, {
    body,
  });
  return res.body;
}

export async function createSubscription(data) {
  const res = await request.post("external/stripe/subscription", {
    body: data,
  });

  return res.body;
}

export async function getSubscription(organizationID) {
  const { body } = await request.get(
    "external/stripe/subscription/?organization_id=" + organizationID
  );
  return body;
}

export async function updateSubscription(data) {
  const res = await request.put("external/stripe/subscription", {
    body: data,
  });

  return res.body;
}

export async function getPlan(organizationID) {
  const { body } = await request.get(
    "external/stripe/plan/?organization_id=" + organizationID
  );
  return body;
}

export async function getPaymentMethod(organizationID) {
  const { body } = await request.get(
    "external/stripe/payment/?organization_id=" + organizationID
  );

  return body;
}

export async function attachPaymentMethod(id) {
  const { body } = await request.post("external/stripe/payment", {
    body: {
      payment_method_id: id,
    },
  });

  return body;
}

export async function deletePaymentMethod(id) {
  const { body } = await request.post("external/stripe/payment", {
    body: {
      payment_method_id: id,
    },
  });

  return body;
}

export async function getInvoices(query, organizationID) {
  const { body } = await request.get("external/stripe/invoice", {
    query,
  });
  return body;
}

export async function getPromo(promoCode) {
  const { body } = await request.get(
    "/external/stripe/promocode?promocode=" + promoCode
  );
  return body;
}

export async function createSetupIntent(data) {
  const { body } = await request.post("/external/stripe/setup_intent", {
    body: data,
  });
  return body;
}
