import React from "react";

import useShortcuts from "../hook";
import { SHORTCUTS_LIST } from "../list";

import {
  Title,
  Wrapper,
  ActionsContainer,
  ShortcutsListItem,
  ShortcutsListContainer,
} from "../styles";

function ShortcutsList() {
  const {
    shortcutsState: { pressedBtn },
  } = useShortcuts();

  return (
    <Wrapper>
      <ShortcutsListContainer>
        {SHORTCUTS_LIST.map((item) => {
          const componentBtnFirst = item.keyFirst ? item.keyFirst() : <></>;
          const componentBtnSecond = item.keySecond ? item.keySecond() : <></>;

          return (
            <ShortcutsListItem
              isTitle={item.title}
              key={item.id}
              isPressed={item.keyBtn?.some((elem) => elem === pressedBtn)}
            >
              <Title isTitle={item.title} isSubTitle={item.subTitle}>
                {item?.shortcutTitle}
              </Title>
              <ActionsContainer>
                <div style={{ marginRight: "3px" }}>{componentBtnFirst}</div>
                <div style={{ opacity: "0.5" }}>{item.divider}</div>
                <div style={{ marginLeft: "3px" }}>{componentBtnSecond}</div>
              </ActionsContainer>
            </ShortcutsListItem>
          );
        })}
      </ShortcutsListContainer>
    </Wrapper>
  );
}

export default ShortcutsList;
