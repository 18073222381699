import { COMMON_MESSAGES } from "./common";

export const LAYOUT_MESSAGES = {
  ...COMMON_MESSAGES,
  METABASE1: "Metabase 1",
  METABASE2: "Metabase 2",

  PREPARING_PAGES: (nbPages: number = 0, totalPages: number = 0) =>
    "preparing pages: " + nbPages + " of " + totalPages,
  FAILED_EXPORT: (name: string = "") => `PDF ${name} failed.`,

  EDIT_ORG_AVATAR: "Edit Avatar",
  READY_FOR_DOWNLOAD: "ready for download.",
  ORG_ERROR: "Please enter your organization.",
  PHONE_ERROR: "Please enter your phone number.",
  TAKEOFF_ERROR: "Please choose your takeoff type.",
  CONTRACTOR_ERROR: "Please choose your contractor type.",
  PHONE_VALIDATION_ERROR: "Please enter a valid phone number.",
  DISCOVERY_METHOD_ERROR: "Please choose your discovery method type.",
  ORG_NAME_ERROR: "Organization name should not be equal to your email.",

  READY_TO_START_TRIAL: "Are you ready to start your free trial?",
  USE_ORG_FOR_7_DAYS:
    "Try out Togal.AI with your own projects in your own account for 7 days.",
  START_FREE_TRIAL: "Yes, I want to start my free trial",
  IGNORE_TRIAL: "No, I’ll come back later",

  CONTRACTOR_TYPE: "What kind of contractor are you?",
  DISCOVERY_METHOD: "How did you hear about Togal AI?",
  CONTACT_SALES: "Please contact our sales department at",
  CONTACT_SALES2:
    "to receive a customized solution that fits your specific needs.",
  CUSTOMIZE_EXPERIENCE: "Let’s customize your experience at Togal.AI",
  TAKEOFF_APPLICATION: "What Takeoff application are you using now?",

  PHONE_PLACEHOLDER: "Enter your phone number",
  ORG_PLACEHOLDER: "Enter the name of your company or organization",

  TEAM_SIZE: "Choose team size",
  CHOOSE_PLAN: "Choose your Togal.AI plan",
  START_FREE_TRIAL_BTN: "Start Free Trial",
  PAYMENT_FAILED: "Payment failed. Try again.",
  FREE_TRIAL_EXPIRED: "Free trial expired. Choose a plan.",

  DAYS_LEFT: (nbDays: number = 0) =>
    nbDays <= 1 ? 1 + " day" : nbDays + " days",
  CHOOSE_A_PLAN: (nbDays: number = 0) =>
    `${nbDays} left in trial. Choose a plan.`,
};
