// code stolen from https://github.com/mourner/kdbush
// adapted to work with geojson instead of 2d points and changing some function to better fit our use case

const DEFAULT_NODE_SIZE = 32; // higher = faster indexing and slower search/ vice versa
const DEFAULT_LIMIT = 20; // higher = faster indexing and slower search/ vice versa

function sqDist(ax: number, ay: number, bx: number, by: number) {
  const dx = ax - bx;
  const dy = ay - by;
  return dx * dx + dy * dy;
}

function within(
  ids: any,
  coords: any[],
  qx: number,
  qy: number,
  r: number,
  nodeSize: number
) {
  const stack = [0, ids.length - 1, 0];
  const result = [];
  const r2 = r * r;

  // recursively search for items within radius in the kd-sorted arrays
  while (stack.length) {
    const axis = stack.pop();
    const right = stack.pop();
    const left = stack.pop();

    // if we reached "tree node", search linearly
    if (right - left <= nodeSize) {
      for (let i = left; i <= right; i++) {
        if (sqDist(coords[2 * i], coords[2 * i + 1], qx, qy) <= r2)
          result.push(ids[i]);
      }
      continue;
    }

    // otherwise find the middle index
    const m = (left + right) >> 1;

    // include the middle item if it's in range
    const x = coords[2 * m];
    const y = coords[2 * m + 1];
    if (sqDist(x, y, qx, qy) <= r2) result.push(ids[m]);

    // queue search in halves that intersect the query
    if (axis === 0 ? qx - r <= x : qy - r <= y) {
      stack.push(left);
      stack.push(m - 1);
      stack.push(1 - axis);
    }
    if (axis === 0 ? qx + r >= x : qy + r >= y) {
      stack.push(m + 1);
      stack.push(right);
      stack.push(1 - axis);
    }
  }

  return result;
}

function sortKD(
  ids: any,
  coords: any,
  nodeSize: number,
  left: number,
  right: number,
  axis: number
) {
  if (right - left <= nodeSize) return;

  const m = (left + right) >> 1; // middle index

  // sort ids and coords around the middle index so that the halves lie
  // either left/right or top/bottom correspondingly (taking turns)
  select(ids, coords, m, left, right, axis);

  // recursively kd-sort first half and second half on the opposite axis
  sortKD(ids, coords, nodeSize, left, m - 1, 1 - axis);
  sortKD(ids, coords, nodeSize, m + 1, right, 1 - axis);
}

// custom Floyd-Rivest selection algorithm: sort ids and coords so that
// [left..k-1] items are smaller than k-th item (on either x or y axis)
function select(
  ids: any,
  coords: any,
  k: number,
  left: number,
  right: number,
  axis: number
) {
  while (right > left) {
    if (right - left > 600) {
      const n = right - left + 1;
      const m = k - left + 1;
      const z = Math.log(n);
      const s = 0.5 * Math.exp((2 * z) / 3);
      const sd =
        0.5 * Math.sqrt((z * s * (n - s)) / n) * (m - n / 2 < 0 ? -1 : 1);
      const newLeft = Math.max(left, Math.floor(k - (m * s) / n + sd));
      const newRight = Math.min(right, Math.floor(k + ((n - m) * s) / n + sd));
      select(ids, coords, k, newLeft, newRight, axis);
    }

    const t = coords[2 * k + axis];
    let i = left;
    let j = right;

    swapItem(ids, coords, left, k);
    if (coords[2 * right + axis] > t) swapItem(ids, coords, left, right);

    while (i < j) {
      swapItem(ids, coords, i, j);
      i++;
      j--;
      while (coords[2 * i + axis] < t) i++;
      while (coords[2 * j + axis] > t) j--;
    }

    if (coords[2 * left + axis] === t) swapItem(ids, coords, left, j);
    else {
      j++;
      swapItem(ids, coords, j, right);
    }

    if (j <= k) left = j + 1;
    if (k <= j) right = j - 1;
  }
}

function swapItem(ids: any, coords: any, i: number, j: number) {
  swap(ids, i, j);
  swap(coords, 2 * i, 2 * j);
  swap(coords, 2 * i + 1, 2 * j + 1);
}

function swap(arr: any[], i: number, j: number) {
  const tmp = arr[i];
  arr[i] = arr[j];
  arr[j] = tmp;
}

export default class KDBush {
  private ids: any;
  private coords: any;
  private features: any;
  private nodeSize: number;

  constructor(
    features: any[],
    nodeSize = DEFAULT_NODE_SIZE,
    ArrayType = Float64Array
  ) {
    this.nodeSize = nodeSize;
    this.features = features;

    const IndexArrayType = features.length < 65536 ? Uint16Array : Uint32Array;

    // store indices to the input array and coordinates in separate typed arrays
    const ids = (this.ids = new IndexArrayType(features.length));
    const coords = (this.coords = new ArrayType(features.length * 2));

    for (let i = 0; i < features.length; i++) {
      ids[i] = i;
      coords[2 * i] = features[i].geometry.coordinates[0];
      coords[2 * i + 1] = features[i].geometry.coordinates[1];
    }

    // kd-sort both arrays for efficient search
    sortKD(ids, coords, nodeSize, 0, ids.length - 1, 0);
  }

  within(x: number, y: number, r: number, limit = DEFAULT_LIMIT) {
    const results = within(this.ids, this.coords, x, y, r, this.nodeSize);

    return results
      .map((i) => {
        const dist = Math.hypot(
          this.features[i].geometry.coordinates[0] - x,
          this.features[i].geometry.coordinates[1] - y
        );

        return {
          ...this.features[i],
          properties: {
            ...this.features[i].properties,
            dist,
          },
        };
      })
      .sort((a: any, b: any): number =>
        a.properties.dist > b.properties.dist
          ? 1
          : a.properties.dist < b.properties.dist
          ? -1
          : 0
      )
      .slice(0, limit);
  }
}
