import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";

import { PERMISSION_STATES } from "src/constants/permissions";

function PermissionChecker({ name }) {
  const [currentState, setCurrentState] = useState(null);

  useEffect(() => {
    if (window?.navigator?.permissions) {
      window.navigator.permissions.query({ name }).then((permission) => {
        setCurrentState(permission.state);

        permission.onchange = ({ target }) => setCurrentState(target.state);
      });
    } else {
      try {
        window.navigator.clipboard.read();
        setCurrentState(PERMISSION_STATES.GRANTED);
      } catch (e) {
        setCurrentState(PERMISSION_STATES.DENIED);
      }
    }
  }, []);

  return currentState === PERMISSION_STATES.GRANTED ? null : (
    <PermissionSelector id={`tgl-permission-missing-${name}`} />
  );
}

export default memo(PermissionChecker);

const PermissionSelector = styled.div`
  width: 0;
  height: 0;
  visibility: hidden;
  position: fixed;
  right: 30px;
  top: 0;
`;
