export const LINKS = [
  {
    isShortcut: true,
    text: "Keyboard shortcuts",
  },
  {
    url: "https://help.togal.ai/",
    text: "Togal Help Center",
  },
  // {
  //   url: "https://www.youtube.com/@togal_ai/videos",
  //   text: "Togal Youtube Videos",
  // },
  {
    openHubspot: true,
    text: "Chat with Support",
  },
];
