import getLocaleMessages from "sf/i18n";
import {
  STATUS_PENDING,
  USAGE_TOGAL_KEY,
  USAGE_SHAREE_KEY,
  USAGE_TOGAL_GPT_KEY,
  USAGE_SHARE_EVENT_KEY,
  USAGE_IMAGE_SEARCH_KEY,
} from "../Settings/const";
import { VIEWER_ROLE } from "sf/permissions";

import { ORG_TYPE } from "./const";

export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export function isValidEmail(value) {
  return value && value.match(EMAIL_REGEX);
}

export const DEFAULT_USAGE = {
  [USAGE_TOGAL_KEY]: { max: 5, current: 0 },
  [USAGE_SHAREE_KEY]: { max: 5, current: 0 },
  [USAGE_TOGAL_GPT_KEY]: { max: 5, current: 0 },
  [USAGE_SHARE_EVENT_KEY]: { max: 5, current: 0 },
  [USAGE_IMAGE_SEARCH_KEY]: { max: 5, current: 0 },
};

/**
 *
 *
 * STATIC UTILITIES
 *
 *
 */

export const getSessionId = () => window.authContext?.sessionId;
export const getUser = () => window.authContext?.user;
export const getUserId = () => window.authContext?.user?.id;
export const getUserOrg = () =>
  window.authContext?.selectedOrg || window.authContext?.user?.org;
export const getUserRole = () => window.authContext?.user?.type;
export const getUserOrganizationId = () =>
  window.authContext?.selectedOrg?.id || window.authContext?.user?.org?.id;

export const getUserUsage = () => window.authContext?.userUsage;

export const getUserPermissions = () => window.authContext?.permissions;
export function getUserLocale() {
  return window.authContext?.locales || getLocaleMessages();
}

export const isSuperAdmin = () => Boolean(window.authContext?.isSuperAdmin);
export const isViewer = () => window.authContext?.user?.type === VIEWER_ROLE;
export const isEssentials = () =>
  Boolean(window.authContext?.user?.isOnEssentialsPlan);

export const isSelfService = () =>
  window.authContext?.selectedOrg?.type === ORG_TYPE.SELF_SERVICE ||
  window.authContext?.user?.org?.type === ORG_TYPE.SELF_SERVICE;

export const isDirect = () =>
  window.authContext?.selectedOrg?.type === ORG_TYPE.DIRECT ||
  window.authContext?.user?.org?.type === ORG_TYPE.DIRECT;

// Super admin has the ability to do things for *any* organization. This var is used to
// have the superadmin act as a member of an org.
export const getSuperAdminOrganizationId = () =>
  window.authContext?.superAdminOrganizationId;

/**
 *
 *
 * FETCH UTILITIES
 *
 *
 */
export const fetchUser = async (id) => {
  const userApi = await import("src/lib/api/user");
  const user = await userApi.get({ id });
  return { ...user };
};

export const fetchUserUsage = async () => {
  try {
    const userApi = await import("src/lib/api/user");

    const usageRes = await userApi.getUsage();

    return {
      [USAGE_TOGAL_KEY]:
        USAGE_TOGAL_KEY in usageRes
          ? {
              max: Number(usageRes[USAGE_TOGAL_KEY]?.max),
              current: Number(usageRes[USAGE_TOGAL_KEY]?.current),
            }
          : DEFAULT_USAGE[USAGE_TOGAL_KEY],
      [USAGE_SHAREE_KEY]:
        USAGE_SHAREE_KEY in usageRes
          ? {
              max: Number(usageRes[USAGE_SHAREE_KEY]?.max),
              current: Number(usageRes[USAGE_SHAREE_KEY]?.current),
            }
          : DEFAULT_USAGE[USAGE_SHAREE_KEY],
      [USAGE_TOGAL_GPT_KEY]:
        USAGE_TOGAL_GPT_KEY in usageRes
          ? {
              max: Number(usageRes[USAGE_TOGAL_GPT_KEY]?.max),
              current: Number(usageRes[USAGE_TOGAL_GPT_KEY]?.current),
            }
          : DEFAULT_USAGE[USAGE_TOGAL_GPT_KEY],
      [USAGE_SHARE_EVENT_KEY]:
        USAGE_SHARE_EVENT_KEY in usageRes
          ? {
              max: Number(usageRes[USAGE_SHARE_EVENT_KEY]?.max),
              current: Number(usageRes[USAGE_SHARE_EVENT_KEY]?.current),
            }
          : DEFAULT_USAGE[USAGE_SHARE_EVENT_KEY],
      [USAGE_IMAGE_SEARCH_KEY]:
        USAGE_IMAGE_SEARCH_KEY in usageRes
          ? {
              max: Number(usageRes[USAGE_IMAGE_SEARCH_KEY]?.max),
              current: Number(usageRes[USAGE_IMAGE_SEARCH_KEY]?.current),
            }
          : DEFAULT_USAGE[USAGE_IMAGE_SEARCH_KEY],
    };
  } catch (e) {
    return DEFAULT_USAGE;
  }
};

export const updateUser = async (updatedUser) => {
  const userApi = await import("src/lib/api/user");
  const newUser = await userApi.update(updatedUser);

  return newUser;
};

export const fetchOrgInvites = async (id) => {
  const inviteApi = await import("src/lib/api/invite");

  const orgInvites = await inviteApi.listAll({
    $offset: 0,
    $limit: 100,
    $where: {
      organization_id: id,
    },
  });

  return (
    orgInvites.rows.map((u) => ({
      ...u,
      name: "______",
      status: STATUS_PENDING,
    })) || []
  );
};

export const fetchOrgUsers = async (id) => {
  const userApi = await import("src/lib/api/user");

  const orgUsers = await userApi.listAll({
    $offset: 0,
    $limit: 100,
    $attributes: ["verified", "projects_count"],
    $where: {
      organization_id: id,
    },
  });

  return orgUsers.rows || [];
};

export const fetchOrgExternalUsers = async (id) => {
  try {
    const userApi = await import("src/lib/api/user");

    const orgUsers = await userApi.listAll({
      $offset: 0,
      $limit: 100,
      $attributes: ["verified", "projects_count"],
      $where: {
        organization_ids: {
          $contains: [id],
        },
      },
    });

    return orgUsers.rows || [];
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const fetchOrgUsersAndInvites = async (id) => {
  try {
    const orgUsers = await fetchOrgUsers(id);
    const orgInvites = await fetchOrgInvites(id);

    return [...(orgUsers || []), ...(orgInvites || [])];
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const fetchUserOrg = async (id) => {
  const organizationApi = await import("src/lib/api/organization");

  const organization = await organizationApi.get({ id });
  const orgUsersAndInvites = await fetchOrgUsersAndInvites(id);
  const externalUsers = await fetchOrgExternalUsers(id);

  return {
    ...organization,
    users: orgUsersAndInvites || [],
    externalUsers: externalUsers || [],
  };
};

export const fetchUserInvites = async (user) => {
  const userApi = await import("src/lib/api/user");
  const invitesRes = await userApi.getUserInvite(user.email);

  const invites = [
    ...new Set((invitesRes?.rows || []).map((i) => i?.organization_id)),
  ].map((id) => invitesRes.rows.find((i) => i?.organization_id === id));

  return invites;
};
