import { ReactNode } from "react";
import { Feature, Geometry } from "geojson";
import { OrthographicView, OrbitView } from "@deck.gl/core";

import {
  IMode,
  IViewState,
  IEditorSettings,
  IClassificationMap,
} from "../../types/editor";

import { IFilter } from "../../consts/editor";

export interface IControllerProps {
  deckChildren?: ReactNode;
  children?: ReactNode | any;
  colorMap: IClassificationMap | null;
  customEvents: Event[];
  customEventHandler: (e: any) => void;
  deckGlId?: string;
  is3dMode?: boolean;
  deleteSearchFeature: any;
  handleSetActiveSearchFeature: any;
  editorSettings: IEditorSettings;
  filteredGeoJson: Feature<Geometry>[];
  geoJson: Feature<Geometry>[] | null;
  geoJsonProps: Record<string, any>;
  getMouseCoords: (info: any) => void;
  getZoom: (initial: number) => number;
  groupFilter: IFilter;
  activeClassification?: any;
  groupedLayers: Record<string, string>;
  hidden: string[];
  imagesData: any[];
  initialViewState: IViewState;
  isCenter: boolean;
  meshData?: any;
  useCanvasPan?: boolean;
  isViewOnlyMode?: boolean;
  showLegendWithOverlay?: boolean;
  isReady: boolean;
  isRefresh: boolean;
  highlited: Set<string>;
  legend: any;
  mode?: IMode;
  isFocused?: boolean;
  shouldShowAutoPan: boolean;
  shouldShowZoom: boolean;
  frameFeature?: Feature<Geometry>;
  setIs3dMode?: (is3dMode: boolean) => void;
  setFrameFeature?: (feature: Feature<Geometry> | null) => void;
  isComparisonMode: boolean;
  viewID?: string | null;
  filteredClassifications?: any;
  handleSaveLegend?: (legend: any) => void;
  referenceInchesPerPixels?: number | null;
  saveFeatures: (features: Feature[], editContext?: any) => void;
  selected: string[];
  setIsCenter?: (isCenter: boolean) => void;
  setIsFocus?: (isFocus: boolean) => void;
  setDefaultMode: () => void;
  setSelected: (selected: string[]) => void;
  showCrosshair: boolean;
  useSelectionBox?: boolean;
  usePatterns?: boolean;
  featureAccessKey?: string;
  shouldGreyOutUnusedFeatures?: boolean;
  setActiveClassification?: any;
  shouldUseLegacyMarkupText?: boolean;
  additionalCanvasElements?: ReactNode[];
}

export interface IGeoJsonState {
  viewID: string;
  hidden: string[];
  selected: string[];
  staticData: Feature[];
  hiddenData: Feature[];
  selectedData: Feature[];
  filteredOffLayers: any[];
  wallsData: Feature[];
  dimentionLines: Feature[];
  wallsGroupsData: Feature[];
  isPanning?: boolean;
  markupTextItems?: Feature[];
}

export const INITIAL_STATE: IGeoJsonState = {
  viewID: "",
  hidden: [],
  selected: [],
  staticData: [],
  hiddenData: [],
  selectedData: [],
  filteredOffLayers: [],
  wallsData: [],
  dimentionLines: [],
  wallsGroupsData: [],
  markupTextItems: [],
};

export const BASELINE_PATTERN_SCALE = 30000;
export const POINT_RADIUS_SCALE = 4;
export const WIDTH_SCALE = 20;
export const POINT_SCALE_PADDING_CONSTANT = 1.385;
export const PATTERNS = ["dots", "hatch-1x", "hatch-2x", "hatch-cross"];
export const PATTERNS_DISPLAY = [
  "none",
  "dots",
  "hatch-1x",
  "hatch-2x",
  "hatch-cross",
];

export const DECK_PATTERNS = {
  "hatch-1x": {
    x: 4,
    y: 4,
    width: 120,
    height: 120,
    mask: true,
    placement: "0 0 ",
    label: "Light Hatch",
  },
  "hatch-2x": {
    x: 132,
    y: 4,
    width: 120,
    height: 120,
    mask: true,
    placement: "-20px 0",
    label: "Dense Hatch",
  },
  "hatch-cross": {
    x: 4,
    y: 132,
    width: 120,
    height: 120,
    mask: true,
    placement: "0 -20px",
    label: "Cross Hatch",
  },
  dots: {
    x: 132,
    y: 132,
    width: 120,
    height: 120,
    mask: true,
    placement: "-20px -20px",
    label: "Dots",
  },
  empty: {
    x: 192,
    y: 232,
    width: 10,
    height: 10,
    mask: true,
    placement: "-20px -20px",
  },
};

export const EMPTY_PATTERN = "empty";
export const DECK_PATTERNS_JSON = JSON.stringify(DECK_PATTERNS);

//LEGEND CONSTS
export const PADDING = 40;
export const ITEM_HEIGHT = 43;
export const CONTAINER_WIDTH = 520;
export const DEFAULT_LEGEND_SCALE = 4;

export const LEGEND_DRAG_TYPE = 0;
export const LEGEND_RESIZE_ALL_TYPE = 1;
export const LEGEND_RESIZE_WIDTH_TYPE = 2;

export const DEFAULT_LEGEND_DRAG_STATE = {
  isDragging: false,
  type: LEGEND_DRAG_TYPE,
  width: CONTAINER_WIDTH,
  scale: DEFAULT_LEGEND_SCALE,

  origin: { x: 0, y: 0 },
  corner: { x: 0, y: 0 },
  translation: { x: 0, y: 0 },
};

export const DEFAULT_VIEWS = new OrthographicView({
  id: "2d-scene",
  flipY: true,
  controller: {
    inertia: false,
    keyboard: false,
    doubleClickZoom: false,
    dragPan: true,
    dragRotate: false,
    scrollZoom: {
      smooth: false,
      speed: 0.003,
    },
  },
});

export const NO_PAN_VIEWS = new OrthographicView({
  id: "2d-scene",
  flipY: true,
  controller: {
    keyboard: false,
    inertia: false,
    doubleClickZoom: false,
    dragPan: false,
    dragRotate: false,
    scrollZoom: {
      smooth: false,
      speed: 0.003,
    },
  },
});

export const ORBIT_VIEW = new (OrbitView as any)({
  id: "3d-scene",
  orbitAxis: "Z",
  fovy: 70,
  near: 0.001,
  controller: true,
  orthographic: false,
});

export const ORTHOGRAPHIC_ORBIT_VIEW = new (OrbitView as any)({
  id: "3d-scene",
  orbitAxis: "Z",
  fovy: 50,
  near: 0,
  controller: true,
  orthographic: true,
});
