import request from "../request";
import { withGlobalOrg, withGlobalOrgWhere } from "./_helpers";

export async function listBreakdowns(query) {
  const { body } = await request.get("breakdown", {
    query: withGlobalOrgWhere(query),
  });
  return body;
}

export async function addBreakdown(props) {
  const { body } = await request.post("breakdown", {
    body: withGlobalOrg(props),
  });
  return body;
}

export async function getBreakdownById(id) {
  const { body } = await request.get(`breakdown/${id}`);
  return body;
}

export async function updateBreakdownById({ id, ...rest }) {
  const { body } = await request.put(`breakdown/${id}`, {
    body: withGlobalOrg(rest),
  });
  return body;
}

export async function removeBreakdownById(id) {
  const { success } = await request.delete(`breakdown/${id}`);
  return success;
}
