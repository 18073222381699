import { useSetRecoilState } from "recoil";

import { cleanPageData } from "../dataUtils";
import { useDataHook } from "./commonDataHook";
import { pageKey, PAGES_ATTRIBUTES } from "../const";
import {
  STORE_RESET,
  pagesCRUDStore,
  pagesErrorStore,
  pagesLoadingStore,
} from "src/state/dataStore";

export function usePagesData(pendingData: React.MutableRefObject<any[]>) {
  /** Pages Store */
  const setPagesCRUD = useSetRecoilState(pagesCRUDStore);
  const setPagesError = useSetRecoilState(pagesErrorStore);
  const setPagesLoading = useSetRecoilState(pagesLoadingStore);
  /** Pages Store */

  async function resetData() {
    setPagesCRUD({
      items: [],
      action: STORE_RESET,
    });
  }

  const {
    getData: getPages,
    syncData: syncPages,
    clearData: clearPages,
    syncSingleItem: syncPage,
    getFullItem: getFullPage,
    getFullItems: getFullPages,
  } = useDataHook({
    pendingData,
    useIdSync: true,
    dataKey: pageKey,
    getApiProps: (id: string) => ({
      $attributes: PAGES_ATTRIBUTES,
      $where: { set_id: id },
    }),
    handleCleanData: cleanPageData,
    getLocalApiProps: (id: string) => ({
      set_id: id,
    }),
    store: {
      resetData,
      setDataError: setPagesError,
      setDataIsLoading: setPagesLoading,
      getItemValue: (page: any) => ({
        id: page.id,
        set_id: page?.set_id,
        project_id: page?.project_id,
        organization_id: page?.organization_id,
      }),
    },
  });

  return {
    getPages,
    syncPage,
    syncPages,
    clearPages,
    getFullPage,
    getFullPages,
  };
}
