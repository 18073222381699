import { COMMON_MESSAGES } from "./common";
import { prettyNumber } from "../utils/string";

export const SETTINGS_MESSAGES = {
  ...COMMON_MESSAGES,

  BILLING: "Billing",
  SETTINGS: "Settings",

  CODE: "Code",
  SEATS: "Seats",
  TOTAL: "Total",
  COUPON: "Coupon",
  AVAILABLE_SEATS: "Available seats",

  OVERVIEW: "Overview",
  INVOICES: "Invoices",
  CREDIT_CARD: "Credit Card",
  PAYMENT_METHOD: "Payment method",
  PAYMENT_INFO: "Payment information",
  BILLING_INFO: "Billing information",

  SELECT_USER_ROLE: "Select a user role",

  YEARLY: "Yearly",
  MONTHLY: "Monthly",
  UNLIMITED: "Unlimited",

  COST: "Cost",
  PLAN: "Plan",
  PERIOD: "Period",
  EDIT_PLAN: "Edit plan",
  RESUME_PLAN: "Resume plan",
  CANCEL_PLAN: "Cancel plan",
  FIX_PAYMENT: "Fix payment",
  CURRENT_USERS_SUB: "Current users / Available seats",

  AMOUNT: "Amount",
  SAVE_CARD: "Save Card",
  PERIOD_END: "Period end",
  ISSUE_DATE: "Issue date",
  PERIOD_START: "Period start",
  INVOICE_NUMBER: "Invoice number",
  CONFIRM_PAYMENT: "Confirm payment",

  NO_INVOICES_FOUND: "No invoices found",

  BILLED_ANNUALLY_AS: "Billed annually as",
  LESS_THAN_24_HOURS: "less than 24 hours",

  USER_MANAGEMENT: "User management",
  INVITE_NEW_USER: "Invite new user",

  USER_ITEM_LAST_SEEN_1: (time: string) => `${time} ago`,
  USER_ITEM_LAST_SEEN_2: (date: string) => `Invited on ${date}`,

  EDITED: (time: string) => `Edited ${time}`,

  SET_REMOVING_WILL: "Removing this user will:",
  SET_REMOVING_WILL_1: "- Remove this user from participating in this Set",
  SET_REMOVING_WILL_2: "- Delete their view",
  SET_REMOVING_WILL_3: "- Delete any work they did",

  SET_REMOVING_DESCRIPTION:
    "Are you sure you want to remove this user's access to this Set?",

  SET_REMOVING_DELETE_TITLE: "Are you sure?",

  SET_ASSIGNING: "Assign to set",

  SET_REMOVED_TIP: "User has been removed from this set",
  SET_REMOVED_PROJECT_TIP: "User has been removed from one or more sets",

  SUCCESS_UPDATING_ROLE: "User role has been successfully updated",
  SUCCESS_INVITING_USER: "Users have been successfully invited to the set",
  SUCCESS_REMOVING_ROLE: "User has been successfully removed from the set",

  ERROR_UPDATING_PM: "Error while updating payment method",
  SUCCESS_UPDATING_PM: "Payment method updated successfully",

  ERROR_UPDATING_SUBSCRIPTION: "Error while updating subscription",
  SUCCESS_UPDATING_SUBSCRIPTION: "Subscription updated successfully",

  CANCEL_SUB_ALERT_TITLE: "Cancel subscription plan",
  CANCEL_SUB_ALERT_DESC:
    "Are you sure you want to cancel your subscription? Please note that your account will remain active until the end of the current billing or trial period.",
  SUCCESS_CANCELING_SUBSCRIPTION: "Subscription canceled successfully",

  EDIT_USER_CM: "Edit user",
  DELETE_USER_CM: "Delete user",
  EDIT_INVIT_CM: "Edit invitation",
  CANCEL_INVI_CM: "Cancel invitation",
  RE_SEND_INVIT_CM: "Re-send invitation",
  RE_SEND_EMAIL_VERIF_CM: "Re-send email verification",

  DELETE_USER_ALERT_TITLE: "Delete user ",
  DELETE_USER_ALERT_SELECT_USER: "Select user",
  DELETE_USER_ALERT_ERROR: "Error while deleting the user",
  DELETE_USER_ALERT_DESCRIPTION_TEXT: (
    isReassign: boolean,
    name: string,
    projectsCount: number
  ) => {
    let message = `Are you sure you want to delete ${name}?`;
    if (isReassign) {
      const firstName = name?.split(" ")?.[0] || name;
      message = `${message} To proceed with deleting please select the user to reassign ${projectsCount} ${firstName}’s ${
        projectsCount === 1 ? "project" : "projects"
      }`;
    }
    return message;
  },

  NEW_INVITE_TITLE: "New Invite",

  DOWNLOAD_INVOICE_ERROR: "Error while downloading invoice",

  ADD_PM_METHOD: "Add payment method",
  NO_PM_SETUP: "You don't yet have a payment method setup",

  FEATURE_COUNT: "Feature Count",
  SHAREE_COUNT: "Shared participants",
  TOGAL_COUNT: "Togals (AI-assisted take-offs)",
  TOGAL_GPT_COUNT: "TogalCHAT prompts / responses",
  IMAGE_SEARCH_COUNT: "AI-assisted image searches",

  FEEATURE_USAGE_TRIAL_INFO_1: "Your free trial ends on",
  FEEATURE_USAGE_TRIAL_INFO_2:
    "After this date you’ll have 5 Togals (AI-assisted take-offs), 5 TogalCHAT prompts / responses and 5 AI-assisted image searches Collaboration feature limited to 2 users.",

  FEEATURE_USAGE_INFO_1: "Your plan refreshes on",
  FEEATURE_USAGE_INFO_2:
    "After this date you’ll have again 5 Togals (AI-assisted take-offs), 5 TogalCHAT prompts / responses and 5 AI-assisted image searches Collaboration feature limited to 2 users.",

  LONG_EMAIL_ERROR: "Email cannot be longer than 128 characters",
  INVALID_EMAIL_ERROR: "Email is not valid",
  ADDING_EXTERNAL_EMAIL_ERROR:
    "You can only invite external user via Share/Collaboration feature",

  SAVE_20: "save 20%",
  FREE_TRIAL_EXPIRED: "Free trial expired.",

  ESSENTIALS_PLAN_FEATURE_1: "5 AI-automated takeoffs (Togalings) per month",
  ESSENTIALS_PLAN_FEATURE_2: "5 TogalCHAT prompts per month",
  ESSENTIALS_PLAN_FEATURE_3: "5 AI-powered image/symbol searches per month",
  ESSENTIALS_PLAN_FEATURE_4: "Internal collaboration between 2 users",

  DEFAULT_PLAN_FEATURES_1: "Unlimited AI-automated takeoffs (Togalings)",
  DEFAULT_PLAN_FEATURES_2: "Unlimited TogalCHAT prompts",
  DEFAULT_PLAN_FEATURES_3:
    "Internal collaboration with any users in the account",
  DEFAULT_PLAN_FEATURES_4: "External collaboration",

  SEATS_MESSAGE_1:
    "You can't choose a number of seats more than 10 during the trial period, there is no such limit in the full subscription.",
  SEATS_MESSAGE_2: "You can't choose a number of seats more than 300.",

  DAYS_LEFT_IN_TRIAL: (daysLeft: number) => `${daysLeft + 1} days`,
  TRIAL_BILLED_MESSAGE: (trialText: string) =>
    `You'll be billed in ${trialText} when your trial finishes`,

  SEATS_MESSAGE_3: (currentUsage: number) =>
    `The entered number of seats is lower than your current number of users - ${currentUsage}, if you want to downgrade it is necessary to delete `,
  SEATS_MESSAGE_3_UNDERLINE: (requiredDeleteNumber: number) =>
    `${requiredDeleteNumber} ${
      requiredDeleteNumber === 1 ? " user." : " users."
    }`,
  SEATS_MESSAGE_4: (currentUsage: number) =>
    `Your current usage is ${currentUsage} users.`,
  SEATS_MESSAGE_5: `The amount of seats can not be 0.`,

  REMAINING_TRIAL_MESSAGE: (trialDaysLeft: number) =>
    trialDaysLeft < 1
      ? "Less than 24 hours left in trial"
      : `You have ${trialDaysLeft + 1} days left in trial`,

  CHANGE_TO_ANNUAL_AMOUNT: (
    isChangeToAnnual: boolean,
    incrementAmount: number
  ) =>
    isChangeToAnnual
      ? `By clicking “Confirm Payment”, you agree to pay $${incrementAmount}, and that your subscription will renew on an annual basis at the then-current standard rate thereafter.`
      : `By clicking “Confirm Payment”, you agree to pay $${incrementAmount}. `,

  /**
   *
   *
   * SUB_INFO_MESSAGES
   *
   */

  TRIAL_INFO_1: "You have a free trial until",
  TRIAL_INFO_2:
    "You can activate a paid account at any time and you will still get the full length of your free trial before you are charged. In order to have uninterrupted service, please choose a plan and provide your",
  TRIAL_INFO_3: "billing information",
  TRIAL_INFO_4:
    "by the end of your trial period. If your trial lapses and you fail to activate a paid account, you will still have view-only access to all of your work. Your account will remain and you can activate a paid account at any time.",

  TRIAL_ONGOING_1: "Your free trial ends on",
  TRIAL_ONGOING_2: "When your trial ends, you will be charged",
  TRIAL_ONGOING_4: "using the payment method you've provided.",
  TRIAL_ONGOING_3: ({
    amount1,
    amount2,
    amount3,
    discount,
    latestInvoice,
  }: any) =>
    latestInvoice
      ? "$" + prettyNumber(latestInvoice?.amount_due / 100)
      : "$" +
        `${
          !!discount
            ? Number.isInteger(discount)
              ? amount1
              : amount2
            : amount3
        }`,

  TRIAL_ENDED_1: "Your free trial expired on",
  TRIAL_ENDED_2:
    "You have view-only access to all of your work. You can activate a paid account by choosing a plan and providing your billing information.",

  FAILED_PAYMENT_1:
    "There was a problem with your payment. Your payment was due on",
  FAILED_PAYMENT_2:
    "You currently have view-only access to all of your work. Please correct your payment information in order to re-activate full access. Contact us at support@togal.ai if you need any assistance.",

  ONGOING_SUB1: (isYearly: boolean) =>
    `Your account is billed ${
      isYearly ? "yearly" : "monthly"
    }. You last payment was on`,
  ONGOING_SUB2: "Your next payment is due on",

  TRIAL_WITH_PAYMENT_1: "You have a free trial until",
  TRIAL_WITH_PAYMENT_2: ". You canceled your payment on",
  TRIAL_WITH_PAYMENT_3: ", so you will not be charged.",
  TRIAL_WITH_PAYMENT_4:
    "When your trial ends, you will have view-only access to all of your work. You can pay and re-activate full access at any time.",

  TRIAL_EXPIRED_1: "Your free trial expired on",
  TRIAL_EXPIRED_2:
    "You have view-only access to all of your work. You can activate a paid account by choosing a plan and providing your billing information.",

  CANCELED_SUB_1: "You canceled your membership on",
  CANCELED_SUB_2:
    "You will have full access to your account until the end of your billing period, which is",
  CANCELED_SUB_3:
    "After that, you will have view-only access to all of your work. You can re-activate full access at any time.",
  CANCELED_SUB_4:
    "You can resume your subscription before the end of the current period by clicking the button below",

  SUBMIT_CARD_ERROR: "Error saving card. Please try again.",
  /**
   *
   *
   * FUNC MESSAGES
   *
   *
   */

  REMAINING_USER_MESSAGE: (remainingInvites: number = 0) =>
    remainingInvites <= 0
      ? "You ran out of invites. You can upgrade your plan"
      : `You have ${remainingInvites} invites left`,

  EDIT_MODAL_TITLE: (isInvitation: boolean) =>
    isInvitation ? "Edit invitation" : "Edit User",

  QUANTITY_DECREASED_MESSAGE: ({
    isYearly,
    discount,
    totalPrice,
    annualPrice,
    nextBillingDate,
    discountedAnnualPrice,
  }: any) =>
    `Your number of seats will remain unchanged until the end of your current billing cycle. Then on ${nextBillingDate}, the number of seats will change as requested and you'll be charged the new price of $${
      isYearly ? (discount ? discountedAnnualPrice : annualPrice) : totalPrice
    }.\n`,

  QUANTITY_INCREASED_MESSAGE: ({
    daysLeft,
    discount,
    annualPrice,
    creditAmount,
    discountedAnnualPrice,
    subscriptionPriceChange,
  }: any) =>
    `Since you have a current monthly subscription, your payment will be $${subscriptionPriceChange} ` +
    (daysLeft > 0
      ? ` ($${
          discount ? discountedAnnualPrice : annualPrice
        } less a $${prettyNumber(
          creditAmount
        )} credit for the remaining ${daysLeft} ${
          daysLeft === 1 ? "day" : "days"
        } of your current month).
\n`
      : "\n"),

  ANNUAL_TO_MONTHLY_MESSAGE: ({ nextBillingDate, totalPrice }: any) =>
    `Since you have a current annual subscription, your change to monthly will take place at the end of your current billing cycle. Then on ${nextBillingDate} you'll transition to a monthly subscription at $${totalPrice}/month.\n`,

  ANNUAL_TO_MONTHLY_MESSAGE_Q_DECRESED: ({
    isYearly,
    discount,
    totalPrice,
    annualPrice,
    nextBillingDate,
    discountedAnnualPrice,
  }: any) =>
    `Your number of seats will remain unchanged until the end of your current billing cycle. Then on ${nextBillingDate}, the number of seats will change as requested and you'll be charged the new price of $${
      isYearly ? (discount ? discountedAnnualPrice : annualPrice) : totalPrice
    }.\n`,

  ANNUAL_TO_MONTHLY_MESSAGE_Q_INCREASED: ({
    daysLeft,
    discount,
    annualPrice,
    creditAmount,
    discountedAnnualPrice,
    subscriptionPriceChange,
  }: any) =>
    `Since you have a current annual subscription, your payment will be $${subscriptionPriceChange}` +
    (daysLeft > 0
      ? ` ($${
          discount ? discountedAnnualPrice : annualPrice
        } less a $${prettyNumber(
          creditAmount
        )} credit for the remaining ${daysLeft} ${
          daysLeft === 1 ? "day" : "days"
        } of your current month).
  \n`
      : "\n"),

  PRICE_UPDATE_MESSAGE: ({
    daysLeft,
    discount,
    annualPrice,
    creditAmount,
    discountedAnnualPrice,
    subscriptionPriceChange,
  }: any) =>
    `Since you have a current monthly subscription, your payment will be $${subscriptionPriceChange} ` +
    (daysLeft > 0
      ? ` ($${
          discount ? discountedAnnualPrice : annualPrice
        } less a $${prettyNumber(
          creditAmount
        )} credit for the remaining ${daysLeft} ${
          daysLeft === 1 ? "day" : "days"
        } of your current month).
\n`
      : "\n"),

  Q_DECREASED_MESSAGE: ({
    isYearly,
    discount,
    totalPrice,
    annualPrice,
    nextBillingDate,
    discountedAnnualPrice,
  }: any) =>
    `Your number of seats will remain unchanged until the end of your current billing cycle. Then on ${nextBillingDate}, the number of seats will change as requested and you'll be charged the new price of $${
      isYearly ? (discount ? discountedAnnualPrice : annualPrice) : totalPrice
    }.\n`,

  Q_INCREASED_MESSAGE: ({
    daysLeft,
    discount,
    isYearly,
    totalPrice,
    annualPrice,
    nextBillingDate,
    discountedAnnualPrice,
    subscriptionPriceChange,
  }: any) =>
    `The amount you need to pay now is $${subscriptionPriceChange}. This is a prorated amount for the ${daysLeft} remaining ${
      daysLeft === 1 ? "day" : "days"
    } of between now and the end of your current billing cycle for the extra user(s). Then on ${nextBillingDate}, you'll be charged the regular price of $${
      isYearly ? (discount ? discountedAnnualPrice : annualPrice) : totalPrice
    }.\n`,

  COUPON_ERROR: "The coupon has not been applied",
  COUPON_MESSAGE: (discountDuration: number, isForever: boolean) =>
    `The coupon has been applied${
      !!discountDuration
        ? discountDuration === 1
          ? ", it will work for one month"
          : `, it will work for ${discountDuration} months`
        : isForever
        ? ", it will work forever"
        : ""
    }`,

  /***
   *
   *
   *
   *
   */
};
