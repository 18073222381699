import { CSSProperties } from "react";
import styled, { css } from "styled-components";
import { CSS, FONT_WEIGHTS } from "../../consts/style";
import { IStyles } from "../Styled";
import { IProps } from "./index";

interface IStyledButton extends IStyles, IProps {}

export const Para = styled.p<IStyles>`
  margin: 0;
  padding: 0;
  font-weight: ${(props) => props.fontWeight || FONT_WEIGHTS.MEDIUM};
  font-size: ${(props) => props.fontSize || "1em"};
  color: ${(props) => props.fontColor || CSS.MAINCOLOR};
  transition: transform 0.2s ease;
`;

export const StyledButton = styled.button<Omit<IStyledButton, "value">>`
  position: relative;
  height: ${(props) =>
    props.height ?? (props.size === "small" ? "30px" : "37px")};
  min-width: ${(props) => props.minWidth};
  border-radius: ${(props) => props.borderRadius};
  padding-left: ${(props) => (props.size === "small" ? "5px" : "10px")};
  padding-right: ${(props) => (props.size === "small" ? "5px" : "10px")};
  outline: none;
  ${({ padding }) => padding && `padding: ${padding};`};
  ${({ margin }) => margin && `margin: ${margin};`};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify ?? "center"};
  align-content: center;
  transition: transform 0.3s ease;
  font-size: 1em;
  font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  background-color: ${(props) => props.bgColor || CSS.MAINCOLORLIGHT};
  border: ${(props) =>
    props.border ?? `1px solid ${props.borderColor || CSS.MAINCOLOR}`};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      background-color: ${props.disabledBgColor ?? CSS.MIDGREY};
      border-color: ${props.disabledBorderColor ?? CSS.MIDGREY};
      color: ${props.disabledColor ?? CSS.WHITE};
      opacity: ${props.disableOpacity ? "0.5" : "1"};

      ${props?.disabledColor &&
      `
      & > p {
        color: ${props.disabledColor};
      }`}
    `};

  ${(props) =>
    props.error &&
    css`
      cursor: pointer;
      background-image: linear-gradient(to right, ${CSS.ERROR}, ${CSS.ERROR});
      color: ${CSS.WHITE};
    `};

  ${(props) =>
    props.animated &&
    css`
      :hover {
        filter: brightness(110%);
      }
    `};

  ${(props) =>
    props.empty &&
    css`
      background-color: transparent;
      border: 1px solid ${CSS.MIDGREY};
      & > p {
        color: ${props.disabled ? CSS.DISABLEDGREY : CSS.BLACK};
      }
    `};
`;
