import kinks from "@turf/kinks";
import turfIntersect from "@turf/intersect";
import turfDifference from "@turf/difference";

import { generateId } from "../../utils/string";
import { head } from "../../utils/functional/list";
import { GEOJSON_TYPES } from "../../consts/editor";
import { CommonDrawMode } from "../draw/common-draw";
import ImmutableLayersData from "../base/ImmutableLayersData";
import { getCleanedShapes, getIntersectFeatures } from "./utils";

export class CutMode extends CommonDrawMode {
  handleAddFeature = (event: any, props: any) => {
    const isCtrlCmd = event.ctrlKey || event.metaKey;

    const clickSequence = this.getClickSequence();

    if (clickSequence?.length > 2) {
      const mainCoordinates = this.mainFeature?.properties?.closed
        ? this.mainFeature.geometry.coordinates[0]
        : clickSequence;
      const coordinates: any = [[...mainCoordinates, head(mainCoordinates)]];

      const feature: any = {
        type: GEOJSON_TYPES.Feature,
        properties: {
          arcs: this._arcs,
        },
        geometry: {
          type: GEOJSON_TYPES.Polygon,
          coordinates,
        },
      };

      const featureKinks = kinks(feature);
      const isEdgeIntersection = featureKinks?.features?.length > 1;

      if (
        (isEdgeIntersection && !this.mainFeature?.properties?.closed) ||
        this._lock
      ) {
        return;
      }

      const editAction = this.getCutEditAction(feature, props, isCtrlCmd);

      if (editAction) {
        props.onEdit(editAction);
      }

      this._resetState();
    }
  };

  getCutEditAction = (feature: any, props: any, isCtrlCmd = false): any => {
    const { geoJson, view, colorMap } = props.modeConfig;

    const selectedFeatures = props.data.features;
    const intersectedFeatures = getIntersectFeatures(feature, props);

    if (!intersectedFeatures?.length) return null;

    let featureIds = [];
    let newFeatures = [];

    let updatedData = new ImmutableLayersData(geoJson);

    for (const selectedFeature of intersectedFeatures) {
      featureIds.push(selectedFeature.properties.id);

      if (selectedFeature.geometry.type === GEOJSON_TYPES.Polygon) {
        let difference = null;
        if (isCtrlCmd) {
          difference = turfIntersect(selectedFeature, feature);
        } else {
          difference = turfDifference(selectedFeature, feature);
        }

        getCleanedShapes([difference]).forEach((cleanedShape) => {
          const newId = generateId();
          featureIds.push(newId);
          newFeatures.push({
            ...cleanedShape,
            properties: {
              ...selectedFeature.properties,
              id: newId,
            },
          });
        });
      }

      if (selectedFeature.geometry.type === GEOJSON_TYPES.MultiPolygon) {
        const cleanedShapes = getCleanedShapes([selectedFeature]);
        for (const cleanedShape of cleanedShapes) {
          const isIntersect = turfIntersect(
            cleanedShape as any,
            feature as any
          );

          if (isIntersect) {
            let difference = null;
            if (isCtrlCmd) {
              difference = turfIntersect(selectedFeature, feature);
            } else {
              difference = turfDifference(selectedFeature, feature);
            }

            getCleanedShapes([difference]).forEach((cleanedShape) => {
              const newId = generateId();
              featureIds.push(newId);
              newFeatures.push({
                ...cleanedShape,
                properties: {
                  ...selectedFeature.properties,
                  id: newId,
                },
              });
            });
          } else {
            const newId = generateId();
            featureIds.push(newId);
            newFeatures.push({
              ...cleanedShape,
              properties: {
                ...selectedFeature.properties,
                id: newId,
              },
            });
          }
        }
      }
    }

    updatedData = updatedData.filterSelected(featureIds, true);

    for (const newFeature of newFeatures) {
      updatedData = updatedData.addFeature(
        newFeature.geometry,
        newFeature.properties.name,
        newFeature.properties.id,
        newFeature.properties.className,
        null,
        view,
        [],
        null,
        null,
        colorMap,
        newFeature.properties
      );
    }

    for (const f of [...selectedFeatures, feature]) {
      for (const id of featureIds) {
        if (f.properties.arcs?.length) {
          updatedData = updatedData.fixArcs(id, f, view, colorMap);
        }
      }
    }

    return {
      updatedData: {
        type: GEOJSON_TYPES.FeatureCollection,
        features: updatedData.getLayers(),
      },
      editType:
        selectedFeatures?.length > 0
          ? "unionGeometry"
          : "unionGeometryNonSelected",
      editContext: {
        featureIds,
      },
    };
  };
}
