import turfBuffer from "@turf/buffer";
import turfDifference from "@turf/difference";
import turfLineSplit from "../../utils/turf/line-split";
import lineIntersect from "../../utils/turf/line-intersect";

import { Feature, Polygon, Geometry } from "geojson";

import {
  updateGeometryWithPadding,
  getRecursivePaddingFunction,
} from "../../utils/coordinates";
import { CommonDrawMode } from "./common-draw";
import { generateId } from "../../utils/string";
import { GEOJSON_TYPES } from "../../consts/editor";
import { validateGeometry } from "../../utils/editor";
import ImmutableLayersData from "../base/ImmutableLayersData";

const PADDING = 10000;

export class DrawSplitMode extends CommonDrawMode {
  handleAddFeature = (event: any, props: any) => {
    const clickSequence = this.getClickSequence();

    if (clickSequence.length > 1) {
      const lineFeature = {
        type: GEOJSON_TYPES.Feature,
        properties: {},
        geometry: {
          type: GEOJSON_TYPES.LineString,
          coordinates: clickSequence,
        },
      };
      const intersectFeatures = this.getIntersectFeatures(lineFeature, props);

      let updatedFeatures = new ImmutableLayersData(props?.modeConfig?.geoJson);
      let featureIds: string[] = [];

      for (const feature of intersectFeatures) {
        if (
          feature.geometry.type === GEOJSON_TYPES.Polygon ||
          feature.geometry.type === GEOJSON_TYPES.MultiPolygon
        ) {
          try {
            const splitResult = this.splitPolygon(
              lineFeature,
              feature,
              updatedFeatures,
              props
            );

            if (splitResult) {
              updatedFeatures = splitResult.updatedData;
              featureIds.push(feature.properties.id);
              featureIds = [...featureIds, ...splitResult.newIds];
            }
          } catch (e) {
            console.error("Error splitting polygon", e);
          }
        } else if (feature.geometry.type === GEOJSON_TYPES.LineString) {
          try {
            const splitResult = this.splitLine(
              lineFeature,
              feature,
              updatedFeatures,
              props
            );

            if (splitResult) {
              updatedFeatures = splitResult.updatedData;
              featureIds.push(feature.properties.id);
              featureIds = [...featureIds, ...splitResult.newIds];
            }
          } catch (e) {
            console.error("Error splitting line", e);
          }
        } else if (feature.geometry.type === GEOJSON_TYPES.MultiLineString) {
          try {
            const splitResult = this.splitMultiLine(
              lineFeature,
              feature,
              updatedFeatures,
              props
            );

            if (splitResult) {
              updatedFeatures = splitResult.updatedData;
              featureIds.push(feature.properties.id);
              featureIds = [...featureIds, ...splitResult.newIds];
            }
          } catch (e) {
            console.error("Error splitting line", e);
          }
        }
      }

      this._resetState();

      const editAction = {
        updatedData: {
          type: GEOJSON_TYPES.FeatureCollection,
          features: updatedFeatures.getLayers(),
        },
        editType: "split",
        editContext: {
          featureIds,
        },
      };

      props.onEdit(editAction);
    }
  };

  getIntersectFeatures(lineFeature: any, props: any) {
    const tentativeFeature: any = updateGeometryWithPadding(
      PADDING,
      lineFeature
    );

    const selectedFeatures = props.data.features;

    const intersectedFeatures = selectedFeatures.filter((feature: any) => {
      const paddedFeature: any = updateGeometryWithPadding(PADDING, feature);
      const intersection = lineIntersect(tentativeFeature, paddedFeature);
      return intersection?.features?.length > 0;
    });

    return intersectedFeatures;
  }

  splitLine(
    tentativeFeature: any,
    selectedfeature: any,
    updatedFeatures: any,
    props: any
  ): any {
    const modeConfig: any = props.modeConfig || {};
    let { colorMap, view, filteredtakeOffTypes } = modeConfig;

    tentativeFeature = updateGeometryWithPadding(PADDING, tentativeFeature);
    selectedfeature = updateGeometryWithPadding(PADDING, selectedfeature);

    const lineSplit = turfLineSplit(selectedfeature, tentativeFeature);

    if (lineSplit?.features?.length <= 1) return null;

    const newIds: string[] = [];
    const newFeatures: Feature<Polygon>[] = [];
    lineSplit?.features.forEach((feature: any) => {
      const updatedFeature = updateGeometryWithPadding(1 / PADDING, feature);

      newFeatures.push({
        type: GEOJSON_TYPES.Feature,
        properties: {},
        geometry: updatedFeature.geometry,
      });
    });

    const updatedData = updatedFeatures.filterSelected(
      [selectedfeature.properties.id],
      true
    );
    newFeatures.forEach((fe) => {
      const newFeatureId = generateId();

      updatedData
        .addFeature(
          fe.geometry,
          selectedfeature.properties.name,
          newFeatureId,
          selectedfeature.properties.className,
          filteredtakeOffTypes.length === 1 ? filteredtakeOffTypes[0] : null,
          view,
          [],
          null,
          null,
          colorMap
        )
        .fixArcs(newFeatureId, selectedfeature, view, colorMap);

      newIds.push(newFeatureId);
    });

    return { updatedData, newIds };
  }

  splitPolygon(
    tentativeFeature: Feature<Geometry>,
    selectedfeature: any,
    updatedFeatures: any,
    props: any
  ): any {
    const modeConfig: any = props.modeConfig || {};
    let {
      colorMap,
      gap = 0.1,
      units = "centimeters",
      view,
      filteredtakeOffTypes,
    } = modeConfig;

    if (gap === 0) {
      gap = 0.1;
      units = "centimeters";
    }

    let selectedGeometry = selectedfeature.geometry;
    selectedGeometry = {
      ...selectedGeometry,
      coordinates: selectedGeometry.coordinates.map(
        getRecursivePaddingFunction(PADDING)
      ),
    };

    tentativeFeature = updateGeometryWithPadding(PADDING, tentativeFeature);

    const buffer = turfBuffer(tentativeFeature, gap, { units });
    let updatedGeometry = turfDifference(selectedGeometry, buffer);
    if (!updatedGeometry) {
      console.warn("Canceling edit. Split Polygon erased");
      return null;
    }

    updatedGeometry = updateGeometryWithPadding(1 / PADDING, updatedGeometry);

    const newFeatures: Feature<Polygon>[] = [];

    const { coordinates } = updatedGeometry.geometry;

    coordinates.forEach((coord: any) => {
      newFeatures.push({
        type: GEOJSON_TYPES.Feature,
        properties: {},
        geometry: {
          type: GEOJSON_TYPES.Polygon,
          coordinates: coord,
        },
      });
    });

    let isNewFeaturesValid = true;

    newFeatures.forEach((fe) => {
      const isValidFe = validateGeometry(
        fe.geometry.type,
        fe.geometry.coordinates
      );
      if (!isValidFe) {
        isNewFeaturesValid = false;
      }
    });

    if (!isNewFeaturesValid) return;

    const updatedData = updatedFeatures.filterSelected(
      [selectedfeature.properties.id],
      true
    );

    const newIds: string[] = [];

    newFeatures.forEach((fe) => {
      const newFeatureId = generateId();

      updatedData
        .addFeature(
          fe.geometry,
          selectedfeature.properties.name,
          newFeatureId,
          selectedfeature.properties.className,
          filteredtakeOffTypes.length === 1 ? filteredtakeOffTypes[0] : null,
          view,
          [],
          null,
          null,
          colorMap
        )
        .fixArcs(newFeatureId, selectedfeature, view, colorMap);

      newIds.push(newFeatureId);
    });

    return { updatedData, newIds };
  }

  splitMultiLine(
    tentativeFeature: any,
    selectedfeature: any,
    updatedFeatures: any,
    props: any
  ): any {
    const modeConfig: any = props.modeConfig || {};
    let { colorMap, view, filteredtakeOffTypes } = modeConfig;

    tentativeFeature = updateGeometryWithPadding(PADDING, tentativeFeature);
    selectedfeature = updateGeometryWithPadding(PADDING, selectedfeature);

    const selectedFeatureAsLines = new ImmutableLayersData([selectedfeature])
      .makeIntoLines(view, colorMap)
      .getLayers();

    let newLines: any = [];
    const splitLines: any = [];

    for (const line of selectedFeatureAsLines) {
      const lineSplit = turfLineSplit(line, tentativeFeature);

      if (lineSplit?.features?.length > 1) {
        lineSplit?.features.forEach((feature: any, idx: number) => {
          const updatedFeature = updateGeometryWithPadding(
            1 / PADDING,
            feature
          );
          const updatedLine = {
            type: GEOJSON_TYPES.Feature,
            properties: {},
            geometry: updatedFeature.geometry,
          };
          if (idx === 0) {
            splitLines.push([...newLines, updatedLine]);
            newLines = [];
          }
          if (idx === 1) {
            newLines.push(updatedLine);
          }
        });
      } else {
        const updatedLine = updateGeometryWithPadding(1 / PADDING, line);
        newLines.push(updatedLine);
      }
    }
    if (newLines.length > 0) {
      splitLines.push(newLines);
    }

    if (splitLines?.length > 1) {
      const updatedData = updatedFeatures.filterSelected(
        [selectedfeature.properties.id],
        true
      );

      const newIds: string[] = [];
      splitLines.forEach((lines: any) => {
        if (lines.length > 0) {
          const newFeatureId = generateId();

          const newMultiLines = new ImmutableLayersData(lines)
            .makeIntoMultiLine(view, colorMap)
            .getLayers();

          if (newMultiLines.length > 0) {
            updatedData.addFeature(
              newMultiLines[0].geometry,
              selectedfeature.properties.name,
              newFeatureId,
              selectedfeature.properties.className,
              filteredtakeOffTypes.length === 1
                ? filteredtakeOffTypes[0]
                : null,
              view,
              [],
              null,
              null,
              colorMap
            );

            newIds.push(newFeatureId);
          }
        }
      });

      return { updatedData, newIds };
    }
    return null;
  }
}
