import { useSetRecoilState } from "recoil";

import { useDataHook } from "./commonDataHook";
import { cleanViewData } from "../dataUtils";
import { viewKey, VIEWS_ATTRIBUTES } from "../const";
import {
  STORE_RESET,
  viewsCRUDStore,
  viewsErrorStore,
  viewsLoadingStore,
} from "src/state/dataStore";

export function useViewsData(pendingData: React.MutableRefObject<any[]>) {
  /** Views Store */
  const setViewsCRUD = useSetRecoilState(viewsCRUDStore);
  const setViewsError = useSetRecoilState(viewsErrorStore);
  const setViewsLoading = useSetRecoilState(viewsLoadingStore);
  /** Views Store */

  async function resetData() {
    setViewsCRUD({
      items: [],
      action: STORE_RESET,
    });
  }

  const {
    getData: getViews,
    syncData: syncViews,
    clearData: clearViews,
    syncSingleItem: syncView,
  } = useDataHook({
    pendingData,
    useIdSync: true,
    dataKey: viewKey,
    getApiProps: (id: string) => ({
      $attributes: VIEWS_ATTRIBUTES,
      $where: { set_id: id },
    }),
    handleCleanData: cleanViewData,
    getLocalApiProps: (id: string) => ({
      set_id: id,
    }),
    store: {
      resetData,
      setDataError: setViewsError,
      setDataIsLoading: setViewsLoading,
      getItemValue: (view: any) => ({
        id: view.id,
        set_id: view?.set_id,
        page_id: view?.page_id,
        project_id: view?.project_id,
        organization_id: view?.organization_id,
      }),
    },
  });

  return {
    getViews,
    syncView,
    syncViews,
    clearViews,
  };
}
