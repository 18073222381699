import { curry, pipe } from "./function";
import { filter } from "./list";

export const assoc = curry(
  <T>(
    key: string,
    value: T,
    object: Record<string, any>
  ): Record<string, any> => ({
    ...object,
    [key]: value,
  })
);

export const assocPath = curry(
  <T>(keys: string[], value: T, object: Record<string, unknown>) => {
    const [head, ...tail] = keys;

    if (tail.length) {
      return {
        ...(object ?? {}),
        [head]: assocPath(tail, value, object[head]),
      };
    }

    return {
      ...(object ?? {}),
      [head]: value,
    };
  }
);

export const defaults = curry(<T>(def: T, obj?: T): T => obj || def);

export const dissocPath = curry(
  <T>(keys: string[], object: Record<string, unknown>) => {
    const [head, ...tail] = keys;

    if (tail.length) {
      return {
        ...(object ?? {}),
        [head]: dissocPath(tail, object[head]),
      };
    }

    return omit([head], object);
  }
);

export const equals = curry((a: any, b: any): boolean => {
  if (Array.isArray(a) && Array.isArray(b)) {
    if (b.length === a.length) {
      return a.every((val, i) => {
        return equals(b[i], val);
      });
    }
    return false;
  } else if (!!a && !!b && typeof a === "object" && typeof b === "object") {
    return equals(Object.entries(a), Object.entries(b));
  } else {
    return a === b;
  }
});

export const eqProp = curry((propKey: string, a: any, b: any): boolean =>
  equals(prop(propKey, a), prop(propKey, b))
);

function pathInternal<T extends Record<string, any>, K>(
  path: string[],
  object: T
): K {
  if (typeof object === "undefined") return undefined;

  const [head, ...tail] = path;
  if (tail.length) {
    return pathInternal(tail, object[head]);
  }

  return object[head];
}

export const path = curry(
  <T, K>(path: string[], object: T): K => pathInternal(path, object)
);

export const pick = curry(
  <T extends Record<string, unknown>, K extends keyof T>(
    keys: K[],
    object: T
  ) => {
    const result = {} as Record<K, T[K]>;

    for (const key of keys) {
      result[key] = object[key];
    }

    return result;
  }
);

export const prop = curry(
  <T, K>(accessor: string, object: T): K =>
    pathInternal(accessor.split("."), object)
);

export const omit = curry(<T>(propKeys: string[], object: Record<string, T>) =>
  pipe(
    Object.entries,
    filter(([key]: string[]) => !propKeys.includes(key)),
    Object.fromEntries
  )(object)
);
