import React, { memo } from "react";

import { Flex } from "../Styled";
import Spinner from "../v2/Spinner";
import { CSS } from "../../consts/style";

const CircularLoading = () => (
  <Flex height="100vh">
    <Spinner size="45px" color={CSS.BLACK} />
  </Flex>
);

export default memo(CircularLoading);
