import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { useRafLoop } from "../../hooks";
import { noop } from "../../utils/function";
import { getUniqueArray } from "../../utils/array";

import { downloadImage, getMapImageData } from "./utils";

const CACHE_PADDING = 2;

interface IImageCacheEntry {
  id: string;
  isLoading: boolean;
  isLoaded: boolean;
  imgData: HTMLImageElement | null;
  url: string;
  error: string | null;
  context?: ImageData | null;
}

export interface IImageWithPriority {
  key: string;
  url: string;
  priority: number;
}

interface ICacheContext {
  cache: Map<string, IImageCacheEntry>;
  addImages: (images: IImageWithPriority[]) => void;
}

const DEFAULT_VALUE: ICacheContext = {
  cache: new Map(),
  addImages: noop,
};

const CacheContext = createContext<ICacheContext>(DEFAULT_VALUE);
export const useCache = () => useContext(CacheContext);

interface IProps {
  children: ReactNode;
}

export const CacheProvider = ({ children }: IProps): ReactElement => {
  const lastTime = useRef(0);

  const imageCache = useRef<Map<string, IImageCacheEntry>>(new Map());
  const imagesQueue = useRef<Set<string>>(new Set());
  const imageCacheMap = useRef(new Map());

  const [cache, setCache] = useState(new Map());

  useRafLoop(
    async (time) => {
      const lastStep = lastTime.current;
      const targetFPS = 1000;
      const step = time - lastStep;

      if (step >= targetFPS) {
        lastTime.current = time;

        const cond = (k: string) =>
          !imageCache.current.has(k) ||
          (imageCache.current.has(k) &&
            !(
              (imageCache.current.get(k) as IImageCacheEntry).isLoaded ||
              (imageCache.current.get(k) as IImageCacheEntry).isLoading ||
              (imageCache.current.get(k) as IImageCacheEntry).error
            ));

        const currentQueue: string[] = getUniqueArray(
          imagesQueue.current
        ).filter((k: string) => cond(k));

        for (const k of imageCache.current.keys()) {
          if (cond(k) && !currentQueue.includes(k)) {
            currentQueue.push(k);
          }
        }

        if (currentQueue.length > 0) {
          currentQueue.forEach((k) => {
            const currentQueueImage = imageCacheMap.current.get(k);

            const url = currentQueueImage?.url || null;
            const cacheData = imageCache.current.has(k)
              ? imageCache.current.get(k)
              : ({} as IImageCacheEntry);

            const newImage: IImageCacheEntry = {
              id: cacheData?.id || k,
              isLoading: cacheData?.isLoading || false,
              isLoaded: cacheData?.isLoaded || false,
              imgData: cacheData?.imgData || null,
              url: cacheData?.url || url,
              error: cacheData?.error || null,
            };

            imageCache.current.set(k, newImage);
          });

          const sortedImages = [...currentQueue].sort((a, b) => {
            const priorityA = imageCacheMap.current.get(a).priority;
            const priorityB = imageCacheMap.current.get(b).priority;

            return priorityA < priorityB ? -1 : priorityA > priorityB ? 1 : 0;
          });

          const imageToProcessKey = sortedImages[0];

          if (
            imageCacheMap.current.get(imageToProcessKey).priority <=
            CACHE_PADDING
          ) {
            imageCache.current.set(imageToProcessKey, {
              ...(imageCache.current.get(
                imageToProcessKey
              ) as IImageCacheEntry),
              isLoading: true,
            });

            setCache(new Map(imageCache.current));

            try {
              const currentImageCache =
                imageCache.current.get(imageToProcessKey);

              if (!currentImageCache) {
                throw new Error("Image not present in cache");
              }

              const url = currentImageCache?.url || "";
              const img = await downloadImage(url);
              const imgContextData = getMapImageData(img);
              imageCache.current.set(imageToProcessKey, {
                ...(imageCache.current.get(
                  imageToProcessKey
                ) as IImageCacheEntry),
                imgData: img,
                context: imgContextData,
                isLoading: false,
                isLoaded: true,
                error: null,
              });
            } catch (e) {
              console.error("error loading the image : ", e);
              imageCache.current.set(imageToProcessKey, {
                ...(imageCache.current.get(
                  imageToProcessKey
                ) as IImageCacheEntry),
                imgData: null,
                context: null,
                isLoading: false,
                isLoaded: false,
                error: "An error occured while trying to load the image : ",
              });
            }
            setCache(new Map(imageCache.current));
          }
        }
      }
    },
    true,
    [cache, imageCache, imagesQueue, imageCacheMap, setCache]
  );

  const addImages = useCallback(
    (imgs: IImageWithPriority[]) => {
      [...imagesQueue.current].forEach((k) => {
        if (cache.has(k)) {
          imagesQueue.current.delete(k);
        }
      });

      imgs.forEach(({ key, url, priority }: IImageWithPriority) => {
        const imageCache = cache.has(key) ? cache.get(key) : null;
        if (
          !imageCache ||
          (imageCache && !imageCache.isLoaded) ||
          (imageCache && !imageCache.isLoading)
        ) {
          imageCacheMap.current.set(key, { key, url, priority });
          imagesQueue.current.add(key);
          return;
        }
      });
    },
    [cache]
  );

  const value = ((window as any).cacheContext = useMemo(
    () => ({
      cache,
      addImages,
    }),
    [cache, addImages]
  ));

  return (
    <CacheContext.Provider value={value}>{children}</CacheContext.Provider>
  );
};
