 import { AnalyticsBrowser } from '@segment/analytics-next';
import IAnalyticsService from "./IAnalyticsService";

class SegmentService implements IAnalyticsService {
  private static analytics: AnalyticsBrowser;

  constructor() {
    SegmentService.init();
  }

  private static init() {
    try {
      SegmentService.analytics = AnalyticsBrowser.load(
        // @ts-ignore
        { writeKey: import.meta.env.VITE_REACT_APP_SEGMENT as string }
      )
    } catch (e) {
      console.error("segment error : ", e);
    }
  }

  sendEvent(eventType: string, eventProperties?: Record<string, string>) {
    // @ts-ignore
    if (import.meta.env.VITE_TGL_ENV === "local") {
      console.log("SEGMENT event called", eventType);
      // @ts-ignore
    }
    try {
      SegmentService.analytics.track(eventType, eventProperties);
    } catch (e) {
      console.error("segment error : ", e);
    }

  }
}

export default SegmentService;
