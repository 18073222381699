import { COMMON_MESSAGES } from "./common";

export const AUTH_MESSAGES = {
  ...COMMON_MESSAGES,

  CREATE_ACCOUNT: "Create Account",
  RESET_PASSWORD: "Reset Password",

  NEW_PASSWORD: "New Password",
  NEW_PASSWORD_CONFIRMATION: "Confirm New Password",
  PASSWORD_CONFIRMATION: "Confirm Password",

  MISSING_INVITATION: "A user may only be created with an invitation.",
  EMAIL_VALIDATION: "Please enter a valid email address.",
  MISSING_PASSWORD: "Please enter a password.",
  MISSING_PASSWORD_CONFIRMATION: "Please confirm your password.",
  PASSWORD_LENGTH: "Password must be at least 6 characters.",
  INCORRECT_EMAIL_OR_PASSWORD:
    "The email address or password you provided is not correct.",

  REQUIRE_INVIATION: "A user may only be created with an invitation.",
  MISSING_FIRSTNAME: "Please enter your first name.",
  MISSING_LASTNAME: "Please enter your last name.",

  VERIFICATION_SUCCESS: "Email verification successful",

  ALREADY_HAVE_ACCOUNT: "Already have an account?",
  BACK_TO_EMAIL: "Back to email",

  TERMS1: "By creating an account, you agree to our ",
  TERMS2: "Terms of Service",
  TERMS3: "and you acknowledge having read our ",
  TERMS4: "Privacy Policy",

  RESET_LINK_SENT: "We have e-mailed your password reset link",

  FORGOT_PASSWORD: "Forgot Password?",
  EMAIL_PLACEHOLDER: "Enter your email",
  FIRSTNAME_PLACEHOLDER: "Enter your first name",
  LASTNAME_PLACEHOLDER: "Enter your last name",
  PASSWORD_PLACEHOLDER: "Enter your password",
  ADD_ORG_NAME: "Type in the name you'd like to use to identify your company.",

  MS_SIGNIN: "Sign In with Microsoft",

  CHECK_YOUR_EMAIL: "Check your email!",
  NO_CREDIT_CARD: "No credit card required",
  CREATE_YOUR_FREE_ACCOUNT: "Create your free account",

  EMAIL_SENT1: "We sent an email to ",
  EMAIL_SENT2: " to confirm your email address and access your new account.",

  DIDNT_RECEIVE_EMAIL: "Still didn't get the email?",

  VERIFY_BY_EMAIL: "Verify by sending us an email",
  MISSING_TOKEN_ERROR: "Error. email and reset token are missing.",
  PASSWORD_RESET_SUCCESS: "Reset password successful",

  FEATURES1: "-day free trial",
  FEATURES2: "Unlimited take-offs",
  FEATURES3: "Access to TogalCHAT",
  FEATURES4: "Up to 10 users",

  FEATURES_DESCRIPTION1: "The Most Innovative Takeoff Tool on the Market",
  FEATURES_DESCRIPTION2: "See the full power of",
  FEATURES_DESCRIPTION3: "Join 2000+ users using Togal.AI",

  USER_INVITE_SENT_SUCCESSFULLY: "Invite resent successfully",
  USER_INVITE_SENT_ERROR: "Error while resending invite",

  EMAIL_VERIFICATION_SENT_SUCCESSFULLY:
    "Email verification resent successfully",
  EMAIL_VERIFICATION_SENT_ERROR: "Error while resending email verification",

  USER_INVITE_UPDATED_SUCCESSFULLY: "Invite updated successfully",
  USER_INVITE_UPDATED_ERROR: "Error while updating invite",

  USER_UPDATED_SUCCESSFULLY: "User updated successfully",
  USER_UPDATED_ERROR: "Error while updating user",

  INVITE_CANCELLED_SUCCESSFULLY: "Invite canceled successfully",
  INVITE_CANCELLED_ERROR: "Error while canceling invite",

  USER_DELETED_SUCCESSFULLY: "User deleted successfully",
  USER_DELETED_ERROR: "Error while deleting user",

  CONFIRM_INVITE_USER: "Invite created successfully",
};
