import { COMMON_MESSAGES } from "./common";

export const EDITOR_MESSAGES = {
  ...COMMON_MESSAGES,

  /**
   *
   *  TOGAL MODAL LOCALES
   *
   */
  TOGAL_MODAL_TITLE: "Will Togal wash my car?",

  TOGAL_MODAL_FIRST_HEADING:
    "Togal’s AI-assisted takeoff automation is able to work with:",
  TOGAL_MODAL_SECOND_HEADING: "With Togal.Chat you are able to:",

  TOGAL_MODAL_FIRST_ITEM: "architectural plans",
  TOGAL_MODAL_SECOND_ITEM:
    "structural, elevation or site plans (but we have great tools to do this manually)",
  TOGAL_MODAL_THIRD_ITEM:
    "talk to your construction plans and documents for the first time ever!",

  TOGAL_MODAL_FEEDBACK_TEXT:
    "We’re making constant improvements. Your feedback is welcome!",
  TOGAL_MODAL_BUTTON_TEXT: "Got it",

  /**
   *
   *  PATTERN SEARCH LOCALES
   *
   */

  PATTERN_SEARCH_NEW: "NEW!",
  PATTERN_SEARCH_DESCRIPTION: "Beta version of AI-driven Pattern Search.",
  PATTERN_SEARCH_READ_TIPS: "Read these tips",
  PATTERN_SEARCH_USAGE: "on what it's for and how to use it.",
};
