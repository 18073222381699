import { publicUrl } from "src/lib/utils";

export const fontFamily = [
  "Proxima Nova",
  "sans-serif",
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(",");

export const proximaNova = [
  {
    fontFamily: "Proxima Nova",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 100,
    src: `url(${publicUrl("/fonts/Proxima Nova Thin.otf")}) format('opentype')`,
  },
  {
    fontFamily: "Proxima Nova",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 400,
    src: `url(${publicUrl(
      "/fonts/ProximaNova-Regular.otf"
    )}) format('opentype')`,
  },
  {
    fontFamily: "Proxima Nova",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 600,
    src: `url(${publicUrl("/fonts/Proxima Nova Bold.otf")}) format('opentype')`,
  },
  {
    fontFamily: "Proxima Nova",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 700,
    src: `url(${publicUrl(
      "/fonts/Proxima Nova Black.otf"
    )}) format('opentype')`,
  },
  {
    fontFamily: "Proxima Nova",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 800,
    src: `url(${publicUrl(
      "/fonts/Proxima Nova Extrabold.otf"
    )}) format('opentype')`,
  },
];
