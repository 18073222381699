import rbush from "rbush";
import turfBBox from "@turf/bbox";
import { featureEach } from "@turf/meta";
import { featureCollection } from "@turf/helpers";
import { Feature } from "geojson";

function geojsonRbush(maxEntries?: number) {
  const tree = new rbush(maxEntries);

  tree.insert = function (feature: Feature) {
    if (feature.type !== "Feature") throw new Error("invalid feature");
    feature.bbox = feature.bbox ? feature.bbox : turfBBox(feature);
    return rbush.prototype.insert.call(this, feature);
  };

  tree.load = function (features: Feature[]) {
    const load: Feature[] = [];
    // Load an Array of Features
    if (Array.isArray(features)) {
      features.forEach(function (feature) {
        if (feature.type !== "Feature") throw new Error("invalid features");
        feature.bbox = feature.bbox ? feature.bbox : turfBBox(feature);
        load.push(feature);
      });
    } else {
      // Load a FeatureCollection
      featureEach(features, function (feature: Feature) {
        if (feature.type !== "Feature") throw new Error("invalid features");
        feature.bbox = feature.bbox ? feature.bbox : turfBBox(feature);
        load.push(feature);
      });
    }
    return rbush.prototype.load.call(this, load);
  };

  tree.remove = function (feature: Feature, equals) {
    if (feature.type !== "Feature") throw new Error("invalid feature");
    feature.bbox = feature.bbox ? feature.bbox : turfBBox(feature);
    return rbush.prototype.remove.call(this, feature, equals);
  };

  tree.clear = function () {
    return rbush.prototype.clear.call(this);
  };

  tree.search = function (geojson: Feature) {
    var features = rbush.prototype.search.call(this, this.toBBox(geojson));
    return featureCollection(features);
  } as any;

  tree.collides = function (geojson) {
    return rbush.prototype.collides.call(this, this.toBBox(geojson));
  };

  tree.all = function () {
    const features = rbush.prototype.all.call(this);
    return featureCollection(features);
  } as any;

  tree.toJSON = function () {
    return rbush.prototype.toJSON.call(this);
  };

  tree.fromJSON = function (json) {
    return rbush.prototype.fromJSON.call(this, json);
  };

  tree.toBBox = function (geojson: Feature) {
    let bbox;
    if (geojson.bbox) bbox = geojson.bbox;
    else if (Array.isArray(geojson) && geojson.length === 4) bbox = geojson;
    else if (Array.isArray(geojson) && geojson.length === 6)
      bbox = [geojson[0], geojson[1], geojson[3], geojson[4]];
    else if (geojson.type === "Feature") bbox = turfBBox(geojson);
    else if (geojson.type === "FeatureCollection") bbox = turfBBox(geojson);
    else throw new Error("invalid geojson");

    return {
      minX: bbox[0],
      minY: bbox[1],
      maxX: bbox[2],
      maxY: bbox[3],
    };
  };
  return tree;
}

export default geojsonRbush;
