import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  createContext,
} from "react";
import localforage from "localforage";
import { useSetRecoilState } from "recoil";

import { useRafLoop } from "sf/hooks";
import { SECOND } from "sf/consts/dateTime";

import * as C from "./const";
import * as IDB from "./localForageUtils";
import * as STORE from "src/state/dataStore";

import {
  HIDDEN_STORE_KEY,
  clearOldTempCache,
  EXPANDED_FOLDERS_STORE_KEY,
} from "src/state/utils";
import { useAuth } from "src/modules/Auth/context";

import { useSetsData } from "./hooks/setsData";
import { usePagesData } from "./hooks/pagesData";
import { useViewsData } from "./hooks/viewsData";
import { useProjectsData } from "./hooks/projectsData";
import { useOrganizationsData } from "./hooks/organizationsData";

const DataContext: any = createContext({});

export const useDataStore = () => useContext(DataContext);

export const DataManager = ({ children }) => {
  const [isPreparing, setIsPreparing] = useState(true);
  const { isLoadingAuth, isAuthenticated, isSuperAdmin } = (useAuth as any)();

  /** used for RAF loop */
  const lastTimeRef = useRef<number>(0);
  const pendingData = useRef<any[]>([]);
  const isRafRunning = useRef<boolean>(false);
  /** used for RAF loop */

  /** CRUD Stores */
  const setSetsCRUD = useSetRecoilState(STORE.setsCRUDStore);
  const setPagesCRUD = useSetRecoilState(STORE.pagesCRUDStore);
  const setViewsCRUD = useSetRecoilState(STORE.viewsCRUDStore);
  const setProjectsCRUD = useSetRecoilState(STORE.projectsCRUDStore);
  const setOrgsCRUD = useSetRecoilState(STORE.organizationsCRUDStore);
  /** CRUD Stores */

  /** DATA Hooks  */
  const setsD = useSetsData(pendingData);
  const pagesD = usePagesData(pendingData);
  const viewsD = useViewsData(pendingData);
  const projectsD = useProjectsData(pendingData);
  const organizationsD = useOrganizationsData(pendingData);
  /** DATA Hooks  */

  const setStoreCRUDMap = new Map<string, any>([
    [C.setKey, setSetsCRUD],
    [C.pageKey, setPagesCRUD],
    [C.viewKey, setViewsCRUD],
    [C.projectKey, setProjectsCRUD],
    [C.organizationKey, setOrgsCRUD],
  ]);

  useRafLoop(
    (time) => {
      const targetStep = SECOND / 20;
      const lastStep = lastTimeRef.current;

      const step = time - lastStep;

      if (step >= targetStep) {
        lastTimeRef.current = time;
        if (!isRafRunning.current && pendingData.current?.length) {
          isRafRunning.current = true;
          Object.freeze(pendingData.current);
          const actionItems = pendingData.current;
          pendingData.current = [];

          actionItems.forEach((actionItem) => {
            const { dataKey, action, filterValue, data } = actionItem;

            const setStoreCRUD = setStoreCRUDMap.get(dataKey);
            const filterKey = C.defaultFilterKeysMap.get(dataKey);
            const searchProps = C.defaultSearchPropsMap.get(dataKey);

            setStoreCRUD({
              action,
              filterKey,
              searchProps,
              items: data,
              filterValue,
            });
          });

          isRafRunning.current = false;
        }
      }
    },
    true,
    [
      setOrgsCRUD,
      setSetsCRUD,
      setPagesCRUD,
      setViewsCRUD,
      setProjectsCRUD,
      setStoreCRUDMap,
    ]
  );

  async function resetProject(project_id: string, set_id: string) {
    await IDB.clearAllLocalSets({
      project_id,
    });

    await IDB.clearAllLocalPages({
      set_id,
    });

    await IDB.clearAllLocalViews({
      set_id,
    });

    setsD.clearSets();
    viewsD.clearViews();
    pagesD.clearPages();
  }

  useEffect(() => {
    async function checkClearCache() {
      const lastSyncedGlobal = await IDB.getGlobalSync();

      if (!lastSyncedGlobal) {
        await resetDataStore();
        IDB.setGlobalSync();
      } else {
        const daysDiff = Math.floor(
          // @ts-ignore
          (new Date() - new Date(lastSyncedGlobal)) / (1000 * 60 * 60 * 24)
        );

        if (daysDiff > 6) {
          await resetDataStore();
          IDB.setGlobalSync();
          return;
        }
      }
    }

    checkClearCache();
    clearOldTempCache(HIDDEN_STORE_KEY);
    clearOldTempCache(EXPANDED_FOLDERS_STORE_KEY);
  }, []);

  useEffect(() => {
    if (isAuthenticated && !isLoadingAuth && isPreparing) {
      initDataStore();
    }
  }, [isAuthenticated, isLoadingAuth, isPreparing]);

  async function resetDataStore() {
    await localforage.clear();

    await viewsD.clearViews();
    await pagesD.clearPages();
    await setsD.clearSets();

    await projectsD.clearProjects();
    await organizationsD.clearOrganizations();

    initDataStore();
  }

  function initDataStore() {
    setIsPreparing(false);

    if (isSuperAdmin) {
      organizationsD.getOrganizations();
    }
  }

  const value = useMemo(
    () => ({
      resetProject,
      resetDataStore,

      ...setsD,
      ...pagesD,
      ...viewsD,
      ...projectsD,
      ...organizationsD,
    }),
    [
      resetProject,
      resetDataStore,
      setsD,
      pagesD,
      viewsD,
      projectsD,
      organizationsD,
    ]
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
