import request from "../request";

export async function list(query) {
  const { body } = await request.get("notification", { query });
  return body;
}

export async function markAsRead(id) {
  const { body } = await request.put(`notification/${id}`, {
    body: { read: true },
  });
  return body;
}

export async function markAllAsRead(user_id, organization_id) {
  const { body } = await request.put(`notification`, {
    body: { read: true },
    query: {
      $where: {
        read: false,
        user_id,
        organization_id,
      },
    },
  });
  return body;
}
