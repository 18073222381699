import React from "react";
import PermissionChecker from "./components/PermissionChecker";

function Permissions() {
  return (
    <>
      <PermissionChecker name="clipboard-read" />
    </>
  );
}

export default Permissions;
