/* eslint-disable camelcase */
import { doInfiniteRequest } from "src/constants/request";
import request from "../request";
import { http } from "src/constants/http";
import {
  withGlobalOrg,
  withCurrentOrgUrl,
  withCurrentOrgQuery,
} from "./_helpers";

export async function create(data) {
  try {
    const { body } = await request.post("user", {
      body: data,
    });
    return body;
  } catch (e) {
    if (e.status === http.CONFLICT) {
      e.message = `Can't create user for email "${data.email}". A user for this e-mail address already exists.`;
    }
    throw e;
  }
}

export async function getRegisterInfoFromToken({ token }) {
  const { body } = await request.get(`user?token=${token}`);
  return body;
}

export async function list(query) {
  const { body } = await request.get("user", {
    query: withCurrentOrgQuery(query),
  });

  return body;
}

export async function listAll(query) {
  return await doInfiniteRequest("user", withCurrentOrgQuery(query));
}

export async function get({ id }) {
  const { body } = await request.get(withCurrentOrgUrl(`user/${id}`));
  return body;
}

export async function getUsage() {
  const { body } = await request.get("user/usage-stats");
  return body;
}

export async function update({ id, ...rest }) {
  try {
    const { body } = await request.put(withCurrentOrgUrl(`user/${id}`), {
      body: rest,
    });
    return body;
  } catch (e) {
    if (e.status === http.CONFLICT) {
      e.message = `Can't change user email to "${rest.email}". A user or invite for this e-mail address already exists.`;
    }
    throw e;
  }
}

export async function remove({ id }) {
  const { body } = await request.delete(withCurrentOrgUrl(`user/${id}`));
  return body;
}

export async function removeFromUserAndReassign({ id, reassignToId }) {
  const { body } = await request.delete(
    withCurrentOrgUrl(`user/${id}?reassign_to=${reassignToId}`)
  );
  return body;
}

export async function verify({ id }) {
  const { body } = await request.put(`user/${id}/verify`);
  return body;
}

export async function verifyEmail({ id, verification }) {
  const { body } = await request.put(`user/${id}/verify`, {
    body: { verification },
  });
  return body;
}

export async function resendVerifyEmail({ id }) {
  const { body } = await request.get(withCurrentOrgUrl(`user/${id}/verify`));
  return body;
}

export async function resetToken({ email }) {
  const { body } = await request.delete(`user/${email}/reset`);
  return body;
}

export async function validateToken({ email, reset, password }) {
  const { body } = await request.put(`user/${email}/reset`, {
    body: {
      reset,
      password,
    },
  });
  return body;
}

export async function inviteUser({ email, role, set_id, organization_id }) {
  const { body } = await request.post(`invite`, {
    body: {
      role,
      email,
      set_id,
      organization_id,
    },
  });
  return body;
}

export async function getSingleUserInvite(id) {
  const { body } = await request.get(`invite/${id}`);
  return body;
}

export async function getUserInvite(email, id = null) {
  const { body } = await request.get(`invite`, {
    query: {
      $where: {
        email,
        ...(id ? { id } : {}),
      },
    },
  });
  return body;
}

export async function getSetInvites(set_id) {
  const { body } = await request.get(`invite`, {
    query: {
      $where: {
        set_id,
      },
    },
  });
  return body;
}

export async function acceptInvite(id) {
  const { body } = await request.post(`invite/${id}/accept`);
  return body;
}

export async function deleteInvite(id) {
  const { body } = await request.delete(`invite/${id}`);
  return body;
}

export async function updateInvite({ id, ...values }) {
  const { body } = await request.put(`invite/${id}`, {
    body: {
      ...values,
    },
  });
  return body;
}

export async function getProjectsWithSets({ id, ...values }) {
  const { body } = await request.get(
    `user/${id}/external_shared_info/${withGlobalOrg(values)?.organization_id}`
  );
  return body;
}
