import merge from "lodash/merge";
import { CSS } from "sf/consts/style";
import lightTheme from "./light";

export default merge({}, lightTheme, {
  palette: {
    type: "dark",
    background: {
      default: CSS.MAINCOLOR,
      gradient: "linear-gradient(#0c4b28 0%, #121c17 100%)",
    },
    primary: {
      main: CSS.MAINCOLOR,
    },
    secondary: {
      main: CSS.WHITE,
      contrastText: CSS.BLACK,
    },
  },
});
