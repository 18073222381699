"use strict";
/**   COPY PASTED from TURF    */

import bbox from "@turf/bbox";
import square from "@turf/square";
import truncate from "@turf/truncate";
import lineSegment from "@turf/line-segment";
import nearestPointOnLine from "@turf/nearest-point-on-line";

import { lineString, featureCollection } from "@turf/helpers";
import { getType, getCoords, getCoord } from "@turf/invariant";
import { flattenEach, featureEach, featureReduce } from "@turf/meta";

import geojsonRbush from "./geojson-rbush";
import lineIntersect from "./line-intersect";

function lineSplit(line: any, splitter: any) {
  if (!line) throw new Error("line is required");
  if (!splitter) throw new Error("splitter is required");

  var lineType = getType(line);
  var splitterType = getType(splitter);

  if (lineType !== "LineString") throw new Error("line must be LineString");
  if (splitterType === "FeatureCollection")
    throw new Error("splitter cannot be a FeatureCollection");
  if (splitterType === "GeometryCollection")
    throw new Error("splitter cannot be a GeometryCollection");

  var truncatedSplitter = truncate(splitter, {
    precision: 7,
  });

  switch (splitterType) {
    case "Point":
      return splitLineWithPoint(line, truncatedSplitter);
    case "MultiPoint":
      return splitLineWithPoints(line, truncatedSplitter);
    case "LineString":
    case "MultiLineString":
    case "Polygon":
    case "MultiPolygon":
      return splitLineWithPoints(line, lineIntersect(line, truncatedSplitter));
  }
}

function splitLineWithPoints(line: any, splitter: any) {
  var results: any = [];
  var tree: any = geojsonRbush();

  flattenEach(splitter, function (point: any) {
    results.forEach(function (feature: any, index: any) {
      feature.id = index;
    });
    if (!results.length) {
      results = splitLineWithPoint(line, point).features;
      results.forEach(function (feature: any) {
        if (!feature.bbox) feature.bbox = square(bbox(feature));
      });
      tree.load(featureCollection(results));
    } else {
      var search = tree.search(point);

      if (search.features.length) {
        var closestLine = findClosestFeature(point, search);
        results = results.filter(function (feature: any) {
          return feature.id !== closestLine.id;
        });
        tree.remove(closestLine);

        featureEach(
          splitLineWithPoint(closestLine, point),
          function (line: any) {
            results.push(line);
            tree.insert(line);
          }
        );
      }
    }
  });
  return featureCollection(results);
}

function splitLineWithPoint(line: any, splitter: any) {
  var results = [];

  var startPoint = getCoords(line)[0];
  var endPoint = getCoords(line)[line.geometry.coordinates.length - 1];
  if (
    pointsEquals(startPoint, getCoord(splitter)) ||
    pointsEquals(endPoint, getCoord(splitter))
  )
    return featureCollection([line]);

  var tree: any = geojsonRbush();
  var segments = lineSegment(line);
  tree.load(segments);

  var search = tree.search(splitter);

  if (!search.features.length) return featureCollection([line]);

  var closestSegment = findClosestFeature(splitter, search);

  var initialValue = [startPoint];
  var lastCoords = featureReduce(
    segments,
    function (previous: any, current: any, index: any) {
      var currentCoords = getCoords(current)[1];
      var splitterCoords = getCoord(splitter);

      if (index === closestSegment.id) {
        previous.push(splitterCoords);
        results.push(lineString(previous));
        if (pointsEquals(splitterCoords, currentCoords))
          return [splitterCoords];
        return [splitterCoords, currentCoords];
      } else {
        previous.push(currentCoords);
        return previous;
      }
    },
    initialValue
  );
  if (lastCoords.length > 1) {
    results.push(lineString(lastCoords));
  }
  return featureCollection(results);
}

function findClosestFeature(point: any, lines: any) {
  if (!lines.features.length) throw new Error("lines must contain features");

  if (lines.features.length === 1) return lines.features[0];

  var closestFeature;
  var closestDistance = Infinity;
  featureEach(lines, function (segment: any) {
    var pt = nearestPointOnLine(segment, point);
    var dist = pt.properties.dist;
    if (dist < closestDistance) {
      closestFeature = segment;
      closestDistance = dist;
    }
  });
  return closestFeature;
}

function pointsEquals(pt1: any, pt2: any) {
  return pt1[0] === pt2[0] && pt1[1] === pt2[1];
}

export default lineSplit;
