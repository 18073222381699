export const CANVAS = {
  MODE_CHANGE_BUTTON: "modechange",
  INTERACTIVE_MODE_CHANGE_BUTTON: "interactivemodechange",

  POLYGON_MODE_CHANGE: "CANVAS_POLYGON_MODE_CHANGE",
  INTERACTIVE_MODE_CHANGE: "CANVAS_INTERACTIVE_MODE_CHANGE",
};

export const USER = {
  ADD: {
    CLASSIFICATION: "USER_ADD_CLASSIFICATION",
  },
  AUTH: {
    REGISTERED: "USER_REGISTERED",
    PLAN_CHANGE: "USER_PLAN_CHANGE",
    PAYMENT_ADDED: "USER_PAYMENT_ADDED",
  },
  QUANTITY_PANEL: {
    ADD_FOLDER: "ADD_FOLDER",
    EDIT_FOLDER: "EDIT_FOLDER",
    SWICTHES_LIBRARY: "SWICTHES_LIBRARY",
    USER_DROPS_TO_QP: "USER_DROPS_TO_QP",
    OPEN_CONTEXT_MENU: "OPEN_CONTEXT_MENU",
    ADD_CLASSIFICATION: "ADD_CLASSIFICATION",
    EDIT_CLASSIFICATION: "EDIT_CLASSIFICATION",
    USER_SWITCHES_HIDDEN: "USER_SWITCHES_HIDDEN",
    SWITCH_GROUP_EXPANDED: "SWITCH_GROUP_EXPANDED",
    SWICTHES_TAKEOFF_FILTER: "SWICTHES_TAKEOFF_FILTER",
    USER_SWITCHES_SELECTION: "USER_SWITCHES_SELECTION",
    DELETE_FEATURES_FROM_QP: "DELETE_FEATURES_FROM_QP",
    SWICTHES_TAKEOFF_FILTER_ALL: "SWICTHES_TAKEOFF_FILTER_ALL",
    SWICTHES_TAKEOFF_FILTER_AREA: "SWICTHES_TAKEOFF_FILTER_AREA",
    SWICTHES_TAKEOFF_FILTER_LINE: "SWICTHES_TAKEOFF_FILTER_LINE",
    USER_STARTS_DRAGGING_FROM_QP: "USER_STARTS_DRAGGING_FROM_QP",
    SWICTHES_TAKEOFF_FILTER_COUNT: "SWICTHES_TAKEOFF_FILTER_COUNT",
    USER_SWITCHES_ACTIVE_CLASSIFICATION: "USER_SWITCHES_ACTIVE_CLASSIFICATION",
  },
  ADD_SET: "USER_ADD_SET",
  ADDED_VECTORS: "USER_ADDED_VECTORS",
  ARCHIVE_PROJECT: "USER_ARCHIVE_PROJECT",
  AUTO: {
    CLASSIFY: "USER_AUTO_CLASSIFY",
    RENAME_VECTOR: "USER_AUTO_RENAME_VECTOR",
  },
  AUTOMATIC: {
    ALIGN: "USER_AUTOMATIC_ALIGN",
  },
  AUTONAMING_TOOL: {
    APPLY: "USER_AUTONAMING_APPLY",
    RUN: "USER_RUN_AUTONAMING_TOOL",
    POINT_TITLE: "USER_AUTONAMING_POINT_TITLE",
    POINT_NUMBER: "USER_AUTONAMING_POINT_NUMBER",
  },
  BREAKDOWN: {
    OPEN_DIALOG: "USER_OPEN_BREAKDOWN_DIALOG",
    SAVE: "USER_SAVE_BREAKDOWN",
  },
  CLASSIFICATIONS: { OPEN_DIALOG: "USER_OPEN_CLASSIFICATIONS_DIALOG" },
  CREATE_PROJECT: "USER_CREATE_PROJECT",
  CHANGED_VECTORS_TO_REVIEW: "CHANGED_VECTORS_TO_REVIEW",
  COPY_EVENTS: "USER_COPY_FEATURES",
  DELETE_SET: "USER_DELETE_SET",
  DELETE_VECTOR: "USER_DELETE_VECTOR",
  DELETE_VECTORS: "USER_DELETE_VECTORS",
  DELETE_PROJECT: "USER_DELETE_PROJECT",
  DUPLICATE_FEATURES: "USER_DUPLICATE_FEATURES",
  DUPLICATE_PROJECT: "USER_DUPLICATE_PROJECT",
  DUPLICATE_VECTOR: "USER_DUPLICATE_VECTOR",
  DUPLICATE_SET: "USER_DUPLICATE_SET",
  EDIT_FEATURES: "USER_EDIT_FEATURES",
  FLIP_X_FEATURES: "USER_FLIP_X_FEATURES",
  FLIP_Y_FEATURES: "USER_FLIP_Y_FEATURES",
  MERGE_FEATURES: "USER_MERGE_FEATURES",
  MOVE_VECTORS_TO_SET: "USER_MOVE_VECTORS_TO_SET",
  NAVIGATION: {
    DETAIL_PAGE: "NAVIGATION_PROJECT_DETAIL_PAGE",
  },
  OPEN_EDITOR: "USER_OPEN_EDITOR",
  RESET: {
    TRANSFORMS: "USER_RESET_TRANSFORMS",
  },
  PASTE_FEATURES: "USER_PASTE_FEATURES",
  QUANTITIES: {
    OPEN_DIALOG: "USER_OPEN_QUANTITIES_DIALOG",
  },
  RENAME_SET: "USER_RENAME_SET",
  RENAME_VECTOR: "USER_RENAME_VECTOR",
  ROTATE_FEATURES: "USER_ROTATE_FEATURES",
  ROTATE_VECTOR: "USER_ROTATE_VECTOR",
  TOOLBAR: {
    COMBINE: "USER_TOOLBAR",
    DUPLICATE: "USER_TOOLBAR",
    FLIPX: "USER_TOOLBAR",
    FLIPY: "USER_TOOLBAR",
    GROUP: "USER_TOOLBAR",
    ROTATE: "USER_TOOLBAR",
    UNGROUP: "USER_TOOLBAR",
  },
  UPLOAD: {
    LAUNCH: "USER_LAUNCH_UPLOAD_DRAWING",
    UPLOAD: "USER_MODAL_UPLOAD_DRAWING",
  },
  UPLOADED_FILE: "USER_UPLOADED_FILE",
  TOGAL_RATING: {
    POOR: "USER_TOGAL_RATING_POOR",
    OKAY: "USER_TOGAL_RATING_OKAY",
    GREAT: "USER_TOGAL_RATING_GREAT",
    SENT: "USER_SENT_RATING",
  },
  EXPORT: {
    EXCEL: "USER_EXPORT_EXCEL",
    OPEN_DIALOG: "USER_OPEN_EXPORT_DIALOG",
    SELECT_PAGES: "USER_EXPORT_SELECT_PAGES",
    SELECT_GROUP: "USER_EXPORT_SELECT_GROUP",
    SELECT_FILTER: "USER_EXPORT_SELECT_FILTER",
    SELECT_COLUMNS: "USER_EXPORT_SELECT_COLUMNS",
  },
  SET_LOCK: {
    DISPLAY: "USER_SET_LOCK_DISPLAY",
  },
  ORG: {
    SWITCH: "USER_SWITCH_ORGANIZATIONS",
  },
  TOGAL_GPT: {
    CLICKED: "CLICKED_TOGALGPT",
    LAUNCHED: "LAUNCHED_TOGALGPT",
    PROMPT: "USER_TOGALGPT",
    DRAWING: "USER_TOGALGPT_DRAWING",
    SET: "USER_TOGALGPT_SET",
    PROJECT: "USER_TOGALGPT_PROJECT",
    PROJECTS: "USER_TOGALGPT_PROJECTS",
    RATE: "USER_TOGALGPT_RATE",
    RATE_UP: "USER_TOGALGPT_RATE_UP",
    RATE_DOWN: "USER_TOGALGPT_RATE_DOWN",
  },
  EDITOR_SEARCH: {
    CLICKED: "CLICKED_SEARCH_INDRAWING",
    TEXT: "USER_SEARCH_INDRAWING_TEXT",
    IMAGE: "USER_SEARCH_INDRAWING_IMAGE",
    PATTERN: "USER_SEARCH_INDRAWING_PATTERN",
  },
  FIRST_TEXT_USED: "FIRST_TEXT_USED",
  FIRST_EXCEL_USED: "FIRST_EXCEL_USED",
  FIRST_PROJECT_LANDING: "FIRST_PROJECT_LANDING",
  FIRST_AUTONAMING_USED: "FIRST_AUTONAMING_USED",
  FIRST_CLASSIFICATION_USED: "FIRST_CLASSIFICATION_USED",
  FIRST_TOGAL_USED: "FIRST_TOGAL_USED",
} as const;
