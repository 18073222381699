import { useEffect } from "react";
import { useInfiniteQuery } from "react-query";

import request from "src/lib/request";
import { omit } from "sf/utils/functional/object";
import { curry } from "sf/utils/functional/function";

export function useUpdatedInfiniteQuery(queryKey, queryFn, queryConfig) {
  const query = useInfiniteQuery(queryKey, queryFn, {
    getNextPageParam: (last) => {
      return last?.next_page;
    },
    ...(queryConfig || {}),
  });

  const { fetchNextPage, isFetchingNextPage, hasNextPage } = query;

  useEffect(() => {
    if (!isFetchingNextPage && hasNextPage) fetchNextPage();
  }, [fetchNextPage, isFetchingNextPage, hasNextPage]);

  return query;
}

export const getInfiniteQueryData = (request) =>
  (request?.data?.pages || [])
    .map((g) => g?.rows || null)
    .flat()
    .filter(Boolean);

export const extractChildren = (list, globalAcc = []) =>
  list.reduce((acc, entry) => {
    if (entry?.children?.length) {
      extractChildren(entry.children, acc);
    }

    acc.push(omit("children", entry));

    return acc;
  }, globalAcc);

export const exportRow = curry(
  (row, isCopy, { key, renderer, exportRenderer, copyRenderer }, index) => {
    const preferredRenderer =
      isCopy && copyRenderer ? copyRenderer : exportRenderer;

    const value = preferredRenderer
      ? preferredRenderer(row[key], row, index)
      : renderer
      ? renderer(row[key], row)
      : row[key];

    return Number(value) || value;
  }
);

export async function doInfiniteRequest(path, query) {
  let totalResult;

  let requestResult = await request.get(path, {
    query,
  });

  totalResult = requestResult.body;

  while (requestResult?.body?.next_page) {
    const { limit, offset } = requestResult?.body.next_page;

    const newQuery = {
      ...query,
      $offset: offset,
      $limit: limit,
    };

    requestResult = await request.get(path, {
      query: newQuery,
    });

    totalResult.rows = [...totalResult.rows, ...requestResult.body.rows];
  }
  return totalResult;
}
