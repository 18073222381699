import React from "react";

import * as P from "./props";
import * as S from "./styles";
import { CSS } from "../../../consts/style";

const Spinner = ({ color = CSS.GRAY6, size = "20px" }: P.SpinnerProps) => {
  return <S.SpinnerWrapper color={color} size={size} />;
};

Spinner.displayName = "Spinner";

export default Spinner;
