export const LABELLER_ROLE = "user";
export const LABELLING_ORG = "labeling";

export const DRAWING_STATUS_DONE = "done"; // status set by laber to flag drawing as done
export const DRAWING_STATUS_NOT_DONE = "undone"; // status set by labeler to reject a drawing, still needs to be rejected by a reviewer
export const DRAWING_STATUS_NOT_STARTED = "none"; // default inital status of a drawing
export const DRAWING_STATUS_REJECTED = "rejected"; // status rejected after validation from a reviewer
export const DRAWING_STATUS_REVIEWED = "accepted"; // status accepted after a validation from a reviewer

export const DRAWING_STATUS_COLORS = {
  [DRAWING_STATUS_DONE]: "yellow",
  [DRAWING_STATUS_REJECTED]: "red",
  [DRAWING_STATUS_REVIEWED]: "green",
  [DRAWING_STATUS_NOT_DONE]: "orange",
  [DRAWING_STATUS_NOT_STARTED]: "grey",
};
