import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

import * as userApi from "src/lib/api/user";

import { fetchOrgUsersAndInvites, useAuth } from "src/modules/Auth/context";

export default function AcceptInvite() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user, setPendingInvites, setSelectedOrg, setUser } = useAuth();

  async function getInvitation(id) {
    try {
      const res = await userApi.getSingleUserInvite(id);
      return res;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async function handleAcceptInvite(inviteId) {
    const invite = await getInvitation(inviteId);
    try {
      await userApi.acceptInvite(inviteId);

      if (invite?.organization_id) {
        setUser({
          ...user,
          organization_ids: [
            ...new Set([
              ...(user?.organization_ids || []),
              invite.organization_id,
            ]),
          ],
          organizations: [
            ...(user?.organizations || []),
            invite?.organization || {},
          ].filter(Boolean),
        });

        const orgUsersAndInvites = await fetchOrgUsersAndInvites(
          invite?.organization_id
        );

        setSelectedOrg({
          ...(invite?.organization || {}),
          id: invite?.organization_id,
          users: orgUsersAndInvites || [],
        });
      }

      setPendingInvites((prev) =>
        prev.filter((prevInvite) => prevInvite.id !== inviteId)
      );
    } catch (e) {
      console.error(e);
    }

    navigate("/dashboard/projects");
    window.location.reload();
  }

  useEffect(() => {
    if (pathname.includes("accept_invite")) {
      const inviteId = pathname.split("/").pop();
      if (inviteId) {
        if (inviteId) {
          handleAcceptInvite(inviteId);
        }
      }
    }
  }, [pathname]);

  return null;
}
