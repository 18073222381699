import bbox from "@turf/bbox";
import kinks from "@turf/kinks";
import turfDistance from "@turf/distance";
import { getCoord } from "@turf/invariant";
import turfIntersect from "@turf/intersect";
import turfTransformScale from "@turf/transform-scale";
import { Feature, Point, Polygon, Position } from "geojson";

import { RGBA_COLORS } from "../../consts/style";
import { RECT_SHAPE } from "../base/GeojsonEditMode";
import { divide } from "../../utils/functional/math";
import { generateId, prettyInches } from "../../utils/string";
import ImmutableLayersData from "../base/ImmutableLayersData";
import { GEOJSON_TYPES, HOLE_TYPE } from "../../consts/editor";

import {
  MODE_SPHERE,
  MODE_RECTANGLE,
  ARC_MODE_CIRCLE,
  DEFAULT_PADDING,
} from "../../consts/coordinates";
import {
  getArcFunction,
  updateGeometryWithPadding,
  calculateDistanceForTooltip,
} from "../../utils/coordinates";
import {
  getPickedEditHandle,
  getPickedIntermediateEditHandle,
} from "../../utils/modes";
import {
  prepareIntermediateHandleDraggingData,
  calculateTranslationDataForDefaultMode,
  calculateTranslationDataForLinePreserve,
} from "./utils";

export function handleRemovePosition(
  props: any,
  pickedExistingHandle: any,
  isMetaKey?: boolean
) {
  let updatedData;
  const view = props.modeConfig.view;
  const geoJson = props.data.features;
  const { featureIndex, positionIndexes } = pickedExistingHandle.properties;

  try {
    if (pickedExistingHandle?.properties?.arcIds?.length > 0) {
      updatedData = new ImmutableLayersData(geoJson)
        .removeArc(
          props.data.features[featureIndex].properties.id,
          positionIndexes,
          view,
          props?.modeConfig?.colorMap,
          !isMetaKey
        )
        .getLayers();
    } else {
      updatedData = new ImmutableLayersData(geoJson)
        .removePosition(
          props.data.features[featureIndex].properties.id,
          positionIndexes,
          view,
          props?.modeConfig?.colorMap
        )
        .getLayers();
    }
  } catch (ignored) {
    console.error("An error occurred while updating geometry");
    // happens if user attempts to remove the last point
  }

  let [updatedFeatures, staticFeatures, staticFeaturesIds] =
    updateParentFeatures(updatedData, props.modeConfig.staticData);
  if (!updatedFeatures) {
    return;
  }

  if (updatedFeatures) {
    props.onEdit({
      updatedData: {
        type: GEOJSON_TYPES.FeatureCollection,
        features: updatedData,
      },
      editType: "removePosition",
      editContext: {
        staticFeatures,
        positionIndexes,
        staticFeaturesIds,
        position: pickedExistingHandle.geometry.coordinates,
        featureIds: [props.data.features[featureIndex].properties.id],
      },
    });
  }
}

export function handleAddPosition(props: any, pickedIntermediateHandle: any) {
  const view = props.modeConfig.view;
  const geoJson = props.data.features;

  const { featureIndex, positionIndexes } = pickedIntermediateHandle.properties;

  const updatedData = new ImmutableLayersData(geoJson)
    .addPosition(
      props.data.features[featureIndex].properties.id,
      positionIndexes,
      pickedIntermediateHandle.geometry.coordinates,
      view,
      props?.modeConfig?.colorMap
    )
    .getLayers();

  let [updatedFeatures, staticFeatures, staticFeaturesIds] =
    updateParentFeatures(updatedData, props.modeConfig.staticData);
  if (!updatedFeatures) {
    return;
  }

  if (updatedFeatures) {
    props.onEdit({
      updatedData: {
        type: GEOJSON_TYPES.FeatureCollection,
        features: updatedData,
      },
      editType: "addPosition",
      editContext: {
        featureIds: [updatedData[featureIndex].properties.id],
        staticFeatures,
        positionIndexes,
        staticFeaturesIds,
        position: pickedIntermediateHandle.geometry.coordinates,
      },
    });
  }
}

function getOppositeScaleHandle(context: any) {
  const selectedHandle = context._selectedEditHandle;
  const selectedHandleIndex =
    selectedHandle &&
    selectedHandle.properties &&
    Array.isArray(selectedHandle.properties.positionIndexes) &&
    selectedHandle.properties.positionIndexes[0];

  if (typeof selectedHandleIndex !== "number") {
    return null;
  }
  const guidePointCount = context._cornerGuidePointsB.length;
  const oppositeIndex =
    (selectedHandleIndex + guidePointCount / 2) % guidePointCount;

  const oppositeIndexCoords = context._cornerGuidePointsB.find((p: any) => {
    if (!Array.isArray(p.properties.positionIndexes)) {
      return false;
    }
    return p.properties.positionIndexes[0] === oppositeIndex;
  });

  return oppositeIndexCoords;
}

function getScaleFactor(
  centroid: Position,
  startDragPoint: Position,
  currentPoint: Position
): number {
  const divideByPadding = divide(DEFAULT_PADDING);
  centroid = centroid.map(divideByPadding);
  startDragPoint = startDragPoint.map(divideByPadding);
  currentPoint = currentPoint.map(divideByPadding);

  const startDistance = turfDistance(centroid, startDragPoint);
  const endDistance = turfDistance(centroid, currentPoint);
  return endDistance / startDistance;
}

function getScaleFactors(
  origin: Position,
  startDragPoint: Position,
  currentPoint: Position
): [number, number] {
  const divideByPadding = divide(DEFAULT_PADDING);
  const [originX, originY]: any = origin.map(divideByPadding);

  startDragPoint = startDragPoint.map(divideByPadding);
  currentPoint = currentPoint.map(divideByPadding);

  const [startDragPointX, startDragPointY] = startDragPoint;
  const [currentPointX, currentPointY] = currentPoint;

  return [
    (currentPointX - originX) / (startDragPointX - originX),
    (currentPointY - originY) / (startDragPointY - originY),
  ];
}

function transformPositionScales(
  coord: Position,
  scaleFactorX: number,
  scaleFactorY: number,
  origin: Position
): Position {
  const [x, y] = coord;
  const [originX, originY] = origin;

  const deltaX = (x - originX) * scaleFactorX;
  const deltaY = (y - originY) * scaleFactorY;

  return [originX + deltaX, originY + deltaY];
}

function transformGeometryScales(
  geometry: any,
  scaleFactorX: number,
  scaleFactorY: number,
  origin: Position
): any {
  if (!Array.isArray(geometry)) return;

  if (
    geometry.length === 2 &&
    typeof geometry[0] === "number" &&
    typeof geometry[1] === "number"
  ) {
    return transformPositionScales(
      geometry,
      scaleFactorX,
      scaleFactorY,
      origin
    );
  }

  return geometry.map((entry, i) => {
    return transformGeometryScales(entry, scaleFactorX, scaleFactorY, origin);
  });
}

function transformScales(
  feature: any,
  scaleFactorX: number,
  scaleFactorY: number,
  origin: Position
): any {
  return {
    type: feature.type,
    properties: feature.properties,
    geometry: {
      type: feature.geometry.type,
      coordinates: transformGeometryScales(
        feature.geometry.coordinates,
        scaleFactorX,
        scaleFactorY,
        origin
      ),
    },
  };
}

export function getScaleAction(event: any, context: any) {
  if (!context._selectedEditHandle) {
    return null;
  }

  const startDragPoint = event.pointerDownMapCoords;
  const currentPoint = event.mapCoords;

  const oppositeHandle = getOppositeScaleHandle(context);
  let origin = getCoord(oppositeHandle);
  const scaleFactor = getScaleFactor(origin, startDragPoint, currentPoint);

  const padding = DEFAULT_PADDING;
  origin = origin.map(divide(padding));

  const scaledDown: any =
    context._geometryBeingScaled && context._geometryBeingScaled.features
      ? context._geometryBeingScaled.features.map(
          updateGeometryWithPadding(padding)
        )
      : null;

  const scaledFeature = turfTransformScale(scaledDown[0], scaleFactor, {
    origin,
  });

  return updateGeometryWithPadding(1 / padding, scaledFeature);
}

export function getResizeAction(event: any, context: any) {
  if (!context._selectedEditHandle) {
    return null;
  }

  const startDragPoint = event.pointerDownMapCoords;
  const currentPoint = event.mapCoords;

  const oppositeHandle = getOppositeScaleHandle(context);
  let origin = getCoord(oppositeHandle);
  const [scaleFactorX, scaleFactorY] = getScaleFactors(
    origin,
    startDragPoint,
    currentPoint
  );

  origin = origin.map(divide(DEFAULT_PADDING));

  const scaledDown: any =
    context._geometryBeingScaled && context._geometryBeingScaled.features
      ? context._geometryBeingScaled.features.map(
          updateGeometryWithPadding(DEFAULT_PADDING)
        )
      : null;

  const scaledFeature = transformScales(
    scaledDown[0],
    scaleFactorX,
    scaleFactorY,
    origin
  );

  return updateGeometryWithPadding(1 / DEFAULT_PADDING, scaledFeature);
}

export function initDragging(event: any, props: any, context: any) {
  const selectedFeatureIndexes = props.selectedIndexes;
  const editHandle: any = getPickedEditHandle(event.picks);
  const geometry = context.getSelectedFeaturesAsFeatureCollection(props);
  const intermediateHandle = getPickedIntermediateEditHandle(event.picks);

  if (selectedFeatureIndexes.length > 0) {
    context._arcs = getFeaturesArcs(
      props.data.features,
      selectedFeatureIndexes
    );
  }

  if (editHandle) {
    if (context.indexedTargets) {
      context._closestPoints = context.indexedTargets.within(
        editHandle.geometry.coordinates[0],
        editHandle.geometry.coordinates[1],
        20,
        20
      );
    }

    const shape = props.data.features[editHandle.properties.featureIndex];
    context._dragginFeature = shape;
    if (shape.properties.types.includes(RECT_SHAPE)) {
      context._isRectTranslateMode = true;
      context._rectShapeBeforeEdit = shape;
    }
  }

  if (context._isMarkupMode && editHandle) {
    context._isScaling = true;
    context._geometryBeingScaled =
      context.getSelectedFeaturesAsFeatureCollection(props);

    return;
  }

  if (
    !intermediateHandle &&
    !editHandle &&
    event.picks.length &&
    selectedFeatureIndexes.includes(event.picks[0].index)
  ) {
    context._geometryBeforeTranslate = geometry;
    context._isGeometryTranslate = true;
  }

  if (selectedFeatureIndexes.length && intermediateHandle) {
    prepareIntermediateHandleDraggingData(
      event,
      intermediateHandle,
      props.data.features,
      event.sourceEvent.altKey || event.sourceEvent.metaKey
    );

    context._geometryBeforeTranslate = intermediateHandle;
    context._isEdgeTranslate = true;
  }
}

export function handleDragAsARectangle(event: any, props: any, context: any) {
  const geoJson = props.data.features;
  const editHandle = getPickedEditHandle(event.pointerDownPicks);

  const handleIndex = editHandle.properties.positionIndexes[1];
  const rectCoordinatesBefore =
    context._rectShapeBeforeEdit.geometry.coordinates[0];

  const newCoordinates = [
    context._rectShapeBeforeEdit.geometry.coordinates[0].map(
      (currentCoordinate: any, idx: number) => {
        if (idx === handleIndex) {
          return event.mapCoords;
        } else if (idx === 4 && handleIndex === 0) {
          return event.mapCoords;
        } else {
          const isSameX =
            Math.round(rectCoordinatesBefore[idx][0]) ===
            Math.round(rectCoordinatesBefore[handleIndex][0]);
          const isSameY =
            Math.round(rectCoordinatesBefore[idx][1]) ===
            Math.round(rectCoordinatesBefore[handleIndex][1]);

          return [
            isSameX ? event.mapCoords[0] : currentCoordinate[0],
            isSameY ? event.mapCoords[1] : currentCoordinate[1],
          ];
        }
      }
    ),
  ];

  const data = new ImmutableLayersData(geoJson).getLayers();

  const updatedData = {
    type: GEOJSON_TYPES.FeatureCollection,
    features: data.map((fe) =>
      fe?.properties?.id === context._rectShapeBeforeEdit.properties.id
        ? {
            ...fe,
            geometry: { ...fe.geometry, coordinates: newCoordinates },
          }
        : fe
    ),
  };

  props.onEdit({
    updatedData,
    editType: "movePosition",
    editContext: {
      featureIds: [context._rectShapeBeforeEdit.properties.id],
    },
  });
}

function updateParentFeatures(updatedFeatures: any, staticData: any) {
  let parentFeatures: any = [];
  const parentFeaturesIds: any = [];
  let ignoreChanges = false;

  if (
    updatedFeatures?.find((fe: any) => fe?.properties?.className === HOLE_TYPE)
  ) {
    updatedFeatures.forEach((fe: any) => {
      if (fe?.properties?.className === HOLE_TYPE) {
        const parentFeature = staticData?.find(
          (f: any) => f?.properties?.id === fe?.properties?.parentId
        );

        if (!parentFeaturesIds.includes(parentFeature?.properties?.id)) {
          parentFeatures.push(parentFeature);
          parentFeaturesIds.push(parentFeature?.properties?.id);
        }
      }
    });

    updatedFeatures.forEach((fe: any) => {
      if (fe?.properties?.className === HOLE_TYPE) {
        const parentFeature = parentFeatures.find(
          (f: any) => f?.properties?.id === fe?.properties?.parentId
        );

        const parentIndexHole = fe?.properties?.index + 1;

        if (parentFeature) {
          const newCoordinates = Object.assign(
            [],
            parentFeature.geometry.coordinates,
            {
              [parentIndexHole]: fe.geometry.coordinates[0],
            }
          );

          const isIntersect = turfIntersect(parentFeature, fe);
          if (!isIntersect) {
            ignoreChanges = true;
            return;
          }

          parentFeatures = parentFeatures.map((pf: any) =>
            pf?.properties?.id === parentFeature?.properties?.id
              ? {
                  ...pf,
                  geometry: {
                    ...pf.geometry,
                    coordinates: newCoordinates,
                  },
                }
              : pf
          );
        }
      }
    });

    updatedFeatures = [...updatedFeatures, ...parentFeatures];

    if (
      ignoreChanges ||
      parentFeatures.some(
        (fe: any) => fe?.type && kinks(fe)?.features?.length > 0
      )
    ) {
      return [null, null, null];
    }
  }

  return [updatedFeatures, parentFeatures, parentFeaturesIds];
}

export function handleMoveFeature(
  props: any,
  diffX: number,
  diffY: number,
  context: any
) {
  const geoJson = props.data.features;

  let updatedFeatures = context?._geometryBeforeTranslate?.features?.map(
    (fe: Feature<Polygon>) => {
      return {
        ...fe,
        geometry: {
          ...fe.geometry,
          coordinates: Array.isArray(fe.geometry.coordinates[0])
            ? fe.geometry.coordinates.map(function updatePosition(
                coord: Position | Position[]
              ): any {
                if (coord.length === 2 && !Array.isArray(coord[0])) {
                  return [coord[0] + diffX, (coord[1] as number) + diffY];
                } else {
                  return coord.map((c: any) => updatePosition(c as Position));
                }
              })
            : [
                (fe.geometry.coordinates[0] as number) + diffX,
                (fe.geometry.coordinates[1] as unknown as number) + diffY,
              ],
        },
        properties: {
          ...fe.properties,
          id: context?._isCopyMode
            ? fe.properties.id.includes("copied_")
              ? fe.properties.id
              : "copied_" + fe.properties.id
            : fe.properties.id.replace("copied_", ""),
        },
      };
    }
  );

  let [newData, staticFeatures, staticFeaturesIds] = updateParentFeatures(
    updatedFeatures,
    props.modeConfig.staticData
  );

  updatedFeatures = newData;

  if (!newData) {
    return;
  }

  const ids = updatedFeatures.map((fe: any) => fe?.properties?.id);
  const originalIds = context?._geometryBeforeTranslate?.features?.map(
    (fe: any) => fe?.properties?.id
  );

  const data = new ImmutableLayersData(geoJson)
    .getLayers()
    .filter((f) => !f.properties.id.includes("copied_"))
    .map((f) => {
      if (originalIds.includes(f?.properties?.id)) {
        return context?._geometryBeforeTranslate?.features.find(
          (uf: any) => uf?.properties?.id === f?.properties?.id
        );
      }
      return f;
    });

  const features = context?._isCopyMode
    ? [...data, ...updatedFeatures]
    : data.map((fe) => {
        if (ids.includes(fe?.properties?.id)) {
          return updatedFeatures.find(
            (efe: any) => efe?.properties?.id === fe?.properties?.id
          );
        }
        return fe;
      });

  const updatedData = {
    type: GEOJSON_TYPES.FeatureCollection,
    features,
  };

  props.onEdit({
    updatedData,
    editType: "moveFeature",
    editContext: {
      featureIds: ids,
      staticFeatures,
      staticFeaturesIds,
    },
  });

  return [updatedData, ids];
}

export function handleScaleMarkups(event: any, props: any, context: any) {
  const geoJson = props.data.features;
  const updatedGeometry = getResizeAction(event, context);

  const data = new ImmutableLayersData(geoJson).getLayers();
  const updatedData = {
    type: GEOJSON_TYPES.FeatureCollection,
    features: data.map((fe) => {
      if (fe?.properties.id === updatedGeometry?.properties?.id) {
        return updatedGeometry;
      }
      return fe;
    }),
  };

  props.onEdit({
    updatedData,
    editType: "moveFeature",
    editContext: {
      featureIds: [updatedGeometry?.properties?.id],
    },
  });
}

export function handleTranslateEdge(event: any, props: any, context: any) {
  const geoJson = props.data.features;
  const { view, colorMap } = props.modeConfig;

  let beforePTranslation;
  let afterPTranslation;

  const beforeP = [
    context._geometryBeforeTranslate.properties.positionIndexes[0],
    context._geometryBeforeTranslate.properties.positionIndexes[1] - 1,
  ];
  const afterP = context._geometryBeforeTranslate.properties.positionIndexes;

  if (context._geometryBeforeTranslate.properties.shouldPreserveLines) {
    const preserveLineData = calculateTranslationDataForLinePreserve(
      event,
      context._geometryBeforeTranslate.properties
    );
    beforePTranslation = preserveLineData.beforePTranslation;
    afterPTranslation = preserveLineData.afterPTranslation;
  } else {
    const defaultData = calculateTranslationDataForDefaultMode(
      event,
      context._geometryBeforeTranslate.properties
    );
    beforePTranslation = defaultData.beforePTranslation;
    afterPTranslation = defaultData.afterPTranslation;
  }

  const featureId = context._dragginFeature?.properties?.id;

  let updatedData = new ImmutableLayersData(geoJson)
    .replacePosition(
      featureId,
      beforeP,
      beforePTranslation,
      view,
      false,
      colorMap
    )
    .replacePosition(
      featureId,
      afterP,
      afterPTranslation,
      view,
      false,
      colorMap
    )
    .getLayers();

  let updatedFeature: any = updatedData.find(
    (fe) =>
      fe?.properties?.id ===
      props.data.features[
        context._geometryBeforeTranslate.properties.featureIndex
      ]?.properties?.id
  );

  if (
    updatedFeature?.properties?.arcs?.length > 0 &&
    [GEOJSON_TYPES.Polygon, GEOJSON_TYPES.LineString].includes(
      updatedFeature?.geometry?.type
    )
  ) {
    const coordinates =
      updatedFeature?.geometry?.type === GEOJSON_TYPES.Polygon
        ? updatedFeature?.geometry?.coordinates[0]
        : updatedFeature?.geometry?.coordinates;

    const pointsIndices = [
      beforeP[beforeP.length - 1],
      afterP[afterP.length - 1],
    ].map((idx) => (idx === coordinates.length - 1 ? 0 : idx));

    const arcIds = updatedFeature?.properties?.arcs
      ?.filter((a: any) =>
        pointsIndices.some((pointIndex) =>
          [a.startPointIdx, a.endPointIdx, ...a.ignoreIndices].includes(
            pointIndex
          )
        )
      )
      .map((a: any) => a.id);

    const featureBefore =
      props.data.features[
        context._geometryBeforeTranslate.properties.featureIndex
      ];

    updatedFeature = updateArcs(
      updatedFeature,
      featureBefore,
      arcIds,
      pointsIndices,
      context._arcs
    );

    updatedData = updatedData.map((fe) =>
      fe?.properties?.id === featureId ? updatedFeature : fe
    );
  }

  if (
    updatedFeature &&
    [GEOJSON_TYPES.Polygon, GEOJSON_TYPES.MultiPolygon].includes(
      updatedFeature?.geometry?.type
    )
  ) {
    // internal edge intersection test
    const kinkFeatures = kinks(updatedFeature)?.features;
    if (kinkFeatures?.length === 1 || kinkFeatures?.length === 2) return;
  }

  let [updatedFeatures, staticFeatures, staticFeaturesIds] =
    updateParentFeatures([updatedFeature], props.modeConfig.staticData);
  if (!updatedFeatures) {
    return;
  }

  props.onEdit({
    updatedData: {
      type: GEOJSON_TYPES.FeatureCollection,
      features: updatedData,
    },
    editType: "moveEdge",
    editContext: {
      featureIds: [
        props.data.features[
          context._geometryBeforeTranslate.properties.featureIndex
        ]?.properties?.id,
      ],
      positionIndexes:
        context._geometryBeforeTranslate.properties.positionIndexes,
      position: event.mapCoords,
      staticFeatures,
      staticFeaturesIds,
    },
  });
}

export function handleMoveVertex(event: any, props: any, context: any) {
  if (!context._dragginFeature) return;
  const isShiftKey = event?.sourceEvent?.shiftKey;

  const features = props.data.features;
  const { view, colorMap } = props.modeConfig;
  const editHandle = getPickedEditHandle(event.pointerDownPicks);

  const editHandleProperties = editHandle.properties;

  let updatedData = new ImmutableLayersData(features).getLayers();

  const featureId = context._dragginFeature?.properties?.id;
  let diffX = event.mapCoords[0] - event.pointerDownMapCoords[0];
  let diffY = event.mapCoords[1] - event.pointerDownMapCoords[1];

  const swapCoords = !!context._snapped
    ? context._snapped?.geometry?.coordinates
    : event.mapCoords;

  if (isShiftKey && !context._snapped) {
    if (Math.abs(diffX) > Math.abs(diffY)) {
      swapCoords[1] = swapCoords[1] - diffY;
    }
    if (Math.abs(diffY) > Math.abs(diffX)) {
      swapCoords[0] = swapCoords[0] - diffX;
    }
  }

  if (
    context._isArcMode &&
    !editHandle?.properties?.arcIds &&
    [GEOJSON_TYPES.Polygon, GEOJSON_TYPES.LineString].includes(
      context._dragginFeature?.geometry?.type
    )
  ) {
    const updatedFeature = getAppendedArcFeature(
      context,
      editHandleProperties,
      swapCoords
    );

    if (updatedFeature) {
      updatedData = updatedData.map((fe) =>
        fe?.properties?.id === featureId ? updatedFeature : fe
      );
      props.onEdit({
        updatedData: {
          type: GEOJSON_TYPES.FeatureCollection,
          features: updatedData.map((fe) =>
            fe?.properties?.id === featureId ? updatedFeature : fe
          ),
        },
        editType: "movePosition",
        editContext: {
          featureIds: [
            props.data.features[editHandleProperties.featureIndex]?.properties
              ?.id,
          ],
          positionIndexes: editHandleProperties.positionIndexes,
          position: event.mapCoords,
        },
      });
      return;
    }
  } else {
    updatedData = updatedData.map((fe) =>
      fe?.properties?.id === featureId ? context._dragginFeature : fe
    );
  }

  if (
    (event.sourceEvent.ctrlKey || event.sourceEvent.metaKey) &&
    context._closestPoints.length > 0
  ) {
    context._closestPoints.forEach((pt: Feature<Point>) => {
      updatedData = new ImmutableLayersData(updatedData)
        .replacePosition(
          pt.properties.id,
          pt.properties.positionIndexes,
          swapCoords,
          view
        )
        .getLayers();
    });
  }

  if (editHandleProperties?.featureIndex !== null && context._dragginFeature) {
    updatedData = new ImmutableLayersData(updatedData)
      .replacePosition(
        featureId,
        editHandleProperties.positionIndexes,
        swapCoords,
        view,
        false,
        colorMap
      )
      .getLayers();
  }

  let updatedFeature: any = updatedData.find(
    (fe) => fe?.properties?.id === featureId
  );

  if (editHandleProperties?.arcIds?.length > 0) {
    const originalFeature = features[editHandleProperties.featureIndex];
    const arcPointIndex =
      editHandleProperties.positionIndexes[
        editHandleProperties.positionIndexes.length - 1
      ];

    updatedFeature = updateArcs(
      updatedFeature,
      originalFeature,
      editHandleProperties.arcIds,
      [arcPointIndex],
      context._arcs
    );

    updatedData = updatedData.map((fe) =>
      fe?.properties?.id === featureId ? updatedFeature : fe
    );
  }

  if (
    updatedFeature &&
    [GEOJSON_TYPES.Polygon, GEOJSON_TYPES.MultiPolygon].includes(
      updatedFeature?.geometry?.type
    )
  ) {
    const kinkFeatures = kinks(updatedFeature)?.features;
    if (kinkFeatures?.length === 1 || kinkFeatures?.length === 2) return;
  }

  // if (updatedFeature.geometry.type === GEOJSON_TYPES.Polygon) {
  //   getEditSnappedAngles(updatedFeature, editHandleProperties.positionIndexes);
  // }

  let [updatedFeatures, staticFeatures, staticFeaturesIds] =
    updateParentFeatures([updatedFeature], props.modeConfig.staticData);
  if (!updatedFeatures) {
    return;
  }

  context._updatedData = updatedFeatures;

  props.onEdit({
    updatedData: {
      type: GEOJSON_TYPES.FeatureCollection,
      features: updatedData,
    },
    editType: "movePosition",
    editContext: {
      featureIds: [
        props.data.features[editHandleProperties.featureIndex]?.properties?.id,
      ],
      positionIndexes: editHandleProperties.positionIndexes,
      position: event.mapCoords,
      staticFeatures,
      staticFeaturesIds,
    },
  });
}

function updateArcs(
  feature: any,
  uneditedFeature: any,
  arcIds: any[] = [],
  updateCoords: any[] = [],
  arcs: any[] = []
) {
  if (
    !feature?.properties?.arcs ||
    feature.properties?.arcs?.length === 0 ||
    arcIds?.length === 0
  )
    return feature;

  if (
    ![GEOJSON_TYPES.LineString, GEOJSON_TYPES.Polygon].includes(
      feature.geometry.type
    )
  ) {
    return feature;
  }

  const is2pointsMode =
    feature?.properties?.arcs?.length === 1 &&
    [MODE_SPHERE, MODE_RECTANGLE].includes(feature?.properties?.arcs[0]?.type);

  let coordinates =
    feature?.geometry?.type === GEOJSON_TYPES.Polygon
      ? feature?.geometry?.coordinates[0]
      : feature?.geometry?.coordinates;

  let originalCoordinates =
    uneditedFeature?.geometry?.type === GEOJSON_TYPES.Polygon
      ? uneditedFeature?.geometry?.coordinates[0]
      : uneditedFeature?.geometry?.coordinates;

  for (const arc of feature?.properties?.arcs) {
    if (arcIds.includes(arc?.id)) {
      const referenceArc = arcs.find((a) => a?.id === arc?.id);
      const controlIndex =
        arc?.ignoreIndices[Math.ceil((arc.ignoreIndices.length - 1) / 2)];

      const startCoords = updateCoords.includes(arc.startPointIdx)
        ? coordinates[arc.startPointIdx]
        : referenceArc?.startPoint || originalCoordinates[arc.startPointIdx];
      const endCoords = updateCoords.includes(arc.endPointIdx)
        ? coordinates[arc.endPointIdx]
        : referenceArc?.endPoint || originalCoordinates[arc.endPointIdx];
      const controlCoords = updateCoords.includes(controlIndex)
        ? coordinates[controlIndex]
        : referenceArc?.controlPoint || originalCoordinates[controlIndex];

      const nbOfSegments = arc.ignoreIndices.length + 1;

      const arcFn = getArcFunction(arc?.type);

      const arcCoordinates = arcFn(
        startCoords,
        controlCoords,
        endCoords,
        nbOfSegments,
        false
      );
      if (is2pointsMode) {
        coordinates = arcCoordinates;
      } else {
        if (arcCoordinates.length === 0) return uneditedFeature;

        for (const [idx, ignoreIdx] of arc.ignoreIndices.entries()) {
          coordinates[ignoreIdx] = arcCoordinates[idx];
        }
      }
    }
  }

  if (feature?.geometry?.type === GEOJSON_TYPES.Polygon) {
    feature.geometry.coordinates[0] = coordinates;
  } else {
    feature.geometry.coordinates = coordinates;
  }

  return feature;
}

function getAppendedArcFeature(
  context: any,
  editHandleProperties: any,
  swapCoords: any
) {
  const type = context._dragginFeature?.geometry?.type;
  const coordinates =
    type === GEOJSON_TYPES.Polygon
      ? context._dragginFeature?.geometry?.coordinates[0]
      : context._dragginFeature?.geometry?.coordinates;

  const controlIndex =
    editHandleProperties.positionIndexes[
      editHandleProperties.positionIndexes.length - 1
    ];

  if (
    type === GEOJSON_TYPES.LineString ||
    (type === GEOJSON_TYPES.Polygon && controlIndex > 0)
  ) {
    const controlCoords = swapCoords;
    const startCoords =
      type === GEOJSON_TYPES.Polygon
        ? coordinates[controlIndex - 1]
        : coordinates[0];
    const endCoords =
      type === GEOJSON_TYPES.Polygon
        ? coordinates[controlIndex + 1]
        : coordinates[coordinates.length - 1];

    const arcFn = getArcFunction(ARC_MODE_CIRCLE);
    const arcCoordinates = arcFn(startCoords, controlCoords, endCoords, 10);

    if (arcCoordinates.length > 0) {
      const newCoordinates =
        type === GEOJSON_TYPES.Polygon
          ? [
              ...coordinates.slice(0, controlIndex),
              ...arcCoordinates,
              ...coordinates.slice(controlIndex + 2),
            ]
          : [startCoords, ...arcCoordinates];

      const newGeometry = {
        ...context._dragginFeature.geometry,
        coordinates:
          type === GEOJSON_TYPES.LineString
            ? newCoordinates
            : [
                newCoordinates,
                ...context._dragginFeature.geometry.coordinates.slice(1),
              ],
      };

      const startPointIdx =
        type === GEOJSON_TYPES.Polygon ? controlIndex - 1 : 0;
      const endPointIdx =
        type === GEOJSON_TYPES.Polygon
          ? controlIndex + arcCoordinates.length - 1
          : arcCoordinates.length;

      const ignoreIndices = new Array(arcCoordinates.length - 1)
        .fill(0)
        .map((_, i) =>
          type === GEOJSON_TYPES.Polygon ? controlIndex + i : i + 1
        );

      const arc = {
        id: generateId(),
        type: context._tempArcMode,
        controlPoint: controlCoords,
        endPointIdx,
        startPointIdx,
        ignoreIndices,
      };

      const arcs =
        type === GEOJSON_TYPES.LineString
          ? [arc]
          : [
              ...(context._dragginFeature.properties.arcs || []).map((a: any) =>
                a.startPointIdx >= startPointIdx
                  ? {
                      ...a,
                      startPointIdx:
                        a.startPointIdx + arcCoordinates.length - 2,
                      endPointIdx: a.endPointIdx + arcCoordinates.length - 2,
                      ignoreIndices: a.ignoreIndices.map(
                        (i: any) => i + arcCoordinates.length - 2
                      ),
                    }
                  : a
              ),
              arc,
            ];

      return {
        ...context._dragginFeature,
        geometry: newGeometry,
        properties: {
          ...context._dragginFeature.properties,
          arcs,
        },
      };
    }
  }

  return context._dragginFeature;
}

function getFeaturesArcs(features: any[], indices: any[]): any[] {
  const arcs: any[] = [];

  for (const index of indices) {
    const feature = features[index];

    if (
      [GEOJSON_TYPES.Polygon, GEOJSON_TYPES.LineString].includes(
        feature?.geometry?.type
      )
    ) {
      const coordinates =
        feature?.geometry?.type === GEOJSON_TYPES.Polygon
          ? feature?.geometry?.coordinates[0]
          : feature?.geometry?.coordinates;

      if (feature?.properties?.arcs?.length > 0) {
        feature?.properties?.arcs.forEach((arc: any) => {
          const controlPointIdx =
            arc?.ignoreIndices[Math.ceil((arc.ignoreIndices.length - 1) / 2)];

          arcs.push({
            ...arc,
            startPoint: coordinates[arc.startPointIdx],
            endPoint: coordinates[arc.endPointIdx],
            controlPoint: coordinates[controlPointIdx],
          });
        });
      }
    }
  }

  return arcs;
}

function getGuideLines(featureId: string, coords: number[][]) {
  return {
    type: GEOJSON_TYPES.Feature,
    properties: {
      id: featureId,
    },
    geometry: {
      type: GEOJSON_TYPES.LineString,
      coordinates: coords,
    },
  };
}

function getCopyModeBboxes(
  boundingAll: number[],
  translateX: number,
  translateY: number
): Feature[] {
  const [minX, minY, maxX, maxY] = boundingAll;
  const width = maxX - minX;
  const height = maxY - minY;

  const bboxCoordinates = [
    [minX, minY],
    [minX + width, minY],
    [minX + width, minY + height],
    [minX, minY + height],
    [minX, minY],
  ];
  const bboxCoordinatesTranslated = bboxCoordinates.map((c) => [
    c[0] + translateX,
    c[1] + translateY,
  ]);

  const bboxLineFeature = getGuideLines("bboxLine", bboxCoordinates);
  const bboxLineFeatureTranslated = getGuideLines(
    "bboxLineTranslated",
    bboxCoordinatesTranslated
  );

  return [bboxLineFeature, bboxLineFeatureTranslated];
}

function getTranslateXLines(
  boundingAll: number[],
  translateX: number,
  translateY: number
) {
  if (Math.abs(translateX) < 2) return [];

  const [minX, minY, maxX, maxY] = boundingAll;
  const width = maxX - minX;
  const height = maxY - minY;
  const centerY = minY + height / 2;

  let y = centerY;
  let x1 = translateX > 0 ? maxX : minX;
  let x2 = (translateX > 0 ? maxX : minX) + translateX;
  const isColliding = translateX < width / 2 && translateX > -width / 2;

  if (translateX > 0) {
    x2 -= width;
  } else {
    x2 += width;
  }

  if (isColliding) {
    if (translateX > 0) {
      x2 += width;
    } else {
      x2 -= width;
    }
  }

  if (!isColliding && (translateY > height / 2 || translateY < -height / 2)) {
    if (translateX > 0) {
      x2 += width / 2;
    } else {
      x2 -= width / 2;
    }
  }

  const translateXMainLine = getGuideLines("translateXLine", [
    [x1, y],
    [x2, y],
  ]);

  let y_s = y + translateY + (translateY > 0 ? -height / 2 : height / 2);

  const translateXSecaondaryLine = getGuideLines("translateXLineSecondary", [
    [x2, y],
    [x2, y_s],
  ]);

  return [translateXMainLine, translateXSecaondaryLine];
}

function getTranslateYLines(
  boundingAll: number[],
  translateX: number,
  translateY: number
) {
  if (Math.abs(translateY) < 2) return [];

  const [minX, minY, maxX, maxY] = boundingAll;
  const width = maxX - minX;
  const height = maxY - minY;
  const centerX = minX + width / 2;

  let x = centerX;
  let y1 = translateY > 0 ? maxY : minY;
  let y2 = (translateY > 0 ? maxY : minY) + translateY;
  const isColliding = translateY < height / 2 && translateY > -height / 2;

  if (translateY > 0) {
    y2 -= height;
  } else {
    y2 += height;
  }

  if (isColliding) {
    if (translateY > 0) {
      y2 += height;
    } else {
      y2 -= height;
    }
  }

  if (!isColliding && (translateX > width / 2 || translateX < -width / 2)) {
    if (translateY > 0) {
      y2 += height / 2;
    } else {
      y2 -= height / 2;
    }
  }

  const translateYMainLine = getGuideLines("translateYLine", [
    [x, y1],
    [x, y2],
  ]);

  let x_s = x + translateX + (translateX > 0 ? -width / 2 : width / 2);
  const translateYSecaondaryLine = getGuideLines("translateYLineSecondary", [
    [x, y2],
    [x_s, y2],
  ]);

  return [translateYMainLine, translateYSecaondaryLine];
}

export function getCopyModeGuides(context: any): any[] {
  if (
    !context?._geometryBeforeTranslate?.features ||
    context?._geometryBeforeTranslate?.features?.length === 0
  )
    return [];

  const pointFeatures = (
    context?._geometryBeforeTranslate?.features || []
  ).filter((f: Feature) => f?.geometry?.type === GEOJSON_TYPES.Point).length;

  const isValid = !(
    pointFeatures === 1 &&
    context?._geometryBeforeTranslate?.features?.length === 1
  );

  if (isValid) {
    const translateX = context?._copyModeDetails?.diffX || 0;
    const translateY = context?._copyModeDetails?.diffY || 0;

    const boundingAll = bbox(context?._geometryBeforeTranslate);

    const [bboxLineFeature, bboxLineFeatureTranslated] = getCopyModeBboxes(
      boundingAll,
      translateX,
      translateY
    );
    const translateXLines = getTranslateXLines(
      boundingAll,
      translateX,
      translateY
    );
    const translateYLines = getTranslateYLines(
      boundingAll,
      translateX,
      translateY
    );

    return [
      bboxLineFeature,
      bboxLineFeatureTranslated,
      ...(translateXLines || []),
      ...(translateYLines || []),
    ];
  }

  return [];
}

function getXTooltipCoords(
  boundingAll: number[],
  translateX: number,
  translateY: number
): number[] | null {
  if (Math.abs(translateX) < 2) return null;

  const [minX, minY, maxX, maxY] = boundingAll;
  const width = maxX - minX;
  const height = maxY - minY;
  const centerY = minY + height / 2;

  let y = centerY;
  let x1 = translateX > 0 ? maxX : minX;
  let x2 = (translateX > 0 ? maxX : minX) + translateX;
  const isColliding = translateX < width / 2 && translateX > -width / 2;

  if (translateX > 0) {
    x2 -= width;
  } else {
    x2 += width;
  }

  if (isColliding) {
    if (translateX > 0) {
      x2 += width;
    } else {
      x2 -= width;
    }
  }

  if (!isColliding && (translateY > height / 2 || translateY < -height / 2)) {
    if (translateX > 0) {
      x2 += width / 2;
    } else {
      x2 -= width / 2;
    }
  }

  return [(x1 + x2) / 2, y];
}
function getYTooltipCoords(
  boundingAll: number[],
  translateX: number,
  translateY: number
): number[] | null {
  if (Math.abs(translateY) < 2) return null;

  const [minX, minY, maxX, maxY] = boundingAll;
  const width = maxX - minX;
  const height = maxY - minY;
  const centerX = minX + width / 2;

  let x = centerX;
  let y1 = translateY > 0 ? maxY : minY;
  let y2 = (translateY > 0 ? maxY : minY) + translateY;
  const isColliding = translateY < height / 2 && translateY > -height / 2;

  if (translateY > 0) {
    y2 -= height;
  } else {
    y2 += height;
  }

  if (isColliding) {
    if (translateY > 0) {
      y2 += height;
    } else {
      y2 -= height;
    }
  }

  if (!isColliding && (translateX > width / 2 || translateX < -width / 2)) {
    if (translateY > 0) {
      y2 += height / 2;
    } else {
      y2 -= height / 2;
    }
  }

  return [x, (y1 + y2) / 2];
}

export function getCopyDistanceTooltip(props: any, context: any): any[] {
  const { modeConfig }: any = props;
  if (modeConfig?.guideType === GEOJSON_TYPES.markup) return [];
  if (
    !context?._geometryBeforeTranslate?.features ||
    context?._geometryBeforeTranslate?.features?.length === 0
  )
    return [];

  const pointFeatures = (
    context?._geometryBeforeTranslate?.features || []
  ).filter((f: Feature) => f?.geometry?.type === GEOJSON_TYPES.Point).length;

  const isValid = !(
    pointFeatures === 1 &&
    context?._geometryBeforeTranslate?.features?.length === 1
  );

  if (isValid) {
    const translateX = context?._copyModeDetails?.diffX || 0;
    const translateY = context?._copyModeDetails?.diffY || 0;
    const boundingAll = bbox(context?._geometryBeforeTranslate);
    const xCoords = getXTooltipCoords(boundingAll, translateX, translateY);
    const yCoords = getYTooltipCoords(boundingAll, translateX, translateY);

    const distanceX = calculateDistanceForTooltip({
      positionA: [0, 0],
      positionB: [translateX, 0],
      modeConfig,
    });

    const distanceY = calculateDistanceForTooltip({
      positionA: [0, 0],
      positionB: [0, translateY],
      modeConfig,
    });

    return [
      distanceX && xCoords
        ? {
            position: xCoords,
            text:
              " " + prettyInches(distanceX, true, modeConfig.useMetrics) + " ",
            size: 10,
            anchor: "middle",
            baseline: "top",
            offset: [0, 8],
            color: RGBA_COLORS.WHITE,
            backgroundColor: RGBA_COLORS.DEFAULT,
          }
        : null,
      distanceY && yCoords
        ? {
            position: yCoords,
            text:
              " " + prettyInches(distanceY, true, modeConfig.useMetrics) + " ",
            size: 10,
            anchor: "start",
            baseline: "center",
            offset: [8, 0],
            color: RGBA_COLORS.WHITE,
            backgroundColor: RGBA_COLORS.DEFAULT,
          }
        : null,
    ].filter(Boolean);
  }

  return [];
}
