import styled from "styled-components";

import * as TooltipPrimitive from "@radix-ui/react-tooltip";

import { CSS } from "../../../consts/style";

export const StyledTooltipContent = styled(TooltipPrimitive.Content as any)`
  color: ${CSS.BLACK};
  background-color: ${CSS.GRAY1};
  box-shadow: ${CSS.BOX_SHADOW};

  padding: 5px 10px;
  max-width: 300px;
  pointer-events: none;
  z-index: ${CSS.Z_INDEX_MAX};
  font-size: ${CSS.FONT_DEFAULT};
  border-radius: ${CSS.BORDER_RADIUS};
`;
