import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  createContext,
} from "react";

import { EVENT_TYPES } from "sf/consts/keysAndMouse";

export const InputContext = createContext({});

export default function InputProvider({ children }) {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [keyCount, setKeyCount] = useState(0);
  const [pressedKeysMap, setPressedKeysMap] = useState(new Map());

  const isKeyDown = useMemo(() => !!keyCount, [keyCount]);

  const handleMouseDown = (ev) => {
    if ([0, 2].includes(ev.button)) {
      setIsMouseDown(true);
    }
  };

  const handleMouseUp = () => setIsMouseDown(false);

  const handleKeyDown = useCallback(
    (e) => {
      const newMap = new Map(pressedKeysMap);
      newMap.set(e.key, true);
      setPressedKeysMap(newMap);
      setKeyCount(keyCount + 1);
    },
    [keyCount, pressedKeysMap]
  );

  const handleKeyUp = useCallback(
    (e) => {
      const newMap = new Map(pressedKeysMap);
      newMap.set(e.key, false);
      setPressedKeysMap(newMap);

      setKeyCount(Math.max(keyCount - 1, 0));
    },
    [keyCount, pressedKeysMap]
  );

  const isKeyPressed = useCallback(
    (key) => pressedKeysMap.get(key),
    [pressedKeysMap]
  );

  useEffect(() => {
    window.addEventListener(EVENT_TYPES.MOUSE_DOWN, handleMouseDown);
    window.addEventListener(EVENT_TYPES.MOUSE_UP, handleMouseUp);

    window.addEventListener(EVENT_TYPES.KEY_DOWN, handleKeyDown);
    window.addEventListener(EVENT_TYPES.KEY_UP, handleKeyUp);

    return () => {
      window.removeEventListener(EVENT_TYPES.MOUSE_DOWN, handleMouseDown);
      window.removeEventListener(EVENT_TYPES.MOUSE_UP, handleMouseUp);

      window.removeEventListener(EVENT_TYPES.KEY_DOWN, handleKeyDown);
      window.removeEventListener(EVENT_TYPES.KEY_UP, handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp, handleMouseUp, handleMouseDown]);

  const value = useMemo(
    () => ({ isMouseDown, isKeyDown, isKeyPressed }),
    [isMouseDown, isKeyDown, isKeyPressed]
  );

  return (
    <InputContext.Provider value={value}>{children}</InputContext.Provider>
  );
}
