import React, { useEffect } from "react";

import Notification from "./Notification";
import { Separator } from "sf/components/Styled";
import { NoticationsListContainer } from "../style";

export default function Notifications({
  isPage = false,
  isLoading,
  notifications,
  markAsRead,
}) {
  useEffect(() => {
    if (isPage) {
      markAsRead();
    }
  }, [isPage]);
  return (
    <NoticationsListContainer>
      <Separator height="10px" />
      {notifications.map((notification) => (
        <Notification
          isPage={isPage}
          isLoading={isLoading}
          key={notification.id}
          markAsRead={markAsRead}
          notification={notification}
        />
      ))}

      <Separator height="40px" />
    </NoticationsListContainer>
  );
}
