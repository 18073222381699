import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import useEventListener from "@use-it/event-listener";

import {
  DEFAULT_SHORTCUTS_STATE,
  shortcutsGlobalState,
} from "src/state/shortcutsStore";

export default function useShortcuts() {
  const [shiftCtrl, setShiftCtrl] = useState({
    ctrlOrCmdKey: "",
    shiftKey: "",
  });

  const [shortcutsState, set] = useRecoilState(shortcutsGlobalState);
  const ref = useRef(null);

  function close() {
    set({
      ...shortcutsState,
      visible: false,
    });

    if (location?.search?.includes("shortcuts")) {
      let url = window.location.href;
      url = url.replace(/\?shortcuts$/, "");

      history.pushState(null, null, url);
    }
  }

  function open() {
    set({
      ...shortcutsState,
      visible: true,
    });
  }

  useEventListener(
    "keydown",
    (e: any) => {
      const ctrlOrCmd = e.metaKey || e.ctrlKey || e.altKey;

      if (ctrlOrCmd && e.keyCode === 55) {
        e.preventDefault();
        set({
          ...shortcutsState,
          visible: true,
        });
        return;
      }

      const ctrlOrCmdKey =
        e.metaKey || e.ctrlKey || e.altKey ? " + CONTROL" : "";
      const shiftKey = e.shiftKey ? " + SHIFT" : "";

      setShiftCtrl({
        ctrlOrCmdKey,
        shiftKey,
      });

      set({
        ...shortcutsState,
        pressedBtn: `${e.key}${shiftKey || ctrlOrCmdKey}`.toUpperCase(),
      });
    },
    document.body
  );

  useEventListener(
    "keyup",
    (e: any) => {
      set({
        ...shortcutsState,
        pressedBtn: "",
      });
      setShiftCtrl({
        ctrlOrCmdKey: "",
        shiftKey: "",
      });
    },
    document.body
  );

  useEventListener(
    "click",
    (e: any) => {
      const doubleClick =
        e.pointerType === "mouse" && e.detail === 2 ? "DOUBLE_CLICK" : "";
      const singleClick =
        e.pointerType === "mouse" && e.detail === 1 ? "SINGLE_CLICK" : "";

      const shiftClick = `${singleClick + shiftCtrl.shiftKey}`;

      set({
        ...shortcutsState,
        pressedBtn: `${shiftClick || doubleClick}`.toUpperCase(),
      });
    },
    document.body
  );

  useEffect(() => {
    return () => set(DEFAULT_SHORTCUTS_STATE);
  }, []);

  useEffect(() => {
    if (location?.search?.includes("shortcuts")) {
      set({
        ...shortcutsState,
        visible: true,
      });
    }
  }, [location?.search]);

  return {
    ref,
    visible: shortcutsState.visible,
    shortcutsState,

    close,
    open,
  };
}
